<template>
    <!-- Skeleton loader -->
    <div class="apaympro-wrapper animated fadeIn container mb-5" :hidden="formLoader">
        <div id="payment-page-pay" class="apaympro-page">
            <div class="apaympro-header">
                <div class="company-logo" style="justify-content: center; position: relative; top: 30px;">
                    <skeleton-loader-vue
                        :width="120"
                        :height="80"
                        :rounded="true"
                        :radius="10"
                        animation="wave"
                    />
                </div>
                <div class="page-info" style="margin-top: -10px;">
                    <h3 class="page-name"
                        style="
                            display: flex;
                            justify-content: center;
                            position: relative;
                            top: 32px;
                        "
                    >
                        <skeleton-loader-vue
                            :width="180"
                            :height="15"
                            :rounded="false"
                            :radius="5"
                            animation="wave"
                        />
                    </h3>
                </div>
                <div class="page-description mb-3" style="display: flex; justify-content: center; position: relative; top: 30px;">
                    <span>
                        <skeleton-loader-vue
                            :width="200"
                            :height="45"
                            :rounded="false"
                            :radius="5"
                            animation="wave"
                        />
                    </span>
                </div>
            </div>

            <div class="body mt-5 mb-5">

                <form class="payment-form">

                    <div class="element-pair">
                        <div class="element-label mb-2">
                            <skeleton-loader-vue
                                :width="100"
                                :height="20"
                                :rounded="true"
                                :radius="5"
                                animation="wave"
                            /> 
                        </div>
                        <div class="element-pair-container">
                            <div class="element-wrap element-wrap-on-right">
                                <skeleton-loader-vue
                                    :width="230"
                                    :height="30"
                                    :rounded="true"
                                    :radius="5"
                                    animation="wave"
                                /> 
                            </div>
                            <div
                                class="element-wrap element-wrap-on-left"
                            >
                                <skeleton-loader-vue
                                    :width="100"
                                    :height="30"
                                    :rounded="true"
                                    :radius="5"
                                    animation="wave"
                                />  
                            </div>
                            
                        </div>
                    </div>

                    <div class="element-pair mt-5 mb-5">
                        <div class="element-label mb-2">
                            <skeleton-loader-vue
                                :width="100"
                                :height="20"
                                :rounded="true"
                                :radius="5"
                                animation="wave"
                            /> 
                        </div>
                        <div class="element-pair-container">
                            <div class="element-wrap element-wrap-on-right">
                                <skeleton-loader-vue
                                    :width="100"
                                    :height="30"
                                    :rounded="true"
                                    :radius="5"
                                    animation="wave"
                                /> 
                            </div>
                            <div
                                class="element-wrap element-wrap-on-left"
                            >
                                <skeleton-loader-vue
                                    :width="230"
                                    :height="30"
                                    :rounded="true"
                                    :radius="5"
                                    animation="wave"
                                /> 
                            </div>
                            
                        </div>
                    </div>

                    <div class="element-wrap element-wrap-for-submit mt-5">
                        <skeleton-loader-vue
                            :width="340"
                            :height="40"
                            :rounded="true"
                            :radius="5"
                            animation="wave"
                        /> 
                    </div>

                </form>

            </div>

        </div>
    </div>
    <!-- Skeleton loader -->
</template>

<script>
    import VueSkeletonLoader from 'skeleton-loader-vue';
    export default {
        components: {
            'skeleton-loader-vue': VueSkeletonLoader,
        },
        props: [ 
            'formLoader', 
        ],
    }
</script>