<template>
    <div id="apaympro-footer" class="apaympro-footer animated fadeIn mb-5">

        <!-- Download APaym -->
        <div class="mb-5">

            <div :class="hideRecu == true ? 'hidden mb-3' : 'mb-3' ">
                <strong> {{ $t('download_apaym.value') }} </strong>
            </div>

            <div :class=" hideRecu == true ? 'row mb-3 hidden' : 'row mb-3' " style="margin: 0 !important; position: relative; top: 10px;">

                <div class="col-3 col-lg-3 col-md-3 col-sm-3 col-xs-3"></div>
                <div class="col-2 col-lg-2 col-md-2 col-sm-2 col-xs-2">
                    <a href="https://apps.apple.com/app/id1211381693" target="_blank" class="btn btn-github btn-floating btn-link">
                        <img src="../assets/img/app-store.png" alt="AppStore logo" width="28">
                    </a>
                </div>
                <div class="col-2 col-lg-2 col-md-2 col-sm-2 col-xs-2">
                    <a href="https://play.google.com/store/apps/details?id=weblogy.com.carteabidjan" target="_blank" class="btn btn-github btn-floating btn-link">
                        <img src="../assets/img/playstore.png" alt="PlayStore icon" width="28">
                    </a>
                </div>
                <div class="col-2 col-lg-2 col-md-2 col-sm-2 col-xs-2">
                    <a href="https://appgallery.huawei.com/app/C104470711" target="_blank" class="btn btn-github btn-floating btn-link">
                        <img src="../assets/img/appgallery-logo.png" alt="AppGallery logo" width="28">
                    </a>
                </div>
                <div class="col-3 col-lg-3 col-md-3 col-sm-3 col-xs-3"></div>

            </div>

            <!-- <div class="row display-for-small-only" style="margin: 0 !important;">
                <div class="col-md-4 mt-2">
                    <a href="https://apps.apple.com/app/id1211381693" target="_blank" >
                        <img src="../assets/img/Logo-App-Store.png" alt="AppStore icon" width="120" >
                    </a>
                </div>
                <div class="col-md-4 mt-2">
                    <a href="https://play.google.com/store/apps/details?id=weblogy.com.carteabidjan" target="_blank" >
                        <img src="../assets/img/logo-plstore.png" alt="PlayStore icon" width="120" >
                    </a>
                </div>
                <div class="col-md-4 mt-2">
                    <a href="https://appgallery.huawei.com/app/C104470711" target="_blank" >
                        <img src="../assets/img/appgallery.png" alt="Huawei icon" width="120" >
                    </a>
                </div>

            </div> -->

        </div>
        <!-- Download APaym -->

        <div class="text-primary font-14" style="position: relative; line-height: 1.7rem;">
            {{ $t('text_sav_0.value') }}  <br>
            <a v-if="hide_wgy_infos !== '1' " class="btn-link btn-whatsapp mt-3" href="https://api.whatsapp.com/send/?phone=2250554175175&text&app_absent=0"> 
                <i class="fab fa-whatsapp"></i> {{ $t('text_sav_1.value') }} {{ $t('text_sav_2.value') }} +225 05 54 175 175
            </a> 
            <a v-if="hide_wgy_infos == '1' " class="btn-link btn-whatsapp mt-3" href="https://api.whatsapp.com/send/?phone=2250767676699&text&app_absent=0"> 
                <i class="fab fa-whatsapp"></i> {{ $t('text_sav_1.value') }} {{ $t('text_sav_2.value') }} +225 07 67 676 699
            </a>
        </div>

        <a href="javascript:void(0);" class="mb-3 mt-4" v-if="hide_wgy_infos !== '1' ">
            <img alt="APAYM Pro secured badge" class="mt-3" src="../assets/Secured-by-apaym-pci-dss.png" style="width: 230px;"/>
        </a>
        <span class="text-primary">
            <br>
            Version 5.5
        </span>
        <br><br>
        <br><br>
    </div>
</template>

<script>

    export default {
        components: {
        },
        data() {
            return {
            }
        },
        methods: {
        },
        mounted(){
        },
        props: [ 
            'hide_wgy_infos', 'hideRecu'
        ],
    }
</script>