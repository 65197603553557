<template>
    <div class="apaympro-wrapper animated fadeIn container" :hidden="!showErrorPage">
        <!-- Loader -->
        <loading
            :active.sync="isLoading"
            :can-cancel="false"
            :on-cancel="onCancel"
            :is-full-page="fullPage"
            loader="bars"
            :opacity="1"
        ></loading>

        <div class="apaympro-wrapper animated fadeIn container" v-if="showErrorPage">
            <div class="apaympro-page">
                <div class="apaympro-header">
                    <div class="page-info mt-3 mb-3">
                        <img src="../assets/apaym-pay.png" width="150" alt="Logo APaym Pro" />
                    </div>
                    <div class="mt-3">
                        <form 
                            class="search-top-bar"
                            @submit.prevent="search"
                        >
                            <input 
                                class="form-control search-input" type="text" 
                                :placeholder="$t('text_search_merchant.value')" 
                                v-model="searchMerchant"  
                            >
                            
                        </form>
                        <div style="display: flex; justify-content: center;" class="mb-5">
                            <a 
                                href="javascript:;" @click="search" 
                                style="
                                    position: relative;
                                    font-size: 20px;
                                    color: rgb(255, 255, 255);
                                    cursor: pointer;
                                    font-weight: 900;
                                    background: rgb(0, 0, 0);
                                    width: 100px;
                                    border-radius: 32px;
                                    height: 38px;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                "
                            >
                                <i 
                                    class="ft-search" 
                                ></i>
                            </a>
                        </div>

                        <div class="mt-4 mb-5" v-if="merchantList.length > 0">
                            <ul 
                                class="list-unstyled media-list-divider"
                                :style=" merchantList.length > 5 ? 'height: 660px; overflow-y: scroll; overflow-x: hidden;' : 'height: auto; overflow-y: scroll; overflow-x: hidden;'"
                            >
                                <li 
                                    class="media py-3 mb-3"
                                    v-for="merchant in merchantList"
                                    :key="merchant.id"
                                >
                                
                                    <img src="../assets/img/building_Artboard.png" v-if="merchant.type !== 'SP' && merchant.partner !== '2'" class="fas fa-building mr-3 rounded-circle align-self-center" width="45" style="margin-top: 6px;" />
                                    <img src="../assets/img/poeple_Artboard.png" v-if="merchant.type == 'SP' && merchant.partner !== '2'" class="fas fa-building mr-3 rounded-circle align-self-center" width="45" style="margin-top: 6px;" />
                                    <img src="../assets/img/yango.png" v-if="merchant.partner == '2'" class="fas fa-building mr-3 rounded-circle align-self-center" width="45" style="margin-top: 6px;" />

                                    <div class="media-body">
                                        <div class="d-flex justify-content-between" style="align-items: center;">
                                            <div @click="loadPage(merchant)" style="cursor:pointer;" >
                                                <h6 class="mb-1 font-16 text-left" style="text-transform: uppercase;">
                                                    {{ merchant.merchantName }} <img src="../assets/img/badge-check.png" v-if="merchant.verified == '1'" width="15" style="margin-top: -3px;" />
                                                </h6>
                                                <p class="text-muted text-left font-12">
                                                    https://pay.apaym.com/{{ merchant.url }}
                                                </p>
                                            </div>
                                            <div v-if="deleteFav" class="text-danger text-left ml-2" style="cursor:pointer;">
                                                <i @click="removeFav(merchant.id)" class="fas fa-trash"></i>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import axios from 'axios';
    import { apiUrl } from '../constants/config';
    import { trim } from 'jquery';
    import { sweetAlert } from "../constants/functions";
    import Loading from 'vue-loading-overlay';

    export default {
        components: {
            Loading,
        },
        data() {
            return {
                merchantList: [],
                searchMerchant: "",
                isLoading: false,
                fullPage: true,
                deleteFav: false
            }
        },

        methods: {

            onCancel() {
                console.log('Loader dismiss') ;
            },
            // Rechercher un marchand
            search(){

                if ( this.searchMerchant.replace(/\s/g, "") == "" ){
                    this.merchantList = [] ;
                    return false ;
                }

                // Init Payment process
                this.isLoading = true;
                this.deleteFav = false ;
                let dataSend = new FormData();
                dataSend.append("search", trim(this.searchMerchant)); 

                axios.post(apiUrl + 'api/paiement-marchand/rechercher-marchand',
                    dataSend
                ).then(response => {

                    this.isLoading = false;
                    this.merchantList = [] ;

                    if ( response.data.code == 1 ) {

                        for ( let [key, rep] of Object.entries(response.data.merchant) ) {

                            this.merchantList.push({
                                id: key,
                                merchantName: rep.merchantName,
                                url: rep.url,
                                type: rep.type,
                                verified: rep.verified,
                                partner: rep.partenaire
                            }) ;
                            
                        }

                        return false;
                    }

                }).catch(error => {

                    this.isLoading = false;
                    console.log(error);
                    sweetAlert(this.$swal, true, 'top-end', 'error', 'Erreur', "Une erreur est survenue, veuillez réessayer plus tard");
                    
                });
            },

            loadPage(merchant){

                let 
                    items = JSON.parse( localStorage.getItem("items") )
                ;

                if ( items == null ) {

                    items = [] ;
                    items.push({
                        "id": merchant.key,
                        "merchantName": merchant.merchantName,
                        "url": merchant.url,
                        "type": merchant.type,
                        "verified": merchant.verified,
                        "partner": merchant.partner
                    }) ;


                } else {

                    let nombre = 0 ;
                    for (let value of items ) {
                        
                        if ( value.url == merchant.url ) {
                            nombre += 1 ;
                        } else {
                            console.log("") ;
                        }
                    }

                    if ( nombre == 1 ) {
                        console.log("") ;
                    } else {
                        items.push({
                            "id": merchant.key,
                            "merchantName": merchant.merchantName,
                            "url": merchant.url,
                            "type": merchant.type,
                            "verified": merchant.verified,
                            "partner": merchant.partner
                        }) ;
                    }

                }

                localStorage.setItem("items", JSON.stringify(items)) ;
                window.location.href = "https://pay.apaym.com/"+ merchant.url ;
                // window.location.href = "http://localhost:8080/"+ merchant.url ;
            },

            removeFav(item){
                // console.log(item);
                // return false;
                let itemsFav = JSON.parse(localStorage.getItem("items"));
                itemsFav.splice(item, 1);
                localStorage.setItem("items", JSON.stringify(itemsFav));

                this.listFav() ;
            },

            listFav(){

                this.merchantList = [] ;
                let 
                    items = JSON.parse( localStorage.getItem("items") )
                ;

                this.deleteFav = false ;
                if ( items !== null ) {

                    this.deleteFav = true ;
                    for ( let [key, rep] of Object.entries(items) ) {

                        this.merchantList.push({
                            id: key,
                            merchantName: rep.merchantName,
                            url: rep.url,
                            type: rep.type,
                            verified: rep.verified,
                            partner: rep.partner
                        }) ;
                    }
                }
            }
        },
        mounted(){
            this.listFav() ;
        },
        props: [ 
            'showErrorPage', 
        ],
    }
</script>