<template>
    <div>
        <div
            id="payment-success"
            :class="hideRecu == true ? 'payment-success animated fadeIn text-center hidden' : 'payment-success animated fadeIn text-center'"
        >

            <img src="../assets/confirmico.gif" alt="Logo payment success" />
            <div>
                <strong>
                    {{ $t('payment_successfully.success.value') }}
                </strong>
                <br>
                <span v-if="nom && prenom"> 
                    {{ $t('payment_successfully.by.value') }} {{ nom }} {{ prenom }}
                </span>
                <h2 class="payment-success-message" style="font-weight: 900; font-size: 3rem;">
                    <strong>{{ Math.round(montant) }} {{ devise == "XOF" ? "F" : devise }}</strong>
                </h2>
                <div v-if="motif" class="mb-3">
                    {{ $t('payment_successfully.reason.value') }} : {{ motif }}
                </div>

                <span 
                    style="align-items: center; justify-content: center;"
                >
                    {{ $t('payment_successfully.by.value') }}  
                    <img :src="require(`../assets/img/menu/${logoSmallDevice}`)" width="70" alt="Logo" style="position: relative; left: 5px;" />
                </span>

            </div>
        </div>
        <div class="element-wrap element-wrap-for-submit mt-5 mb-5"> 
            <a :href="'https://pay.apaym.com/recu/'+ this.refPayment" target="_blank" :class="hideRecu == true ? 'btn btn-dark hidden btn-block' : 'btn btn-dark btn-block font-15'">
                {{ $t('payment_successfully.receipt.value') }}
            </a>
            
            <a href="https://apaym.com/app" target="_blank" v-if=" nombre_transactions == '' "
                :class="hideRecu == true ? 'btn btn-dark hidden btn-block' : 'btn btn-outline-dark btn-block font-15'"
            >{{ $t('download_apaym.value') }}</a>

            <button @click="reload" :class="hideRecu == true ? 'btn btn-link hidden btn-block' : 'btn btn-link btn-block'" style="font-weight: 800; color: #0e2abd;">
                {{ $t('payment_successfully.button.value') }}
            </button>
        </div>
        
    </div>
</template>

<script>


    export default {
        components:{
        },
        data() {
            return {
            }
        },
        props: [ 
            'hideRecu', 
            'nombre_transactions',
            'nom',
            'prenom',
            'montant',
            'logoSmallDevice',
            'refPayment',
            'devise',
            'motif'
        ],
        mounted(){
        },
        methods: {
            // Reload la page
            reload(){
                sessionStorage.clear();
                localStorage.clear();
                location.reload();
            },

            downloadReceipt()
            {
                sessionStorage.clear();
                localStorage.clear();
                window.location.href = 'https://pay.apaym.com/recu/'+ this.refPayment
                // window.location.href = 'http://localhost:8080/recu/'+ this.refPayment
            }
        },
    }
</script>

<style scoped>

    

</style>