<template>
    <div>
        <!-- Color Top Bar -->
        <div class="apaympro-color-bar"></div>
        <!-- Color Top Bar -->
        
        <div style="display: flex; align-items: center; justify-content: space-between;">

            <!-- Return to search for a merchant -->
            <a class="element-wrap element-wrap-on-left m-2" href="https://pay.apaym.com/" v-if="showBtnSearch">
                <i class="fas fa-search"></i>
            </a>
            <!-- Return to search for a merchant -->

            <!-- Choose language -->
            <div class="element-wrap element-wrap-on-left m-2">
                <!-- element-wrap-for-select -->
                <select class="element-is-select" @change="changeLanguage($event)" v-model="$i18n.locale" style="border: none;background: transparent;">
                    <option v-for="(lang, i) in langs" :key="`lang-${i}`" :value="lang">
                        {{ lang == 'fr' ? '🇫🇷 Français' : ( lang == 'en' ? '🇬🇧 English' : '🇨🇮 Malinké' ) }}
                    </option>
                </select>
            </div>
            <!-- Choose language -->

        </div>


    </div>
</template>

<script>


    export default {
        components:{
        },
        data() {
            return {
                langs: ['fr', 'en', 'mal'],
            }
        },
        props:['showBtnSearch'],
        watch: {
        },
        methods: {
            // Store the language value
            changeLanguage(event) {
                window.localStorage.setItem("lang", event.target.value)
            }
        },

        mounted() { 
        },
    }
</script>

<style scoped>

</style>