<template>
    <div class="apaympro-container">
        <!-- Loader -->
        <loading :active.sync="isLoading" :can-cancel="false" :on-cancel="onCancel" :is-full-page="fullPage"
                 loader="bars"
                 :opacity="1"></loading>
        <!-- Loader -->

        <!-- Top Bar Component -->
        <top-bar :showBtnSearch="showBtnSearch == 'true' ? true : false"></top-bar>
        <!-- Top Bar Component -->

        <!-- Skeleton Loader Component -->
        <skeleton-loader :formLoader="formLoader"></skeleton-loader>
        <!-- Skeleton Loader Component -->

        <!-- Form Payment - Paye avec tous les champs obligatoires sauf le motif et le e-mail -->
        <div class="apaympro-wrapper animated fadeIn container" :hidden="!formPayment" style="width: 500px">
            <div class="apaympro-page">
                <!-- ****** -->
                <!-- ****** -->
                <!-- Header -->
                <!-- ****** -->
                <!-- ****** -->
                <div class="apaympro-header" style="margin-top: 15px">
                    <div class="company-logo" v-if="dataPage.business_logo"
                         style="width: 150px !important; justify-content: center">
                        <img :src="dataPage.business_logo" alt="Business Logo"/>
                    </div>
                    <div class="page-info" v-if="dataPage.deleg_pdci !== '1'">
                        <h3 class="page-name font-16" v-if="dataPage.partner !== '2'">
                            {{
                                dataPage.type == "ONG" || dataPage.type == "PERSONNALITE"
                                        ? $t("text_header.donation.value")
                                        : $t("text_header.pay.value")
                            }}
                            <strong style="text-transform: uppercase">
                                {{ dataPage.business_name }}
                                <img src="../assets/img/badge-check.png" v-b-tooltip.hover
                                     :title="$t('text_certified_account.value')" v-if="dataPage.verified == '1'"
                                     width="15"
                                     style="margin-top: -3px"/>
                            </strong>
                            <!-- <p class="company-name mb-2" style="font-weight: 500; font-size: 11px">{{ dataPage.ville }}</p> -->
                            <p class="company-name mb-2" style="font-weight: 500; font-size: 11px">
                                {{ dataPage.ville }} / {{ dataPage.type }}
                            </p>
                        </h3>

                        <!-- Yango -->
                        <h3 class="page-name font-16" v-if="dataPage.partner == '2'">
                            <strong style="text-transform: uppercase">
                                YANGO
                                <img src="../assets/img/badge-check.png" v-b-tooltip.hover
                                     :title="$t('text_certified_account.value')" v-if="dataPage.verified == '1'"
                                     width="15"
                                     style="margin-top: -3px"/>
                            </strong>
                            <p class="company-name mb-2" style="font-weight: 500; font-size: 11px">
                                {{ dataPage.ville }}
                            </p>
                        </h3>
                        <!-- Yango -->

                        <p class="company-name text-dark" v-if="dataPage.type == 'SP'">
                            {{ $t("text_header.by2.value") }}
                            <span style="font-weight: 700">{{ dataPage.nom }}</span> <br/>
                            <span class="font-12" style="text-transform: none">{{
                                    $t("text_header.enterprising.value")
                                }}</span>
                        </p>
                        <p class="company-name" v-if="dataPage.type !== 'SP' && dataPage.type !== 'AUTRE'">
                            <span style="font-weight: 500; font-size: 11px">
                                {{
                                    dataPage.rcc !== "0" || dataPage.rcc.includes("---") == "-1"
                                            ? dataPage.rcc !== ""
                                                    ? dataPage.rcc
                                                    : ""
                                            : ""
                                }}
                            </span>
                        </p>
                    </div>

                    <!-- Il s'agit du PDCI -->
                    <div class="page-info" v-if="dataPage.deleg_pdci == '1'">
                        <h3 class="page-name font-16">
                            <strong style="text-transform: uppercase">
                                {{ dataPage.business_name }}
                                <img src="../assets/img/badge-check.png" v-b-tooltip.hover
                                     :title="$t('text_certified_account.value')" v-if="dataPage.verified == '1'"
                                     width="15"
                                     style="margin-top: -3px"/>
                            </strong>
                            <br/>
                            {{ $t("text_header.pdci.value") }}
                            <p class="company-name mb-2" style="font-weight: 500; font-size: 11px">
                                {{ dataPage.ville }} / {{ dataPage.type }}
                            </p>
                        </h3>
                    </div>
                    <!-- Il s'agit du PDCI -->

                    <div class="page-description" v-if="dataPage.description_page && dataPage.merchant !== '25010'">
                        <span id="tooltip-description-page" style="cursor: pointer">
                            {{ dataPage.description_page }}
                        </span>
                        <b-tooltip target="tooltip-description-page" triggers="hover">
                            {{ dataPage.description_page_2 }}
                        </b-tooltip>
                    </div>

                    <div class="page-description" v-else>
                        <span style="cursor: pointer; font-weight: bold;">
                            {{ dataPage.description_page_2 }}
                        </span>
                    </div>

                    <!-- Social medias -->
                    <div class="company-name" style="text-transform: none">
                        <span class="mr-1 ml-1">
                            <a class="btn btn-lg btn-link btn-facebook" v-b-modal.openQrCode style="padding: 0">
                                <!-- <i class="fab fa-qr"></i> -->
                                <i class="fas fa-solid fa-qrcode text-primary"></i>
                            </a>
                        </span>
                        <span v-if="dataPage.facebook" class="mr-1 ml-1">
                            <a class="btn btn-lg btn-link btn-facebook" :href="dataPage.facebook" target="_blank"
                               style="padding: 0">
                                <i class="fab fa-facebook-f"></i>
                            </a>
                        </span>
                        <span v-if="dataPage.instagram" class="mr-1 ml-1">
                            <a class="btn btn-lg btn-link btn-instagram" :href="dataPage.instagram" target="_blank"
                               style="padding: 0">
                                <i class="fab fa-instagram"></i>
                            </a>
                        </span>
                        <span v-if="dataPage.whatsapp" class="mr-1 ml-1">
                            <a class="btn btn-lg btn-link btn-whatsapp" :href="dataPage.whatsapp" target="_blank"
                               style="padding: 0">
                                <i class="fab fa-whatsapp"></i>
                            </a>
                        </span>
                        <span v-if="dataPage.site" class="mr-1 ml-1">
                            <a class="btn btn-lg btn-link btn-site" :href="dataPage.site" target="_blank"
                               style="padding: 0">
                                <i class="fas fa-solid fa-globe"></i>
                            </a>
                        </span>
                    </div>
                    <!-- Social medias -->

                    <!-- Collected Amount -->
                    <div v-if="dataPage.collected_amount !== 0">
                        <h3 class="font-16 collected-transactions" style="margin-top: 10px;">
                            <div>
                                {{ $t("text_header.collected.value") }} :
                                <strong>
                                    {{
                                        new Intl.NumberFormat("fr-FR", {
                                            style: "currency",
                                            currency: "XOF",
                                        }).format(dataPage.collected_amount)
                                    }}
                                </strong>
                            </div>
                            <div>
                                {{ $t("text_header.transactions.value") }} :
                                <strong> {{ dataPage.collected_qty }} </strong>
                            </div>
                        </h3>
                    </div>
                    <!-- Collected Amount -->

                    <div style="display:flex; justify-content: center; gap: 5px;" class="mb-3 mt-2" v-if="hideRecu">
                        <div v-for="mode in modesPaiementDispo2" :key="mode.mode" v-show="mode.actif == 'oui'">
                            <img :src="require(`../assets/img/menu/${mode.img}`)" alt="Logo Operateur" width="50"/>
                        </div>
                    </div>

                </div>
                <!-- ****** -->
                <!-- ****** -->
                <!-- Header -->
                <!-- ****** -->
                <!-- ****** -->

                <!-- *************** -->
                <!-- *************** -->
                <!-- PDCI - RDA Form -->
                <!-- *************** -->
                <!-- *************** -->
                <div class="body" v-if="dataPage.deleg_pdci == '1' && dataPage.type !== 'SGI'">
                    <form name="payment-form" :class="hideRecu == true ? 'payment-form' : 'payment-form hidden'">
                        <!-- Formules e-ticket -->
                        <div class="element-pair" v-if="dataPage.eticket.length > 0">
                            <div class="element-label" style="line-height: inherit !important;">
                                <span>{{ $t("text_form.formulas.value") }} -
                                    {{ dataPage.eticket[0].programme.libelle }}</span>
                            </div>

                            <div class="element-pair-container">
                                <b-form-group>
                                    <div class="formules-container">
                                        <div v-for="(formule, index) in dataPage.eticket" :key="index"
                                             :id="formule.libelle">
                                            <div class="formule-bloc" :style="dataFormulaire.formule == formule
                                                ? 'background-color: #f6f6f6; border: 1px solid #005CC8'
                                                : 'background-color: #fdfdfd; ; border: 1px solid #b2bec3'
                                                " v-if="formule.quantite_indisponible === false &&
        formule.expiration_formule === false
        " @click="() => {
        dataFormulaire.formule = formule;
        dataFormulaire.motif = formule.libelle;
        dataFormulaire.quantite_indisponbile =
            formule.quantite_indisponbile;
        getMontantFormule(typeForm, dataFormulaire.formule);
    }
        ">
                                                <div style="
                                                    display: flex;
                                                    justify-content: flex-end;
                                                    align-items: center;
                                                ">
                                                    <input type="radio" v-model="dataFormulaire.formule" @change="
                                                        getMontantFormule(
                                                            typeForm,
                                                            dataFormulaire.formule
                                                        )
                                                        " :name="formule.libelle" :value="formule"
                                                           style="cursor: pointer; width: 18px; height: 18px"/>
                                                </div>
                                                <div style="
                                                    padding: 1px;
                                                    margin-top: 5px;
                                                    display: block;
                                                    line-height: 15px;
                                                ">
                                                    <span style="">
                                                        {{ formule.libelle }}
                                                    </span>
                                                </div>

                                                <div style="
                                                    position: absolute;
                                                    bottom: 3px;
                                                    margin-top: 5px;
                                                ">
                                                    <span class="" :style="formule.quantite_indisponbile === true
                                                        ? 'font-size: 14px; color: #1e017d; font-weight: bolder'
                                                        : 'font-size: 14px; color: #1b1e21; font-weight: bolder'
                                                        ">
                                                        {{
                                                            new Intl.NumberFormat("fr-FR", {
                                                                style: "currency",
                                                                currency: "XOF",
                                                            }).format(formule.montant)
                                                        }}
                                                    </span>
                                                </div>
                                            </div>

                                            <div class="formule-bloc-disabled" :style="dataFormulaire.formule == formule
                                                ? 'background-color: #f6f6f6; border: 1px solid #005CC8'
                                                : 'background-color: #fdfdfd; ; border: 1px solid #b2bec3'
                                                " v-if="formule.expiration_formule === true">
                                                <div style="
                                                    width: 100%;
                                                    display: flex;
                                                    justify-content: right;
                                                ">
                                                    <div style="
                                                        display: flex;
                                                        justify-content: space-around;
                                                        align-items: center;
                                                        padding: 2px;
                                                        border-radius: 5px;
                                                        background-color: #fff3cd;
                                                        color: #f77f00;
                                                        width: 60px;
                                                        border: 1px solid rgb(247 127 0 / 25%);
                                                    ">
                                                        <span class="font-11">{{
                                                                $t("text_form.formulas_expired.value")
                                                            }}</span>
                                                        <i class="fa fa-clock font-11"></i>
                                                    </div>
                                                </div>
                                                <div style="
                                                    padding: 1px;
                                                    margin-top: 5px;
                                                    display: block;
                                                    line-height: 15px;
                                                ">
                                                    <span style="">
                                                        {{ formule.libelle }}
                                                    </span>
                                                </div>

                                                <div style="
                                                    position: absolute;
                                                    bottom: 3px;
                                                    margin-top: 5px;
                                                ">
                                                    <span class="" :style="formule.quantite_indisponbile === true
                                                        ? 'font-size: 14px; color: #1e017d; font-weight: bolder'
                                                        : 'font-size: 14px; color: #1b1e21; font-weight: bolder'
                                                        ">
                                                        {{
                                                            new Intl.NumberFormat("fr-FR", {
                                                                style: "currency",
                                                                currency: "XOF",
                                                            }).format(formule.montant)
                                                        }}
                                                    </span>

                                                    <br/>
                                                </div>
                                            </div>

                                            <div class="formule-bloc-disabled" :style="dataFormulaire.formule == formule
                                                ? 'background-color: #f6f6f6; border: 1px solid #005CC8'
                                                : 'background-color: #fdfdfd; ; border: 1px solid #b2bec3'
                                                " v-if="formule.quantite_indisponible === true">
                                                <div style="
                                                    width: 100%;
                                                    display: flex;
                                                    justify-content: right;
                                                ">
                                                    <div style="
                                                        display: flex;
                                                        justify-content: space-around;
                                                        align-items: center;
                                                        padding: 2px;
                                                        border-radius: 5px;
                                                        background-color: #f8d7db;
                                                        color: #dc3545;
                                                        width: 60px;
                                                    ">
                                                        <span class="font-11">{{
                                                                $t("text_form.sold_out.value")
                                                            }}</span>
                                                        <i class="fa fa-lock font-11"></i>
                                                    </div>
                                                </div>
                                                <div style="
                                                    padding: 1px;
                                                    margin-top: 5px;
                                                    display: block;
                                                    line-height: 15px;
                                                ">
                                                    <span style="">
                                                        {{ formule.libelle }}
                                                    </span>
                                                </div>

                                                <div style="
                                                    position: absolute;
                                                    bottom: 3px;
                                                    margin-top: 5px;
                                                ">
                                                    <span class="" :style="formule.quantite_indisponbile === true
                                                        ? 'font-size: 14px; color: #1e017d; font-weight: bolder'
                                                        : 'font-size: 14px; color: #1b1e21; font-weight: bolder'
                                                        ">
                                                        {{
                                                            new Intl.NumberFormat("fr-FR", {
                                                                style: "currency",
                                                                currency: "XOF",
                                                            }).format(formule.montant)
                                                        }}
                                                    </span>

                                                    <br/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div v-if="dataFormulaire.formule" class="formule-info">
                                        {{ dataFormulaire.formule.description }}
                                    </div>
                                </b-form-group>
                            </div>
                        </div>
                        <!-- Formules e-ticket -->

                        <!-- Motif PDCI -->
                        <div class="element-wrap element-wrap-for-select element-wrap-on-left"
                             v-if="dataPage.deleg_pdci == '1'">
                            <div class="element-label">
                                <span> {{ $t("text_form.reason.value") }} </span>
                            </div>
                            <select v-model="dataFormulaire.motif" @change="setMontantPdci" class="element-is-select"
                                    name="motif">
                                <option value="" selected>
                                    {{ $t("text_form.select_reason.value") }}
                                </option>
                                <option v-for="(motif, i) in motifsPdci" :key="`${i}`" :value="motif.motif">
                                    {{ motif.motif }}
                                </option>
                            </select>
                        </div>
                        <!-- Motif PDCI -->

                        <!-- Qualité grade -->
                        <div class="element-wrap">
                            <div class="element-label">
                                <span>
                                    {{ $t("text_form.grade.value") }}
                                </span>
                            </div>
                            <multiselect v-model="dataFormulaire.grade" :options="gradesPdci" :multiple="false"
                                         :placeholder="$t('text_form.select_grade.value')" track-by="organe"
                                         label="organe"
                                         @input="setMontantPdci" :select-label="''" :deselect-label="''"
                                         :selected-label="'✓'">
                                <template slot="singleLabel" slot-scope="{ option }">{{
                                        option.name_to_show
                                    }}
                                </template>
                                <span slot="noResult"></span>
                            </multiselect>
                        </div>
                        <!-- Qualité grade -->

                        <!-- Montant a payer -->
                        <div class="element-pair">
                            <div class="element-label">
                                <span>
                                    {{ $t("text_form.amount.value") }}
                                    <a href="javascript:;" class="none-outline" style="color: rgb(14, 42, 189)" v-if="!(
                                        dataPage.amount !== '' &&
                                        parseFloat(dataPage.amount) >= 100
                                    )
                                        " v-b-modal.convertAmount>
                                        | {{ $t("text_form.convert_amount.value") }} &nbsp;
                                        <i class="fas fa-euro-sign"></i> &nbsp;
                                        <i class="fas fa-dollar-sign"></i>
                                    </a>
                                </span>
                            </div>
                            <div class="element-pair-container" style="align-items: center">
                                <div class="element-wrap element-wrap-on-right">
                                    <div style="
                                        display: flex;
                                        flex-direction: row;
                                        align-items: center;
                                        " v-if="dataPage.eticket.length <= 0">
                                        <input :disabled="dataPage.amount !== '' &&
                                            parseFloat(dataPage.amount) >= 100 &&
                                            parseFloat(dataPage.amount) <= 1000000
                                            " v-model="dataFormulaire.montant" type="tel" v-on:keypress="numbersOnly"
                                               class="element-is-input" name="montant" maxlength="9" v-b-tooltip.hover
                                               :title="(dataFormulaire.montant !== '' &&
                                                dataFormulaire.montant < 100) ||
                                                dataFormulaire.montant > 1000000
                                                ? 'Vous pouvez payer au minimum 100 F et au maximum 1 000 000 F'
                                                : ''
                                                " :style="(dataFormulaire.montant !== '' &&
        dataFormulaire.montant < 100) ||
        dataFormulaire.montant > 1000000
        ? 'border: 1px solid red; font-weight: 900; font-size: 22px'
        : 'font-weight: 900; font-size: 22px'
        "/>
                                        <a href="javascript:void(0);" @click="openCalculator" v-if="!(
                                            dataPage.amount !== '' &&
                                            parseFloat(dataPage.amount) >= 100
                                        )
                                            " style="
                                                font-size: 18px;
                                                color: #0e2abd;
                                                margin-left: -35px;
                                            ">
                                            <i class="fas fa-calculator"></i>
                                        </a>
                                    </div>
                                    <div class="element-is-input" v-else style="
                                        font-weight: 900;
                                        font-size: 22px;
                                        pointer-events: none;
                                        background-color: rgb(238, 238, 238);
                                        border: 1px solid rgb(204, 204, 204);
                                    ">
                                        {{ dataFormulaire.montant }}
                                    </div>
                                </div>

                                <div style="font-weight: 800; font-size: 20px; white-space: nowrap">
                                    F CFA
                                </div>
                            </div>
                        </div>
                        <div v-if="detailsMontantPdci && dataFormulaire.grade" class="font-12 mb-3"
                             style="margin-top: -5px; font-style: italic">
                            {{ detailsMontantPdci }}
                        </div>
                        <!-- Montant a payer -->

                        <!-- Delegations -->
                        <div class="element-wrap">
                            <div class="element-label">
                                <span>{{ $t("text_form.delegation.value") }}</span>
                            </div>
                            <multiselect v-model="dataFormulaire.deleg" :options="delegationsPdci" :multiple="false"
                                         :placeholder="$t('text_form.select_delegation.value')" track-by="name"
                                         label="name"
                                         :select-label="''" :deselect-label="''" :selected-label="'✓'">
                                <template slot="singleLabel" slot-scope="{ option }">{{
                                        option.name_to_show
                                    }}
                                </template>
                                <span slot="noResult"></span>
                            </multiselect>
                        </div>
                        <!-- Delegations -->

                        <!-- Pourboire -->
                        <div class="element-pair" v-if="dataPage.pourboire == '1'">
                            <div class="element-label">
                                <span>
                                    {{ $t("text_pourboire.value") }}
                                </span>
                            </div>
                            <div class="element-pair-container">
                                <label :style="pourboire !== '0'
                                    ? 'background: #eeeeee; border: #eeeeee; color: rgb(119 119 119);width: 90px; display: flex; align-items: center; border-radius: 10px; height: 30px; justify-content: center;'
                                    : 'width: 90px; display: flex; align-items: center; border-radius: 10px; height: 30px; justify-content: center;'
                                    " class="btn btn-dark btn-sm ml-1 mr-1">
                                    <input type="radio" value="0" v-model="pourboire" style="display: none"/><span
                                        class="font-12" style="z-index: 200">0 F</span>
                                </label>

                                <label :style="pourboire !== '500'
                                    ? 'background: #eeeeee; border: #eeeeee; color: rgb(119 119 119);width: 90px; display: flex; align-items: center; border-radius: 10px; height: 30px; justify-content: center;'
                                    : 'width: 90px; display: flex; align-items: center; border-radius: 10px; height: 30px; justify-content: center;'
                                    " class="btn btn-dark btn-sm ml-1 mr-1">
                                    <input type="radio" value="500" v-model="pourboire" style="display: none"/><span
                                        class="font-12" style="z-index: 200">500 F</span>
                                </label>

                                <label :style="pourboire !== '1000'
                                    ? 'background: #eeeeee; border: #eeeeee; color: rgb(119 119 119);width: 90px; display: flex; align-items: center; border-radius: 10px; height: 30px; justify-content: center;'
                                    : 'width: 90px; display: flex; align-items: center; border-radius: 10px; height: 30px; justify-content: center;'
                                    " class="btn btn-dark btn-sm ml-1 mr-1">
                                    <input type="radio" value="1000" v-model="pourboire" style="display: none"/><span
                                        class="font-12" style="z-index: 200">1000 F</span>
                                </label>

                                <label :style="pourboire !== 'autre'
                                    ? 'background: #eeeeee; border: #eeeeee; color: rgb(119 119 119);width: 90px; display: flex; align-items: center; border-radius: 10px; height: 30px; justify-content: center;'
                                    : 'width: 90px; display: flex; align-items: center; border-radius: 10px; height: 30px; justify-content: center;'
                                    " class="btn btn-dark btn-sm ml-1 mr-1">
                                    <input type="radio" value="autre" v-model="pourboire" style="display: none"/><span
                                        class="font-12" style="z-index: 200">{{
                                        $t("text_pourboire2.value")
                                    }}</span>
                                </label>
                                <!-- </div> -->
                            </div>

                            <div class="element-pair-container" style="align-items: center"
                                 :hidden="pourboire !== 'autre'">
                                <div class="element-wrap element-wrap-on-right">
                                    <input v-model="dataFormulaire.amountPourboire" type="tel"
                                           v-on:keypress="numbersOnly"
                                           class="element-is-input" name="montant" maxlength="5" v-b-tooltip.hover
                                           :title="dataFormulaire.amountPourboire < 10 ||
                                            dataFormulaire.montant > 20000
                                            ? 'Vous pouvez payé au minimum 10 F et au maximum 20 000 F'
                                            : ''
                                            "/>
                                </div>
                                <div>F CFA</div>
                            </div>
                        </div>
                        <!-- Pourboire -->

                        <!-- Numero de telephone -->
                        <div class="element-pair" style="margin-top: 15px">
                            <div class="element-label" v-if="dataPage.deleg_pdci == '1'">
                                <span>{{ $t("text_form.number_activist.value") }}</span>
                            </div>
                            <div class="element-label" v-if="dataPage.deleg_pdci == '0'">
                                <span>{{ $t("text_form.number.value") }}</span>
                            </div>
                            <div class="element-pair-container">
                                <div class="element-wrap element-wrap-on-left">
                                    <multiselect v-model="indice.i" :options="options" :multiple="false"
                                                 placeholder="Indicatif" tag-placeholder="Indicatif" track-by="code"
                                                 label="country"
                                                 :searchable="true" :select-label="''" :deselect-label="''"
                                                 :selected-label="'✓'"
                                                 :allow-empty="false">
                                        <template slot="singleLabel" slot-scope="{ option }">{{
                                                option.country_code
                                            }}
                                        </template>
                                        <span slot="noResult"></span>
                                    </multiselect>
                                </div>
                                <div class="element-wrap element-wrap-on-right">
                                    <input class="element-is-input" type="tel" v-mask="'## ## ## ## ##'"
                                           v-model="dataFormulaire.telephone" name="telephone"
                                           style="font-weight: 900; font-size: 22px"/>
                                </div>
                            </div>
                        </div>
                        <!-- Numero de telephone -->

                        <!-- Nom -->
                        <div class="element-pair">
                            <div class="element-pair-container">
                                <div class="element-wrap">
                                    <div class="element-label" v-if="dataPage.deleg_pdci == '0'">
                                        <span>{{ $t("text_form.name.value") }}</span>
                                    </div>
                                    <div class="element-label" v-if="dataPage.deleg_pdci == '1'">
                                        <span>{{ $t("text_form.name_activist.value") }}</span>
                                    </div>
                                    <input v-model="dataFormulaire.nom" type="text" class="element-is-input"
                                           name="name"/>
                                </div>
                            </div>
                        </div>
                        <!-- Nom -->

                        <!-- Prenom(s) -->
                        <div class="element-pair">
                            <div class="element-pair-container">
                                <div class="element-wrap">
                                    <div class="element-label" v-if="dataPage.deleg_pdci == '1'">
                                        <span>{{ $t("text_form.surname_activist.value") }}</span>
                                    </div>
                                    <div class="element-label" v-else>
                                        <span>{{ $t("text_form.surname.value") }}</span>
                                    </div>
                                    <input v-model="dataFormulaire.prenom" type="text" class="element-is-input"
                                           name="prenom"/>
                                </div>
                            </div>
                        </div>
                        <!-- Prenom(s) -->

                        <!-- E-mail -->
                        <div class="element-wrap">
                            <div class="element-label">
                                <span>{{ $t("text_form.email_activist.value") }}</span>
                                {{ $t("text_form.optional.value") }}
                            </div>
                            <input v-model="dataFormulaire.email" type="email" class="element-is-input" name="email"/>
                        </div>
                        <!-- E-mail -->

                        <!-- Succursale -->
                        <div class="element-pair-container" v-if="dataPage.boutiques.length > 0">
                            <div class="element-wrap element-wrap-for-select">
                                <div class="element-label">
                                    <span> {{ $t("text_form.branch_store.value") }} </span>
                                    {{ $t("text_form.optional.value") }}
                                </div>
                                <select v-model="dataFormulaire.boutique" type="text" class="element-is-select"
                                        name="succursale">
                                    <option value="" selected>
                                        {{ $t("text_form.branch_store_option.value") }}
                                    </option>
                                    <option :value="boutique" v-for="(boutique, index) in dataPage.boutiques"
                                            :key="index">
                                        {{ boutique.nom_boutique }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <!-- Succursale -->

                        <!-- Button submit form -->
                        <div class="element-wrap element-wrap-for-submit">
                            <div>
                                <button type="button" @click="payer" v-if="dataPage.eticket.length <= 0" :class="!dataFormulaire.nom ||
                                    !dataFormulaire.prenom ||
                                    !dataFormulaire.telephone ||
                                    dataFormulaire.telephone.length < 11 ||
                                    !dataFormulaire.montant ||
                                    dataFormulaire.montant < 100 ||
                                    dataFormulaire.montant > 1000000
                                    ? 'btn btn-dark btn-disabled-custom btn-block'
                                    : 'btn btn-dark btn-block'
                                    " :disabled="!dataFormulaire.nom ||
        !dataFormulaire.prenom ||
        !dataFormulaire.telephone ||
        dataFormulaire.telephone.length < 11 ||
        !dataFormulaire.montant ||
        dataFormulaire.montant < 100 ||
        dataFormulaire.montant > 1000000
        ">
                                    {{ $t("text_form.button.value") }}
                                    {{
                                        dataFormulaire.montant !== ""
                                                ? new Intl.NumberFormat("fr-FR", {
                                                    style: "currency",
                                                    currency: "XOF",
                                                }).format(parseFloat(dataFormulaire.montant))
                                                : ""
                                    }}
                                </button>

                                <button type="button" @click="payer" v-if="dataPage.eticket.length > 0" :class="!dataFormulaire.formule ||
                                    !dataFormulaire.nom ||
                                    !dataFormulaire.prenom ||
                                    !dataFormulaire.telephone ||
                                    dataFormulaire.telephone.length < 11 ||
                                    !dataFormulaire.montant ||
                                    dataFormulaire.montant < 100 ||
                                    dataFormulaire.montant > 1000000
                                    ? 'btn btn-dark btn-disabled-custom btn-block'
                                    : 'btn btn-dark btn-block'
                                    " :disabled="!dataFormulaire.formule ||
        !dataFormulaire.nom ||
        !dataFormulaire.prenom ||
        !dataFormulaire.telephone ||
        dataFormulaire.telephone.length < 11 ||
        !dataFormulaire.montant ||
        dataFormulaire.montant < 100 ||
        dataFormulaire.montant > 1000000
        ">
                                    {{ $t("text_form.button.value") }}
                                    {{
                                        dataFormulaire.montant !== ""
                                                ? new Intl.NumberFormat("fr-FR", {
                                                    style: "currency",
                                                    currency: "XOF",
                                                }).format(parseFloat(dataFormulaire.montant))
                                                : ""
                                    }}
                                </button>
                            </div>
                        </div>
                        <!-- Button submit form -->
                    </form>

                    <!-- ******** -->
                    <!-- Bloc success payment -->
                    <!-- ******** -->
                    <success-page :hideRecu="hideRecu" :logoSmallDevice="logoSmallDevice"
                                  :montant="dataFormulaire.montant"
                                  :nom="dataFormulaire.nom" :prenom="dataFormulaire.prenom"
                                  :devise="dataFormulaire.devise"
                                  :motif="dataFormulaire.motif" :refPayment="refPayment"
                                  :nombre_transactions="nombre_transactions">
                    </success-page>
                    <!-- ******** -->
                    <!-- Bloc success payment -->
                    <!-- ******** -->
                </div>
                <!-- *************** -->
                <!-- *************** -->
                <!-- PDCI - RDA Form -->
                <!-- *************** -->
                <!-- *************** -->

                <!-- ********************** -->
                <!-- ********************** -->
                <!-- Air France Form -->
                <!-- ********************** -->
                <!-- ********************** -->
                <div class="body" v-if="dataPage.air_france === 1 && dataPage.type !== 'SGI'">
                    <form :class="hideRecu == true ? 'payment-form' : 'payment-form hidden'">
                        <!-- Formules e-ticket -->
                        <div class="element-pair" v-if="dataPage.eticket.length > 0">
                            <div v-if="dataPage.eticket[0].programme.img_event !== null">
                                <div class="element-label" style="line-height: inherit !important;">
                                    <span style="text-transform: uppercase">{{
                                            dataPage.eticket[0].programme.libelle
                                        }}</span>
                                </div>
                                <!-- Image du programme -->
                                <div class="image-container">
                                    <img :src="`https://carte.abidjan.net/apaym_pro/apis/dashboard-merchant/events/${dataPage.eticket[0].programme.img_event}`"
                                         style="width: 100%; object-fit: contain"/>
                                </div>
                                <!-- Image du programme -->
                            </div>

                            <div class="element-label" :style="dataPage.eticket[0].programme.img_event !== null
                                ? 'margin-top: 20px;'
                                : ''
                                ">
                                <span v-if="dataPage.eticket[0].programme.img_event === null">
                                    {{ $t("text_form.formulas.value") }} -
                                    <span style="text-transform: uppercase">{{
                                            dataPage.eticket[0].programme.libelle
                                        }}</span>
                                </span>
                                <span v-else>
                                    {{ $t("text_form.formulas.value") }}
                                </span>
                            </div>

                            <div class="element-pair-container">
                                <b-form-group>
                                    <div class="formules-container">
                                        <div v-for="(formule, index) in dataPage.eticket" :key="index"
                                             :id="formule.libelle">
                                            <div class="formule-bloc" :style="dataFormulaire.formule == formule
                                                ? 'background-color: #f6f6f6; border: 1px solid #005CC8'
                                                : 'background-color: #fdfdfd; ; border: 1px solid #b2bec3'
                                                " v-if="formule.quantite_indisponible === false &&
        formule.expiration_formule === false
        " @click="() => {
        dataFormulaire.formule = formule;
        dataFormulaire.motif = formule.libelle;
        dataFormulaire.quantite_indisponbile =
            formule.quantite_indisponbile;
        getMontantFormule(typeForm, dataFormulaire.formule);
    }
        ">
                                                <div style="display: flex; justify-content: flex-end; align-items: center;">
                                                    <input type="radio" v-model="dataFormulaire.formule" @change="
                                                        getMontantFormule(
                                                            typeForm,
                                                            dataFormulaire.formule
                                                        )
                                                        " :name="formule.libelle" :value="formule"
                                                           style="cursor: pointer; width: 18px; height: 18px"/>
                                                </div>
                                                <div
                                                        style="padding: 1px; margin-top: 5px; display: block; line-height: 15px;">
                                                    <span style="">
                                                        {{ formule.libelle }}
                                                    </span>
                                                </div>

                                                <div style="position: absolute; bottom: 3px; margin-top: 5px;">
                                                    <span class="" :style="formule.quantite_indisponbile === true
                                                        ? 'font-size: 14px; color: #1e017d; font-weight: bolder'
                                                        : 'font-size: 14px; color: #1b1e21; font-weight: bolder'
                                                        ">
                                                        {{
                                                            new Intl.NumberFormat("fr-FR", {
                                                                style: "currency",
                                                                currency: "XOF",
                                                            }).format(formule.montant)
                                                        }}
                                                    </span>
                                                </div>
                                            </div>

                                            <div class="formule-bloc-disabled" :style="dataFormulaire.formule == formule
                                                ? 'background-color: #f6f6f6; border: 1px solid #005CC8'
                                                : 'background-color: #fdfdfd; ; border: 1px solid #b2bec3'
                                                " v-if="formule.expiration_formule === true">
                                                <div style="width: 100%; display: flex; justify-content: right;">
                                                    <div style="
                                                            display: flex;
                                                            justify-content: space-around;
                                                            align-items: center;
                                                            padding: 2px;
                                                            border-radius: 5px;
                                                            background-color: #fff3cd;
                                                            color: #f77f00;
                                                            width: 60px;
                                                            border: 1px solid rgb(247 127 0 / 25%);
                                                        ">
                                                        <span class="font-11">{{
                                                                $t("text_form.formulas_expired.value")
                                                            }}</span>
                                                        <i class="fa fa-clock font-11"></i>
                                                    </div>
                                                </div>
                                                <div style="
                            padding: 1px;
                            margin-top: 5px;
                            display: block;
                            line-height: 15px;
                          ">
                                                    <span style="">
                                                        {{ formule.libelle }}
                                                    </span>
                                                </div>

                                                <div style="
                            position: absolute;
                            bottom: 3px;
                            margin-top: 5px;
                          ">
                                                    <span class="" :style="formule.quantite_indisponbile === true
                                                        ? 'font-size: 14px; color: #1e017d; font-weight: bolder'
                                                        : 'font-size: 14px; color: #1b1e21; font-weight: bolder'
                                                        ">
                                                        {{
                                                            new Intl.NumberFormat("fr-FR", {
                                                                style: "currency",
                                                                currency: "XOF",
                                                            }).format(formule.montant)
                                                        }}
                                                    </span>

                                                    <br/>
                                                </div>
                                            </div>

                                            <div class="formule-bloc-disabled" :style="dataFormulaire.formule == formule
                                                ? 'background-color: #f6f6f6; border: 1px solid #005CC8'
                                                : 'background-color: #fdfdfd; ; border: 1px solid #b2bec3'
                                                " v-if="formule.quantite_indisponible === true">
                                                <div style="
                            width: 100%;
                            display: flex;
                            justify-content: right;
                          ">
                                                    <div style="
                              display: flex;
                              justify-content: space-around;
                              align-items: center;
                              padding: 2px;
                              border-radius: 5px;
                              background-color: #f8d7db;
                              color: #dc3545;
                              width: 60px;
                            ">
                                                        <span class="font-11">{{
                                                                $t("text_form.sold_out.value")
                                                            }}</span>
                                                        <i class="fa fa-lock font-11"></i>
                                                    </div>
                                                </div>
                                                <div style="
                            padding: 1px;
                            margin-top: 5px;
                            display: block;
                            line-height: 15px;
                          ">
                                                    <span style="">
                                                        {{ formule.libelle }}
                                                    </span>
                                                </div>

                                                <div style="
                            position: absolute;
                            bottom: 3px;
                            margin-top: 5px;
                          ">
                                                    <span class="" :style="formule.quantite_indisponbile === true
                                                        ? 'font-size: 14px; color: #1e017d; font-weight: bolder'
                                                        : 'font-size: 14px; color: #1b1e21; font-weight: bolder'
                                                        ">
                                                        {{
                                                            new Intl.NumberFormat("fr-FR", {
                                                                style: "currency",
                                                                currency: "XOF",
                                                            }).format(formule.montant)
                                                        }}
                                                    </span>

                                                    <br/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div v-if="dataFormulaire.formule" class="formule-info">
                                        {{ dataFormulaire.formule.description }}
                                    </div>
                                </b-form-group>
                            </div>
                        </div>
                        <!-- Formules e-ticket -->

                        <!-- Montant a payer -->
                        <div class="element-pair">
                            <div class="element-label">
                                <span>
                                    {{ $t("text_form.amount.value") }}
                                    <a href="javascript:void(0);" class="none-outline" style="color: rgb(14, 42, 189)"
                                       v-if="!(
                                        dataPage.amount !== '' &&
                                        parseFloat(dataPage.amount) >= 100
                                    )
                                        " v-b-modal.convertAmount>
                                        | {{ $t("text_form.convert_amount.value") }} &nbsp;
                                        <i class="fas fa-euro-sign"></i> &nbsp;
                                        <i class="fas fa-dollar-sign"></i>
                                    </a>
                                </span>
                            </div>
                            <div class="element-pair-container" style="align-items: center">
                                <div class="element-wrap element-wrap-on-right">
                                    <div style="
                      display: flex;
                      flex-direction: row;
                      align-items: center;
                    " v-if="dataPage.eticket.length <= 0">
                                        <div class="element-is-input" v-if="dataPage.amount !== '' &&
                                            parseFloat(dataPage.amount) >= 100
                                            " style="
                        font-weight: 900;
                        font-size: 22px;
                        pointer-events: none;
                        background-color: #eee;
                        border: 1px solid #ccc;
                      ">
                                            {{ dataPage.amount | formatAmount }}
                                        </div>

                                        <input v-if="dataPage.amount == ''" :disabled="dataPage.amount !== '' &&
                                            parseFloat(dataPage.amount) >= 100
                                            " v-model="montantFormate" type="tel" v-on:keypress="numbersOnly"
                                               v-mask="currencyMask" class="element-is-input" name="montant"
                                               maxlength="9"
                                               v-b-tooltip.hover :title="(montantFormate.replace(/\s/g, '') !== '' &&
                                                montantFormate.replace(/\s/g, '') < 100) ||
                                                montantFormate.replace(/\s/g, '') > 1000000
                                                ? 'Vous pouvez payer au minimum 100 F et au maximum 1 000 000 F'
                                                : ''
                                                " :style="(montantFormate.replace(/\s/g, '') !== '' &&
        montantFormate.replace(/\s/g, '') < 100) ||
        montantFormate.replace(/\s/g, '') > 1000000
        ? 'border: 1px solid red; font-weight: 900; font-size: 22px'
        : 'font-weight: 900; font-size: 22px'
        "/>
                                        <a href="javascript:;" @click="openCalculator" v-if="!(
                                            dataPage.amount !== '' &&
                                            parseFloat(dataPage.amount) >= 100
                                        )
                                            " style="
                        font-size: 18px;
                        color: #0e2abd;
                        margin-left: -35px;
                      ">
                                            <i class="fas fa-calculator"></i>
                                        </a>
                                    </div>

                                    <div class="element-is-input" v-else style="
                      font-weight: 900;
                      font-size: 22px;
                      pointer-events: none;
                      background-color: rgb(238, 238, 238);
                      border: 1px solid rgb(204, 204, 204);
                    ">
                                        {{ montantFormate }}
                                    </div>
                                </div>

                                <div style="font-weight: 800; font-size: 20px; white-space: nowrap">
                                    F CFA
                                </div>
                            </div>
                        </div>
                        <!-- Montant a payer -->

                        <!-- Pourboire -->
                        <div class="element-pair" v-if="dataPage.pourboire == '1'">
                            <div class="element-label">
                                <span>
                                    {{ $t("text_pourboire.value") }}
                                </span>
                            </div>
                            <div class="element-pair-container">
                                <label :style="pourboire !== '0'
                                    ? 'background: #eeeeee; border: #eeeeee; color: rgb(119 119 119);width: 90px; display: flex; align-items: center; border-radius: 10px; height: 30px; justify-content: center;'
                                    : 'width: 90px; display: flex; align-items: center; border-radius: 10px; height: 30px; justify-content: center;'
                                    " class="btn btn-dark btn-sm ml-1 mr-1">
                                    <input type="radio" value="0" v-model="pourboire" style="display: none"/><span
                                        class="font-12" style="z-index: 200">0 F</span>
                                </label>

                                <label :style="pourboire !== '500'
                                    ? 'background: #eeeeee; border: #eeeeee; color: rgb(119 119 119);width: 90px; display: flex; align-items: center; border-radius: 10px; height: 30px; justify-content: center;'
                                    : 'width: 90px; display: flex; align-items: center; border-radius: 10px; height: 30px; justify-content: center;'
                                    " class="btn btn-dark btn-sm ml-1 mr-1">
                                    <input type="radio" value="500" v-model="pourboire" style="display: none"/><span
                                        class="font-12" style="z-index: 200">500 F</span>
                                </label>

                                <label :style="pourboire !== '1000'
                                    ? 'background: #eeeeee; border: #eeeeee; color: rgb(119 119 119);width: 90px; display: flex; align-items: center; border-radius: 10px; height: 30px; justify-content: center;'
                                    : 'width: 90px; display: flex; align-items: center; border-radius: 10px; height: 30px; justify-content: center;'
                                    " class="btn btn-dark btn-sm ml-1 mr-1">
                                    <input type="radio" value="1000" v-model="pourboire" style="display: none"/><span
                                        class="font-12" style="z-index: 200">1000 F</span>
                                </label>

                                <label :style="pourboire !== 'autre'
                                    ? 'background: #eeeeee; border: #eeeeee; color: rgb(119 119 119);width: 90px; display: flex; align-items: center; border-radius: 10px; height: 30px; justify-content: center;'
                                    : 'width: 90px; display: flex; align-items: center; border-radius: 10px; height: 30px; justify-content: center;'
                                    " class="btn btn-dark btn-sm ml-1 mr-1">
                                    <input type="radio" value="autre" v-model="pourboire" style="display: none"/><span
                                        class="font-12" style="z-index: 200">{{
                                        $t("text_pourboire2.value")
                                    }}</span>
                                </label>
                                <!-- </div> -->
                            </div>

                            <div class="element-pair-container" style="align-items: center"
                                 :hidden="pourboire !== 'autre'">
                                <div class="element-wrap element-wrap-on-right">
                                    <input v-model="dataFormulaire.amountPourboire" type="tel"
                                           v-on:keypress="numbersOnly"
                                           class="element-is-input" name="montant" maxlength="5" v-b-tooltip.hover
                                           :title="dataFormulaire.amountPourboire < 10 ||
                                            dataFormulaire.montant > 20000
                                            ? 'Vous pouvez payé au minimum 10 F et au maximum 20 000 F'
                                            : ''
                                            "/>
                                </div>
                                <div>F CFA</div>
                            </div>
                        </div>
                        <!-- Pourboire -->

                        <!-- Numero de telephone -->
                        <div class="element-pair" style="margin-top: 15px">
                            <div class="element-label">
                                <span>{{ $t("text_form.number_air_france.value") }}</span>
                            </div>
                            <div class="element-pair-container">
                                <div class="element-wrap element-wrap-on-left">
                                    <multiselect v-model="indice.i" :options="options" :multiple="false"
                                                 placeholder="Indicatif" tag-placeholder="Indicatif" track-by="code"
                                                 label="country"
                                                 :searchable="true" :select-label="''" :deselect-label="''"
                                                 :selected-label="'✓'"
                                                 :allow-empty="false">
                                        <template slot="singleLabel" slot-scope="{ option }">{{
                                                option.country_code
                                            }}
                                        </template>
                                        <span slot="noResult"></span>
                                    </multiselect>
                                </div>
                                <div class="element-wrap element-wrap-on-right">
                                    <input class="element-is-input" type="tel" v-mask="'## ## ## ## ##'"
                                           v-model="dataFormulaire.telephone" name="telephone"
                                           style="font-weight: 900; font-size: 22px"/>
                                </div>
                            </div>
                        </div>
                        <!-- Numero de telephone -->

                        <!-- Nom -->
                        <div class="element-pair">
                            <div class="element-pair-container">
                                <div class="element-wrap element-wrap-on-left">
                                    <div class="element-label">
                                        <span>{{ $t("text_form.name_air_france.value") }}</span>
                                    </div>
                                    <input v-model="dataFormulaire.nom" type="text" class="element-is-input"
                                           name="name"/>
                                </div>
                                <div class="element-wrap element-wrap-on-right">
                                    <div class="element-label">
                                        <span>{{ $t("text_form.surname_air_france.value") }}</span>
                                    </div>
                                    <input v-model="dataFormulaire.prenom" type="text" class="element-is-input"
                                           name="prenom"/>
                                </div>
                            </div>
                        </div>
                        <!-- Prenom(s) -->

                        <!-- Email -->
                        <div class="element-wrap">
                            <div class="element-label">
                                <span>{{ $t("text_form.email.value") }}</span>
                            </div>
                            <input v-model="dataFormulaire.email" type="email" class="element-is-input" name="email"/>
                        </div>
                        <!-- Email -->

                        <!-- Succursale -->
                        <div class="element-pair-container" v-if="dataPage.boutiques.length > 0">
                            <div class="element-wrap element-wrap-for-select">
                                <div class="element-label">
                                    <span> {{ $t("text_form.branch_store.value") }} </span>
                                    {{ $t("text_form.optional.value") }}
                                </div>
                                <select v-model="dataFormulaire.boutique" type="text" class="element-is-select"
                                        name="succursale">
                                    <option value="" selected>
                                        {{ $t("text_form.branch_store_option.value") }}
                                    </option>
                                    <option :value="boutique" v-for="(boutique, index) in dataPage.boutiques"
                                            :key="index">
                                        {{ boutique.nom_boutique }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <!-- Succursale -->

                        <!-- Numéro de PNR (numéro de réservation) -->
                        <div class="element-wrap">
                            <div class="element-label">
                                <span>{{ $t("text_form.pnr_air_france.value") }}</span>
                            </div>
                            <input v-model="dataFormulaire.numeroCompte" type="text" class="element-is-input"
                                   name="pnr"/>
                        </div>
                        <!-- Numéro de PNR (numéro de réservation) -->

                        <!-- Nature de transaction -->
                        <div class="element-pair-container">
                            <div class="element-wrap element-wrap-for-select">
                                <div class="element-label">
                                    <span> {{ $t("text_form.transaction_nature2.value") }} </span>
                                </div>
                                <select v-model="dataFormulaire.service" type="text" class="element-is-select"
                                        name="nature">
                                    <option value="" selected>
                                        {{ $t("text_form.transaction_nature.value") }}
                                    </option>
                                    <option value="Billet">Billet</option>
                                    <option value="Excédent bagage">Excédent bagage</option>
                                    <option value="Siège +">Siège +</option>
                                    <option value="Surclassement">Surclassement</option>
                                    <option value="Repas">Repas</option>
                                </select>
                            </div>
                        </div>
                        <!-- Nature de transaction -->

                        <!-- Numéro Flying Blue -->
                        <div class="element-wrap">
                            <div class="element-label">
                                <span>{{
                                        $t("text_form.flying_number_air_france.value")
                                    }}</span>
                                {{ $t("text_form.optional.value") }}
                            </div>
                            <input v-model="dataFormulaire.immatriculation" type="text" class="element-is-input"
                                   name="flying"/>
                        </div>
                        <!-- Numéro Flying Blue -->

                        <!-- Button submit form -->
                        <div class="element-wrap element-wrap-for-submit">
                            <div>
                                <button type="button" @click="payerAirFrance" v-if="dataPage.eticket.length <= 0"
                                        :class="!dataFormulaire.service ||
                                    !dataFormulaire.numeroCompte ||
                                    !dataFormulaire.email ||
                                    !dataFormulaire.nom ||
                                    !dataFormulaire.prenom ||
                                    !dataFormulaire.telephone ||
                                    dataFormulaire.telephone.length < 11 ||
                                    !montantFormate.replace(/\s/g, '') ||
                                    montantFormate.replace(/\s/g, '') < 100 ||
                                    montantFormate.replace(/\s/g, '') > 1000000
                                    ? 'btn btn-dark btn-disabled-custom btn-block'
                                    : 'btn btn-dark btn-block'
                                    " :disabled="!dataFormulaire.service ||
        !dataFormulaire.numeroCompte ||
        !dataFormulaire.email ||
        !dataFormulaire.nom ||
        !dataFormulaire.prenom ||
        !dataFormulaire.telephone ||
        dataFormulaire.telephone.length < 11 ||
        !montantFormate.replace(/\s/g, '') ||
        montantFormate.replace(/\s/g, '') < 100 ||
        montantFormate.replace(/\s/g, '') > 1000000
        ">
                                    {{ $t("text_form.button.value") }}
                                    {{
                                        montantFormate.replace(/\s/g, "") !== ""
                                                ? new Intl.NumberFormat("fr-FR", {
                                                    style: "currency",
                                                    currency: "XOF",
                                                }).format(parseFloat(montantFormate.replace(/\s/g, "")))
                                                : ""
                                    }}
                                </button>

                                <button type="button" @click="payerAirFrance" v-if="dataPage.eticket.length > 0"
                                        :class="!dataFormulaire.formule ||
                                    !dataFormulaire.service ||
                                    !dataFormulaire.numeroCompte ||
                                    !dataFormulaire.email ||
                                    !dataFormulaire.nom ||
                                    !dataFormulaire.prenom ||
                                    !dataFormulaire.telephone ||
                                    dataFormulaire.telephone.length < 11 ||
                                    !montantFormate.replace(/\s/g, '') ||
                                    montantFormate.replace(/\s/g, '') < 100 ||
                                    montantFormate.replace(/\s/g, '') > 1000000
                                    ? 'btn btn-dark btn-disabled-custom btn-block'
                                    : 'btn btn-dark btn-block'
                                    " :disabled="!dataFormulaire.formule ||
        !dataFormulaire.service ||
        !dataFormulaire.numeroCompte ||
        !dataFormulaire.email ||
        !dataFormulaire.nom ||
        !dataFormulaire.prenom ||
        !dataFormulaire.telephone ||
        dataFormulaire.telephone.length < 11 ||
        !montantFormate.replace(/\s/g, '') ||
        montantFormate.replace(/\s/g, '') < 100 ||
        montantFormate.replace(/\s/g, '') > 1000000
        ">
                                    {{ $t("text_form.button.value") }}
                                    {{
                                        montantFormate.replace(/\s/g, "") !== ""
                                                ? new Intl.NumberFormat("fr-FR", {
                                                    style: "currency",
                                                    currency: "XOF",
                                                }).format(parseFloat(montantFormate.replace(/\s/g, "")))
                                                : ""
                                    }}
                                </button>
                            </div>
                        </div>
                        <!-- Button submit form -->
                    </form>

                    <!-- ******** -->
                    <!-- Bloc success payment -->
                    <!-- ******** -->
                    <success-page :hideRecu="hideRecu" :logoSmallDevice="logoSmallDevice"
                                  :montant="dataFormulaire.montant"
                                  :nom="dataFormulaire.nom" :prenom="dataFormulaire.prenom"
                                  :devise="dataFormulaire.devise"
                                  :motif="dataFormulaire.motif" :refPayment="refPayment"
                                  :nombre_transactions="nombre_transactions">
                    </success-page>
                    <!-- ******** -->
                    <!-- Bloc success payment -->
                    <!-- ******** -->
                </div>
                <!-- ********************** -->
                <!-- ********************** -->
                <!-- Air France Form -->
                <!-- ********************** -->
                <!-- ********************** -->

                <!-- ********************** -->
                <!-- ********************** -->
                <!-- Damac Immobilier Form -->
                <!-- ********************** -->
                <!-- ********************** -->
                <div class="body" v-if="dataPage.damac === 1 && dataPage.type !== 'SGI'">
                    <form :class="hideRecu == true ? 'payment-form' : 'payment-form hidden'">
                        <!-- Formules e-ticket -->
                        <div class="element-pair" v-if="dataPage.eticket.length > 0">
                            <div v-if="dataPage.eticket[0].programme.img_event !== null">
                                <div class="element-label" style="line-height: inherit !important;">
                                    <span style="text-transform: uppercase">{{
                                            dataPage.eticket[0].programme.libelle
                                        }}</span>
                                </div>
                                <!-- Image du programme -->
                                <div class="image-container">
                                    <img :src="`https://carte.abidjan.net/apaym_pro/apis/dashboard-merchant/events/${dataPage.eticket[0].programme.img_event}`"
                                         style="width: 100%; object-fit: contain"/>
                                </div>
                                <!-- Image du programme -->
                            </div>

                            <div class="element-label" :style="dataPage.eticket[0].programme.img_event !== null
                                ? 'margin-top: 20px;'
                                : ''
                                ">
                                <span v-if="dataPage.eticket[0].programme.img_event === null">
                                    {{ $t("text_form.formulas.value") }} -
                                    <span style="text-transform: uppercase">{{
                                            dataPage.eticket[0].programme.libelle
                                        }}</span>
                                </span>
                                <span v-else>
                                    {{ $t("text_form.formulas.value") }}
                                </span>
                            </div>

                            <div class="element-pair-container">
                                <b-form-group>
                                    <div class="formules-container">
                                        <div v-for="(formule, index) in dataPage.eticket" :key="index"
                                             :id="formule.libelle">
                                            <div class="formule-bloc" :style="dataFormulaire.formule == formule
                                                ? 'background-color: #f6f6f6; border: 1px solid #005CC8'
                                                : 'background-color: #fdfdfd; ; border: 1px solid #b2bec3'
                                                " v-if="formule.quantite_indisponible === false &&
        formule.expiration_formule === false
        " @click="() => {
        dataFormulaire.formule = formule;
        dataFormulaire.motif = formule.libelle;
        dataFormulaire.quantite_indisponbile =
            formule.quantite_indisponbile;
        getMontantFormule(typeForm, dataFormulaire.formule);
    }
        ">
                                                <div style="
                            display: flex;
                            justify-content: flex-end;
                            align-items: center;
                          ">
                                                    <input type="radio" v-model="dataFormulaire.formule" @change="
                                                        getMontantFormule(
                                                            typeForm,
                                                            dataFormulaire.formule
                                                        )
                                                        " :name="formule.libelle" :value="formule"
                                                           style="cursor: pointer; width: 18px; height: 18px"/>
                                                </div>
                                                <div style="
                            padding: 1px;
                            margin-top: 5px;
                            display: block;
                            line-height: 15px;
                          ">
                                                    <span style="">
                                                        {{ formule.libelle }}
                                                    </span>
                                                </div>

                                                <div style="
                            position: absolute;
                            bottom: 3px;
                            margin-top: 5px;
                          ">
                                                    <span class="" :style="formule.quantite_indisponbile === true
                                                        ? 'font-size: 14px; color: #1e017d; font-weight: bolder'
                                                        : 'font-size: 14px; color: #1b1e21; font-weight: bolder'
                                                        ">
                                                        {{
                                                            new Intl.NumberFormat("fr-FR", {
                                                                style: "currency",
                                                                currency: "XOF",
                                                            }).format(formule.montant)
                                                        }}
                                                    </span>
                                                </div>
                                            </div>

                                            <div class="formule-bloc-disabled" :style="dataFormulaire.formule == formule
                                                ? 'background-color: #f6f6f6; border: 1px solid #005CC8'
                                                : 'background-color: #fdfdfd; ; border: 1px solid #b2bec3'
                                                " v-if="formule.expiration_formule === true">
                                                <div style="
                            width: 100%;
                            display: flex;
                            justify-content: right;
                          ">
                                                    <div style="
                              display: flex;
                              justify-content: space-around;
                              align-items: center;
                              padding: 2px;
                              border-radius: 5px;
                              background-color: #fff3cd;
                              color: #f77f00;
                              width: 60px;
                              border: 1px solid rgb(247 127 0 / 25%);
                            ">
                                                        <span class="font-11">{{
                                                                $t("text_form.formulas_expired.value")
                                                            }}</span>
                                                        <i class="fa fa-clock font-11"></i>
                                                    </div>
                                                </div>
                                                <div style="
                            padding: 1px;
                            margin-top: 5px;
                            display: block;
                            line-height: 15px;
                          ">
                                                    <span style="">
                                                        {{ formule.libelle }}
                                                    </span>
                                                </div>

                                                <div style="
                            position: absolute;
                            bottom: 3px;
                            margin-top: 5px;
                          ">
                                                    <span class="" :style="formule.quantite_indisponbile === true
                                                        ? 'font-size: 14px; color: #1e017d; font-weight: bolder'
                                                        : 'font-size: 14px; color: #1b1e21; font-weight: bolder'
                                                        ">
                                                        {{
                                                            new Intl.NumberFormat("fr-FR", {
                                                                style: "currency",
                                                                currency: "XOF",
                                                            }).format(formule.montant)
                                                        }}
                                                    </span>

                                                    <br/>
                                                </div>
                                            </div>

                                            <div class="formule-bloc-disabled" :style="dataFormulaire.formule == formule
                                                ? 'background-color: #f6f6f6; border: 1px solid #005CC8'
                                                : 'background-color: #fdfdfd; ; border: 1px solid #b2bec3'
                                                " v-if="formule.quantite_indisponible === true">
                                                <div style="
                            width: 100%;
                            display: flex;
                            justify-content: right;
                          ">
                                                    <div style="
                              display: flex;
                              justify-content: space-around;
                              align-items: center;
                              padding: 2px;
                              border-radius: 5px;
                              background-color: #f8d7db;
                              color: #dc3545;
                              width: 60px;
                            ">
                                                        <span class="font-11">{{
                                                                $t("text_form.sold_out.value")
                                                            }}</span>
                                                        <i class="fa fa-lock font-11"></i>
                                                    </div>
                                                </div>
                                                <div style="
                            padding: 1px;
                            margin-top: 5px;
                            display: block;
                            line-height: 15px;
                          ">
                                                    <span style="">
                                                        {{ formule.libelle }}
                                                    </span>
                                                </div>

                                                <div style="
                            position: absolute;
                            bottom: 3px;
                            margin-top: 5px;
                          ">
                                                    <span class="" :style="formule.quantite_indisponbile === true
                                                        ? 'font-size: 14px; color: #1e017d; font-weight: bolder'
                                                        : 'font-size: 14px; color: #1b1e21; font-weight: bolder'
                                                        ">
                                                        {{
                                                            new Intl.NumberFormat("fr-FR", {
                                                                style: "currency",
                                                                currency: "XOF",
                                                            }).format(formule.montant)
                                                        }}
                                                    </span>

                                                    <br/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div v-if="dataFormulaire.formule" class="formule-info">
                                        {{ dataFormulaire.formule.description }}
                                    </div>
                                </b-form-group>
                            </div>
                        </div>
                        <!-- Formules e-ticket -->

                        <!-- Montant a payer -->
                        <div class="element-pair">
                            <div class="element-label">
                                <span>
                                    {{ $t("text_form.amount.value") }}
                                    <a href="javascript:;" class="none-outline" style="color: rgb(14, 42, 189)" v-if="!(
                                        dataPage.amount !== '' &&
                                        parseFloat(dataPage.amount) >= 100
                                    )
                                        " v-b-modal.convertAmount>
                                        | {{ $t("text_form.convert_amount.value") }} &nbsp;
                                        <i class="fas fa-euro-sign"></i> &nbsp;
                                        <i class="fas fa-dollar-sign"></i>
                                    </a>
                                </span>
                            </div>
                            <div class="element-pair-container" style="align-items: center">
                                <div class="element-wrap element-wrap-on-right">
                                    <div style="
                      display: flex;
                      flex-direction: row;
                      align-items: center;
                    " v-if="dataPage.eticket.length <= 0">
                                        <div class="element-is-input" v-if="dataPage.amount !== '' &&
                                            parseFloat(dataPage.amount) >= 100
                                            " style="
                        font-weight: 900;
                        font-size: 22px;
                        pointer-events: none;
                        background-color: #eee;
                        border: 1px solid #ccc;
                      ">
                                            {{ dataPage.amount | formatAmount }}
                                        </div>

                                        <input v-if="dataPage.amount == ''" :disabled="dataPage.amount !== '' &&
                                            parseFloat(dataPage.amount) >= 100
                                            " v-model="montantFormate" type="tel" v-on:keypress="numbersOnly"
                                               v-mask="currencyMask" class="element-is-input" name="montant"
                                               maxlength="9"
                                               v-b-tooltip.hover :title="(montantFormate.replace(/\s/g, '') !== '' &&
                                                montantFormate.replace(/\s/g, '') < 100) ||
                                                montantFormate.replace(/\s/g, '') > 1000000
                                                ? 'Vous pouvez payer au minimum 100 F et au maximum 1 000 000 F'
                                                : ''
                                                " :style="(montantFormate.replace(/\s/g, '') !== '' &&
        montantFormate.replace(/\s/g, '') < 100) ||
        montantFormate.replace(/\s/g, '') > 1000000
        ? 'border: 1px solid red; font-weight: 900; font-size: 22px'
        : 'font-weight: 900; font-size: 22px'
        "/>
                                        <a href="javascript:;" @click="openCalculator" v-if="!(
                                            dataPage.amount !== '' &&
                                            parseFloat(dataPage.amount) >= 100
                                        )
                                            " style="
                        font-size: 18px;
                        color: #0e2abd;
                        margin-left: -35px;
                      ">
                                            <i class="fas fa-calculator"></i>
                                        </a>
                                    </div>

                                    <div class="element-is-input" v-else style="
                      font-weight: 900;
                      font-size: 22px;
                      pointer-events: none;
                      background-color: rgb(238, 238, 238);
                      border: 1px solid rgb(204, 204, 204);
                    ">
                                        {{ montantFormate }}
                                    </div>
                                </div>

                                <div style="font-weight: 800; font-size: 20px; white-space: nowrap">
                                    F CFA
                                </div>
                            </div>
                        </div>
                        <!-- Montant a payer -->

                        <!-- Pourboire -->
                        <div class="element-pair" v-if="dataPage.pourboire == '1'">
                            <div class="element-label">
                                <span>
                                    {{ $t("text_pourboire.value") }}
                                </span>
                            </div>
                            <div class="element-pair-container">
                                <label :style="pourboire !== '0'
                                    ? 'background: #eeeeee; border: #eeeeee; color: rgb(119 119 119);width: 90px; display: flex; align-items: center; border-radius: 10px; height: 30px; justify-content: center;'
                                    : 'width: 90px; display: flex; align-items: center; border-radius: 10px; height: 30px; justify-content: center;'
                                    " class="btn btn-dark btn-sm ml-1 mr-1">
                                    <input type="radio" value="0" v-model="pourboire" style="display: none"/><span
                                        class="font-12" style="z-index: 200">0 F</span>
                                </label>

                                <label :style="pourboire !== '500'
                                    ? 'background: #eeeeee; border: #eeeeee; color: rgb(119 119 119);width: 90px; display: flex; align-items: center; border-radius: 10px; height: 30px; justify-content: center;'
                                    : 'width: 90px; display: flex; align-items: center; border-radius: 10px; height: 30px; justify-content: center;'
                                    " class="btn btn-dark btn-sm ml-1 mr-1">
                                    <input type="radio" value="500" v-model="pourboire" style="display: none"/><span
                                        class="font-12" style="z-index: 200">500 F</span>
                                </label>

                                <label :style="pourboire !== '1000'
                                    ? 'background: #eeeeee; border: #eeeeee; color: rgb(119 119 119);width: 90px; display: flex; align-items: center; border-radius: 10px; height: 30px; justify-content: center;'
                                    : 'width: 90px; display: flex; align-items: center; border-radius: 10px; height: 30px; justify-content: center;'
                                    " class="btn btn-dark btn-sm ml-1 mr-1">
                                    <input type="radio" value="1000" v-model="pourboire" style="display: none"/><span
                                        class="font-12" style="z-index: 200">1000 F</span>
                                </label>

                                <label :style="pourboire !== 'autre'
                                    ? 'background: #eeeeee; border: #eeeeee; color: rgb(119 119 119);width: 90px; display: flex; align-items: center; border-radius: 10px; height: 30px; justify-content: center;'
                                    : 'width: 90px; display: flex; align-items: center; border-radius: 10px; height: 30px; justify-content: center;'
                                    " class="btn btn-dark btn-sm ml-1 mr-1">
                                    <input type="radio" value="autre" v-model="pourboire" style="display: none"/><span
                                        class="font-12" style="z-index: 200">{{
                                        $t("text_pourboire2.value")
                                    }}</span>
                                </label>
                                <!-- </div> -->
                            </div>

                            <div class="element-pair-container" style="align-items: center"
                                 :hidden="pourboire !== 'autre'">
                                <div class="element-wrap element-wrap-on-right">
                                    <input v-model="dataFormulaire.amountPourboire" type="tel"
                                           v-on:keypress="numbersOnly"
                                           class="element-is-input" name="montant" maxlength="5" v-b-tooltip.hover
                                           :title="dataFormulaire.amountPourboire < 10 ||
                                            dataFormulaire.montant > 20000
                                            ? 'Vous pouvez payé au minimum 10 F et au maximum 20 000 F'
                                            : ''
                                            "/>
                                </div>
                                <div>F CFA</div>
                            </div>
                        </div>
                        <!-- Pourboire -->

                        <!-- Numero de telephone -->
                        <div class="element-pair" style="margin-top: 15px">
                            <div class="element-label">
                                <span>{{ $t("text_form.number.value") }}</span>
                            </div>
                            <div class="element-pair-container">
                                <div class="element-wrap element-wrap-on-left">
                                    <multiselect v-model="indice.i" :options="options" :multiple="false"
                                                 placeholder="Indicatif" tag-placeholder="Indicatif" track-by="code"
                                                 label="country"
                                                 :searchable="true" :select-label="''" :deselect-label="''"
                                                 :selected-label="'✓'"
                                                 :allow-empty="false">
                                        <template slot="singleLabel" slot-scope="{ option }">{{
                                                option.country_code
                                            }}
                                        </template>
                                        <span slot="noResult"></span>
                                    </multiselect>
                                </div>
                                <div class="element-wrap element-wrap-on-right">
                                    <input class="element-is-input" type="tel" v-mask="'## ## ## ## ##'"
                                           v-model="dataFormulaire.telephone" name="telephone"
                                           style="font-weight: 900; font-size: 22px"/>
                                </div>
                            </div>
                        </div>
                        <!-- Numero de telephone -->

                        <!-- Nom -->
                        <div v-if="dataPage.custom_fields !== '1'" class="element-pair">
                            <div class="element-pair-container">
                                <div class="element-wrap element-wrap-on-left">
                                    <div class="element-label">
                                        <span>{{ $t("text_form.name.value") }}</span>
                                    </div>
                                    <input v-model="dataFormulaire.nom" type="text" class="element-is-input"
                                           name="name"/>
                                </div>
                                <div class="element-wrap element-wrap-on-right">
                                    <div class="element-label">
                                        <span>{{ $t("text_form.surname.value") }}</span>
                                    </div>
                                    <input v-model="dataFormulaire.prenom" type="text" class="element-is-input"
                                           name="prenom"/>
                                </div>
                            </div>
                        </div>
                        <!-- Prenom(s) -->

                        <!-- Email - v-if dataPage.show_email_by_default == '1' -->
                        <div class="element-wrap" v-if="dataPage.show_email_by_default == '1'">
                            <div class="element-label">
                                <span>{{ $t("text_form.email.value") }}</span>
                            </div>
                            <input v-model="dataFormulaire.email" type="email" class="element-is-input" name="email"/>
                        </div>
                        <!-- Email - v-if dataPage.show_email_by_default == '1' -->

                        <!-- Succursale -->
                        <div class="element-pair-container" v-if="dataPage.boutiques.length > 0">
                            <div class="element-wrap element-wrap-for-select">
                                <div class="element-label">
                                    <span> {{ $t("text_form.branch_store.value") }} </span>
                                    {{ $t("text_form.optional.value") }}
                                </div>
                                <select v-model="dataFormulaire.boutique" type="text" class="element-is-select"
                                        name="succursale">
                                    <option value="" selected>
                                        {{ $t("text_form.branch_store_option.value") }}
                                    </option>
                                    <option :value="boutique" v-for="(boutique, index) in dataPage.boutiques"
                                            :key="index">
                                        {{ boutique.nom_boutique }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <!-- Succursale -->

                        <!-- Numero d'appartement -->
                        <div v-if="dataPage.custom_fields !== '1'" class="element-wrap">
                            <div class="element-label">
                                <span> {{ $t("text_form.apartment_number.value") }} </span>
                            </div>
                            <input v-model="dataFormulaire.motif" type="text" class="element-is-input"
                                   name="apartment_nimber" maxlength="40"/>
                        </div>
                        <!-- Numero d'appartement -->

                        <!-- Infos supplémentaires -->
                        <div>
                            <div class="mt-4 mb-3">
                                <a href="javascript:;" style="font-size: 14px; color: #0e2abd" @click="showInfosSup2">
                                    <i :class="infosSup2 ? 'fas fa-plus' : 'fas fa-minus'"></i>
                                    {{ $t("text_form.additional_data.value") }}
                                </a>
                            </div>

                            <div v-if="!infosSup2">
                                <transition name="fade">
                                    <div class="element-pair">
                                        <!-- Society -->
                                        <div class="element-wrap">
                                            <div class="element-label">
                                                <span>{{ $t("text_form.society.value") }}</span>
                                            </div>
                                            <input v-model="dataFormulaire.society" type="text" class="element-is-input"
                                                   name="societe"/>
                                        </div>
                                        <!-- Society -->

                                        <!-- E-mail -->
                                        <div class="element-wrap" v-if="dataPage.show_email_by_default !== '1'">
                                            <div class="element-label">
                                                <span>{{ $t("text_form.email.value") }}</span>
                                            </div>
                                            <input v-model="dataFormulaire.email" type="email" class="element-is-input"
                                                   name="email"/>
                                        </div>
                                        <!-- E-mail -->
                                    </div>
                                </transition>
                            </div>
                        </div>
                        <!-- Infos supplémentaires -->

                        <!-- Button submit form -->
                        <div class="element-wrap element-wrap-for-submit">
                            <div>
                                <!-- ************************ -->
                                <!-- ************************ -->
                                <!-- ************************ -->
                                <button type="button" v-if="dataPage.show_email_by_default !== '1' &&
                                    dataPage.custom_fields !== '1' &&
                                    dataPage.eticket.length <= 0
                                    " @click="payer" :class="!dataFormulaire.motif ||
        !dataFormulaire.nom ||
        !dataFormulaire.prenom ||
        !dataFormulaire.telephone ||
        dataFormulaire.telephone.length < 11 ||
        !montantFormate.replace(/\s/g, '') ||
        montantFormate.replace(/\s/g, '') < 100 ||
        montantFormate.replace(/\s/g, '') > 1000000
        ? 'btn btn-dark btn-disabled-custom btn-block'
        : 'btn btn-dark btn-block'
        " :disabled="!dataFormulaire.motif ||
        !dataFormulaire.nom ||
        !dataFormulaire.prenom ||
        !dataFormulaire.telephone ||
        dataFormulaire.telephone.length < 11 ||
        !montantFormate.replace(/\s/g, '') ||
        montantFormate.replace(/\s/g, '') < 100 ||
        montantFormate.replace(/\s/g, '') > 1000000
        ">
                                    {{ $t("text_form.button.value") }}
                                    {{
                                        montantFormate.replace(/\s/g, "") !== ""
                                                ? new Intl.NumberFormat("fr-FR", {
                                                    style: "currency",
                                                    currency: "XOF",
                                                }).format(parseFloat(montantFormate.replace(/\s/g, "")))
                                                : ""
                                    }}
                                </button>

                                <button type="button" v-if="dataPage.show_email_by_default !== '1' &&
                                        dataPage.custom_fields !== '1' &&
                                        dataPage.eticket.length > 0
                                        " @click="payer" :class="!dataFormulaire.formule ||
            !dataFormulaire.motif ||
            !dataFormulaire.nom ||
            !dataFormulaire.prenom ||
            !dataFormulaire.telephone ||
            dataFormulaire.telephone.length < 11 ||
            !montantFormate.replace(/\s/g, '') ||
            montantFormate.replace(/\s/g, '') < 100 ||
            montantFormate.replace(/\s/g, '') > 1000000
            ? 'btn btn-dark btn-disabled-custom btn-block'
            : 'btn btn-dark btn-block'
            " :disabled="!dataFormulaire.formule ||
        !dataFormulaire.motif ||
        !dataFormulaire.nom ||
        !dataFormulaire.prenom ||
        !dataFormulaire.telephone ||
        dataFormulaire.telephone.length < 11 ||
        !montantFormate.replace(/\s/g, '') ||
        montantFormate.replace(/\s/g, '') < 100 ||
        montantFormate.replace(/\s/g, '') > 1000000
        ">
                                    {{ $t("text_form.button.value") }}
                                    {{
                                        montantFormate.replace(/\s/g, "") !== ""
                                                ? new Intl.NumberFormat("fr-FR", {
                                                    style: "currency",
                                                    currency: "XOF",
                                                }).format(parseFloat(montantFormate.replace(/\s/g, "")))
                                                : ""
                                    }}
                                </button>
                                <!-- ************************ -->
                                <!-- ************************ -->
                                <!-- ************************ -->

                                <!-- ************************ -->
                                <!-- ************************ -->
                                <!-- ************************ -->
                                <button type="button" v-if="dataPage.show_email_by_default == '1' &&
                                        dataPage.custom_fields !== '1' &&
                                        dataPage.eticket.length <= 0
                                        " @click="payer" :class="!dataFormulaire.motif ||
            !dataFormulaire.email ||
            !dataFormulaire.nom ||
            !dataFormulaire.prenom ||
            !dataFormulaire.telephone ||
            dataFormulaire.telephone.length < 11 ||
            !montantFormate.replace(/\s/g, '') ||
            montantFormate.replace(/\s/g, '') < 100 ||
            montantFormate.replace(/\s/g, '') > 1000000
            ? 'btn btn-dark btn-disabled-custom btn-block'
            : 'btn btn-dark btn-block'
            " :disabled="!dataFormulaire.motif ||
        !dataFormulaire.email ||
        !dataFormulaire.nom ||
        !dataFormulaire.prenom ||
        !dataFormulaire.telephone ||
        dataFormulaire.telephone.length < 11 ||
        !montantFormate.replace(/\s/g, '') ||
        montantFormate.replace(/\s/g, '') < 100 ||
        montantFormate.replace(/\s/g, '') > 1000000
        ">
                                    {{ $t("text_form.button.value") }}
                                    {{
                                        montantFormate.replace(/\s/g, "") !== ""
                                                ? new Intl.NumberFormat("fr-FR", {
                                                    style: "currency",
                                                    currency: "XOF",
                                                }).format(parseFloat(montantFormate.replace(/\s/g, "")))
                                                : ""
                                    }}
                                </button>

                                <button type="button" v-if="dataPage.show_email_by_default == '1' &&
                                        dataPage.custom_fields !== '1' &&
                                        dataPage.eticket.length > 0
                                        " @click="payer" :class="!dataFormulaire.formule ||
            !dataFormulaire.motif ||
            !dataFormulaire.email ||
            !dataFormulaire.nom ||
            !dataFormulaire.prenom ||
            !dataFormulaire.telephone ||
            dataFormulaire.telephone.length < 11 ||
            !montantFormate.replace(/\s/g, '') ||
            montantFormate.replace(/\s/g, '') < 100 ||
            montantFormate.replace(/\s/g, '') > 1000000
            ? 'btn btn-dark btn-disabled-custom btn-block'
            : 'btn btn-dark btn-block'
            " :disabled="!dataFormulaire.formule ||
        !dataFormulaire.motif ||
        !dataFormulaire.email ||
        !dataFormulaire.nom ||
        !dataFormulaire.prenom ||
        !dataFormulaire.telephone ||
        dataFormulaire.telephone.length < 11 ||
        !montantFormate.replace(/\s/g, '') ||
        montantFormate.replace(/\s/g, '') < 100 ||
        montantFormate.replace(/\s/g, '') > 1000000
        ">
                                    {{ $t("text_form.button.value") }}
                                    {{
                                        montantFormate.replace(/\s/g, "") !== ""
                                                ? new Intl.NumberFormat("fr-FR", {
                                                    style: "currency",
                                                    currency: "XOF",
                                                }).format(parseFloat(montantFormate.replace(/\s/g, "")))
                                                : ""
                                    }}
                                </button>
                                <!-- ************************ -->
                                <!-- ************************ -->
                                <!-- ************************ -->

                                <!-- ************************ -->
                                <!-- ************************ -->
                                <!-- ************************ -->
                                <button type="button" v-if="dataPage.show_email_by_default !== '1' &&
                                        dataPage.custom_fields == '1' &&
                                        dataPage.eticket.length <= 0
                                        " @click="payer" :class="!dataFormulaire.immatriculation ||
            !dataFormulaire.service ||
            !dataFormulaire.nom ||
            !dataFormulaire.prenom ||
            !dataFormulaire.telephone ||
            dataFormulaire.telephone.length < 11 ||
            !montantFormate.replace(/\s/g, '') ||
            montantFormate.replace(/\s/g, '') < 100 ||
            montantFormate.replace(/\s/g, '') > 1000000
            ? 'btn btn-dark btn-disabled-custom btn-block'
            : 'btn btn-dark btn-block'
            " :disabled="!dataFormulaire.immatriculation ||
        !dataFormulaire.service ||
        !dataFormulaire.nom ||
        !dataFormulaire.prenom ||
        !dataFormulaire.telephone ||
        dataFormulaire.telephone.length < 11 ||
        !montantFormate.replace(/\s/g, '') ||
        montantFormate.replace(/\s/g, '') < 100 ||
        montantFormate.replace(/\s/g, '') > 1000000
        ">
                                    {{ $t("text_form.button.value") }}
                                    {{
                                        montantFormate.replace(/\s/g, "") !== ""
                                                ? new Intl.NumberFormat("fr-FR", {
                                                    style: "currency",
                                                    currency: "XOF",
                                                }).format(parseFloat(montantFormate.replace(/\s/g, "")))
                                                : ""
                                    }}
                                </button>

                                <button type="button" v-if="dataPage.show_email_by_default !== '1' &&
                                        dataPage.custom_fields == '1' &&
                                        dataPage.eticket.length > 0
                                        " @click="payer" :class="!dataFormulaire.immatriculation ||
            !dataFormulaire.service ||
            !dataFormulaire.nom ||
            !dataFormulaire.prenom ||
            !dataFormulaire.telephone ||
            dataFormulaire.telephone.length < 11 ||
            !montantFormate.replace(/\s/g, '') ||
            montantFormate.replace(/\s/g, '') < 100 ||
            montantFormate.replace(/\s/g, '') > 1000000
            ? 'btn btn-dark btn-disabled-custom btn-block'
            : 'btn btn-dark btn-block'
            " :disabled="!dataFormulaire.immatriculation ||
        !dataFormulaire.service ||
        !dataFormulaire.nom ||
        !dataFormulaire.prenom ||
        !dataFormulaire.telephone ||
        dataFormulaire.telephone.length < 11 ||
        !montantFormate.replace(/\s/g, '') ||
        montantFormate.replace(/\s/g, '') < 100 ||
        montantFormate.replace(/\s/g, '') > 1000000
        ">
                                    {{ $t("text_form.button.value") }}
                                    {{
                                        montantFormate.replace(/\s/g, "") !== ""
                                                ? new Intl.NumberFormat("fr-FR", {
                                                    style: "currency",
                                                    currency: "XOF",
                                                }).format(parseFloat(montantFormate.replace(/\s/g, "")))
                                                : ""
                                    }}
                                </button>
                                <!-- ************************ -->
                                <!-- ************************ -->
                                <!-- ************************ -->
                            </div>
                        </div>
                        <!-- Button submit form -->
                    </form>

                    <!-- ******** -->
                    <!-- Bloc success payment -->
                    <!-- ******** -->
                    <success-page :hideRecu="hideRecu" :logoSmallDevice="logoSmallDevice"
                                  :montant="dataFormulaire.montant"
                                  :nom="dataFormulaire.nom" :prenom="dataFormulaire.prenom"
                                  :devise="dataFormulaire.devise"
                                  :motif="dataFormulaire.motif" :refPayment="refPayment"
                                  :nombre_transactions="nombre_transactions">
                    </success-page>
                    <!-- ******** -->
                    <!-- Bloc success payment -->
                    <!-- ******** -->
                </div>
                <!-- ********************** -->
                <!-- ********************** -->
                <!-- Damac Immobilier Form -->
                <!-- ********************** -->
                <!-- ********************** -->

                <!-- ********************** -->
                <!-- ********************** -->
                <!-- Baie des Sirenes Form -->
                <!-- ********************** -->
                <!-- ********************** -->
                <div class="body" v-if="dataPage.baie_sirenes === 1 && dataPage.type !== 'SGI'">
                    <form :class="hideRecu == true ? 'payment-form' : 'payment-form hidden'">
                        <!-- Formules e-ticket -->
                        <div class="element-pair" v-if="dataPage.eticket.length > 0">
                            <div v-if="dataPage.eticket[0].programme.img_event !== null">
                                <div class="element-label" style="line-height: inherit !important;">
                                    <span style="text-transform: uppercase">{{
                                            dataPage.eticket[0].programme.libelle
                                        }}</span>
                                </div>
                                <!-- Image du programme -->
                                <div class="image-container">
                                    <img :src="`https://carte.abidjan.net/apaym_pro/apis/dashboard-merchant/events/${dataPage.eticket[0].programme.img_event}`"
                                         style="width: 100%; object-fit: contain"/>
                                </div>
                                <!-- Image du programme -->
                            </div>

                            <div class="element-label" :style="dataPage.eticket[0].programme.img_event !== null
                                ? 'margin-top: 20px;'
                                : ''
                                ">
                                <span v-if="dataPage.eticket[0].programme.img_event === null">
                                    {{ $t("text_form.formulas.value") }} -
                                    <span style="text-transform: uppercase">{{
                                            dataPage.eticket[0].programme.libelle
                                        }}</span>
                                </span>
                                <span v-else>
                                    {{ $t("text_form.formulas.value") }}
                                </span>
                            </div>

                            <div class="element-pair-container">
                                <b-form-group>
                                    <div class="formules-container">
                                        <div v-for="(formule, index) in dataPage.eticket" :key="index"
                                             :id="formule.libelle">
                                            <div class="formule-bloc" :style="dataFormulaire.formule == formule
                                                ? 'background-color: #f6f6f6; border: 1px solid #005CC8'
                                                : 'background-color: #fdfdfd; ; border: 1px solid #b2bec3'
                                                " v-if="formule.quantite_indisponible === false &&
        formule.expiration_formule === false
        " @click="() => {
        dataFormulaire.formule = formule;
        dataFormulaire.motif = formule.libelle;
        dataFormulaire.quantite_indisponbile =
            formule.quantite_indisponbile;
        getMontantFormule(typeForm, dataFormulaire.formule);
    }
        ">
                                                <div style="
                            display: flex;
                            justify-content: flex-end;
                            align-items: center;
                          ">
                                                    <input type="radio" v-model="dataFormulaire.formule" @change="
                                                        getMontantFormule(
                                                            typeForm,
                                                            dataFormulaire.formule
                                                        )
                                                        " :name="formule.libelle" :value="formule"
                                                           style="cursor: pointer; width: 18px; height: 18px"/>
                                                </div>
                                                <div style="
                            padding: 1px;
                            margin-top: 5px;
                            display: block;
                            line-height: 15px;
                          ">
                                                    <span style="">
                                                        {{ formule.libelle }}
                                                    </span>
                                                </div>

                                                <div style="
                            position: absolute;
                            bottom: 3px;
                            margin-top: 5px;
                          ">
                                                    <span class="" :style="formule.quantite_indisponbile === true
                                                        ? 'font-size: 14px; color: #1e017d; font-weight: bolder'
                                                        : 'font-size: 14px; color: #1b1e21; font-weight: bolder'
                                                        ">
                                                        {{
                                                            new Intl.NumberFormat("fr-FR", {
                                                                style: "currency",
                                                                currency: "XOF",
                                                            }).format(formule.montant)
                                                        }}
                                                    </span>
                                                </div>
                                            </div>

                                            <div class="formule-bloc-disabled" :style="dataFormulaire.formule == formule
                                                ? 'background-color: #f6f6f6; border: 1px solid #005CC8'
                                                : 'background-color: #fdfdfd; ; border: 1px solid #b2bec3'
                                                " v-if="formule.expiration_formule === true">
                                                <div style="
                            width: 100%;
                            display: flex;
                            justify-content: right;
                          ">
                                                    <div style="
                              display: flex;
                              justify-content: space-around;
                              align-items: center;
                              padding: 2px;
                              border-radius: 5px;
                              background-color: #fff3cd;
                              color: #f77f00;
                              width: 60px;
                              border: 1px solid rgb(247 127 0 / 25%);
                            ">
                                                        <span class="font-11">{{
                                                                $t("text_form.formulas_expired.value")
                                                            }}</span>
                                                        <i class="fa fa-clock font-11"></i>
                                                    </div>
                                                </div>
                                                <div style="
                            padding: 1px;
                            margin-top: 5px;
                            display: block;
                            line-height: 15px;
                          ">
                                                    <span style="">
                                                        {{ formule.libelle }}
                                                    </span>
                                                </div>

                                                <div style="
                            position: absolute;
                            bottom: 3px;
                            margin-top: 5px;
                          ">
                                                    <span class="" :style="formule.quantite_indisponbile === true
                                                        ? 'font-size: 14px; color: #1e017d; font-weight: bolder'
                                                        : 'font-size: 14px; color: #1b1e21; font-weight: bolder'
                                                        ">
                                                        {{
                                                            new Intl.NumberFormat("fr-FR", {
                                                                style: "currency",
                                                                currency: "XOF",
                                                            }).format(formule.montant)
                                                        }}
                                                    </span>

                                                    <br/>
                                                </div>
                                            </div>

                                            <div class="formule-bloc-disabled" :style="dataFormulaire.formule == formule
                                                ? 'background-color: #f6f6f6; border: 1px solid #005CC8'
                                                : 'background-color: #fdfdfd; ; border: 1px solid #b2bec3'
                                                " v-if="formule.quantite_indisponible === true">
                                                <div style="
                            width: 100%;
                            display: flex;
                            justify-content: right;
                          ">
                                                    <div style="
                              display: flex;
                              justify-content: space-around;
                              align-items: center;
                              padding: 2px;
                              border-radius: 5px;
                              background-color: #f8d7db;
                              color: #dc3545;
                              width: 60px;
                            ">
                                                        <span class="font-11">{{
                                                                $t("text_form.sold_out.value")
                                                            }}</span>
                                                        <i class="fa fa-lock font-11"></i>
                                                    </div>
                                                </div>
                                                <div style="
                            padding: 1px;
                            margin-top: 5px;
                            display: block;
                            line-height: 15px;
                          ">
                                                    <span style="">
                                                        {{ formule.libelle }}
                                                    </span>
                                                </div>

                                                <div style="
                            position: absolute;
                            bottom: 3px;
                            margin-top: 5px;
                          ">
                                                    <span class="" :style="formule.quantite_indisponbile === true
                                                        ? 'font-size: 14px; color: #1e017d; font-weight: bolder'
                                                        : 'font-size: 14px; color: #1b1e21; font-weight: bolder'
                                                        ">
                                                        {{
                                                            new Intl.NumberFormat("fr-FR", {
                                                                style: "currency",
                                                                currency: "XOF",
                                                            }).format(formule.montant)
                                                        }}
                                                    </span>

                                                    <br/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div v-if="dataFormulaire.formule" class="formule-info">
                                        {{ dataFormulaire.formule.description }}
                                    </div>
                                </b-form-group>
                            </div>
                        </div>
                        <!-- Formules e-ticket -->

                        <!-- Montant a payer -->
                        <div class="element-pair">
                            <div class="element-label">
                                <span>
                                    {{ $t("text_form.amount.value") }}
                                    <a href="javascript:;" class="none-outline" style="color: rgb(14, 42, 189)" v-if="!(
                                        dataPage.amount !== '' &&
                                        parseFloat(dataPage.amount) >= 100
                                    )
                                        " v-b-modal.convertAmount>
                                        | {{ $t("text_form.convert_amount.value") }} &nbsp;
                                        <i class="fas fa-euro-sign"></i> &nbsp;
                                        <i class="fas fa-dollar-sign"></i>
                                    </a>
                                </span>
                            </div>
                            <div class="element-pair-container" style="align-items: center">
                                <div class="element-wrap element-wrap-on-right">
                                    <div style="
                      display: flex;
                      flex-direction: row;
                      align-items: center;
                    " v-if="dataPage.eticket.length <= 0">
                                        <div class="element-is-input" v-if="dataPage.amount !== '' &&
                                            parseFloat(dataPage.amount) >= 100
                                            " style="
                        font-weight: 900;
                        font-size: 22px;
                        pointer-events: none;
                        background-color: #eee;
                        border: 1px solid #ccc;
                      ">
                                            {{ dataPage.amount | formatAmount }}
                                        </div>

                                        <input v-if="dataPage.amount == ''" :disabled="dataPage.amount !== '' &&
                                            parseFloat(dataPage.amount) >= 100
                                            " v-model="montantFormate" type="tel" v-on:keypress="numbersOnly"
                                               v-mask="currencyMask" class="element-is-input" name="montant"
                                               maxlength="9"
                                               v-b-tooltip.hover :title="(montantFormate.replace(/\s/g, '') !== '' &&
                                                montantFormate.replace(/\s/g, '') < 100) ||
                                                montantFormate.replace(/\s/g, '') > 1000000
                                                ? 'Vous pouvez payer au minimum 100 F et au maximum 1 000 000 F'
                                                : ''
                                                " :style="(montantFormate.replace(/\s/g, '') !== '' &&
        montantFormate.replace(/\s/g, '') < 100) ||
        montantFormate.replace(/\s/g, '') > 1000000
        ? 'border: 1px solid red; font-weight: 900; font-size: 22px'
        : 'font-weight: 900; font-size: 22px'
        "/>
                                        <a href="javascript:void(0);" @click="openCalculator" v-if="!(
                                            dataPage.amount !== '' &&
                                            parseFloat(dataPage.amount) >= 100
                                        )
                                            " style="
                        font-size: 18px;
                        color: #0e2abd;
                        margin-left: -35px;
                      ">
                                            <i class="fas fa-calculator"></i>
                                        </a>
                                    </div>

                                    <div class="element-is-input" v-else style="
                      font-weight: 900;
                      font-size: 22px;
                      pointer-events: none;
                      background-color: rgb(238, 238, 238);
                      border: 1px solid rgb(204, 204, 204);
                    ">
                                        {{ montantFormate }}
                                    </div>
                                </div>

                                <div style="font-weight: 800; font-size: 20px; white-space: nowrap">
                                    F CFA
                                </div>
                            </div>
                        </div>
                        <!-- Montant a payer -->

                        <!-- Pourboire -->
                        <div class="element-pair" v-if="dataPage.pourboire == '1'">
                            <div class="element-label">
                                <span>
                                    {{ $t("text_pourboire.value") }}
                                </span>
                            </div>
                            <div class="element-pair-container">
                                <label :style="pourboire !== '0'
                                    ? 'background: #eeeeee; border: #eeeeee; color: rgb(119 119 119);width: 90px; display: flex; align-items: center; border-radius: 10px; height: 30px; justify-content: center;'
                                    : 'width: 90px; display: flex; align-items: center; border-radius: 10px; height: 30px; justify-content: center;'
                                    " class="btn btn-dark btn-sm ml-1 mr-1">
                                    <input type="radio" value="0" v-model="pourboire" style="display: none"/><span
                                        class="font-12" style="z-index: 200">0 F</span>
                                </label>

                                <label :style="pourboire !== '500'
                                    ? 'background: #eeeeee; border: #eeeeee; color: rgb(119 119 119);width: 90px; display: flex; align-items: center; border-radius: 10px; height: 30px; justify-content: center;'
                                    : 'width: 90px; display: flex; align-items: center; border-radius: 10px; height: 30px; justify-content: center;'
                                    " class="btn btn-dark btn-sm ml-1 mr-1">
                                    <input type="radio" value="500" v-model="pourboire" style="display: none"/><span
                                        class="font-12" style="z-index: 200">500 F</span>
                                </label>

                                <label :style="pourboire !== '1000'
                                    ? 'background: #eeeeee; border: #eeeeee; color: rgb(119 119 119);width: 90px; display: flex; align-items: center; border-radius: 10px; height: 30px; justify-content: center;'
                                    : 'width: 90px; display: flex; align-items: center; border-radius: 10px; height: 30px; justify-content: center;'
                                    " class="btn btn-dark btn-sm ml-1 mr-1">
                                    <input type="radio" value="1000" v-model="pourboire" style="display: none"/><span
                                        class="font-12" style="z-index: 200">1000 F</span>
                                </label>

                                <label :style="pourboire !== 'autre'
                                    ? 'background: #eeeeee; border: #eeeeee; color: rgb(119 119 119);width: 90px; display: flex; align-items: center; border-radius: 10px; height: 30px; justify-content: center;'
                                    : 'width: 90px; display: flex; align-items: center; border-radius: 10px; height: 30px; justify-content: center;'
                                    " class="btn btn-dark btn-sm ml-1 mr-1">
                                    <input type="radio" value="autre" v-model="pourboire" style="display: none"/><span
                                        class="font-12" style="z-index: 200">{{
                                        $t("text_pourboire2.value")
                                    }}</span>
                                </label>
                                <!-- </div> -->
                            </div>

                            <div class="element-pair-container" style="align-items: center"
                                 :hidden="pourboire !== 'autre'">
                                <div class="element-wrap element-wrap-on-right">
                                    <input v-model="dataFormulaire.amountPourboire" type="tel"
                                           v-on:keypress="numbersOnly"
                                           class="element-is-input" name="montant" maxlength="5" v-b-tooltip.hover
                                           :title="dataFormulaire.amountPourboire < 10 ||
                                            dataFormulaire.montant > 20000
                                            ? 'Vous pouvez payé au minimum 10 F et au maximum 20 000 F'
                                            : ''
                                            "/>
                                </div>
                                <div>F CFA</div>
                            </div>
                        </div>
                        <!-- Pourboire -->

                        <!-- Numero de telephone -->
                        <div class="element-pair" style="margin-top: 15px">
                            <div class="element-label">
                                <span>{{ $t("text_form.number.value") }}</span>
                            </div>
                            <div class="element-pair-container">
                                <div class="element-wrap element-wrap-on-left">
                                    <multiselect v-model="indice.i" :options="options" :multiple="false"
                                                 placeholder="Indicatif" tag-placeholder="Indicatif" track-by="code"
                                                 label="country"
                                                 :searchable="true" :select-label="''" :deselect-label="''"
                                                 :selected-label="'✓'"
                                                 :allow-empty="false">
                                        <template slot="singleLabel" slot-scope="{ option }">{{
                                                option.country_code
                                            }}
                                        </template>
                                        <span slot="noResult"></span>
                                    </multiselect>
                                </div>
                                <div class="element-wrap element-wrap-on-right">
                                    <input class="element-is-input" type="tel" v-mask="'## ## ## ## ##'"
                                           v-model="dataFormulaire.telephone" name="telephone"
                                           style="font-weight: 900; font-size: 22px"/>
                                </div>
                            </div>
                        </div>
                        <!-- Numero de telephone -->

                        <!-- Nom -->
                        <div class="element-pair">
                            <div class="element-pair-container">
                                <div class="element-wrap element-wrap-on-left">
                                    <div class="element-label">
                                        <span>{{ $t("text_form.name.value") }}</span>
                                    </div>
                                    <input v-model="dataFormulaire.nom" type="text" class="element-is-input"
                                           name="name"/>
                                </div>
                                <div class="element-wrap element-wrap-on-right">
                                    <div class="element-label">
                                        <span>{{ $t("text_form.surname.value") }}</span>
                                    </div>
                                    <input v-model="dataFormulaire.prenom" type="text" class="element-is-input"
                                           name="prenom"/>
                                </div>
                            </div>
                        </div>
                        <!-- Prenom(s) -->

                        <!-- Succursale -->
                        <div class="element-pair-container" v-if="dataPage.boutiques.length > 0">
                            <div class="element-wrap element-wrap-for-select">
                                <div class="element-label">
                                    <span> {{ $t("text_form.branch_store.value") }} </span>
                                    {{ $t("text_form.optional.value") }}
                                </div>
                                <select v-model="dataFormulaire.boutique" type="text" class="element-is-select"
                                        name="succursale">
                                    <option value="" selected>
                                        {{ $t("text_form.branch_store_option.value") }}
                                    </option>
                                    <option :value="boutique" v-for="(boutique, index) in dataPage.boutiques"
                                            :key="index">
                                        {{ boutique.nom_boutique }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <!-- Succursale -->

                        <!-- Motif -->
                        <div class="element-wrap">
                            <div class="element-label">
                                <span>{{
                                        $t("text_form.hotel_reservation_number.value")
                                    }}</span>
                            </div>
                            <input v-model="dataFormulaire.motif" type="text" class="element-is-input" name="motif"
                                   maxlength="40" v-if="dataPage.eticket.length <= 0"/>
                            <div class="element-is-input" v-else style="
                  font-weight: 900;
                  pointer-events: none;
                  background-color: rgb(238, 238, 238);
                  border: 1px solid rgb(204, 204, 204);
                ">
                                {{ dataFormulaire.motif }}
                            </div>
                        </div>
                        <!-- Motif -->

                        <!-- Infos supplémentaires -->
                        <div>
                            <div class="mt-4 mb-3">
                                <a href="javascript:;" style="font-size: 14px; color: #0e2abd" @click="showInfosSup2">
                                    <i :class="infosSup2 ? 'fas fa-plus' : 'fas fa-minus'"></i>
                                    {{ $t("text_form.additional_data.value") }}
                                </a>
                            </div>

                            <div v-if="!infosSup2">
                                <transition name="fade">
                                    <div class="element-pair">
                                        <!-- Society -->
                                        <div class="element-wrap">
                                            <div class="element-label">
                                                <span>{{ $t("text_form.remark.value") }}</span>
                                            </div>
                                            <input v-model="dataFormulaire.society" type="text" class="element-is-input"
                                                   name="societe"/>
                                        </div>
                                        <!-- Society -->
                                    </div>
                                </transition>
                            </div>
                        </div>
                        <!-- Infos supplémentaires -->

                        <!-- Button submit form -->
                        <div class="element-wrap element-wrap-for-submit">
                            <div>
                                <button type="button" @click="payerBaieSirenes" v-if="dataPage.eticket.length <= 0"
                                        :class="!dataFormulaire.motif ||
                                    !dataFormulaire.nom ||
                                    !dataFormulaire.prenom ||
                                    !dataFormulaire.telephone ||
                                    dataFormulaire.telephone.length < 11 ||
                                    !montantFormate.replace(/\s/g, '') ||
                                    montantFormate.replace(/\s/g, '') < 100 ||
                                    montantFormate.replace(/\s/g, '') > 1000000
                                    ? 'btn btn-dark btn-disabled-custom btn-block'
                                    : 'btn btn-dark btn-block'
                                    " :disabled="!dataFormulaire.motif ||
        !dataFormulaire.nom ||
        !dataFormulaire.prenom ||
        !dataFormulaire.telephone ||
        dataFormulaire.telephone.length < 11 ||
        !montantFormate.replace(/\s/g, '') ||
        montantFormate.replace(/\s/g, '') < 100 ||
        montantFormate.replace(/\s/g, '') > 1000000
        ">
                                    {{ $t("text_form.button.value") }}
                                    {{
                                        montantFormate.replace(/\s/g, "") !== ""
                                                ? new Intl.NumberFormat("fr-FR", {
                                                    style: "currency",
                                                    currency: "XOF",
                                                }).format(parseFloat(montantFormate.replace(/\s/g, "")))
                                                : ""
                                    }}
                                </button>

                                <button type="button" @click="payerBaieSirenes" v-else :class="!dataFormulaire.formule ||
                                            !dataFormulaire.motif ||
                                            !dataFormulaire.nom ||
                                            !dataFormulaire.prenom ||
                                            !dataFormulaire.telephone ||
                                            dataFormulaire.telephone.length < 11 ||
                                            !montantFormate.replace(/\s/g, '') ||
                                            montantFormate.replace(/\s/g, '') < 100 ||
                                            montantFormate.replace(/\s/g, '') > 1000000
                                            ? 'btn btn-dark btn-disabled-custom btn-block'
                                            : 'btn btn-dark btn-block'
                                        " :disabled="!dataFormulaire.formule ||
            !dataFormulaire.motif ||
            !dataFormulaire.nom ||
            !dataFormulaire.prenom ||
            !dataFormulaire.telephone ||
            dataFormulaire.telephone.length < 11 ||
            !montantFormate.replace(/\s/g, '') ||
            montantFormate.replace(/\s/g, '') < 100 ||
            montantFormate.replace(/\s/g, '') > 1000000
            ">
                                    {{ $t("text_form.button.value") }}
                                    {{
                                        montantFormate.replace(/\s/g, "") !== ""
                                                ? new Intl.NumberFormat("fr-FR", {
                                                    style: "currency",
                                                    currency: "XOF",
                                                }).format(parseFloat(montantFormate.replace(/\s/g, "")))
                                                : ""
                                    }}
                                </button>
                            </div>
                        </div>
                        <!-- Button submit form -->
                    </form>

                    <!-- ******** -->
                    <!-- Bloc success payment -->
                    <!-- ******** -->
                    <success-page :hideRecu="hideRecu" :logoSmallDevice="logoSmallDevice"
                                  :montant="dataFormulaire.montant"
                                  :nom="dataFormulaire.nom" :prenom="dataFormulaire.prenom"
                                  :devise="dataFormulaire.devise"
                                  :motif="dataFormulaire.motif" :refPayment="refPayment"
                                  :nombre_transactions="nombre_transactions">
                    </success-page>
                    <!-- ******** -->
                    <!-- Bloc success payment -->
                    <!-- ******** -->
                </div>
                <!-- ********************** -->
                <!-- ********************** -->
                <!-- Baie des Sirenes Form -->
                <!-- ********************** -->
                <!-- ********************** -->

                <!-- ******************** -->
                <!-- ******************** -->
                <!-- NSIA ASSURANCES Form -->
                <!-- ******************** -->
                <!-- ******************** -->
                <div class="body" v-if="dataPage.merchant == '24111' && dataPage.type !== 'SGI'">
                    <form name="payment-form" :class="hideRecu == true ? 'payment-form' : 'payment-form hidden'">
                        <!-- Formules e-ticket -->
                        <div class="element-pair" v-if="dataPage.eticket.length > 0">
                            <div class="element-label" style="line-height: inherit !important;">
                                <span>{{ $t("text_form.formulas.value") }} -
                                    {{ dataPage.eticket[0].programme.libelle }}</span>
                            </div>

                            <div class="element-pair-container">
                                <b-form-group>
                                    <div class="formules-container">
                                        <div v-for="(formule, index) in dataPage.eticket" :key="index"
                                             :id="formule.libelle">
                                            <div class="formule-bloc" :style="dataFormulaire.formule == formule
                                                ? 'background-color: #f6f6f6; border: 1px solid #005CC8'
                                                : 'background-color: #fdfdfd; ; border: 1px solid #b2bec3'
                                                " v-if="formule.quantite_indisponible === false &&
        formule.expiration_formule === false
        " @click="() => {
        dataFormulaire.formule = formule;
        dataFormulaire.motif = formule.libelle;
        dataFormulaire.quantite_indisponbile =
            formule.quantite_indisponbile;
        getMontantFormule(typeForm, dataFormulaire.formule);
    }
        ">
                                                <div style="
                                                    display: flex;
                                                    justify-content: flex-end;
                                                    align-items: center;
                                                ">
                                                    <input type="radio" v-model="dataFormulaire.formule" @change="
                                                        getMontantFormule(
                                                            typeForm,
                                                            dataFormulaire.formule
                                                        )
                                                        " :name="formule.libelle" :value="formule"
                                                           style="cursor: pointer; width: 18px; height: 18px"/>
                                                </div>
                                                <div style="
                                                    padding: 1px;
                                                    margin-top: 5px;
                                                    display: block;
                                                    line-height: 15px;
                                                ">
                                                    <span style="">
                                                        {{ formule.libelle }}
                                                    </span>
                                                </div>

                                                <div style="
                                                    position: absolute;
                                                    bottom: 3px;
                                                    margin-top: 5px;
                                                ">
                                                    <span class="" :style="formule.quantite_indisponbile === true
                                                        ? 'font-size: 14px; color: #1e017d; font-weight: bolder'
                                                        : 'font-size: 14px; color: #1b1e21; font-weight: bolder'
                                                        ">
                                                        {{
                                                            new Intl.NumberFormat("fr-FR", {
                                                                style: "currency",
                                                                currency: "XOF",
                                                            }).format(formule.montant)
                                                        }}
                                                    </span>
                                                </div>
                                            </div>

                                            <div class="formule-bloc-disabled" :style="dataFormulaire.formule == formule
                                                ? 'background-color: #f6f6f6; border: 1px solid #005CC8'
                                                : 'background-color: #fdfdfd; ; border: 1px solid #b2bec3'
                                                " v-if="formule.expiration_formule === true">
                                                <div style="
                                                    width: 100%;
                                                    display: flex;
                                                    justify-content: right;
                                                ">
                                                    <div style="
                                                        display: flex;
                                                        justify-content: space-around;
                                                        align-items: center;
                                                        padding: 2px;
                                                        border-radius: 5px;
                                                        background-color: #fff3cd;
                                                        color: #f77f00;
                                                        width: 60px;
                                                        border: 1px solid rgb(247 127 0 / 25%);
                                                    ">
                                                        <span class="font-11">{{
                                                                $t("text_form.formulas_expired.value")
                                                            }}</span>
                                                        <i class="fa fa-clock font-11"></i>
                                                    </div>
                                                </div>
                                                <div style="
                                                    padding: 1px;
                                                    margin-top: 5px;
                                                    display: block;
                                                    line-height: 15px;
                                                ">
                                                    <span style="">
                                                        {{ formule.libelle }}
                                                    </span>
                                                </div>

                                                <div style="
                                                    position: absolute;
                                                    bottom: 3px;
                                                    margin-top: 5px;
                                                ">
                                                    <span class="" :style="formule.quantite_indisponbile === true
                                                        ? 'font-size: 14px; color: #1e017d; font-weight: bolder'
                                                        : 'font-size: 14px; color: #1b1e21; font-weight: bolder'
                                                        ">
                                                        {{
                                                            new Intl.NumberFormat("fr-FR", {
                                                                style: "currency",
                                                                currency: "XOF",
                                                            }).format(formule.montant)
                                                        }}
                                                    </span>

                                                    <br/>
                                                </div>
                                            </div>

                                            <div class="formule-bloc-disabled" :style="dataFormulaire.formule == formule
                                                ? 'background-color: #f6f6f6; border: 1px solid #005CC8'
                                                : 'background-color: #fdfdfd; ; border: 1px solid #b2bec3'
                                                " v-if="formule.quantite_indisponible === true">
                                                <div style="
                                                    width: 100%;
                                                    display: flex;
                                                    justify-content: right;
                                                ">
                                                    <div style="
                                                        display: flex;
                                                        justify-content: space-around;
                                                        align-items: center;
                                                        padding: 2px;
                                                        border-radius: 5px;
                                                        background-color: #f8d7db;
                                                        color: #dc3545;
                                                        width: 60px;
                                                    ">
                                                        <span class="font-11">{{
                                                                $t("text_form.sold_out.value")
                                                            }}</span>
                                                        <i class="fa fa-lock font-11"></i>
                                                    </div>
                                                </div>
                                                <div style="
                                                    padding: 1px;
                                                    margin-top: 5px;
                                                    display: block;
                                                    line-height: 15px;
                                                ">
                                                    <span style="">
                                                        {{ formule.libelle }}
                                                    </span>
                                                </div>

                                                <div style="
                                                    position: absolute;
                                                    bottom: 3px;
                                                    margin-top: 5px;
                                                ">
                                                    <span class="" :style="formule.quantite_indisponbile === true
                                                        ? 'font-size: 14px; color: #1e017d; font-weight: bolder'
                                                        : 'font-size: 14px; color: #1b1e21; font-weight: bolder'
                                                        ">
                                                        {{
                                                            new Intl.NumberFormat("fr-FR", {
                                                                style: "currency",
                                                                currency: "XOF",
                                                            }).format(formule.montant)
                                                        }}
                                                    </span>

                                                    <br/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div v-if="dataFormulaire.formule" class="formule-info">
                                        {{ dataFormulaire.formule.description }}
                                    </div>
                                </b-form-group>
                            </div>
                        </div>
                        <!-- Formules e-ticket -->

                        <!-- Montant a payer -->
                        <div class="element-pair">
                            <div class="element-label">
                                <span>
                                    {{ $t("text_form.amount.value") }}
                                    <a href="javascript:void(0);" class="none-outline" style="color: rgb(14, 42, 189)"
                                       v-if="!(
                                        dataPage.amount !== '' &&
                                        parseFloat(dataPage.amount) >= 100
                                    )
                                        " v-b-modal.convertAmount>
                                        | {{ $t("text_form.convert_amount.value") }} &nbsp;
                                        <i class="fas fa-euro-sign"></i> &nbsp;
                                        <i class="fas fa-dollar-sign"></i>
                                    </a>
                                </span>
                            </div>
                            <div class="element-pair-container" style="align-items: center">
                                <div class="element-wrap element-wrap-on-right">
                                    <div style="
                      display: flex;
                      flex-direction: row;
                      align-items: center;
                    " v-if="dataPage.eticket.length <= 0">
                                        <div class="element-is-input" v-if="dataPage.amount !== '' &&
                                            parseFloat(dataPage.amount) >= 100
                                            " style="
                        font-weight: 900;
                        font-size: 22px;
                        pointer-events: none;
                        background-color: #eee;
                        border: 1px solid #ccc;
                      ">
                                            {{ dataPage.amount | formatAmount }}
                                        </div>

                                        <input v-if="dataPage.amount == ''" :disabled="dataPage.amount !== '' &&
                                            parseFloat(dataPage.amount) >= 100
                                            " v-model="montantFormate" type="tel" v-on:keypress="numbersOnly"
                                               v-mask="currencyMask" class="element-is-input" name="montant"
                                               maxlength="9"
                                               v-b-tooltip.hover :title="(montantFormate.replace(/\s/g, '') !== '' &&
                                                montantFormate.replace(/\s/g, '') < 100) ||
                                                montantFormate.replace(/\s/g, '') > 1000000
                                                ? 'Vous pouvez payer au minimum 100 F et au maximum 1 000 000 F'
                                                : ''
                                                " :style="(montantFormate.replace(/\s/g, '') !== '' &&
        montantFormate.replace(/\s/g, '') < 100) ||
        montantFormate.replace(/\s/g, '') > 1000000
        ? 'border: 1px solid red; font-weight: 900; font-size: 22px'
        : 'font-weight: 900; font-size: 22px'
        "/>
                                        <a href="javascript:;" @click="openCalculator" v-if="!(
                                            dataPage.amount !== '' &&
                                            parseFloat(dataPage.amount) >= 100
                                        )
                                            " style="
                        font-size: 18px;
                        color: #0e2abd;
                        margin-left: -35px;
                      ">
                                            <i class="fas fa-calculator"></i>
                                        </a>
                                    </div>

                                    <div class="element-is-input" v-else style="
                      font-weight: 900;
                      font-size: 22px;
                      pointer-events: none;
                      background-color: rgb(238, 238, 238);
                      border: 1px solid rgb(204, 204, 204);
                    ">
                                        {{ montantFormate }}
                                    </div>
                                </div>

                                <div style="font-weight: 800; font-size: 20px; white-space: nowrap">
                                    F CFA
                                </div>
                            </div>
                        </div>
                        <!-- Montant a payer -->

                        <!-- Numero de telephone -->
                        <div class="element-pair" style="margin-top: 15px">
                            <div class="element-label" v-if="dataPage.deleg_pdci == '1'">
                                <span>{{ $t("text_form.number_activist.value") }}</span>
                            </div>
                            <div class="element-label" v-if="dataPage.deleg_pdci == '0'">
                                <span>{{ $t("text_form.number.value") }}</span>
                            </div>
                            <div class="element-pair-container">
                                <div class="element-wrap element-wrap-on-left">
                                    <multiselect v-model="indice.i" :options="options" :multiple="false"
                                                 placeholder="Indicatif" tag-placeholder="Indicatif" track-by="code"
                                                 label="country"
                                                 :searchable="true" :select-label="''" :deselect-label="''"
                                                 :selected-label="'✓'"
                                                 :allow-empty="false">
                                        <template slot="singleLabel" slot-scope="{ option }">{{
                                                option.country_code
                                            }}
                                        </template>
                                        <span slot="noResult"></span>
                                    </multiselect>
                                </div>
                                <div class="element-wrap element-wrap-on-right">
                                    <input class="element-is-input" type="tel" v-mask="'## ## ## ## ##'"
                                           v-model="dataFormulaire.telephone" name="telephone"
                                           style="font-weight: 900; font-size: 22px"/>
                                </div>
                            </div>
                        </div>
                        <!-- Numero de telephone -->

                        <!-- Type client -->
                        <div class="element-wrap">
                            <div class="element-label">
                                <span>
                                    {{ $t("text_form.type_client.value") }}
                                </span>
                            </div>
                            <select v-model="dataFormulaire.typeClient" type="text" class="element-is-select"
                                    name="nature"
                                    required>
                                <option value="" selected>
                                    {{ $t("text_form.type_client_selection.value") }}
                                </option>
                                <option value="Particulier">Particulier</option>
                                <option value="Entreprise">Entreprise</option>
                            </select>
                        </div>
                        <!-- Type client -->

                        <!-- Nom -->
                        <div class="element-pair" v-if="dataFormulaire.typeClient === 'Particulier'">
                            <div class="element-pair-container">
                                <div class="element-wrap element-wrap-on-left">
                                    <div class="element-label">
                                        <span>{{ $t("text_form.name.value") }}</span>
                                    </div>
                                    <input v-model="dataFormulaire.nom" type="text" class="element-is-input"
                                           name="name"/>
                                </div>
                                <div class="element-wrap element-wrap-on-right">
                                    <div class="element-label">
                                        <span>{{ $t("text_form.surname.value") }}</span>
                                    </div>
                                    <input v-model="dataFormulaire.prenom" type="text" class="element-is-input"
                                           name="prenom"/>
                                </div>
                            </div>
                        </div>
                        <!-- Prenom(s) -->

                        <!-- Nom Entreprise -->
                        <div class="element-pair" v-if="dataFormulaire.typeClient === 'Entreprise'">
                            <div class="element-pair-container">
                                <div class="element-wrap element-wrap-on-left">
                                    <div class="element-label">
                                        <span>{{ $t("text_form.entreprise.value") }}</span>
                                    </div>
                                    <input v-model="dataFormulaire.nomEntreprise" type="text" class="element-is-input"
                                           name="name"/>
                                </div>
                            </div>
                        </div>
                        <!-- Nom Entreprise -->

                        <!-- Numéro de Police -->
                        <div class="element-pair">
                            <div class="element-pair-container">
                                <div class="element-wrap element-wrap-on-left">
                                    <div class="element-label">
                                        <span>{{ $t("text_form.numero_police.value") }}</span>
                                    </div>
                                    <input v-model="dataFormulaire.numeroPolice" type="text" class="element-is-input"
                                           name="name" required/>
                                </div>
                            </div>
                        </div>
                        <!-- Numéro de Police -->

                        <!-- Numéro de Quittance -->
                        <div class="element-pair">
                            <div class="element-pair-container">
                                <div class="element-wrap element-wrap-on-left">
                                    <div class="element-label">
                                        <span>{{ $t("text_form.numero_quittance.value") }}</span>
                                    </div>
                                    <input v-model="dataFormulaire.numeroQuittance" type="text" class="element-is-input"
                                           name="name" required/>
                                </div>
                            </div>
                        </div>
                        <!-- Numéro de Quittance -->

                        <!-- Succursale -->
                        <div class="element-pair-container" v-if="dataPage.boutiques.length > 0">
                            <div class="element-wrap element-wrap-for-select">
                                <div class="element-label">
                                    <span> {{ $t("text_form.branch_store.value") }} </span>
                                    {{ $t("text_form.optional.value") }}
                                </div>
                                <select v-model="dataFormulaire.boutique" type="text" class="element-is-select"
                                        name="succursale">
                                    <option value="" selected>
                                        {{ $t("text_form.branch_store_option.value") }}
                                    </option>
                                    <option :value="boutique" v-for="(boutique, index) in dataPage.boutiques"
                                            :key="index">
                                        {{ boutique.nom_boutique }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <!-- Succursale -->

                        <!-- Motif -->
                        <div class="element-pair-container">
                            <div class="element-wrap element-wrap-for-select element-wrap-on-left">
                                <div class="element-label">
                                    <span>{{ $t("text_form.reason.value") }} </span>
                                </div>

                                <select v-model="dataFormulaire.motif" type="text" class="element-is-select"
                                        name="motif">
                                    <option value="" selected>
                                        {{ $t("text_form.select_reason.value") }}
                                    </option>
                                    <option value="Règlement prime auto">
                                        Règlement prime auto
                                    </option>
                                    <option value="Règlement prime MRH (Multi-risques Habitation)">
                                        Règlement prime MRH (Multi-risques Habitation)
                                    </option>
                                    <option value="Règlement prime Santé">
                                        Règlement prime Santé
                                    </option>
                                    <option value="Règlement prime IA (Individuel Accident)">
                                        Règlement prime IA (Individuel Accident)
                                    </option>
                                    <option value="Règlement prime Transport">
                                        Règlement prime Transport
                                    </option>
                                    <option value="Règlement prime MP (Multi-risques professionnel)">
                                        Règlement prime MP (Multi-risques professionnel)
                                    </option>
                                    <option value="Autres">Autres</option>
                                </select>
                            </div>
                        </div>
                        <!-- Motif -->

                        <!-- Immatriculation -->
                        <div class="element-pair" v-if="dataFormulaire.motif == 'Règlement prime auto'">
                            <div class="element-pair-container">
                                <div class="element-wrap element-wrap-on-left">
                                    <div class="element-label">
                                        <span>{{ $t("text_form.registration_number.value") }}</span>
                                    </div>
                                    <input v-model="dataFormulaire.immatriculation" type="text" class="element-is-input"
                                           style="text-transform: uppercase" name="immatriculation"/>
                                </div>
                            </div>
                        </div>
                        <!-- Immatriculation -->

                        <!-- Button submit form -->
                        <div class="element-wrap element-wrap-for-submit">
                            <div>
                                <!-- ********************************** -->
                                <!-- ********************************** -->
                                <!-- ********************************** -->
                                <button type="button" v-if="dataPage.show_email_by_default !== '1' &&
                                    dataPage.custom_fields !== '1' &&
                                    dataPage.fixedAmount !== '0' &&
                                    dataPage.eticket.length <= 0
                                    " @click="payer" :class="checkForm
        ? 'btn btn-dark btn-disabled-custom btn-block'
        : 'btn btn-dark btn-block'
        " :disabled="checkForm">
                                    {{ $t("text_form.button.value") }}
                                    {{
                                        montantFormate.replace(/\s/g, "") !== ""
                                                ? new Intl.NumberFormat("fr-FR", {
                                                    style: "currency",
                                                    currency: "XOF",
                                                }).format(parseFloat(montantFormate.replace(/\s/g, "")))
                                                : ""
                                    }}
                                </button>

                                <button type="button" v-if="dataPage.show_email_by_default !== '1' &&
                                        dataPage.custom_fields !== '1' &&
                                        dataPage.fixedAmount !== '0' &&
                                        dataPage.eticket.length > 0
                                        " @click="payer" :class="!dataFormulaire.formule ||
            !dataFormulaire.nom ||
            !dataFormulaire.prenom ||
            !dataFormulaire.telephone ||
            dataFormulaire.telephone.length < 11 ||
            !montantFormate.replace(/\s/g, '') ||
            montantFormate.replace(/\s/g, '') < 100 ||
            montantFormate.replace(/\s/g, '') > 1000000
            ? 'btn btn-dark btn-disabled-custom btn-block'
            : 'btn btn-dark btn-block'
            " :disabled="!dataFormulaire.formule ||
        !dataFormulaire.nom ||
        !dataFormulaire.prenom ||
        !dataFormulaire.telephone ||
        dataFormulaire.telephone.length < 11 ||
        !montantFormate.replace(/\s/g, '') ||
        montantFormate.replace(/\s/g, '') < 100 ||
        montantFormate.replace(/\s/g, '') > 1000000
        ">
                                    {{ $t("text_form.button.value") }}
                                    {{
                                        montantFormate.replace(/\s/g, "") !== ""
                                                ? new Intl.NumberFormat("fr-FR", {
                                                    style: "currency",
                                                    currency: "XOF",
                                                }).format(parseFloat(montantFormate.replace(/\s/g, "")))
                                                : ""
                                    }}
                                </button>
                                <!-- ********************************** -->
                                <!-- ********************************** -->
                                <!-- ********************************** -->

                                <!-- ********************************** -->
                                <!-- ********************************** -->
                                <!-- ********************************** -->
                                <button type="button" v-if="dataPage.show_email_by_default !== '1' &&
                                        dataPage.custom_fields !== '1' &&
                                        dataPage.fixedAmount == '0' &&
                                        dataPage.eticket.length <= 0
                                        " @click="payer" :class="!dataFormulaire.numeroPolice ||
            !dataFormulaire.motif ||
            !dataFormulaire.typeClient ||
            !dataFormulaire.telephone ||
            dataFormulaire.telephone.length < 11 ||
            !montantFormate.replace(/\s/g, '') ||
            montantFormate.replace(/\s/g, '') < 100 ||
            montantFormate.replace(/\s/g, '') > 1000000
            ? 'btn btn-dark btn-disabled-custom btn-block'
            : 'btn btn-dark btn-block'
            " :disabled="!dataFormulaire.numeroPolice ||
        !dataFormulaire.motif ||
        !dataFormulaire.typeClient ||
        !dataFormulaire.telephone ||
        dataFormulaire.telephone.length < 11 ||
        !montantFormate.replace(/\s/g, '') ||
        montantFormate.replace(/\s/g, '') < 100 ||
        montantFormate.replace(/\s/g, '') > 1000000
        ">
                                    {{ $t("text_form.button.value") }}
                                    {{
                                        montantFormate.replace(/\s/g, "") !== ""
                                                ? new Intl.NumberFormat("fr-FR", {
                                                    style: "currency",
                                                    currency: "XOF",
                                                }).format(parseFloat(montantFormate.replace(/\s/g, "")))
                                                : ""
                                    }}
                                </button>

                                <button type="button" v-if="dataPage.show_email_by_default !== '1' &&
                                        dataPage.custom_fields !== '1' &&
                                        dataPage.fixedAmount == '0' &&
                                        dataPage.eticket.length > 0
                                        " @click="payer" :class="!dataFormulaire.formule ||
            !dataFormulaire.motif ||
            !dataFormulaire.nom ||
            !dataFormulaire.prenom ||
            !dataFormulaire.telephone ||
            dataFormulaire.telephone.length < 11 ||
            !montantFormate.replace(/\s/g, '') ||
            montantFormate.replace(/\s/g, '') < 100 ||
            montantFormate.replace(/\s/g, '') > 1000000
            ? 'btn btn-dark btn-disabled-custom btn-block'
            : 'btn btn-dark btn-block'
            " :disabled="!dataFormulaire.formule ||
        !dataFormulaire.motif ||
        !dataFormulaire.nom ||
        !dataFormulaire.prenom ||
        !dataFormulaire.telephone ||
        dataFormulaire.telephone.length < 11 ||
        !montantFormate.replace(/\s/g, '') ||
        montantFormate.replace(/\s/g, '') < 100 ||
        montantFormate.replace(/\s/g, '') > 1000000
        ">
                                    {{ $t("text_form.button.value") }}
                                    {{
                                        montantFormate.replace(/\s/g, "") !== ""
                                                ? new Intl.NumberFormat("fr-FR", {
                                                    style: "currency",
                                                    currency: "XOF",
                                                }).format(parseFloat(montantFormate.replace(/\s/g, "")))
                                                : ""
                                    }}
                                </button>
                                <!-- ********************************** -->
                                <!-- ********************************** -->
                                <!-- ********************************** -->

                                <!-- ********************************** -->
                                <!-- ********************************** -->
                                <!-- ********************************** -->
                                <button type="button" v-if="dataPage.show_email_by_default == '1' &&
                                        dataPage.custom_fields !== '1' &&
                                        dataPage.fixedAmount == '0' &&
                                        dataPage.eticket.length <= 0
                                        " @click="payer" :class="!dataFormulaire.motif ||
            !dataFormulaire.email ||
            !dataFormulaire.nom ||
            !dataFormulaire.prenom ||
            !dataFormulaire.telephone ||
            dataFormulaire.telephone.length < 11 ||
            !montantFormate.replace(/\s/g, '') ||
            montantFormate.replace(/\s/g, '') < 100 ||
            montantFormate.replace(/\s/g, '') > 1000000
            ? 'btn btn-dark btn-disabled-custom btn-block'
            : 'btn btn-dark btn-block'
            " :disabled="!dataFormulaire.motif ||
        !dataFormulaire.email ||
        !dataFormulaire.nom ||
        !dataFormulaire.prenom ||
        !dataFormulaire.telephone ||
        dataFormulaire.telephone.length < 11 ||
        !montantFormate.replace(/\s/g, '') ||
        montantFormate.replace(/\s/g, '') < 100 ||
        montantFormate.replace(/\s/g, '') > 1000000
        ">
                                    {{ $t("text_form.button.value") }}
                                    {{
                                        montantFormate.replace(/\s/g, "") !== ""
                                                ? new Intl.NumberFormat("fr-FR", {
                                                    style: "currency",
                                                    currency: "XOF",
                                                }).format(parseFloat(montantFormate.replace(/\s/g, "")))
                                                : ""
                                    }}
                                </button>

                                <button type="button" v-if="dataPage.show_email_by_default == '1' &&
                                        dataPage.custom_fields !== '1' &&
                                        dataPage.fixedAmount == '0' &&
                                        dataPage.eticket.length > 0
                                        " @click="payer" :class="!dataFormulaire.formule ||
            !dataFormulaire.motif ||
            !dataFormulaire.email ||
            !dataFormulaire.nom ||
            !dataFormulaire.prenom ||
            !dataFormulaire.telephone ||
            dataFormulaire.telephone.length < 11 ||
            !montantFormate.replace(/\s/g, '') ||
            montantFormate.replace(/\s/g, '') < 100 ||
            montantFormate.replace(/\s/g, '') > 1000000
            ? 'btn btn-dark btn-disabled-custom btn-block'
            : 'btn btn-dark btn-block'
            " :disabled="!dataFormulaire.formule ||
        !dataFormulaire.motif ||
        !dataFormulaire.email ||
        !dataFormulaire.nom ||
        !dataFormulaire.prenom ||
        !dataFormulaire.telephone ||
        dataFormulaire.telephone.length < 11 ||
        !montantFormate.replace(/\s/g, '') ||
        montantFormate.replace(/\s/g, '') < 100 ||
        montantFormate.replace(/\s/g, '') > 1000000
        ">
                                    {{ $t("text_form.button.value") }}
                                    {{
                                        montantFormate.replace(/\s/g, "") !== ""
                                                ? new Intl.NumberFormat("fr-FR", {
                                                    style: "currency",
                                                    currency: "XOF",
                                                }).format(parseFloat(montantFormate.replace(/\s/g, "")))
                                                : ""
                                    }}
                                </button>
                                <!-- ********************************** -->
                                <!-- ********************************** -->
                                <!-- ********************************** -->

                                <!-- ********************************** -->
                                <!-- ********************************** -->
                                <!-- ********************************** -->
                                <button type="button" v-if="dataPage.show_email_by_default !== '1' &&
                                        dataPage.custom_fields == '1' &&
                                        dataPage.fixedAmount == '0' &&
                                        dataPage.eticket.length <= 0
                                        " @click="payer" :class="!dataFormulaire.immatriculation ||
            !dataFormulaire.service ||
            !dataFormulaire.nom ||
            !dataFormulaire.prenom ||
            !dataFormulaire.telephone ||
            dataFormulaire.telephone.length < 11 ||
            !montantFormate.replace(/\s/g, '') ||
            montantFormate.replace(/\s/g, '') < 100 ||
            montantFormate.replace(/\s/g, '') > 1000000
            ? 'btn btn-dark btn-disabled-custom btn-block'
            : 'btn btn-dark btn-block'
            " :disabled="!dataFormulaire.immatriculation ||
        !dataFormulaire.service ||
        !dataFormulaire.nom ||
        !dataFormulaire.prenom ||
        !dataFormulaire.telephone ||
        dataFormulaire.telephone.length < 11 ||
        !montantFormate.replace(/\s/g, '') ||
        montantFormate.replace(/\s/g, '') < 100 ||
        montantFormate.replace(/\s/g, '') > 1000000
        ">
                                    {{ $t("text_form.button.value") }}
                                    {{
                                        montantFormate.replace(/\s/g, "") !== ""
                                                ? new Intl.NumberFormat("fr-FR", {
                                                    style: "currency",
                                                    currency: "XOF",
                                                }).format(parseFloat(montantFormate.replace(/\s/g, "")))
                                                : ""
                                    }}
                                </button>

                                <button type="button" v-if="dataPage.show_email_by_default !== '1' &&
                                        dataPage.custom_fields == '1' &&
                                        dataPage.fixedAmount == '0' &&
                                        dataPage.eticket.length > 0
                                        " @click="payer" :class="!dataFormulaire.formule ||
            !dataFormulaire.immatriculation ||
            !dataFormulaire.service ||
            !dataFormulaire.nom ||
            !dataFormulaire.prenom ||
            !dataFormulaire.telephone ||
            dataFormulaire.telephone.length < 11 ||
            !montantFormate.replace(/\s/g, '') ||
            montantFormate.replace(/\s/g, '') < 100 ||
            montantFormate.replace(/\s/g, '') > 1000000
            ? 'btn btn-dark btn-disabled-custom btn-block'
            : 'btn btn-dark btn-block'
            " :disabled="!dataFormulaire.formule ||
        !dataFormulaire.immatriculation ||
        !dataFormulaire.service ||
        !dataFormulaire.nom ||
        !dataFormulaire.prenom ||
        !dataFormulaire.telephone ||
        dataFormulaire.telephone.length < 11 ||
        !montantFormate.replace(/\s/g, '') ||
        montantFormate.replace(/\s/g, '') < 100 ||
        montantFormate.replace(/\s/g, '') > 1000000
        ">
                                    {{ $t("text_form.button.value") }}
                                    {{
                                        montantFormate.replace(/\s/g, "") !== ""
                                                ? new Intl.NumberFormat("fr-FR", {
                                                    style: "currency",
                                                    currency: "XOF",
                                                }).format(parseFloat(montantFormate.replace(/\s/g, "")))
                                                : ""
                                    }}
                                </button>
                                <!-- ********************************** -->
                                <!-- ********************************** -->
                                <!-- ********************************** -->
                            </div>
                        </div>
                        <!-- Button submit form -->
                    </form>

                    <!-- ******** -->
                    <!-- Bloc success payment -->
                    <!-- ******** -->
                    <success-page :hideRecu="hideRecu" :logoSmallDevice="logoSmallDevice"
                                  :montant="dataFormulaire.montant"
                                  :nom="dataFormulaire.nom" :prenom="dataFormulaire.prenom"
                                  :devise="dataFormulaire.devise"
                                  :motif="dataFormulaire.motif" :refPayment="refPayment"
                                  :nombre_transactions="nombre_transactions">
                    </success-page>
                    <!-- ******** -->
                    <!-- Bloc success payment -->
                    <!-- ******** -->
                </div>
                <!-- ******************** -->
                <!-- ******************** -->
                <!-- NSIA ASSURANCES Form -->
                <!-- ******************** -->
                <!-- ******************** -->

                <!-- ********************* -->
                <!-- ********************* -->
                <!-- ZINO CIGARES EL. Form -->
                <!-- ********************* -->
                <!-- ********************* -->
                <div class="body" v-if="dataPage.merchant == '24714' && dataPage.type !== 'SGI'">
                    <form name="payment-form" :class="hideRecu == true ? 'payment-form' : 'payment-form hidden'">
                        <!-- Formules e-ticket -->
                        <div class="element-pair" v-if="dataPage.eticket.length > 0">
                            <div class="element-label" style="line-height: inherit !important;">
                                <span>{{ $t("text_form.formulas.value") }} -
                                    {{ dataPage.eticket[0].programme.libelle }}</span>
                            </div>

                            <div class="element-pair-container">
                                <b-form-group>
                                    <div class="formules-container">
                                        <div v-for="(formule, index) in dataPage.eticket" :key="index"
                                             :id="formule.libelle">
                                            <div class="formule-bloc" :style="dataFormulaire.formule == formule
                                                ? 'background-color: #f6f6f6; border: 1px solid #005CC8'
                                                : 'background-color: #fdfdfd; ; border: 1px solid #b2bec3'
                                                " v-if="formule.quantite_indisponible === false &&
        formule.expiration_formule === false
        " @click="() => {
        dataFormulaire.formule = formule;
        dataFormulaire.motif = formule.libelle;
        dataFormulaire.quantite_indisponbile =
            formule.quantite_indisponbile;
        getMontantFormule(typeForm, dataFormulaire.formule);
    }
        ">
                                                <div style="
                                                    display: flex;
                                                    justify-content: flex-end;
                                                    align-items: center;
                                                ">
                                                    <input type="radio" v-model="dataFormulaire.formule" @change="
                                                        getMontantFormule(
                                                            typeForm,
                                                            dataFormulaire.formule
                                                        )
                                                        " :name="formule.libelle" :value="formule"
                                                           style="cursor: pointer; width: 18px; height: 18px"/>
                                                </div>
                                                <div style="
                                                    padding: 1px;
                                                    margin-top: 5px;
                                                    display: block;
                                                    line-height: 15px;
                                                ">
                                                    <span style="">
                                                        {{ formule.libelle }}
                                                    </span>
                                                </div>

                                                <div style="
                                                    position: absolute;
                                                    bottom: 3px;
                                                    margin-top: 5px;
                                                ">
                                                    <span class="" :style="formule.quantite_indisponbile === true
                                                        ? 'font-size: 14px; color: #1e017d; font-weight: bolder'
                                                        : 'font-size: 14px; color: #1b1e21; font-weight: bolder'
                                                        ">
                                                        {{
                                                            new Intl.NumberFormat("fr-FR", {
                                                                style: "currency",
                                                                currency: "XOF",
                                                            }).format(formule.montant)
                                                        }}
                                                    </span>
                                                </div>
                                            </div>

                                            <div class="formule-bloc-disabled" :style="dataFormulaire.formule == formule
                                                ? 'background-color: #f6f6f6; border: 1px solid #005CC8'
                                                : 'background-color: #fdfdfd; ; border: 1px solid #b2bec3'
                                                " v-if="formule.expiration_formule === true">
                                                <div style="
                                                    width: 100%;
                                                    display: flex;
                                                    justify-content: right;
                                                ">
                                                    <div style="
                                                        display: flex;
                                                        justify-content: space-around;
                                                        align-items: center;
                                                        padding: 2px;
                                                        border-radius: 5px;
                                                        background-color: #fff3cd;
                                                        color: #f77f00;
                                                        width: 60px;
                                                        border: 1px solid rgb(247 127 0 / 25%);
                                                        ">
                                                        <span class="font-11">{{
                                                                $t("text_form.formulas_expired.value")
                                                            }}</span>
                                                        <i class="fa fa-clock font-11"></i>
                                                    </div>
                                                </div>
                                                <div style="
                                                    padding: 1px;
                                                    margin-top: 5px;
                                                    display: block;
                                                    line-height: 15px;
                                                ">
                                                    <span style="">
                                                        {{ formule.libelle }}
                                                    </span>
                                                </div>

                                                <div style="
                                                    position: absolute;
                                                    bottom: 3px;
                                                    margin-top: 5px;
                                                ">
                                                    <span class="" :style="formule.quantite_indisponbile === true
                                                        ? 'font-size: 14px; color: #1e017d; font-weight: bolder'
                                                        : 'font-size: 14px; color: #1b1e21; font-weight: bolder'
                                                        ">
                                                        {{
                                                            new Intl.NumberFormat("fr-FR", {
                                                                style: "currency",
                                                                currency: "XOF",
                                                            }).format(formule.montant)
                                                        }}
                                                    </span>

                                                    <br/>
                                                </div>
                                            </div>

                                            <div class="formule-bloc-disabled" :style="dataFormulaire.formule == formule
                                                ? 'background-color: #f6f6f6; border: 1px solid #005CC8'
                                                : 'background-color: #fdfdfd; ; border: 1px solid #b2bec3'
                                                " v-if="formule.quantite_indisponible === true">
                                                <div style="
                                                    width: 100%;
                                                    display: flex;
                                                    justify-content: right;
                                                ">
                                                    <div style="
                                                        display: flex;
                                                        justify-content: space-around;
                                                        align-items: center;
                                                        padding: 2px;
                                                        border-radius: 5px;
                                                        background-color: #f8d7db;
                                                        color: #dc3545;
                                                        width: 60px;
                                                        ">
                                                        <span class="font-11">{{
                                                                $t("text_form.sold_out.value")
                                                            }}</span>
                                                        <i class="fa fa-lock font-11"></i>
                                                    </div>
                                                </div>
                                                <div style="
                                                    padding: 1px;
                                                    margin-top: 5px;
                                                    display: block;
                                                    line-height: 15px;
                                                ">
                                                    <span style="">
                                                        {{ formule.libelle }}
                                                    </span>
                                                </div>

                                                <div style="
                                                    position: absolute;
                                                    bottom: 3px;
                                                    margin-top: 5px;
                                                ">
                                                    <span class="" :style="formule.quantite_indisponbile === true
                                                        ? 'font-size: 14px; color: #1e017d; font-weight: bolder'
                                                        : 'font-size: 14px; color: #1b1e21; font-weight: bolder'
                                                        ">
                                                        {{
                                                            new Intl.NumberFormat("fr-FR", {
                                                                style: "currency",
                                                                currency: "XOF",
                                                            }).format(formule.montant)
                                                        }}
                                                    </span>

                                                    <br/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div v-if="dataFormulaire.formule" class="formule-info">
                                        {{ dataFormulaire.formule.description }}
                                    </div>
                                </b-form-group>
                            </div>
                        </div>
                        <!-- Formules e-ticket -->

                        <!-- Quantité de boite souhaitée -->
                        <div class="element-pair">
                            <div class="element-pair-container">
                                <div class="element-wrap element-wrap-on-left">
                                    <div class="element-label">
                                        <span>{{ $t("text_form.box_quantity.value") }}</span>
                                    </div>
                                    <select v-model="dataFormulaire.quantite" type="text" class="element-is-select"
                                            name="succursale" @change="selectQuantity()">
                                        <option v-for="item in [1, 2, 3, 4, 5, 6]" :value="item" :key="item">
                                            {{ item }}
                                        </option>
                                    </select>

                                </div>
                            </div>
                        </div>
                        <!-- Quantité de boite souhaitée -->

                        <!-- Montant a payer -->
                        <div class="element-pair">
                            <div class="element-label">
                                <span>
                                    {{ $t("text_form.amount.value") }}
                                    <a href="javascript:void(0);" class="none-outline" style="color: rgb(14, 42, 189)"
                                       v-if="!(
                                        dataPage.amount !== '' &&
                                        parseFloat(dataPage.amount) >= 100
                                    )
                                        " v-b-modal.convertAmount>
                                        | {{ $t("text_form.convert_amount.value") }} &nbsp;
                                        <i class="fas fa-euro-sign"></i> &nbsp;
                                        <i class="fas fa-dollar-sign"></i>
                                    </a>
                                </span>
                            </div>
                            <div class="element-pair-container" style="align-items: center">
                                <div class="element-wrap element-wrap-on-right">
                                    <div style="
                                          display: flex;
                                          flex-direction: row;
                                          align-items: center;
                                        " v-if="dataPage.eticket.length <= 0">
                                        <div class="element-is-input" v-if="dataPage.amount !== '' &&
                                            parseFloat(dataPage.amount) >= 100
                                            " style="
                                                font-weight: 900;
                                                font-size: 22px;
                                                pointer-events: none;
                                                background-color: #eee;
                                                border: 1px solid #ccc;
                                            ">
                                            {{ dataPage.amount | formatAmount }}
                                        </div>

                                        <input v-if="dataPage.amount == ''" :disabled="dataPage.amount !== '' &&
                                            parseFloat(dataPage.amount) >= 100
                                            " v-model="montantFormate" type="tel" v-on:keypress="numbersOnly"
                                               v-mask="currencyMask" class="element-is-input" name="montant"
                                               maxlength="9"
                                               v-b-tooltip.hover :title="(montantFormate.replace(/\s/g, '') !== '' &&
                                                montantFormate.replace(/\s/g, '') < 100) ||
                                                montantFormate.replace(/\s/g, '') > 1000000
                                                ? 'Vous pouvez payer au minimum 100 F et au maximum 1 000 000 F'
                                                : ''
                                                " :style="(montantFormate.replace(/\s/g, '') !== '' &&
        montantFormate.replace(/\s/g, '') < 100) ||
        montantFormate.replace(/\s/g, '') > 1000000
        ? 'border: 1px solid red; font-weight: 900; font-size: 22px'
        : 'font-weight: 900; font-size: 22px'
        "/>
                                        <a href="javascript:;" @click="openCalculator" v-if="!(
                                            dataPage.amount !== '' &&
                                            parseFloat(dataPage.amount) >= 100
                                        )
                                            " style="
                                                font-size: 18px;
                                                color: #0e2abd;
                                                margin-left: -35px;
                                              ">
                                            <i class="fas fa-calculator"></i>
                                        </a>
                                    </div>

                                    <div class="element-is-input" v-else style="
                                          font-weight: 900;
                                          font-size: 22px;
                                          pointer-events: none;
                                          background-color: rgb(238, 238, 238);
                                          border: 1px solid rgb(204, 204, 204);
                                        ">
                                        {{ montantFormate }}
                                    </div>
                                </div>

                                <div style="font-weight: 800; font-size: 20px; white-space: nowrap">
                                    F CFA
                                </div>
                            </div>
                        </div>
                        <!-- Montant a payer -->

                        <!-- Numero de telephone -->
                        <div class="element-pair" style="margin-top: 15px">
                            <div class="element-label" v-if="dataPage.deleg_pdci == '1'">
                                <span>{{ $t("text_form.number_activist.value") }}</span>
                            </div>
                            <div class="element-label" v-if="dataPage.deleg_pdci == '0'">
                                <span>{{ $t("text_form.number.value") }}</span>
                            </div>
                            <div class="element-pair-container">
                                <div class="element-wrap element-wrap-on-left">
                                    <multiselect v-model="indice.i" :options="options" :multiple="false"
                                                 placeholder="Indicatif" tag-placeholder="Indicatif" track-by="code"
                                                 label="country"
                                                 :searchable="true" :select-label="''" :deselect-label="''"
                                                 :selected-label="'✓'"
                                                 :allow-empty="false">
                                        <template slot="singleLabel" slot-scope="{ option }">{{
                                                option.country_code
                                            }}
                                        </template>
                                        <span slot="noResult"></span>
                                    </multiselect>
                                </div>
                                <div class="element-wrap element-wrap-on-right">
                                    <input class="element-is-input" type="tel" v-mask="'## ## ## ## ##'"
                                           v-model="dataFormulaire.telephone" name="telephone"
                                           style="font-weight: 900; font-size: 22px"/>
                                </div>
                            </div>
                        </div>
                        <!-- Numero de telephone -->

                        <!-- Nom -->
                        <div v-if="dataPage.custom_fields !== '1'" class="element-pair">
                            <div class="element-pair-container">
                                <div class="element-wrap element-wrap-on-left">
                                    <div class="element-label" v-if="dataPage.umed === 1">
                                        <span>{{ $t("text_form.name_patient.value") }}</span>
                                    </div>
                                    <div class="element-label" v-else>
                                        <span>{{ $t("text_form.name.value") }}</span>
                                    </div>
                                    <input v-model="dataFormulaire.nom" type="text" class="element-is-input"
                                           name="name"/>
                                </div>
                                <div class="element-wrap element-wrap-on-right">
                                    <div class="element-label" v-if="dataPage.umed === 1">
                                        <span>{{ $t("text_form.surname_patient.value") }}</span>
                                    </div>
                                    <div class="element-label" v-else>
                                        <span>{{ $t("text_form.surname.value") }}</span>
                                    </div>
                                    <input v-model="dataFormulaire.prenom" type="text" class="element-is-input"
                                           name="prenom"/>
                                </div>
                            </div>
                        </div>
                        <!-- Prenom(s) -->

                        <!-- E-mail -->
                        <div class="element-pair-container">
                            <div class="element-wrap">
                                <div class="element-label">
                                    <span v-if="dataPage.deleg_pdci !== '3'">{{ $t("text_form.email.value") }}
                                    </span>
                                    <span v-if="dataPage.yango === 1">{{ $t("text_form.email_driver.value") }}
                                    </span>
                                    <!-- {{ $t('text_form.optional.value') }} -->
                                </div>
                                <input v-model="dataFormulaire.email" type="email" class="element-is-input"
                                       name="email"/>
                            </div>
                        </div>
                        <!-- E-mail -->


                        <!-- Succursale -->
                        <div class="element-pair-container" v-if="dataPage.boutiques.length > 0">
                            <div class="element-wrap element-wrap-for-select">
                                <div class="element-label">
                                    <span> {{ $t("text_form.branch_store.value") }} </span>
                                    {{ $t("text_form.optional.value") }}
                                </div>
                                <select v-model="dataFormulaire.boutique" type="text" class="element-is-select"
                                        name="succursale">
                                    <option value="" selected>
                                        {{ $t("text_form.branch_store_option.value") }}
                                    </option>
                                    <option :value="boutique" v-for="(boutique, index) in dataPage.boutiques"
                                            :key="index">
                                        {{ boutique.nom_boutique }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <!-- Succursale -->

                        <!-- Lieu de livraison -->
                        <div class="element-pair-container">
                            <div class="element-wrap element-wrap-for-select element-wrap-on-left">
                                <div class="element-label">
                                    <span>{{ $t("text_form.delivery_address.value") }} </span>
                                </div>

                                <select v-model="dataFormulaire.adresse" type="text" class="element-is-select"
                                        name="motif">
                                    <option value="" selected>
                                        Choisissez un lieu
                                    </option>
                                    <option value="Cave Sofitel">
                                        Cave Sofitel
                                    </option>
                                    <option value="Cave Cap Nord">
                                        Cave Cap Nord
                                    </option>
                                    <option value="Cave Cap Sud">
                                        Cave Cap Sud
                                    </option>
                                    <option value="Cave Rue des Jardins">
                                        Cave Rue des Jardins
                                    </option>
                                    <option value="Cave Cosmos">
                                        Cave Cosmos
                                    </option>
                                    <option value="Cave Plateau">
                                        Cave Plateau
                                    </option>
                                </select>
                            </div>
                        </div>
                        <!-- Lieu de livraison -->

                        <!-- Client privilégié -->
                        <div class="element-pair-container">
                            <div class="element-wrap element-wrap-for-select element-wrap-on-left">
                                <div class="element-label">
                                    <span>{{ $t("text_form.client_vip.value") }} </span>
                                </div>

                                <select v-model="dataFormulaire.vip" type="text" class="element-is-select" name="motif">
                                    <option value="Oui">
                                        Oui
                                    </option>
                                    <option selected value="Non">
                                        Non
                                    </option>
                                </select>
                            </div>
                        </div>
                        <!-- Client privilégié -->

                        <!-- Button submit form -->
                        <div class="element-wrap element-wrap-for-submit">
                            <div>
                                <!-- ********************************** -->
                                <!-- ********************************** -->
                                <!-- ********************************** -->
                                <button type="button" v-if="dataPage.show_email_by_default !== '1' &&
                                    dataPage.custom_fields !== '1' &&
                                    dataPage.fixedAmount !== '0' &&
                                    dataPage.eticket.length <= 0
                                    " @click="payer" :class="checkForm
        ? 'btn btn-dark btn-disabled-custom btn-block'
        : 'btn btn-dark btn-block'
        " :disabled="checkForm">
                                    {{ $t("text_form.button.value") }}
                                    {{
                                        montantFormate.replace(/\s/g, "") !== ""
                                                ? new Intl.NumberFormat("fr-FR", {
                                                    style: "currency",
                                                    currency: "XOF",
                                                }).format(parseFloat(montantFormate.replace(/\s/g, "")))
                                                : ""
                                    }}
                                </button>

                                <button type="button" v-if="dataPage.show_email_by_default !== '1' &&
                                        dataPage.custom_fields !== '1' &&
                                        dataPage.fixedAmount !== '0' &&
                                        dataPage.eticket.length > 0
                                        " @click="payer" :class="!dataFormulaire.formule ||
            !dataFormulaire.nom ||
            !dataFormulaire.prenom ||
            !dataFormulaire.telephone ||
            dataFormulaire.telephone.length < 11 ||
            !montantFormate.replace(/\s/g, '') ||
            montantFormate.replace(/\s/g, '') < 100 ||
            montantFormate.replace(/\s/g, '') > 1000000
            ? 'btn btn-dark btn-disabled-custom btn-block'
            : 'btn btn-dark btn-block'
            " :disabled="!dataFormulaire.formule ||
        !dataFormulaire.nom ||
        !dataFormulaire.prenom ||
        !dataFormulaire.telephone ||
        dataFormulaire.telephone.length < 11 ||
        !montantFormate.replace(/\s/g, '') ||
        montantFormate.replace(/\s/g, '') < 100 ||
        montantFormate.replace(/\s/g, '') > 1000000
        ">
                                    {{ $t("text_form.button.value") }}
                                    {{
                                        montantFormate.replace(/\s/g, "") !== ""
                                                ? new Intl.NumberFormat("fr-FR", {
                                                    style: "currency",
                                                    currency: "XOF",
                                                }).format(parseFloat(montantFormate.replace(/\s/g, "")))
                                                : ""
                                    }}
                                </button>
                                <!-- ********************************** -->
                                <!-- ********************************** -->
                                <!-- ********************************** -->

                                <!-- ********************************** -->
                                <!-- ********************************** -->
                                <!-- ********************************** -->
                                <button type="button" v-if="dataPage.show_email_by_default !== '1' &&
                                        dataPage.custom_fields !== '1' &&
                                        dataPage.fixedAmount == '0' &&
                                        dataPage.eticket.length <= 0
                                        " @click="payer" :class="!dataFormulaire.quantite ||
            !dataFormulaire.adresse ||
            !dataFormulaire.telephone ||
            dataFormulaire.telephone.length < 11 ||
            !montantFormate.replace(/\s/g, '') ||
            montantFormate.replace(/\s/g, '') < 100 ||
            montantFormate.replace(/\s/g, '') > 1000000
            ? 'btn btn-dark btn-disabled-custom btn-block'
            : 'btn btn-dark btn-block'
            " :disabled="!dataFormulaire.quantite ||
        !dataFormulaire.adresse ||
        !dataFormulaire.telephone ||
        dataFormulaire.telephone.length < 11 ||
        !montantFormate.replace(/\s/g, '') ||
        montantFormate.replace(/\s/g, '') < 100 ||
        montantFormate.replace(/\s/g, '') > 1000000
        ">
                                    {{ $t("text_form.button.value") }}
                                    {{
                                        montantFormate.replace(/\s/g, "") !== ""
                                                ? new Intl.NumberFormat("fr-FR", {
                                                    style: "currency",
                                                    currency: "XOF",
                                                }).format(parseFloat(montantFormate.replace(/\s/g, "")))
                                                : ""
                                    }}
                                </button>

                                <button type="button" v-if="dataPage.show_email_by_default !== '1' &&
                                        dataPage.custom_fields !== '1' &&
                                        dataPage.fixedAmount == '0' &&
                                        dataPage.eticket.length > 0
                                        " @click="payer" :class="!dataFormulaire.formule ||
            !dataFormulaire.motif ||
            !dataFormulaire.nom ||
            !dataFormulaire.prenom ||
            !dataFormulaire.telephone ||
            dataFormulaire.telephone.length < 11 ||
            !montantFormate.replace(/\s/g, '') ||
            montantFormate.replace(/\s/g, '') < 100 ||
            montantFormate.replace(/\s/g, '') > 1000000
            ? 'btn btn-dark btn-disabled-custom btn-block'
            : 'btn btn-dark btn-block'
            " :disabled="!dataFormulaire.formule ||
        !dataFormulaire.motif ||
        !dataFormulaire.nom ||
        !dataFormulaire.prenom ||
        !dataFormulaire.telephone ||
        dataFormulaire.telephone.length < 11 ||
        !montantFormate.replace(/\s/g, '') ||
        montantFormate.replace(/\s/g, '') < 100 ||
        montantFormate.replace(/\s/g, '') > 1000000
        ">
                                    {{ $t("text_form.button.value") }}
                                    {{
                                        montantFormate.replace(/\s/g, "") !== ""
                                                ? new Intl.NumberFormat("fr-FR", {
                                                    style: "currency",
                                                    currency: "XOF",
                                                }).format(parseFloat(montantFormate.replace(/\s/g, "")))
                                                : ""
                                    }}
                                </button>
                                <!-- ********************************** -->
                                <!-- ********************************** -->
                                <!-- ********************************** -->

                                <!-- ********************************** -->
                                <!-- ********************************** -->
                                <!-- ********************************** -->
                                <button type="button" v-if="dataPage.show_email_by_default == '1' &&
                                        dataPage.custom_fields !== '1' &&
                                        dataPage.fixedAmount == '0' &&
                                        dataPage.eticket.length <= 0
                                        " @click="payer" :class="!dataFormulaire.motif ||
            !dataFormulaire.email ||
            !dataFormulaire.nom ||
            !dataFormulaire.prenom ||
            !dataFormulaire.telephone ||
            dataFormulaire.telephone.length < 11 ||
            !montantFormate.replace(/\s/g, '') ||
            montantFormate.replace(/\s/g, '') < 100 ||
            montantFormate.replace(/\s/g, '') > 1000000
            ? 'btn btn-dark btn-disabled-custom btn-block'
            : 'btn btn-dark btn-block'
            " :disabled="!dataFormulaire.motif ||
        !dataFormulaire.email ||
        !dataFormulaire.nom ||
        !dataFormulaire.prenom ||
        !dataFormulaire.telephone ||
        dataFormulaire.telephone.length < 11 ||
        !montantFormate.replace(/\s/g, '') ||
        montantFormate.replace(/\s/g, '') < 100 ||
        montantFormate.replace(/\s/g, '') > 1000000
        ">
                                    {{ $t("text_form.button.value") }}
                                    {{
                                        montantFormate.replace(/\s/g, "") !== ""
                                                ? new Intl.NumberFormat("fr-FR", {
                                                    style: "currency",
                                                    currency: "XOF",
                                                }).format(parseFloat(montantFormate.replace(/\s/g, "")))
                                                : ""
                                    }}
                                </button>

                                <button type="button" v-if="dataPage.show_email_by_default == '1' &&
                                        dataPage.custom_fields !== '1' &&
                                        dataPage.fixedAmount == '0' &&
                                        dataPage.eticket.length > 0
                                        " @click="payer" :class="!dataFormulaire.formule ||
            !dataFormulaire.motif ||
            !dataFormulaire.email ||
            !dataFormulaire.nom ||
            !dataFormulaire.prenom ||
            !dataFormulaire.telephone ||
            dataFormulaire.telephone.length < 11 ||
            !montantFormate.replace(/\s/g, '') ||
            montantFormate.replace(/\s/g, '') < 100 ||
            montantFormate.replace(/\s/g, '') > 1000000
            ? 'btn btn-dark btn-disabled-custom btn-block'
            : 'btn btn-dark btn-block'
            " :disabled="!dataFormulaire.formule ||
        !dataFormulaire.motif ||
        !dataFormulaire.email ||
        !dataFormulaire.nom ||
        !dataFormulaire.prenom ||
        !dataFormulaire.telephone ||
        dataFormulaire.telephone.length < 11 ||
        !montantFormate.replace(/\s/g, '') ||
        montantFormate.replace(/\s/g, '') < 100 ||
        montantFormate.replace(/\s/g, '') > 1000000
        ">
                                    {{ $t("text_form.button.value") }}
                                    {{
                                        montantFormate.replace(/\s/g, "") !== ""
                                                ? new Intl.NumberFormat("fr-FR", {
                                                    style: "currency",
                                                    currency: "XOF",
                                                }).format(parseFloat(montantFormate.replace(/\s/g, "")))
                                                : ""
                                    }}
                                </button>
                                <!-- ********************************** -->
                                <!-- ********************************** -->
                                <!-- ********************************** -->

                                <!-- ********************************** -->
                                <!-- ********************************** -->
                                <!-- ********************************** -->
                                <button type="button" v-if="dataPage.show_email_by_default !== '1' &&
                                        dataPage.custom_fields == '1' &&
                                        dataPage.fixedAmount == '0' &&
                                        dataPage.eticket.length <= 0
                                        " @click="payer" :class="!dataFormulaire.immatriculation ||
            !dataFormulaire.service ||
            !dataFormulaire.nom ||
            !dataFormulaire.prenom ||
            !dataFormulaire.telephone ||
            dataFormulaire.telephone.length < 11 ||
            !montantFormate.replace(/\s/g, '') ||
            montantFormate.replace(/\s/g, '') < 100 ||
            montantFormate.replace(/\s/g, '') > 1000000
            ? 'btn btn-dark btn-disabled-custom btn-block'
            : 'btn btn-dark btn-block'
            " :disabled="!dataFormulaire.immatriculation ||
        !dataFormulaire.service ||
        !dataFormulaire.nom ||
        !dataFormulaire.prenom ||
        !dataFormulaire.telephone ||
        dataFormulaire.telephone.length < 11 ||
        !montantFormate.replace(/\s/g, '') ||
        montantFormate.replace(/\s/g, '') < 100 ||
        montantFormate.replace(/\s/g, '') > 1000000
        ">
                                    {{ $t("text_form.button.value") }}
                                    {{
                                        montantFormate.replace(/\s/g, "") !== ""
                                                ? new Intl.NumberFormat("fr-FR", {
                                                    style: "currency",
                                                    currency: "XOF",
                                                }).format(parseFloat(montantFormate.replace(/\s/g, "")))
                                                : ""
                                    }}
                                </button>

                                <button type="button" v-if="dataPage.show_email_by_default !== '1' &&
                                        dataPage.custom_fields == '1' &&
                                        dataPage.fixedAmount == '0' &&
                                        dataPage.eticket.length > 0
                                        " @click="payer" :class="!dataFormulaire.formule ||
            !dataFormulaire.immatriculation ||
            !dataFormulaire.service ||
            !dataFormulaire.nom ||
            !dataFormulaire.prenom ||
            !dataFormulaire.telephone ||
            dataFormulaire.telephone.length < 11 ||
            !montantFormate.replace(/\s/g, '') ||
            montantFormate.replace(/\s/g, '') < 100 ||
            montantFormate.replace(/\s/g, '') > 1000000
            ? 'btn btn-dark btn-disabled-custom btn-block'
            : 'btn btn-dark btn-block'
            " :disabled="!dataFormulaire.formule ||
        !dataFormulaire.immatriculation ||
        !dataFormulaire.service ||
        !dataFormulaire.nom ||
        !dataFormulaire.prenom ||
        !dataFormulaire.telephone ||
        dataFormulaire.telephone.length < 11 ||
        !montantFormate.replace(/\s/g, '') ||
        montantFormate.replace(/\s/g, '') < 100 ||
        montantFormate.replace(/\s/g, '') > 1000000
        ">
                                    {{ $t("text_form.button.value") }}
                                    {{
                                        montantFormate.replace(/\s/g, "") !== ""
                                                ? new Intl.NumberFormat("fr-FR", {
                                                    style: "currency",
                                                    currency: "XOF",
                                                }).format(parseFloat(montantFormate.replace(/\s/g, "")))
                                                : ""
                                    }}
                                </button>
                                <!-- ********************************** -->
                                <!-- ********************************** -->
                                <!-- ********************************** -->
                            </div>
                        </div>
                        <!-- Button submit form -->
                    </form>

                    <!-- ******** -->
                    <!-- Bloc success payment -->
                    <!-- ******** -->
                    <success-page :hideRecu="hideRecu" :logoSmallDevice="logoSmallDevice"
                                  :montant="dataFormulaire.montant"
                                  :nom="dataFormulaire.nom" :prenom="dataFormulaire.prenom"
                                  :devise="dataFormulaire.devise"
                                  :motif="dataFormulaire.motif" :refPayment="refPayment"
                                  :nombre_transactions="nombre_transactions">
                    </success-page>
                    <!-- ******** -->
                    <!-- Bloc success payment -->
                    <!-- ******** -->
                </div>
                <!-- ********************* -->
                <!-- ********************* -->
                <!-- ZINO CIGARES EL. Form -->
                <!-- ********************* -->
                <!-- ********************* -->


                <!-- ********************* -->
                <!-- ********************* -->
                <!-- Marchand CONVERSATION -->
                <!-- ********************* -->
                <!-- ********************* -->
                <div class="body" v-if="dataPage.merchant === '25010'">
                    <form :class="hideRecu == true ? 'payment-form' : 'payment-form hidden'">


                        <!-- Numero de telephone -->
                        <div class="element-pair" style="margin-top: 15px">
                            <div class="element-label">
                                <span v-if="dataPage.umed === 1">{{
                                        $t("text_form.number_patient.value")
                                    }}</span>
                                <span v-else>{{ $t("text_form.number.value") }}</span>
                            </div>
                            <div class="element-pair-container">
                                <div class="element-wrap element-wrap-on-left">
                                    <multiselect v-model="indice.i" :options="options" :multiple="false"
                                                 placeholder="Indicatif" tag-placeholder="Indicatif" track-by="code"
                                                 label="country"
                                                 :searchable="true" :select-label="''" :deselect-label="''"
                                                 :selected-label="'✓'"
                                                 :allow-empty="false">
                                        <template slot="singleLabel" slot-scope="{ option }">{{
                                                option.country_code
                                            }}
                                        </template>
                                        <span slot="noResult"></span>
                                    </multiselect>
                                </div>
                                <div class="element-wrap element-wrap-on-right">
                                    <input class="element-is-input" type="tel" v-mask="'## ## ## ## ##'"
                                           v-model="dataFormulaire.telephone" name="telephone"
                                           style="font-weight: 900; font-size: 22px"/>
                                </div>
                            </div>
                        </div>
                        <!-- Numero de telephone -->


                        <!-- Nom -->
                        <div v-if="dataPage.custom_fields !== '1'" class="element-pair">
                            <div class="element-pair-container">
                                <div class="element-wrap element-wrap-on-left">
                                    <div class="element-label" v-if="dataPage.umed === 1">
                                        <span>{{ $t("text_form.name_patient.value") }}</span>
                                    </div>
                                    <div class="element-label" v-else>
                                        <span>{{ $t("text_form.name.value") }}</span>
                                    </div>
                                    <input v-model="dataFormulaire.nom" type="text" class="element-is-input"
                                           name="name"/>
                                </div>
                                <div class="element-wrap element-wrap-on-right">
                                    <div class="element-label" v-if="dataPage.umed === 1">
                                        <span>{{ $t("text_form.surname_patient.value") }}</span>
                                    </div>
                                    <div class="element-label" v-else>
                                        <span>{{ $t("text_form.surname.value") }}</span>
                                    </div>
                                    <input v-model="dataFormulaire.prenom" type="text" class="element-is-input"
                                           name="prenom"/>
                                </div>
                            </div>
                        </div>
                        <!-- Prenom(s) -->

                        <!-- E-mail -->
                        <div class="element-wrap">
                            <div class="element-label">
                                <span>{{ $t("text_form.email.value") }}</span>
                            </div>
                            <input v-model="dataFormulaire.email" type="email" class="element-is-input" name="email"/>
                        </div>
                        <!-- E-mail -->


                        <!-- Motif -->
                        <div class="element-wrap hidden">
                            <div class="element-label">
                                <span>{{ $t("text_form.reason.value") }}</span>
                            </div>
                            <input v-model="dataFormulaire.motif" value="Paiement chez CONVERSATION" type="text"
                                   class="element-is-input" name="motif" maxlength="40"/>
                        </div>
                        <!-- Motif -->


                        <!-- Infos supplémentaires -->
                        <div v-if=" dataPage.merchant !== 14040 ">
                            <div class="mt-4 mb-3" v-if="dataPage.deleg_pdci == '0' || dataPage.umed === 1">
                                <a href="javascript:;" style="font-size: 14px; color: #0e2abd" @click="showInfosSup2">
                                    <i :class="infosSup2 ? 'fas fa-plus' : 'fas fa-minus'"></i>
                                    {{ $t("text_form.additional_data.value") }}
                                </a>
                            </div>

                            <div v-if="!infosSup2">
                                <transition name="fade">
                                    <div class="element-pair">
                                        <!-- Society -->
                                        <div class="element-wrap" v-if="dataPage.fixedAmount == '0'">
                                            <div class="element-label">
                                                <span v-if="dataPage.umed === 1">{{
                                                        $t("text_form.society_patient.value")
                                                    }}</span>
                                                <span v-else>{{ $t("text_form.society.value") }}</span>
                                            </div>
                                            <input v-model="dataFormulaire.society" type="text" class="element-is-input"
                                                   name="societe"/>
                                        </div>
                                        <!-- Society -->

                                        <!-- Choix notification client -->
                                        <!-- <div class="element-pair">

                                            <div class="element-label">
                                                <span class="font-13" style="font-weight: lighter;">
                                                    {{ $t("text_receive_notif.value") }}
                                                </span>
                                            </div>

                                            <div class="element-pair-container">

                                                <label
                                                    :style="
                                                        sendNotif !== 'whatsapp' ?
                                                        'background: #eeeeee; border: #eeeeee; color: #cdcccc;width: 90px; display: flex; align-items: center; border-radius: 10px; height: 30px; justify-content: center; z-index: 1' :
                                                        'width: 90px; display: flex; align-items: center; border-radius: 10px; height: 30px; justify-content: center; z-index: 1'
                                                    "
                                                    class="btn btn-primary btn-sm ml-1 mr-1"
                                                >
                                                    <input type="radio" value="whatsapp" v-model="sendNotif" style="display:none;">
                                                    <span class="font-12" style="z-index: 200">
                                                        <i class="fab fa-whatsapp"></i> WhatsApp
                                                    </span>
                                                </label>

                                                <label
                                                    :style="
                                                        sendNotif !== 'sms' ?
                                                        'background: #eeeeee; border: #eeeeee; color: #cdcccc;width: 90px; display: flex; align-items: center; border-radius: 10px; height: 30px; justify-content: center; z-index: 1' :
                                                        'width: 90px; display: flex; align-items: center; border-radius: 10px; height: 30px; justify-content: center; z-index: 1'
                                                    "
                                                    class="btn btn-primary btn-sm ml-1 mr-1">
                                                    <input type="radio" value="sms" v-model="sendNotif" style="display:none;">
                                                    <span class="font-12" style="z-index: 200">
                                                        <i class="fas fa-envelope"></i> SMS
                                                        </span>
                                                </label>

                                            </div>

                                        </div> -->
                                        <!-- Choix notification client -->
                                    </div>
                                </transition>
                            </div>
                        </div>
                        <!-- Infos supplémentaires -->


                        <!-- Button submit form   -->
                        <div class="element-wrap element-wrap-for-submit">
                            <div>
                                <!-- ********************************** -->
                                <!-- ********************************** -->
                                <!-- ********************************** -->
                                <button type="button" @click="payer"
                                        :disabled="!dataFormulaire.nom || !dataFormulaire.prenom || !dataFormulaire.email || !dataFormulaire.telephone || dataFormulaire.telephone.length < 11"
                                        :class="!dataFormulaire.nom || !dataFormulaire.prenom || !dataFormulaire.email || !dataFormulaire.telephone || dataFormulaire.telephone.length < 11 ? 'btn btn-disabled-custom btn-dark btn-block' : 'btn btn-dark btn-block'">
                                    {{ $t("text_form.button.value") }}
                                </button>

                                <!-- ********************************** -->
                                <!-- ********************************** -->
                                <!-- ********************************** -->
                            </div>
                        </div>
                        <!-- Button submit form -->


                    </form>

                    <!-- ******** -->
                    <!-- Bloc success payment -->
                    <!-- ******** -->
                    <success-page :hideRecu="hideRecu" :logoSmallDevice="logoSmallDevice"
                                  :montant="dataFormulaire.montant"
                                  :nom="dataFormulaire.nom" :prenom="dataFormulaire.prenom"
                                  :devise="dataFormulaire.devise"
                                  :motif="dataFormulaire.motif" :refPayment="refPayment"
                                  :nombre_transactions="nombre_transactions">
                    </success-page>
                    <!-- ******** -->
                    <!-- Bloc success payment -->
                    <!-- ******** -->
                </div>
                <!-- ********************* -->
                <!-- ********************* -->
                <!-- Marchand CONVERSATION -->
                <!-- ********************* -->
                <!-- ********************* -->


                <!-- ********************** -->
                <!-- ********************** -->
                <!-- Others enterprise Form -->
                <!-- ********************** -->
                <!-- ********************** -->
                <div class="body" v-if="(dataPage.deleg_pdci == '0' || dataPage.umed === 1) &&
                    dataPage.baie_sirenes !== 1 &&
                    dataPage.air_france !== 1 &&
                    dataPage.damac !== 1 &&
                    dataPage.merchant !== 24111 &&
                    dataPage.merchant !== '24714' &&
                    dataPage.merchant !== '25010' &&
                    dataPage.type !== 'SGI'
                    ">
                    <form :class="hideRecu == true ? 'payment-form' : 'payment-form hidden'">

                        <!-- Formules e-ticket -->
                        <div class="element-pair" v-if="dataPage.eticket.length > 0">
                            <div v-if="dataPage.eticket[0].programme.img_event !== null">
                                <div class="element-label" style="line-height: inherit !important;">
                                    <span style="text-transform: uppercase">{{
                                            dataPage.eticket[0].programme.libelle
                                        }}</span>
                                </div>
                                <!-- Image du programme -->
                                <div class="image-container">
                                    <img :src="`https://carte.abidjan.net/apaym_pro/apis/dashboard-merchant/events/${dataPage.eticket[0].programme.img_event}`"
                                         style="width: 100%; object-fit: contain"/>
                                </div>
                                <!-- Image du programme -->
                            </div>

                            <div class="element-label" :style="dataPage.eticket[0].programme.img_event !== null
                                ? 'margin-top: 20px;'
                                : ''
                                ">
                                <span v-if="dataPage.eticket[0].programme.img_event === null">
                                    {{ $t("text_form.formulas.value") }} -
                                    <span style="text-transform: uppercase">{{
                                            dataPage.eticket[0].programme.libelle
                                        }}</span>
                                </span>
                                <span v-else>
                                    {{ $t("text_form.formulas.value") }}
                                </span>
                            </div>

                            <div class="element-pair-container">
                                <b-form-group>
                                    <div class="formules-container">
                                        <div v-for="(formule, index) in dataPage.eticket" :key="index"
                                             :id="formule.libelle">
                                            <div class="formule-bloc" :style="dataFormulaire.formule == formule
                                                ? 'background-color: #f6f6f6; border: 1px solid #005CC8'
                                                : 'background-color: #fdfdfd; ; border: 1px solid #b2bec3'
                                                " v-if="formule.quantite_indisponible === false &&
        formule.expiration_formule === false
        " @click="() => {
        dataFormulaire.formule = formule;
        dataFormulaire.motif = formule.libelle;
        dataFormulaire.quantite_indisponbile =
            formule.quantite_indisponbile;
        getMontantFormule(typeForm, dataFormulaire.formule);
    }
        ">
                                                <div style="
                            display: flex;
                            justify-content: flex-end;
                            align-items: center;
                          ">
                                                    <input type="radio" v-model="dataFormulaire.formule" @change="
                                                        getMontantFormule(
                                                            typeForm,
                                                            dataFormulaire.formule
                                                        )
                                                        " :name="formule.libelle" :value="formule"
                                                           style="cursor: pointer; width: 18px; height: 18px"/>
                                                </div>
                                                <div style="
                            padding: 1px;
                            margin-top: 5px;
                            display: block;
                            line-height: 15px;
                          ">
                                                    <span style="">
                                                        {{ formule.libelle }}
                                                    </span>
                                                </div>

                                                <div style="
                            position: absolute;
                            bottom: 3px;
                            margin-top: 5px;
                          ">
                                                    <span class="" :style="formule.quantite_indisponbile === true
                                                        ? 'font-size: 14px; color: #1e017d; font-weight: bolder'
                                                        : 'font-size: 14px; color: #1b1e21; font-weight: bolder'
                                                        ">
                                                        {{
                                                            new Intl.NumberFormat("fr-FR", {
                                                                style: "currency",
                                                                currency: "XOF",
                                                            }).format(formule.montant)
                                                        }}
                                                    </span>
                                                </div>
                                            </div>

                                            <div class="formule-bloc-disabled" :style="dataFormulaire.formule == formule
                                                ? 'background-color: #f6f6f6; border: 1px solid #005CC8'
                                                : 'background-color: #fdfdfd; ; border: 1px solid #b2bec3'
                                                " v-if="formule.expiration_formule === true">
                                                <div style="
                            width: 100%;
                            display: flex;
                            justify-content: right;
                          ">
                                                    <div style="
                              display: flex;
                              justify-content: space-around;
                              align-items: center;
                              padding: 2px;
                              border-radius: 5px;
                              background-color: #fff3cd;
                              color: #f77f00;
                              width: 60px;
                              border: 1px solid rgb(247 127 0 / 25%);
                            ">
                                                        <span class="font-11">{{
                                                                $t("text_form.formulas_expired.value")
                                                            }}</span>
                                                        <i class="fa fa-clock font-11"></i>
                                                    </div>
                                                </div>
                                                <div style="
                            padding: 1px;
                            margin-top: 5px;
                            display: block;
                            line-height: 15px;
                          ">
                                                    <span style="">
                                                        {{ formule.libelle }}
                                                    </span>
                                                </div>

                                                <div style="
                            position: absolute;
                            bottom: 3px;
                            margin-top: 5px;
                          ">
                                                    <span class="" :style="formule.quantite_indisponbile === true
                                                        ? 'font-size: 14px; color: #1e017d; font-weight: bolder'
                                                        : 'font-size: 14px; color: #1b1e21; font-weight: bolder'
                                                        ">
                                                        {{
                                                            new Intl.NumberFormat("fr-FR", {
                                                                style: "currency",
                                                                currency: "XOF",
                                                            }).format(formule.montant)
                                                        }}
                                                    </span>

                                                    <br/>
                                                </div>
                                            </div>

                                            <div class="formule-bloc-disabled" :style="dataFormulaire.formule == formule
                                                ? 'background-color: #f6f6f6; border: 1px solid #005CC8'
                                                : 'background-color: #fdfdfd; ; border: 1px solid #b2bec3'
                                                " v-if="formule.quantite_indisponible === true">
                                                <div style="
                            width: 100%;
                            display: flex;
                            justify-content: right;
                          ">
                                                    <div style="
                              display: flex;
                              justify-content: space-around;
                              align-items: center;
                              padding: 2px;
                              border-radius: 5px;
                              background-color: #f8d7db;
                              color: #dc3545;
                              width: 60px;
                            ">
                                                        <span class="font-11">{{
                                                                $t("text_form.sold_out.value")
                                                            }}</span>
                                                        <i class="fa fa-lock font-11"></i>
                                                    </div>
                                                </div>
                                                <div style="
                            padding: 1px;
                            margin-top: 5px;
                            display: block;
                            line-height: 15px;
                          ">
                                                    <span style="">
                                                        {{ formule.libelle }}
                                                    </span>
                                                </div>

                                                <div style="
                            position: absolute;
                            bottom: 3px;
                            margin-top: 5px;
                          ">
                                                    <span class="" :style="formule.quantite_indisponbile === true
                                                        ? 'font-size: 14px; color: #1e017d; font-weight: bolder'
                                                        : 'font-size: 14px; color: #1b1e21; font-weight: bolder'
                                                        ">
                                                        {{
                                                            new Intl.NumberFormat("fr-FR", {
                                                                style: "currency",
                                                                currency: "XOF",
                                                            }).format(formule.montant)
                                                        }}
                                                    </span>

                                                    <br/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div v-if="dataFormulaire.formule" class="formule-info">
                                        {{ dataFormulaire.formule.description }}
                                    </div>
                                </b-form-group>
                            </div>
                        </div>
                        <!-- Formules e-ticket -->

                        <!-- Immatriculation -->
                        <div class="element-pair" v-if="dataPage.custom_fields == '1'">
                            <div class="element-pair-container">
                                <div class="element-wrap element-wrap-on-left">
                                    <div class="element-label">
                                        <span>{{ $t("text_form.registration_number.value") }}</span>
                                    </div>
                                    <input v-model="dataFormulaire.immatriculation" type="text" class="element-is-input"
                                           name="immatriculation"/>
                                </div>
                            </div>
                        </div>
                        <!-- Immatriculation -->

                        <!-- Types de service -->
                        <div class="element-pair" v-if="dataPage.custom_fields == '1'">
                            <div class="element-pair-container">
                                <div class="element-wrap element-wrap-for-select">
                                    <div class="element-label">
                                        <span>{{ $t("text_form.type_service.value") }}</span>
                                    </div>
                                    <select v-model="dataFormulaire.service" type="text" class="element-is-select"
                                            name="service">
                                        <option value="" selected>
                                            {{ $t("text_form.service_option.value") }}
                                        </option>
                                        <option :value="service.nom_service" v-for="(service, index) in typeServices"
                                                :key="index">
                                            {{ service.nom_service }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <!-- Types de service -->

                        <!-- Montant a payer -->
                        <div class="element-pair">
                            <div class="element-label">
                                <span>
                                    {{ $t("text_form.amount.value") }}
                                    <a href="javascript:void(0);" class="none-outline" style="color: rgb(14, 42, 189)"
                                       v-if="!(
                                        dataPage.amount !== '' &&
                                        parseFloat(dataPage.amount) >= 100
                                    )
                                        " v-b-modal.convertAmount>
                                        | {{ $t("text_form.convert_amount.value") }} &nbsp;
                                        <i class="fas fa-euro-sign"></i> &nbsp;
                                        <i class="fas fa-dollar-sign"></i>
                                    </a>
                                </span>
                            </div>
                            <div class="element-pair-container" style="align-items: center">
                                <div class="element-wrap element-wrap-on-right">
                                    <div style="
                      display: flex;
                      flex-direction: row;
                      align-items: center;
                    " v-if="dataPage.eticket.length <= 0">
                                        <div class="element-is-input" v-if="dataPage.amount !== '' &&
                                            parseFloat(dataPage.amount) >= 100
                                            " style="
                        font-weight: 900;
                        font-size: 22px;
                        pointer-events: none;
                        background-color: #eee;
                        border: 1px solid #ccc;
                      ">
                                            {{ dataPage.amount | formatAmount }}
                                        </div>

                                        <input v-if="dataPage.amount == ''" :disabled="dataPage.amount !== '' &&
                                            parseFloat(dataPage.amount) >= 100
                                            " v-model="montantFormate" type="tel" v-on:keypress="numbersOnly"
                                               v-mask="currencyMask" class="element-is-input" name="montant"
                                               maxlength="9"
                                               v-b-tooltip.hover :title="(montantFormate.replace(/\s/g, '') !== '' &&
                                                montantFormate.replace(/\s/g, '') < 100) ||
                                                montantFormate.replace(/\s/g, '') > 1000000
                                                ? 'Vous pouvez payer au minimum 100 F et au maximum 1 000 000 F'
                                                : ''
                                                " :style="(montantFormate.replace(/\s/g, '') !== '' &&
        montantFormate.replace(/\s/g, '') < 100) ||
        montantFormate.replace(/\s/g, '') > 1000000
        ? 'border: 1px solid red; font-weight: 900; font-size: 22px'
        : 'font-weight: 900; font-size: 22px'
        "/>
                                        <a href="javascript:;" @click="openCalculator" v-if="!(
                                            dataPage.amount !== '' &&
                                            parseFloat(dataPage.amount) >= 100
                                        )
                                            " style="
                        font-size: 18px;
                        color: #0e2abd;
                        margin-left: -35px;
                      ">
                                            <i class="fas fa-calculator"></i>
                                        </a>
                                    </div>

                                    <div class="element-is-input" v-else style="
                      font-weight: 900;
                      font-size: 22px;
                      pointer-events: none;
                      background-color: rgb(238, 238, 238);
                      border: 1px solid rgb(204, 204, 204);
                    ">
                                        {{ montantFormate }}
                                    </div>
                                </div>

                                <div style="font-weight: 800; font-size: 20px; white-space: nowrap">
                                    F CFA
                                </div>
                            </div>
                        </div>
                        <!-- Montant a payer -->

                        <!-- Pourboire -->
                        <div class="element-pair" v-if="dataPage.pourboire == '1'">
                            <div class="element-label">
                                <span>
                                    {{ $t("text_pourboire.value") }}
                                </span>
                            </div>
                            <div class="element-pair-container">
                                <label :style="pourboire !== '0'
                                    ? 'background: #eeeeee; border: #eeeeee; color: rgb(119 119 119);width: 90px; display: flex; align-items: center; border-radius: 10px; height: 30px; justify-content: center;'
                                    : 'width: 90px; display: flex; align-items: center; border-radius: 10px; height: 30px; justify-content: center;'
                                    " class="btn btn-dark btn-sm ml-1 mr-1">
                                    <input type="radio" value="0" v-model="pourboire" style="display: none"/><span
                                        class="font-12" style="z-index: 200">0 F</span>
                                </label>

                                <label :style="pourboire !== '500'
                                    ? 'background: #eeeeee; border: #eeeeee; color: rgb(119 119 119);width: 90px; display: flex; align-items: center; border-radius: 10px; height: 30px; justify-content: center;'
                                    : 'width: 90px; display: flex; align-items: center; border-radius: 10px; height: 30px; justify-content: center;'
                                    " class="btn btn-dark btn-sm ml-1 mr-1">
                                    <input type="radio" value="500" v-model="pourboire" style="display: none"/><span
                                        class="font-12" style="z-index: 200">500 F</span>
                                </label>

                                <label :style="pourboire !== '1000'
                                    ? 'background: #eeeeee; border: #eeeeee; color: rgb(119 119 119);width: 90px; display: flex; align-items: center; border-radius: 10px; height: 30px; justify-content: center;'
                                    : 'width: 90px; display: flex; align-items: center; border-radius: 10px; height: 30px; justify-content: center;'
                                    " class="btn btn-dark btn-sm ml-1 mr-1">
                                    <input type="radio" value="1000" v-model="pourboire" style="display: none"/><span
                                        class="font-12" style="z-index: 200">1000 F</span>
                                </label>

                                <label :style="pourboire !== 'autre'
                                    ? 'background: #eeeeee; border: #eeeeee; color: rgb(119 119 119);width: 90px; display: flex; align-items: center; border-radius: 10px; height: 30px; justify-content: center;'
                                    : 'width: 90px; display: flex; align-items: center; border-radius: 10px; height: 30px; justify-content: center;'
                                    " class="btn btn-dark btn-sm ml-1 mr-1">
                                    <input type="radio" value="autre" v-model="pourboire" style="display: none"/><span
                                        class="font-12" style="z-index: 200">{{
                                        $t("text_pourboire2.value")
                                    }}</span>
                                </label>
                                <!-- </div> -->
                            </div>

                            <div class="element-pair-container" style="align-items: center"
                                 :hidden="pourboire !== 'autre'">
                                <div class="element-wrap element-wrap-on-right">
                                    <input v-model="dataFormulaire.amountPourboire" type="tel"
                                           v-on:keypress="numbersOnly"
                                           class="element-is-input" name="montant" maxlength="5" v-b-tooltip.hover
                                           :title="dataFormulaire.amountPourboire < 10 ||
                                            dataFormulaire.montant > 20000
                                            ? 'Vous pouvez payé au minimum 10 F et au maximum 20 000 F'
                                            : ''
                                            "/>
                                </div>
                                <div>F CFA</div>
                            </div>
                        </div>
                        <!-- Pourboire -->

                        <!-- Numero de telephone -->
                        <div class="element-pair" style="margin-top: 15px">
                            <div class="element-label">
                                <span v-if="dataPage.umed === 1">{{
                                        $t("text_form.number_patient.value")
                                    }}</span>
                                <span v-else>{{ $t("text_form.number.value") }}</span>
                            </div>
                            <div class="element-pair-container">
                                <div class="element-wrap element-wrap-on-left">
                                    <multiselect v-model="indice.i" :options="options" :multiple="false"
                                                 placeholder="Indicatif" tag-placeholder="Indicatif" track-by="code"
                                                 label="country"
                                                 :searchable="true" :select-label="''" :deselect-label="''"
                                                 :selected-label="'✓'"
                                                 :allow-empty="false">
                                        <template slot="singleLabel" slot-scope="{ option }">{{
                                                option.country_code
                                            }}
                                        </template>
                                        <span slot="noResult"></span>
                                    </multiselect>
                                </div>
                                <div class="element-wrap element-wrap-on-right">
                                    <input class="element-is-input" type="tel" v-mask="'## ## ## ## ##'"
                                           v-model="dataFormulaire.telephone" name="telephone"
                                           style="font-weight: 900; font-size: 22px"/>
                                </div>
                            </div>
                        </div>
                        <!-- Numero de telephone -->


                        <!-- Numero de compte -->
                        <div class="element-wrap" v-if="dataPage.merchant === '24821'">
                            <div class="element-label">
                                <span>{{ $t("text_form.account_number2.value") }}</span>
                            </div>
                            <input v-model="dataFormulaire.numeroCompte" type="tel" v-on:keypress="numbersOnly"
                                   class="element-is-input" name="numero_compte"/>
                        </div>
                        <!-- Numero de compte -->

                        <!-- Nom -->
                        <div v-if="dataPage.custom_fields !== '1'" class="element-pair">
                            <div class="element-pair-container">
                                <div class="element-wrap element-wrap-on-left">
                                    <div class="element-label" v-if="dataPage.umed === 1">
                                        <span>{{ $t("text_form.name_patient.value") }}</span>
                                    </div>
                                    <div class="element-label" v-else>
                                        <span>{{ $t("text_form.name.value") }}</span>
                                    </div>
                                    <input v-model="dataFormulaire.nom" type="text" class="element-is-input"
                                           name="name"/>
                                </div>
                                <div class="element-wrap element-wrap-on-right">
                                    <div class="element-label" v-if="dataPage.umed === 1">
                                        <span>{{ $t("text_form.surname_patient.value") }}</span>
                                    </div>
                                    <div class="element-label" v-else>
                                        <span>{{ $t("text_form.surname.value") }}</span>
                                    </div>
                                    <input v-model="dataFormulaire.prenom" type="text" class="element-is-input"
                                           name="prenom"/>
                                </div>
                            </div>
                        </div>
                        <!-- Prenom(s) -->

                        <!-- E-mail -->
                        <div class="element-wrap" v-if="dataPage.show_email_by_default !== '1'">
                            <div class="element-label">
                                <span v-if="dataPage.umed === 1">{{
                                        $t("text_form.email_patient.value")
                                    }}</span>
                                <span v-else>{{ $t("text_form.email.value") }}</span>
                            </div>
                            <input v-model="dataFormulaire.email" type="email" class="element-is-input" name="email"/>
                        </div>
                        <!-- E-mail -->

                        <!-- Email - v-if dataPage.show_email_by_default == '1' -->
                        <div class="element-wrap" v-if="dataPage.show_email_by_default == '1'">
                            <div class="element-label">
                                <span v-if="dataPage.umed === 1">{{
                                        $t("text_form.email_patient.value")
                                    }}</span>
                                <span v-else>{{ $t("text_form.email.value") }}</span>
                            </div>
                            <input v-model="dataFormulaire.email" type="email" class="element-is-input" name="email"/>
                        </div>
                        <!-- Email - v-if dataPage.show_email_by_default == '1' -->

                        <!-- Succursale -->
                        <div class="element-pair-container" v-if="dataPage.boutiques.length > 0">
                            <div class="element-wrap element-wrap-for-select">
                                <div class="element-label">
                                    <span> {{ $t("text_form.branch_store.value") }} </span>
                                    {{ $t("text_form.optional.value") }}
                                </div>
                                <select v-model="dataFormulaire.boutique" type="text" class="element-is-select"
                                        name="succursale">
                                    <option value="" selected>
                                        {{ $t("text_form.branch_store_option.value") }}
                                    </option>
                                    <option :value="boutique" v-for="(boutique, index) in dataPage.boutiques"
                                            :key="index">
                                        {{ boutique.nom_boutique }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <!-- Succursale -->

                        <!-- Motif -->
                        <div v-if="dataPage.custom_fields !== '1' && dataPage.fixedAmount == '0'" class="element-wrap">
                            <div class="element-label">
                                <span>{{ $t("text_form.reason.value") }}</span>
                            </div>
                            <input v-model="dataFormulaire.motif" type="text" class="element-is-input" name="motif"
                                   maxlength="40" v-if="dataPage.eticket.length <= 0"/>
                            <div class="element-is-input" v-else style="
                                font-weight: 900;
                                pointer-events: none;
                                background-color: rgb(238, 238, 238);
                                border: 1px solid rgb(204, 204, 204);
                            ">
                                {{ dataFormulaire.motif }}
                            </div>
                        </div>
                        <!-- Motif -->


                        <!-- Fonction JCI -->
                        <div v-if="dataPage.merchant === 14040">
                            <div class="element-label">
                                <span>{{ $i18n.locale == "fr" ? "Fonction JCI" : "JCI occupation" }}</span>
                            </div>
                            <input v-model="dataFormulaire.grade" type="text" class="element-is-input" name="fonction"/>
                        </div>
                        <!-- Fonction JCI -->

                        <!-- Society -->
                        <div class="element-wrap" v-if="dataPage.fixedAmount !== '0'">
                            <div class="element-label">
                                <span>{{ $t("text_form.entreprise.value") }}</span>
                            </div>
                            <input v-model="dataFormulaire.society" type="text" class="element-is-input"
                                   name="societe"/>
                        </div>
                        <!-- Society -->

                        <!-- Infos supplémentaires -->
                        <div v-if=" dataPage.merchant !== 14040 ">
                            <div class="mt-4 mb-3" v-if="dataPage.deleg_pdci == '0' || dataPage.umed === 1">
                                <a href="javascript:;" style="font-size: 14px; color: #0e2abd" @click="showInfosSup2">
                                    <i :class="infosSup2 ? 'fas fa-plus' : 'fas fa-minus'"></i>
                                    {{ $t("text_form.additional_data.value") }}
                                </a>
                            </div>

                            <div v-if="!infosSup2">
                                <transition name="fade">
                                    <div class="element-pair">
                                        <!-- Society -->
                                        <div class="element-wrap" v-if="dataPage.fixedAmount == '0'">
                                            <div class="element-label">
                                                <span v-if="dataPage.umed === 1">{{
                                                        $t("text_form.society_patient.value")
                                                    }}</span>
                                                <span v-else>{{ $t("text_form.society.value") }}</span>
                                            </div>
                                            <input v-model="dataFormulaire.society" type="text" class="element-is-input"
                                                   name="societe"/>
                                        </div>
                                        <!-- Society -->

                                        <!-- Choix notification client -->
                                        <!-- <div class="element-pair">

                                            <div class="element-label">
                                                <span class="font-13" style="font-weight: lighter;">
                                                    {{ $t("text_receive_notif.value") }}
                                                </span>
                                            </div>

                                            <div class="element-pair-container">

                                                <label
                                                    :style="
                                                        sendNotif !== 'whatsapp' ?
                                                        'background: #eeeeee; border: #eeeeee; color: #cdcccc;width: 90px; display: flex; align-items: center; border-radius: 10px; height: 30px; justify-content: center; z-index: 1' :
                                                        'width: 90px; display: flex; align-items: center; border-radius: 10px; height: 30px; justify-content: center; z-index: 1'
                                                    "
                                                    class="btn btn-primary btn-sm ml-1 mr-1"
                                                >
                                                    <input type="radio" value="whatsapp" v-model="sendNotif" style="display:none;">
                                                    <span class="font-12" style="z-index: 200">
                                                        <i class="fab fa-whatsapp"></i> WhatsApp
                                                    </span>
                                                </label>

                                                <label
                                                    :style="
                                                        sendNotif !== 'sms' ?
                                                        'background: #eeeeee; border: #eeeeee; color: #cdcccc;width: 90px; display: flex; align-items: center; border-radius: 10px; height: 30px; justify-content: center; z-index: 1' :
                                                        'width: 90px; display: flex; align-items: center; border-radius: 10px; height: 30px; justify-content: center; z-index: 1'
                                                    "
                                                    class="btn btn-primary btn-sm ml-1 mr-1">
                                                    <input type="radio" value="sms" v-model="sendNotif" style="display:none;">
                                                    <span class="font-12" style="z-index: 200">
                                                        <i class="fas fa-envelope"></i> SMS
                                                        </span>
                                                </label>

                                            </div>

                                        </div> -->
                                        <!-- Choix notification client -->
                                    </div>
                                </transition>
                            </div>
                        </div>
                        <!-- Infos supplémentaires -->


                        <div class="mt-4 mb-3 element-wrap" v-if="dataPage.merchant === 14040">
                            <div class="element-label">
                                <span>{{ $i18n.locale == "fr" ? "Organisation locale" : "Local organisation" }}</span>
                            </div>
                            <input v-model="dataFormulaire.society" type="text" class="element-is-input"
                                   name="societe"/>
                        </div>

                        <!-- Button submit form   -->
                        <div class="element-wrap element-wrap-for-submit" v-if="dataPage.merchant !== '24821'">
                            <div>
                                <!-- ********************************** -->
                                <!-- ********************************** -->
                                <!-- ********************************** -->
                                <button type="button" v-if="dataPage.show_email_by_default !== '1' &&
                                    dataPage.custom_fields !== '1' &&
                                    dataPage.fixedAmount !== '0' &&
                                    dataPage.eticket.length <= 0
                                    " @click="payer" :class="!dataFormulaire.nom ||
        !dataFormulaire.prenom ||
        !dataFormulaire.telephone ||
        dataFormulaire.telephone.length < 11 ||
        !montantFormate.replace(/\s/g, '') ||
        montantFormate.replace(/\s/g, '') < 100 ||
        montantFormate.replace(/\s/g, '') > 1000000
        ? 'btn btn-dark btn-disabled-custom btn-block'
        : 'btn btn-dark btn-block'
        " :disabled="!dataFormulaire.nom ||
        !dataFormulaire.prenom ||
        !dataFormulaire.telephone ||
        dataFormulaire.telephone.length < 11 ||
        !montantFormate.replace(/\s/g, '') ||
        montantFormate.replace(/\s/g, '') < 100 ||
        montantFormate.replace(/\s/g, '') > 1000000
        ">
                                    {{ $t("text_form.button.value") }}
                                    {{
                                        montantFormate.replace(/\s/g, "") !== ""
                                                ? new Intl.NumberFormat("fr-FR", {
                                                    style: "currency",
                                                    currency: "XOF",
                                                }).format(parseFloat(montantFormate.replace(/\s/g, "")))
                                                : ""
                                    }}
                                </button>

                                <button type="button" v-if="dataPage.show_email_by_default !== '1' &&
                                        dataPage.custom_fields !== '1' &&
                                        dataPage.fixedAmount !== '0' &&
                                        dataPage.eticket.length > 0
                                        " @click="payer" :class="!dataFormulaire.formule ||
            !dataFormulaire.nom ||
            !dataFormulaire.prenom ||
            !dataFormulaire.telephone ||
            dataFormulaire.telephone.length < 11 ||
            !montantFormate.replace(/\s/g, '') ||
            montantFormate.replace(/\s/g, '') < 100 ||
            montantFormate.replace(/\s/g, '') > 1000000
            ? 'btn btn-dark btn-disabled-custom btn-block'
            : 'btn btn-dark btn-block'
            " :disabled="!dataFormulaire.formule ||
        !dataFormulaire.nom ||
        !dataFormulaire.prenom ||
        !dataFormulaire.telephone ||
        dataFormulaire.telephone.length < 11 ||
        !montantFormate.replace(/\s/g, '') ||
        montantFormate.replace(/\s/g, '') < 100 ||
        montantFormate.replace(/\s/g, '') > 1000000
        ">
                                    {{ $t("text_form.button.value") }}
                                    {{
                                        montantFormate.replace(/\s/g, "") !== ""
                                                ? new Intl.NumberFormat("fr-FR", {
                                                    style: "currency",
                                                    currency: "XOF",
                                                }).format(parseFloat(montantFormate.replace(/\s/g, "")))
                                                : ""
                                    }}
                                </button>
                                <!-- ********************************** -->
                                <!-- ********************************** -->
                                <!-- ********************************** -->

                                <!-- ********************************** -->
                                <!-- ********************************** -->
                                <!-- ********************************** -->
                                <button type="button" v-if="dataPage.show_email_by_default !== '1' &&
                                        dataPage.custom_fields !== '1' &&
                                        dataPage.fixedAmount == '0' &&
                                        dataPage.eticket.length <= 0
                                        " @click="payer" :class="!dataFormulaire.motif ||
            !dataFormulaire.nom ||
            !dataFormulaire.prenom ||
            !dataFormulaire.telephone ||
            dataFormulaire.telephone.length < 11 ||
            !montantFormate.replace(/\s/g, '') ||
            montantFormate.replace(/\s/g, '') < 100 ||
            montantFormate.replace(/\s/g, '') > 1000000
            ? 'btn btn-dark btn-disabled-custom btn-block'
            : 'btn btn-dark btn-block'
            " :disabled="!dataFormulaire.motif ||
        !dataFormulaire.nom ||
        !dataFormulaire.prenom ||
        !dataFormulaire.telephone ||
        dataFormulaire.telephone.length < 11 ||
        !montantFormate.replace(/\s/g, '') ||
        montantFormate.replace(/\s/g, '') < 100 ||
        montantFormate.replace(/\s/g, '') > 1000000
        ">
                                    {{ $t("text_form.button.value") }}
                                    {{
                                        montantFormate.replace(/\s/g, "") !== ""
                                                ? new Intl.NumberFormat("fr-FR", {
                                                    style: "currency",
                                                    currency: "XOF",
                                                }).format(parseFloat(montantFormate.replace(/\s/g, "")))
                                                : ""
                                    }}
                                </button>

                                <button type="button" v-if="dataPage.show_email_by_default !== '1' &&
                                        dataPage.custom_fields !== '1' &&
                                        dataPage.fixedAmount == '0' &&
                                        dataPage.eticket.length > 0
                                        " @click="payer" :class="!dataFormulaire.formule ||
            !dataFormulaire.motif ||
            !dataFormulaire.nom ||
            !dataFormulaire.prenom ||
            !dataFormulaire.telephone ||
            dataFormulaire.telephone.length < 11 ||
            !montantFormate.replace(/\s/g, '') ||
            montantFormate.replace(/\s/g, '') < 100 ||
            montantFormate.replace(/\s/g, '') > 1000000
            ? 'btn btn-dark btn-disabled-custom btn-block'
            : 'btn btn-dark btn-block'
            " :disabled="!dataFormulaire.formule ||
        !dataFormulaire.motif ||
        !dataFormulaire.nom ||
        !dataFormulaire.prenom ||
        !dataFormulaire.telephone ||
        dataFormulaire.telephone.length < 11 ||
        !montantFormate.replace(/\s/g, '') ||
        montantFormate.replace(/\s/g, '') < 100 ||
        montantFormate.replace(/\s/g, '') > 1000000
        ">
                                    {{ $t("text_form.button.value") }}
                                    {{
                                        montantFormate.replace(/\s/g, "") !== ""
                                                ? new Intl.NumberFormat("fr-FR", {
                                                    style: "currency",
                                                    currency: "XOF",
                                                }).format(parseFloat(montantFormate.replace(/\s/g, "")))
                                                : ""
                                    }}
                                </button>
                                <!-- ********************************** -->
                                <!-- ********************************** -->
                                <!-- ********************************** -->

                                <!-- ********************************** -->
                                <!-- ********************************** -->
                                <!-- ********************************** -->
                                <button type="button" v-if="dataPage.show_email_by_default == '1' &&
                                        dataPage.custom_fields !== '1' &&
                                        dataPage.fixedAmount == '0' &&
                                        dataPage.eticket.length <= 0
                                        " @click="payer" :class="!dataFormulaire.motif ||
            !dataFormulaire.email ||
            !dataFormulaire.nom ||
            !dataFormulaire.prenom ||
            !dataFormulaire.telephone ||
            dataFormulaire.telephone.length < 11 ||
            !montantFormate.replace(/\s/g, '') ||
            montantFormate.replace(/\s/g, '') < 100 ||
            montantFormate.replace(/\s/g, '') > 1000000
            ? 'btn btn-dark btn-disabled-custom btn-block'
            : 'btn btn-dark btn-block'
            " :disabled="!dataFormulaire.motif ||
        !dataFormulaire.email ||
        !dataFormulaire.nom ||
        !dataFormulaire.prenom ||
        !dataFormulaire.telephone ||
        dataFormulaire.telephone.length < 11 ||
        !montantFormate.replace(/\s/g, '') ||
        montantFormate.replace(/\s/g, '') < 100 ||
        montantFormate.replace(/\s/g, '') > 1000000
        ">
                                    {{ $t("text_form.button.value") }}
                                    {{
                                        montantFormate.replace(/\s/g, "") !== ""
                                                ? new Intl.NumberFormat("fr-FR", {
                                                    style: "currency",
                                                    currency: "XOF",
                                                }).format(parseFloat(montantFormate.replace(/\s/g, "")))
                                                : ""
                                    }}
                                </button>

                                <button type="button" v-if="dataPage.show_email_by_default == '1' &&
                                        dataPage.custom_fields !== '1' &&
                                        dataPage.fixedAmount == '0' &&
                                        dataPage.eticket.length > 0
                                        " @click="payer" :class="!dataFormulaire.formule ||
            !dataFormulaire.motif ||
            !dataFormulaire.email ||
            !dataFormulaire.nom ||
            !dataFormulaire.prenom ||
            !dataFormulaire.telephone ||
            dataFormulaire.telephone.length < 11 ||
            !montantFormate.replace(/\s/g, '') ||
            montantFormate.replace(/\s/g, '') < 100 ||
            montantFormate.replace(/\s/g, '') > 1000000
            ? 'btn btn-dark btn-disabled-custom btn-block'
            : 'btn btn-dark btn-block'
            " :disabled="!dataFormulaire.formule ||
        !dataFormulaire.motif ||
        !dataFormulaire.email ||
        !dataFormulaire.nom ||
        !dataFormulaire.prenom ||
        !dataFormulaire.telephone ||
        dataFormulaire.telephone.length < 11 ||
        !montantFormate.replace(/\s/g, '') ||
        montantFormate.replace(/\s/g, '') < 100 ||
        montantFormate.replace(/\s/g, '') > 1000000
        ">
                                    {{ $t("text_form.button.value") }}
                                    {{
                                        montantFormate.replace(/\s/g, "") !== ""
                                                ? new Intl.NumberFormat("fr-FR", {
                                                    style: "currency",
                                                    currency: "XOF",
                                                }).format(parseFloat(montantFormate.replace(/\s/g, "")))
                                                : ""
                                    }}
                                </button>
                                <!-- ********************************** -->
                                <!-- ********************************** -->
                                <!-- ********************************** -->

                                <!-- ********************************** -->
                                <!-- ********************************** -->
                                <!-- ********************************** -->
                                <button type="button" v-if="dataPage.show_email_by_default !== '1' &&
                                        dataPage.custom_fields == '1' &&
                                        dataPage.fixedAmount == '0' &&
                                        dataPage.eticket.length <= 0
                                        " @click="payer" :class="!dataFormulaire.immatriculation ||
            !dataFormulaire.service ||
            !dataFormulaire.nom ||
            !dataFormulaire.prenom ||
            !dataFormulaire.telephone ||
            dataFormulaire.telephone.length < 11 ||
            !montantFormate.replace(/\s/g, '') ||
            montantFormate.replace(/\s/g, '') < 100 ||
            montantFormate.replace(/\s/g, '') > 1000000
            ? 'btn btn-dark btn-disabled-custom btn-block'
            : 'btn btn-dark btn-block'
            " :disabled="!dataFormulaire.immatriculation ||
        !dataFormulaire.service ||
        !dataFormulaire.nom ||
        !dataFormulaire.prenom ||
        !dataFormulaire.telephone ||
        dataFormulaire.telephone.length < 11 ||
        !montantFormate.replace(/\s/g, '') ||
        montantFormate.replace(/\s/g, '') < 100 ||
        montantFormate.replace(/\s/g, '') > 1000000
        ">
                                    {{ $t("text_form.button.value") }}
                                    {{
                                        montantFormate.replace(/\s/g, "") !== ""
                                                ? new Intl.NumberFormat("fr-FR", {
                                                    style: "currency",
                                                    currency: "XOF",
                                                }).format(parseFloat(montantFormate.replace(/\s/g, "")))
                                                : ""
                                    }}
                                </button>

                                <button type="button" v-if="dataPage.show_email_by_default !== '1' &&
                                        dataPage.custom_fields == '1' &&
                                        dataPage.fixedAmount == '0' &&
                                        dataPage.eticket.length > 0
                                        " @click="payer" :class="!dataFormulaire.formule ||
            !dataFormulaire.immatriculation ||
            !dataFormulaire.service ||
            !dataFormulaire.nom ||
            !dataFormulaire.prenom ||
            !dataFormulaire.telephone ||
            dataFormulaire.telephone.length < 11 ||
            !montantFormate.replace(/\s/g, '') ||
            montantFormate.replace(/\s/g, '') < 100 ||
            montantFormate.replace(/\s/g, '') > 1000000
            ? 'btn btn-dark btn-disabled-custom btn-block'
            : 'btn btn-dark btn-block'
            " :disabled="!dataFormulaire.formule ||
        !dataFormulaire.immatriculation ||
        !dataFormulaire.service ||
        !dataFormulaire.nom ||
        !dataFormulaire.prenom ||
        !dataFormulaire.telephone ||
        dataFormulaire.telephone.length < 11 ||
        !montantFormate.replace(/\s/g, '') ||
        montantFormate.replace(/\s/g, '') < 100 ||
        montantFormate.replace(/\s/g, '') > 1000000
        ">
                                    {{ $t("text_form.button.value") }}
                                    {{
                                        montantFormate.replace(/\s/g, "") !== ""
                                                ? new Intl.NumberFormat("fr-FR", {
                                                    style: "currency",
                                                    currency: "XOF",
                                                }).format(parseFloat(montantFormate.replace(/\s/g, "")))
                                                : ""
                                    }}
                                </button>
                                <!-- ********************************** -->
                                <!-- ********************************** -->
                                <!-- ********************************** -->
                            </div>
                        </div>
                        <!-- Button submit form -->

                        <!-- Button submit form -->
                        <div class="element-wrap element-wrap-for-submit" v-else>
                            <button type="button" v-if="dataPage.show_email_by_default !== '1' &&
                                    dataPage.custom_fields !== '1' &&
                                    dataPage.fixedAmount == '0' &&
                                    dataPage.eticket.length <= 0
                                    " @click="payer" :class="!dataFormulaire.motif || !dataFormulaire.nom || !dataFormulaire.numeroCompte ||
            !dataFormulaire.prenom || !dataFormulaire.telephone || dataFormulaire.telephone.length < 11 ||
            !montantFormate.replace(/\s/g, '') || montantFormate.replace(/\s/g, '') < 100 ||
            montantFormate.replace(/\s/g, '') > 1000000 ? 'btn btn-dark btn-disabled-custom btn-block'
            : 'btn btn-dark btn-block'
            " :disabled="!dataFormulaire.motif ||
        !dataFormulaire.nom || !dataFormulaire.numeroCompte ||
        !dataFormulaire.prenom ||
        !dataFormulaire.telephone ||
        dataFormulaire.telephone.length < 11 ||
        !montantFormate.replace(/\s/g, '') ||
        montantFormate.replace(/\s/g, '') < 100 ||
        montantFormate.replace(/\s/g, '') > 1000000
        ">
                                {{ $t("text_form.button.value") }}
                                {{
                                    montantFormate.replace(/\s/g, "") !== ""
                                            ? new Intl.NumberFormat("fr-FR", {
                                                style: "currency",
                                                currency: "XOF",
                                            }).format(parseFloat(montantFormate.replace(/\s/g, "")))
                                            : ""
                                }}
                            </button>
                        </div>
                        <!-- Button submit form -->

                    </form>

                    <!-- ******** -->
                    <!-- Bloc success payment -->
                    <!-- ******** -->
                    <success-page :hideRecu="hideRecu" :logoSmallDevice="logoSmallDevice"
                                  :montant="dataFormulaire.montant"
                                  :nom="dataFormulaire.nom" :prenom="dataFormulaire.prenom"
                                  :devise="dataFormulaire.devise"
                                  :motif="dataFormulaire.motif" :refPayment="refPayment"
                                  :nombre_transactions="nombre_transactions">
                    </success-page>
                    <!-- ******** -->
                    <!-- Bloc success payment -->
                    <!-- ******** -->
                </div>
                <!-- ********************** -->
                <!-- ********************** -->
                <!-- Others enterprise Form -->
                <!-- ********************** -->
                <!-- ********************** -->

                <!-- ******** -->
                <!-- ******** -->
                <!-- SGI Form -->
                <!-- ******** -->
                <!-- ******** -->
                <div class="body" v-if="dataPage.type == 'SGI'">
                    <form :class="hideRecu == true ? 'payment-form' : 'payment-form hidden'">
                        <!-- Formules e-ticket -->
                        <div class="element-pair" v-if="dataPage.eticket.length > 0">
                            <div v-if="dataPage.eticket[0].programme.img_event !== null">
                                <div class="element-label">
                                    <span style="text-transform: uppercase">{{
                                            dataPage.eticket[0].programme.libelle
                                        }}</span>
                                </div>
                                <!-- Image du programme -->
                                <div class="image-container">
                                    <img :src="`https://carte.abidjan.net/apaym_pro/apis/dashboard-merchant/events/${dataPage.eticket[0].programme.img_event}`"
                                         style="width: 100%; object-fit: contain"/>
                                </div>
                                <!-- Image du programme -->
                            </div>

                            <div class="element-label" :style="dataPage.eticket[0].programme.img_event !== null
                                ? 'margin-top: 20px;'
                                : ''
                                ">
                                <span v-if="dataPage.eticket[0].programme.img_event === null">
                                    {{ $t("text_form.formulas.value") }} -
                                    <span style="text-transform: uppercase">{{
                                            dataPage.eticket[0].programme.libelle
                                        }}</span>
                                </span>
                                <span v-else>
                                    {{ $t("text_form.formulas.value") }}
                                </span>
                            </div>

                            <div class="element-pair-container">
                                <b-form-group>
                                    <div class="formules-container">
                                        <div v-for="(formule, index) in dataPage.eticket" :key="index"
                                             :id="formule.libelle">
                                            <div class="formule-bloc" :style="dataFormulaire.formule == formule
                                                ? 'background-color: #f6f6f6; border: 1px solid #005CC8'
                                                : 'background-color: #fdfdfd; ; border: 1px solid #b2bec3'
                                                " v-if="formule.quantite_indisponible === false &&
        formule.expiration_formule === false
        " @click="() => {
        dataFormulaire.formule = formule;
        dataFormulaire.motif = formule.libelle;
        dataFormulaire.quantite_indisponbile =
            formule.quantite_indisponbile;
        getMontantFormule(typeForm, dataFormulaire.formule);
    }
        ">
                                                <div style="
                            display: flex;
                            justify-content: flex-end;
                            align-items: center;
                          ">
                                                    <input type="radio" v-model="dataFormulaire.formule" @change="
                                                        getMontantFormule(
                                                            typeForm,
                                                            dataFormulaire.formule
                                                        )
                                                        " :name="formule.libelle" :value="formule"
                                                           style="cursor: pointer; width: 18px; height: 18px"/>
                                                </div>
                                                <div style="
                            padding: 1px;
                            margin-top: 5px;
                            display: block;
                            line-height: 15px;
                          ">
                                                    <span style="">
                                                        {{ formule.libelle }}
                                                    </span>
                                                </div>

                                                <div style="
                            position: absolute;
                            bottom: 3px;
                            margin-top: 5px;
                          ">
                                                    <span class="" :style="formule.quantite_indisponbile === true
                                                        ? 'font-size: 14px; color: #1e017d; font-weight: bolder'
                                                        : 'font-size: 14px; color: #1b1e21; font-weight: bolder'
                                                        ">
                                                        {{
                                                            new Intl.NumberFormat("fr-FR", {
                                                                style: "currency",
                                                                currency: "XOF",
                                                            }).format(formule.montant)
                                                        }}
                                                    </span>
                                                </div>
                                            </div>

                                            <div class="formule-bloc-disabled" :style="dataFormulaire.formule == formule
                                                ? 'background-color: #f6f6f6; border: 1px solid #005CC8'
                                                : 'background-color: #fdfdfd; ; border: 1px solid #b2bec3'
                                                " v-if="formule.expiration_formule === true">
                                                <div style="
                            width: 100%;
                            display: flex;
                            justify-content: right;
                          ">
                                                    <div style="
                              display: flex;
                              justify-content: space-around;
                              align-items: center;
                              padding: 2px;
                              border-radius: 5px;
                              background-color: #fff3cd;
                              color: #f77f00;
                              width: 60px;
                              border: 1px solid rgb(247 127 0 / 25%);
                            ">
                                                        <span class="font-11">{{
                                                                $t("text_form.formulas_expired.value")
                                                            }}</span>
                                                        <i class="fa fa-clock font-11"></i>
                                                    </div>
                                                </div>
                                                <div style="
                            padding: 1px;
                            margin-top: 5px;
                            display: block;
                            line-height: 15px;
                          ">
                                                    <span style="">
                                                        {{ formule.libelle }}
                                                    </span>
                                                </div>

                                                <div style="
                            position: absolute;
                            bottom: 3px;
                            margin-top: 5px;
                          ">
                                                    <span class="" :style="formule.quantite_indisponbile === true
                                                        ? 'font-size: 14px; color: #1e017d; font-weight: bolder'
                                                        : 'font-size: 14px; color: #1b1e21; font-weight: bolder'
                                                        ">
                                                        {{
                                                            new Intl.NumberFormat("fr-FR", {
                                                                style: "currency",
                                                                currency: "XOF",
                                                            }).format(formule.montant)
                                                        }}
                                                    </span>

                                                    <br/>
                                                </div>
                                            </div>

                                            <div class="formule-bloc-disabled" :style="dataFormulaire.formule == formule
                                                ? 'background-color: #f6f6f6; border: 1px solid #005CC8'
                                                : 'background-color: #fdfdfd; ; border: 1px solid #b2bec3'
                                                " v-if="formule.quantite_indisponible === true">
                                                <div style="
                            width: 100%;
                            display: flex;
                            justify-content: right;
                          ">
                                                    <div style="
                              display: flex;
                              justify-content: space-around;
                              align-items: center;
                              padding: 2px;
                              border-radius: 5px;
                              background-color: #f8d7db;
                              color: #dc3545;
                              width: 60px;
                            ">
                                                        <span class="font-11">{{
                                                                $t("text_form.sold_out.value")
                                                            }}</span>
                                                        <i class="fa fa-lock font-11"></i>
                                                    </div>
                                                </div>
                                                <div style="
                            padding: 1px;
                            margin-top: 5px;
                            display: block;
                            line-height: 15px;
                          ">
                                                    <span style="">
                                                        {{ formule.libelle }}
                                                    </span>
                                                </div>

                                                <div style="
                            position: absolute;
                            bottom: 3px;
                            margin-top: 5px;
                          ">
                                                    <span class="" :style="formule.quantite_indisponbile === true
                                                        ? 'font-size: 14px; color: #1e017d; font-weight: bolder'
                                                        : 'font-size: 14px; color: #1b1e21; font-weight: bolder'
                                                        ">
                                                        {{
                                                            new Intl.NumberFormat("fr-FR", {
                                                                style: "currency",
                                                                currency: "XOF",
                                                            }).format(formule.montant)
                                                        }}
                                                    </span>

                                                    <br/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div v-if="dataFormulaire.formule" class="formule-info">
                                        {{ dataFormulaire.formule.description }}
                                    </div>
                                </b-form-group>
                            </div>
                        </div>
                        <!-- Formules e-ticket -->

                        <!-- Montant a payer -->
                        <div class="element-pair">
                            <div class="element-label">
                                <span>
                                    {{ $t("text_form.amount.value") }}
                                    <a href="javascript:;" class="none-outline" style="color: rgb(14, 42, 189)" v-if="!(
                                        dataPage.amount !== '' &&
                                        parseFloat(dataPage.amount) >= 100
                                    )
                                        " v-b-modal.convertAmount>
                                        | {{ $t("text_form.convert_amount.value") }} &nbsp;
                                        <i class="fas fa-euro-sign"></i> &nbsp;
                                        <i class="fas fa-dollar-sign"></i>
                                    </a>
                                </span>
                            </div>
                            <div class="element-pair-container" style="align-items: center">
                                <div class="element-wrap element-wrap-on-right">
                                    <div style="
                      display: flex;
                      flex-direction: row;
                      align-items: center;
                    " v-if="dataPage.eticket.length <= 0">
                                        <div class="element-is-input" v-if="dataPage.amount !== '' &&
                                            parseFloat(dataPage.amount) >= 100
                                            " style="
                        font-weight: 900;
                        font-size: 22px;
                        pointer-events: none;
                        background-color: #eee;
                        border: 1px solid #ccc;
                      ">
                                            {{ dataPage.amount | formatAmount }}
                                        </div>

                                        <input v-if="dataPage.amount == ''" :disabled="dataPage.amount !== '' &&
                                            parseFloat(dataPage.amount) >= 100
                                            " v-model="montantFormate" type="tel" v-on:keypress="numbersOnly"
                                               class="element-is-input" name="montant" maxlength="9"
                                               v-mask="currencyMask"
                                               v-b-tooltip.hover :title="(montantFormate.replace(/\s/g, '') !== '' &&
                                                montantFormate.replace(/\s/g, '') < 100) ||
                                                montantFormate.replace(/\s/g, '') > 1000000
                                                ? 'Vous pouvez payer au minimum 100 F et au maximum 1 000 000 F'
                                                : ''
                                                " :style="(montantFormate.replace(/\s/g, '') !== '' &&
        montantFormate.replace(/\s/g, '') < 100) ||
        montantFormate.replace(/\s/g, '') > 1000000
        ? 'border: 1px solid red; font-weight: 900; font-size: 22px'
        : 'font-weight: 900; font-size: 22px'
        "/>
                                        <a href="javascript:;" @click="openCalculator" v-if="!(
                                            dataPage.amount !== '' &&
                                            parseFloat(dataPage.amount) >= 100
                                        )
                                            " style="
                        font-size: 18px;
                        color: #0e2abd;
                        margin-left: -35px;
                      ">
                                            <i class="fas fa-calculator"></i>
                                        </a>
                                    </div>

                                    <div class="element-is-input" v-else style="
                      font-weight: 900;
                      font-size: 22px;
                      pointer-events: none;
                      background-color: rgb(238, 238, 238);
                      border: 1px solid rgb(204, 204, 204);
                    ">
                                        {{ montantFormate }}
                                    </div>
                                </div>

                                <div style="font-weight: 800; font-size: 20px; white-space: nowrap">
                                    F CFA
                                </div>
                            </div>
                        </div>
                        <!-- Montant a payer -->

                        <!-- Pourboire -->
                        <div class="element-pair" v-if="dataPage.pourboire == '1'">
                            <div class="element-label">
                                <span>
                                    {{ $t("text_pourboire.value") }}
                                </span>
                            </div>
                            <div class="element-pair-container">
                                <label :style="pourboire !== '0'
                                    ? 'background: #eeeeee; border: #eeeeee; color: rgb(119 119 119);width: 90px; display: flex; align-items: center; border-radius: 10px; height: 30px; justify-content: center;'
                                    : 'width: 90px; display: flex; align-items: center; border-radius: 10px; height: 30px; justify-content: center;'
                                    " class="btn btn-dark btn-sm ml-1 mr-1">
                                    <input type="radio" value="0" v-model="pourboire" style="display: none"/><span
                                        class="font-12" style="z-index: 200">0 F</span>
                                </label>

                                <label :style="pourboire !== '500'
                                    ? 'background: #eeeeee; border: #eeeeee; color: rgb(119 119 119);width: 90px; display: flex; align-items: center; border-radius: 10px; height: 30px; justify-content: center;'
                                    : 'width: 90px; display: flex; align-items: center; border-radius: 10px; height: 30px; justify-content: center;'
                                    " class="btn btn-dark btn-sm ml-1 mr-1">
                                    <input type="radio" value="500" v-model="pourboire" style="display: none"/><span
                                        class="font-12" style="z-index: 200">500 F</span>
                                </label>

                                <label :style="pourboire !== '1000'
                                    ? 'background: #eeeeee; border: #eeeeee; color: rgb(119 119 119);width: 90px; display: flex; align-items: center; border-radius: 10px; height: 30px; justify-content: center;'
                                    : 'width: 90px; display: flex; align-items: center; border-radius: 10px; height: 30px; justify-content: center;'
                                    " class="btn btn-dark btn-sm ml-1 mr-1">
                                    <input type="radio" value="1000" v-model="pourboire" style="display: none"/><span
                                        class="font-12" style="z-index: 200">1000 F</span>
                                </label>

                                <label :style="pourboire !== 'autre'
                                    ? 'background: #eeeeee; border: #eeeeee; color: rgb(119 119 119);width: 90px; display: flex; align-items: center; border-radius: 10px; height: 30px; justify-content: center;'
                                    : 'width: 90px; display: flex; align-items: center; border-radius: 10px; height: 30px; justify-content: center;'
                                    " class="btn btn-dark btn-sm ml-1 mr-1">
                                    <input type="radio" value="autre" v-model="pourboire" style="display: none"/><span
                                        class="font-12" style="z-index: 200">{{
                                        $t("text_pourboire2.value")
                                    }}</span>
                                </label>
                                <!-- </div> -->
                            </div>

                            <div class="element-pair-container" style="align-items: center"
                                 :hidden="pourboire !== 'autre'">
                                <div class="element-wrap element-wrap-on-right">
                                    <input v-model="dataFormulaire.amountPourboire" type="tel"
                                           v-on:keypress="numbersOnly"
                                           class="element-is-input" name="montant" maxlength="5" v-b-tooltip.hover
                                           :title="dataFormulaire.amountPourboire < 10 ||
                                            dataFormulaire.montant > 20000
                                            ? 'Vous pouvez payé au minimum 10 F et au maximum 20 000 F'
                                            : ''
                                            "/>
                                </div>
                                <div>F CFA</div>
                            </div>
                        </div>
                        <!-- Pourboire -->

                        <!-- Numero de telephone -->
                        <div class="element-pair" style="margin-top: 15px">
                            <div class="element-label">
                                <span v-if="dataPage.deleg_pdci == '0'">{{
                                        $t("text_form.number.value")
                                    }}</span>
                                <span v-if="dataPage.umed === 1">{{
                                        $t("text_form.number_patient.value")
                                    }}</span>
                            </div>
                            <div class="element-pair-container">
                                <div class="element-wrap element-wrap-on-left">
                                    <multiselect v-model="indice.i" :options="options" :multiple="false"
                                                 placeholder="Indicatif" tag-placeholder="Indicatif" track-by="code"
                                                 label="country"
                                                 :searchable="true" :select-label="''" :deselect-label="''"
                                                 :selected-label="'✓'"
                                                 :allow-empty="false">
                                        <template slot="singleLabel" slot-scope="{ option }">{{
                                                option.country_code
                                            }}
                                        </template>
                                        <span slot="noResult"></span>
                                    </multiselect>
                                </div>
                                <div class="element-wrap element-wrap-on-right">
                                    <input class="element-is-input" type="tel" v-mask="'## ## ## ## ##'"
                                           v-model="dataFormulaire.telephone" name="telephone"
                                           style="font-weight: 900; font-size: 22px"/>
                                </div>
                            </div>
                        </div>
                        <!-- Numero de telephone -->

                        <!-- Numero de compte -->
                        <div class="element-wrap">
                            <div class="element-label">
                                <span>{{ $t("text_form.account_number.value") }}</span>
                            </div>
                            <input v-model="dataFormulaire.numeroCompte" type="tel" v-on:keypress="numbersOnly"
                                   class="element-is-input" name="numero_compte"/>
                        </div>
                        <!-- Numero de compte -->

                        <!-- Societe en bourse -->
                        <div class="element-wrap">
                            <div class="element-label">
                                <span>{{ $t("text_form.traded_company.value") }}</span>
                                {{ $t("text_form.optional.value") }}
                            </div>
                            <multiselect v-model="dataFormulaire.societeEnBourse" :options="traded_company"
                                         :multiple="false" :placeholder="$t('text_form.traded_company_selected.value')"
                                         :tag-placeholder="$t('text_form.traded_company_selected.value')"
                                         track-by="company_to_show"
                                         label="company_to_show" :searchable="true" :select-label="''"
                                         :deselect-label="''"
                                         :selected-label="'✓'" :allow-empty="false" style="text-overflow: ellipsis">
                                <template slot="singleLabel" slot-scope="{ option }">{{
                                        option.company
                                    }}
                                </template>
                                <span slot="noResult"></span>
                            </multiselect>
                        </div>
                        <!-- Societe en bourse -->

                        <!-- Nombre d'actions -->
                        <div class="element-pair mb-3">
                            <div class="element-label">
                                <span>{{ $t("text_form.shares.value") }}</span>
                                {{ $t("text_form.optional.value") }}
                            </div>
                            <input v-model="dataFormulaire.nombreActions" type="tel" class="element-is-input"
                                   name="shares"
                                   v-on:keypress="numbersOnly"/>
                        </div>
                        <!-- Nombre d'actions -->

                        <!-- Nom & Prenom(s) -->
                        <div class="element-pair">
                            <div class="element-pair-container">
                                <div class="element-wrap element-wrap-on-left">
                                    <div class="element-label" v-if="dataPage.deleg_pdci == '0'">
                                        <span>{{ $t("text_form.name.value") }}</span>
                                    </div>
                                    <div class="element-label" v-if="dataPage.umed === 1">
                                        <span>{{ $t("text_form.name_patient.value") }}</span>
                                    </div>
                                    <input v-model="dataFormulaire.nom" type="text" class="element-is-input"
                                           name="name"/>
                                </div>
                                <div class="element-wrap element-wrap-on-right">
                                    <div class="element-label" v-if="dataPage.deleg_pdci == '0'">
                                        <span>{{ $t("text_form.surname.value") }}</span>
                                    </div>
                                    <div class="element-label" v-if="dataPage.umed === 1">
                                        <span>{{ $t("text_form.surname_patient.value") }}</span>
                                    </div>
                                    <input v-model="dataFormulaire.prenom" type="text" class="element-is-input"
                                           name="prenom"/>
                                </div>
                            </div>
                        </div>
                        <!-- Nom & Prenom(s) -->

                        <!-- Succursale -->
                        <div class="element-pair-container" v-if="dataPage.boutiques.length > 0">
                            <div class="element-wrap element-wrap-for-select">
                                <div class="element-label">
                                    <span> {{ $t("text_form.branch_store.value") }} </span>
                                    {{ $t("text_form.optional.value") }}
                                </div>
                                <select v-model="dataFormulaire.boutique" type="text" class="element-is-select"
                                        name="succursale">
                                    <option value="" selected>
                                        {{ $t("text_form.branch_store_option.value") }}
                                    </option>
                                    <option :value="boutique" v-for="(boutique, index) in dataPage.boutiques"
                                            :key="index">
                                        {{ boutique.nom_boutique }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <!-- Succursale -->

                        <!-- Motif -->
                        <div class="element-wrap">
                            <div class="element-label">
                                <span>{{ $t("text_form.reason.value") }}</span>
                            </div>
                            <input v-model="dataFormulaire.motif" type="text" class="element-is-input" name="motif"
                                   maxlength="40" v-if="dataPage.eticket.length <= 0"/>
                            <div class="element-is-input" v-else style="
                  font-weight: 900;
                  pointer-events: none;
                  background-color: rgb(238, 238, 238);
                  border: 1px solid rgb(204, 204, 204);
                ">
                                {{ dataFormulaire.motif }}
                            </div>
                        </div>
                        <!-- Motif -->

                        <!-- Infos supplémentaires -->
                        <div>
                            <div class="mt-4 mb-3" v-if="dataPage.deleg_pdci == '0' || dataPage.umed === 1">
                                <a href="javascript:;" style="font-size: 14px; color: #0e2abd" @click="showInfosSup2">
                                    <i :class="infosSup2 ? 'fas fa-plus' : 'fas fa-minus'"></i>
                                    {{ $t("text_form.additional_data.value") }}
                                </a>
                            </div>

                            <div v-if="!infosSup2">
                                <transition name="fade">
                                    <div class="element-pair">
                                        <!-- Society -->
                                        <div class="element-wrap">
                                            <div class="element-label">
                                                <span v-if="dataPage.umed === 1">{{
                                                        $t("text_form.society_patient.value")
                                                    }}</span>
                                                <span v-else>{{ $t("text_form.society.value") }}</span>
                                            </div>
                                            <input v-model="dataFormulaire.society" type="text" class="element-is-input"
                                                   name="societe"/>
                                        </div>
                                        <!-- Society -->

                                        <!-- E-mail -->
                                        <div class="element-wrap" v-if="dataPage.show_email_by_default !== '1'">
                                            <div class="element-label">
                                                <span v-if="dataPage.umed === 1">{{
                                                        $t("text_form.email_patient.value")
                                                    }}</span>
                                                <span v-else>{{ $t("text_form.email.value") }}</span>
                                            </div>
                                            <input v-model="dataFormulaire.email" type="email" class="element-is-input"
                                                   name="email"/>
                                        </div>

                                    </div>
                                </transition>
                            </div>
                        </div>
                        <!-- Infos supplémentaires -->

                        <!-- Button submit form -->
                        <div class="element-wrap element-wrap-for-submit">
                            <div>
                                <!-- *************************************** -->
                                <!-- *************************************** -->
                                <!-- *************************************** -->
                                <button type="button" v-if="dataPage.show_email_by_default !== '1' &&
                                    dataPage.eticket.length <= 0
                                    " @click="payer" :class="!dataFormulaire.motif ||
        !dataFormulaire.nom ||
        !dataFormulaire.prenom ||
        !dataFormulaire.telephone ||
        dataFormulaire.telephone.length < 11 ||
        !montantFormate.replace(/\s/g, '') ||
        montantFormate.replace(/\s/g, '') < 100 ||
        montantFormate.replace(/\s/g, '') > 1000000
        ? 'btn btn-dark btn-disabled-custom btn-block'
        : 'btn btn-dark btn-block'
        " :disabled="!dataFormulaire.motif ||
        !dataFormulaire.nom ||
        !dataFormulaire.prenom ||
        !dataFormulaire.telephone ||
        dataFormulaire.telephone.length < 11 ||
        !montantFormate.replace(/\s/g, '') ||
        montantFormate.replace(/\s/g, '') < 100 ||
        montantFormate.replace(/\s/g, '') > 1000000
        ">
                                    {{ $t("text_form.button.value") }}
                                    {{
                                        montantFormate.replace(/\s/g, "") !== ""
                                                ? new Intl.NumberFormat("fr-FR", {
                                                    style: "currency",
                                                    currency: "XOF",
                                                }).format(parseFloat(montantFormate.replace(/\s/g, "")))
                                                : ""
                                    }}
                                </button>

                                <button type="button" v-if="dataPage.show_email_by_default !== '1' &&
                                        dataPage.eticket.length > 0
                                        " @click="payer" :class="!dataFormulaire.formule ||
            !dataFormulaire.motif ||
            !dataFormulaire.nom ||
            !dataFormulaire.prenom ||
            !dataFormulaire.telephone ||
            dataFormulaire.telephone.length < 11 ||
            !montantFormate.replace(/\s/g, '') ||
            montantFormate.replace(/\s/g, '') < 100 ||
            montantFormate.replace(/\s/g, '') > 1000000
            ? 'btn btn-dark btn-disabled-custom btn-block'
            : 'btn btn-dark btn-block'
            " :disabled="!dataFormulaire.formule ||
        !dataFormulaire.motif ||
        !dataFormulaire.nom ||
        !dataFormulaire.prenom ||
        !dataFormulaire.telephone ||
        dataFormulaire.telephone.length < 11 ||
        !montantFormate.replace(/\s/g, '') ||
        montantFormate.replace(/\s/g, '') < 100 ||
        montantFormate.replace(/\s/g, '') > 1000000
        ">
                                    {{ $t("text_form.button.value") }}
                                    {{
                                        montantFormate.replace(/\s/g, "") !== ""
                                                ? new Intl.NumberFormat("fr-FR", {
                                                    style: "currency",
                                                    currency: "XOF",
                                                }).format(parseFloat(montantFormate.replace(/\s/g, "")))
                                                : ""
                                    }}
                                </button>
                                <!-- *************************************** -->
                                <!-- *************************************** -->
                                <!-- *************************************** -->

                                <!-- *************************************** -->
                                <!-- *************************************** -->
                                <!-- *************************************** -->
                                <button type="button" v-if="dataPage.show_email_by_default == '1' &&
                                        dataPage.eticket.length <= 0
                                        " @click="payer" :class="!dataFormulaire.motif ||
            !dataFormulaire.email ||
            !dataFormulaire.nom ||
            !dataFormulaire.prenom ||
            !dataFormulaire.telephone ||
            dataFormulaire.telephone.length < 11 ||
            !montantFormate.replace(/\s/g, '') ||
            montantFormate.replace(/\s/g, '') < 100 ||
            montantFormate.replace(/\s/g, '') > 1000000
            ? 'btn btn-dark btn-disabled-custom btn-block'
            : 'btn btn-dark btn-block'
            " :disabled="!dataFormulaire.motif ||
        !dataFormulaire.email ||
        !dataFormulaire.nom ||
        !dataFormulaire.prenom ||
        !dataFormulaire.telephone ||
        dataFormulaire.telephone.length < 11 ||
        !montantFormate.replace(/\s/g, '') ||
        montantFormate.replace(/\s/g, '') < 100 ||
        montantFormate.replace(/\s/g, '') > 1000000
        ">
                                    {{ $t("text_form.button.value") }}
                                    {{
                                        montantFormate.replace(/\s/g, "") !== ""
                                                ? new Intl.NumberFormat("fr-FR", {
                                                    style: "currency",
                                                    currency: "XOF",
                                                }).format(parseFloat(montantFormate.replace(/\s/g, "")))
                                                : ""
                                    }}
                                </button>

                                <button type="button" v-if="dataPage.show_email_by_default == '1' &&
                                        dataPage.eticket.length > 0
                                        " @click="payer" :class="!dataFormulaire.formule ||
            !dataFormulaire.motif ||
            !dataFormulaire.email ||
            !dataFormulaire.nom ||
            !dataFormulaire.prenom ||
            !dataFormulaire.telephone ||
            dataFormulaire.telephone.length < 11 ||
            !montantFormate.replace(/\s/g, '') ||
            montantFormate.replace(/\s/g, '') < 100 ||
            montantFormate.replace(/\s/g, '') > 1000000
            ? 'btn btn-dark btn-disabled-custom btn-block'
            : 'btn btn-dark btn-block'
            " :disabled="!dataFormulaire.formule ||
        !dataFormulaire.motif ||
        !dataFormulaire.email ||
        !dataFormulaire.nom ||
        !dataFormulaire.prenom ||
        !dataFormulaire.telephone ||
        dataFormulaire.telephone.length < 11 ||
        !montantFormate.replace(/\s/g, '') ||
        montantFormate.replace(/\s/g, '') < 100 ||
        montantFormate.replace(/\s/g, '') > 1000000
        ">
                                    {{ $t("text_form.button.value") }}
                                    {{
                                        montantFormate.replace(/\s/g, "") !== ""
                                                ? new Intl.NumberFormat("fr-FR", {
                                                    style: "currency",
                                                    currency: "XOF",
                                                }).format(parseFloat(montantFormate.replace(/\s/g, "")))
                                                : ""
                                    }}
                                </button>
                                <!-- *************************************** -->
                                <!-- *************************************** -->
                                <!-- *************************************** -->
                            </div>
                        </div>
                        <!-- Button submit form -->
                    </form>

                    <!-- ******** -->
                    <!-- Bloc success payment -->
                    <!-- ******** -->
                    <success-page :hideRecu="hideRecu" :logoSmallDevice="logoSmallDevice"
                                  :montant="dataFormulaire.montant"
                                  :nom="dataFormulaire.nom" :prenom="dataFormulaire.prenom"
                                  :devise="dataFormulaire.devise"
                                  :motif="dataFormulaire.motif" :refPayment="refPayment"
                                  :nombre_transactions="nombre_transactions">
                    </success-page>
                    <!-- ******** -->
                    <!-- Bloc success payment -->
                    <!-- ******** -->
                </div>
                <!-- ******** -->
                <!-- ******** -->
                <!-- SGI Form -->
                <!-- ******** -->
                <!-- ******** -->
            </div>
        </div>
        <!-- Form Payment -->

        <!-- Form Quickly Payment - Paye vite avec seulement le montant et le numéro de téléphone comme champs obligatoires -->
        <div class="apaympro-wrapper animated fadeIn container" :hidden="!formQuiclyPayment" style="width: 500px">
            <div id="payment-page-pay" class="apaympro-page">
                <!-- Informations du marchand -->
                <div class="apaympro-header" style="margin-top: 15px">
                    <div class="company-logo" v-if="dataPage.business_logo"
                         style="width: 150px !important; justify-content: center">
                        <img id="company-logo-img" :src="dataPage.business_logo" alt="Business Logo"/>
                    </div>

                    <div class="page-info">
                        <h3 id="page-name" class="page-name font-16" v-if="dataPage.partner !== '2'">
                            {{
                                dataPage.type == "ONG" || dataPage.type == "PERSONNALITE"
                                        ? $t("text_header.donation.value")
                                        : $t("text_header.pay.value")
                            }}
                            <strong style="text-transform: uppercase">
                                {{ dataPage.business_name }}
                                <img src="../assets/img/badge-check.png" v-b-tooltip.hover
                                     :title="$t('text_certified_account.value')" v-if="dataPage.verified == '1'"
                                     width="15"
                                     style="margin-top: -3px"/>
                            </strong>
                            <!-- <p class="company-name mb-2" style="font-weight: 500; font-size: 11px">{{ dataPage.ville }}</p> -->
                            <p class="company-name mb-2" style="font-weight: 500; font-size: 11px">
                                {{ dataPage.ville }} / {{ dataPage.type }}
                            </p>
                        </h3>

                        <!-- Yango -->
                        <h3 id="page-name" class="page-name font-16" v-if="dataPage.partner == '2'">
                            <strong style="text-transform: uppercase">
                                YANGO
                                <img src="../assets/img/badge-check.png" v-b-tooltip.hover
                                     :title="$t('text_certified_account.value')" v-if="dataPage.verified == '1'"
                                     width="15"
                                     style="margin-top: -3px"/>
                            </strong>
                            <p class="company-name mb-2" style="font-weight: 500; font-size: 11px">
                                {{ dataPage.ville }}
                            </p>
                        </h3>
                        <!-- Yango -->

                        <p class="company-name text-dark" v-if="dataPage.type == 'SP'">
                            {{ $t("text_header.by2.value") }}
                            <span style="font-weight: 600">{{ dataPage.nom }}</span> <br/>
                            <span class="font-12" style="text-transform: none">{{
                                    $t("text_header.enterprising.value")
                                }}</span>
                        </p>
                        <p class="company-name" v-if="dataPage.type !== 'SP' && dataPage.type !== 'AUTRE'">
                            <span style="font-weight: 500; font-size: 11px">
                                <!-- {{ dataPage.type }}  -->
                                {{
                                    dataPage.rcc !== "0" || dataPage.rcc.includes("---") == "-1"
                                            ? dataPage.rcc !== ""
                                                    ? dataPage.rcc
                                                    : ""
                                            : ""
                                }}
                            </span>
                        </p>
                    </div>

                    <div class="page-description" v-if="dataPage.description_page">
                        <span id="tooltip-description-page-quickly" style="cursor: pointer">
                            {{ dataPage.description_page }}
                        </span>
                        <b-tooltip target="tooltip-description-page-quickly" triggers="hover">
                            {{ dataPage.description_page_2 }}
                        </b-tooltip>
                    </div>

                    <!-- Social medias -->
                    <div class="company-name" style="text-transform: none">
                        <span class="mr-1 ml-1">
                            <a class="btn btn-lg btn-link btn-facebook" v-b-modal.openQrCode style="padding: 0">
                                <i class="fas fa-solid fa-qrcode text-primary"></i>
                            </a>
                        </span>
                        <span v-if="dataPage.facebook" class="mr-1 ml-1">
                            <a class="btn btn-lg btn-link btn-facebook" :href="dataPage.facebook" target="_blank"
                               style="padding: 0">
                                <i class="fab fa-facebook-f"></i>
                            </a>
                        </span>
                        <span v-if="dataPage.instagram" class="mr-1 ml-1">
                            <a class="btn btn-lg btn-link btn-instagram" :href="dataPage.instagram" target="_blank"
                               style="padding: 0">
                                <i class="fab fa-instagram"></i>
                            </a>
                        </span>
                        <span v-if="dataPage.whatsapp" class="mr-1 ml-1">
                            <a class="btn btn-lg btn-link btn-whatsapp" :href="dataPage.whatsapp" target="_blank"
                               style="padding: 0">
                                <i class="fab fa-whatsapp"></i>
                            </a>
                        </span>
                        <span v-if="dataPage.youtube" class="mr-1 ml-1">
                            <a class="btn btn-lg btn-link btn-youtube" :href="dataPage.youtube" target="_blank"
                               style="padding: 0">
                                <i class="fab fa-youtube"></i>
                            </a>
                        </span>
                        <span v-if="dataPage.twitter" class="mr-1 ml-1">
                            <a class="btn btn-lg btn-link btn-twitter" :href="dataPage.twitter" target="_blank"
                               style="padding: 0">
                                <i class="fab fa-twitter"></i>
                            </a>
                        </span>
                        <span v-if="dataPage.site" class="mr-1 ml-1">
                            <a class="btn btn-lg btn-link btn-site" :href="dataPage.site" target="_blank"
                               style="padding: 0">
                                <i class="fas fa-solid fa-globe"></i>
                            </a>
                        </span>
                    </div>
                    <!-- Social medias -->

                    <!-- Collected Amount -->
                    <div v-if="dataPage.collected_amount !== 0">
                        <h3 class="font-16 collected-transactions" style="margin-top: 10px;">
                            <div>
                                {{ $t("text_header.collected.value") }} :
                                <strong>
                                    {{
                                        new Intl.NumberFormat("fr-FR", {
                                            style: "currency",
                                            currency: "XOF",
                                        }).format(dataPage.collected_amount)
                                    }}
                                </strong>
                            </div>
                            <div>
                                {{ $t("text_header.transactions.value") }} :
                                <strong> {{ dataPage.collected_qty }} </strong>
                            </div>
                        </h3>
                        <!-- <h3 class="font-16" style="margin-top: -5px;">
                            Transactions : <strong> {{  dataPage.collected_qty }} </strong>
                        </h3> -->
                    </div>
                    <!-- Collected Amount -->

                    <!-- Bouton pour la video Yango -->
                    <div class="company-name mb-3" style="text-transform: none" v-if="dataPage.yango === 1">
                        <button class="btn btn-light btn-sm btn-rounded" type="button" style="line-height: 1.6"
                                v-b-modal.openYangoVideo>
                            <i class="fas fa-play"></i> Video Tuto
                        </button>
                    </div>
                    <!-- Bouton pour la video Yango -->

                    <div style="display:flex; justify-content: center; gap: 5px;" class="mb-3 mt-2" v-if="hideRecu">
                        <div v-for="mode in modesPaiementDispo2" :key="mode.mode" v-show="mode.actif == 'oui'">

                            <img :src="require(`../assets/img/menu/${mode.img}`)" alt="Logo Operateur" width="50"/>
                        </div>
                    </div>

                </div>
                <!-- Informations du marchand -->

                <!-- Formulaire -->
                <div class="body">
                    <form name="payment-form" :class="hideRecu == true ? 'payment-form' : 'payment-form hidden'">
                        <!-- Formules e-ticket -->
                        <div class="element-pair" v-if="dataPage.eticket.length > 0">
                            <div v-if="dataPage.eticket[0].programme.img_event !== null">
                                <div class="element-label" style="line-height: inherit !important;">
                                    <span style="text-transform: uppercase">{{
                                            dataPage.eticket[0].programme.libelle
                                        }}</span>
                                </div>
                                <!-- Image du programme -->
                                <div class="image-container">
                                    <img :src="`https://carte.abidjan.net/apaym_pro/apis/dashboard-merchant/events/${dataPage.eticket[0].programme.img_event}`"
                                         style="width: 100%; object-fit: contain"/>
                                </div>
                                <!-- Image du programme -->
                            </div>

                            <div class="element-label" :style="dataPage.eticket[0].programme.img_event !== null
                                ? 'margin-top: 20px;'
                                : ''
                                ">
                                <span v-if="dataPage.eticket[0].programme.img_event === null">
                                    {{ $t("text_form.formulas.value") }} -
                                    <span style="text-transform: uppercase">{{
                                            dataPage.eticket[0].programme.libelle
                                        }}</span>
                                </span>
                                <span v-else>
                                    {{ $t("text_form.formulas.value") }}
                                </span>
                            </div>

                            <!--<div class="element-label">
                                <span>{{ $t('text_form.formulas.value') }} - {{ dataPage.eticket[0].programme.libelle
                                }}</span>
                            </div>-->

                            <div class="element-pair-container">
                                <b-form-group>
                                    <div class="formules-container">
                                        <div v-for="(formule, index) in dataPage.eticket" :key="index"
                                             :id="formule.libelle">
                                            <div class="formule-bloc" :style="dataFormulaireQuick.formule == formule
                                                ? 'background-color: #f6f6f6; border: 1px solid #005CC8'
                                                : 'background-color: #fdfdfd; ; border: 1px solid #b2bec3'
                                                " v-if="formule.quantite_indisponible === false &&
        formule.expiration_formule === false
        " @click="() => {
        dataFormulaireQuick.formule = formule;
        dataFormulaireQuick.motif = formule.libelle;
        dataFormulaireQuick.quantite_indisponbile =
            formule.quantite_indisponbile;
        getMontantFormule(
            typeForm,
            dataFormulaireQuick.formule
        );
    }
        ">
                                                <div style="
                            display: flex;
                            justify-content: flex-end;
                            align-items: center;
                          ">
                                                    <input type="radio" v-model="dataFormulaireQuick.formule" @change="
                                                        getMontantFormule(
                                                            typeForm,
                                                            dataFormulaireQuick.formule
                                                        )
                                                        " :name="formule.libelle" :value="formule"
                                                           style="cursor: pointer; width: 18px; height: 18px"/>
                                                </div>
                                                <div style="
                            padding: 1px;
                            margin-top: 5px;
                            display: block;
                            line-height: 15px;
                          ">
                                                    <span style="">
                                                        {{ formule.libelle }}
                                                    </span>
                                                </div>

                                                <div style="
                            position: absolute;
                            bottom: 3px;
                            margin-top: 5px;
                          ">
                                                    <span class="" :style="formule.quantite_indisponbile === true
                                                        ? 'font-size: 14px; color: #1e017d; font-weight: bolder'
                                                        : 'font-size: 14px; color: #1b1e21; font-weight: bolder'
                                                        ">
                                                        {{
                                                            new Intl.NumberFormat("fr-FR", {
                                                                style: "currency",
                                                                currency: "XOF",
                                                            }).format(formule.montant)
                                                        }}
                                                    </span>
                                                </div>
                                            </div>

                                            <div class="formule-bloc-disabled" :style="dataFormulaireQuick.formule == formule
                                                ? 'background-color: #f6f6f6; border: 1px solid #005CC8'
                                                : 'background-color: #fdfdfd; ; border: 1px solid #b2bec3'
                                                " v-if="formule.expiration_formule === true">
                                                <div style="
                            width: 100%;
                            display: flex;
                            justify-content: right;
                          ">
                                                    <div style="
                              display: flex;
                              justify-content: space-around;
                              align-items: center;
                              padding: 2px;
                              border-radius: 5px;
                              background-color: #fff3cd;
                              color: #f77f00;
                              width: 60px;
                              border: 1px solid rgb(247 127 0 / 25%);
                            ">
                                                        <span class="font-11">{{
                                                                $t("text_form.formulas_expired.value")
                                                            }}</span>
                                                        <i class="fa fa-clock font-11"></i>
                                                    </div>
                                                </div>
                                                <div style="
                            padding: 1px;
                            margin-top: 5px;
                            display: block;
                            line-height: 15px;
                          ">
                                                    <span style="">
                                                        {{ formule.libelle }}
                                                    </span>
                                                </div>

                                                <div style="
                            position: absolute;
                            bottom: 3px;
                            margin-top: 5px;
                          ">
                                                    <span class="" :style="formule.quantite_indisponbile === true
                                                        ? 'font-size: 14px; color: #1e017d; font-weight: bolder'
                                                        : 'font-size: 14px; color: #1b1e21; font-weight: bolder'
                                                        ">
                                                        {{
                                                            new Intl.NumberFormat("fr-FR", {
                                                                style: "currency",
                                                                currency: "XOF",
                                                            }).format(formule.montant)
                                                        }}
                                                    </span>

                                                    <br/>
                                                </div>
                                            </div>

                                            <div class="formule-bloc-disabled" :style="dataFormulaireQuick.formule == formule
                                                ? 'background-color: #f6f6f6; border: 1px solid #005CC8'
                                                : 'background-color: #fdfdfd; ; border: 1px solid #b2bec3'
                                                " v-if="formule.quantite_indisponible === true">
                                                <div style="
                            width: 100%;
                            display: flex;
                            justify-content: right;
                          ">
                                                    <div style="
                              display: flex;
                              justify-content: space-around;
                              align-items: center;
                              padding: 2px;
                              border-radius: 5px;
                              background-color: #f8d7db;
                              color: #dc3545;
                              width: 60px;
                            ">
                                                        <span class="font-11">{{
                                                                $t("text_form.sold_out.value")
                                                            }}</span>
                                                        <i class="fa fa-lock font-11"></i>
                                                    </div>
                                                </div>
                                                <div style="
                            padding: 1px;
                            margin-top: 5px;
                            display: block;
                            line-height: 15px;
                          ">
                                                    <span style="">
                                                        {{ formule.libelle }}
                                                    </span>
                                                </div>

                                                <div style="
                            position: absolute;
                            bottom: 3px;
                            margin-top: 5px;
                          ">
                                                    <span class="" :style="formule.quantite_indisponbile === true
                                                        ? 'font-size: 14px; color: #1e017d; font-weight: bolder'
                                                        : 'font-size: 14px; color: #1b1e21; font-weight: bolder'
                                                        ">
                                                        {{
                                                            new Intl.NumberFormat("fr-FR", {
                                                                style: "currency",
                                                                currency: "XOF",
                                                            }).format(formule.montant)
                                                        }}
                                                    </span>

                                                    <br/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div v-if="dataFormulaireQuick.formule" class="formule-info">
                                        {{ dataFormulaireQuick.formule.description }}
                                    </div>
                                </b-form-group>
                            </div>
                        </div>
                        <!-- Formules e-ticket -->

                        <!-- Immatriculation -->
                        <div class="element-pair" v-if="dataPage.custom_fields == '1'">
                            <div class="element-pair-container">
                                <div class="element-wrap element-wrap-on-left">
                                    <div class="element-label">
                                        <span>{{ $t("text_form.registration_number.value") }}</span>
                                    </div>
                                    <input v-model="dataFormulaireQuick.immatriculation" type="text"
                                           class="element-is-input" name="immatriculation"
                                           style="text-transform: uppercase !important"/>
                                </div>
                            </div>
                        </div>
                        <!-- Immatriculation -->

                        <!-- Types de service -->
                        <div class="element-pair" v-if="dataPage.custom_fields == '1'">
                            <div class="element-pair-container">
                                <div class="element-wrap element-wrap-for-select">
                                    <div class="element-label">
                                        <span>{{ $t("text_form.type_service.value") }}</span>
                                    </div>
                                    <select v-model="dataFormulaireQuick.service" type="text" class="element-is-select"
                                            name="service">
                                        <option value="" selected>
                                            {{ $t("text_form.service_option.value") }}
                                        </option>
                                        <option :value="service.nom_service" v-for="(service, index) in typeServices"
                                                :key="index">
                                            {{ service.nom_service }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <!-- Types de service -->

                        <!-- Quantité de boite souhaitée -->
                        <div class="element-pair" v-if="dataPage.merchant === '24823'">
                            <div class="element-pair-container">
                                <div class="element-wrap element-wrap-on-left">
                                    <div class="element-label">
                                        <span>{{ $t("text_form.nombre_ticket.value") }}</span>
                                    </div>
                                    <select v-model="dataFormulaireQuick.quantite" type="text" class="element-is-select"
                                            name="succursale" @change="selectQuantity2()">
                                        <option v-for="item in [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]" :value="item"
                                                :key="item">
                                            {{ item }}
                                        </option>
                                    </select>

                                </div>
                            </div>
                        </div>
                        <!-- Quantité de boite souhaitée -->

                        <!-- Montant a payer -->
                        <div class="element-pair">
                            <div class="element-label">
                                <span>{{ $t("text_form.amount.value") }}</span>
                                <a href="javascript:void(0);" class="none-outline" style="color: rgb(14, 42, 189)"
                                   v-if="!(
                                    dataPage.amount !== '' &&
                                    parseFloat(dataPage.amount) >= 100
                                )
                                    " v-b-modal.convertAmount>
                                    | {{ $t("text_form.convert_amount.value") }} &nbsp;
                                    <i class="fas fa-euro-sign"></i> &nbsp;
                                    <i class="fas fa-dollar-sign"></i>
                                </a>
                            </div>
                            <div class="element-pair-container" style="align-items: center">
                                <div class="element-wrap element-wrap-on-right">
                                    <div style="
                      display: flex;
                      flex-direction: row;
                      align-items: center;
                    " v-if="dataPage.eticket.length <= 0">
                                        <div class="element-is-input" v-if="dataPage.amount !== '' &&
                                            parseFloat(dataPage.amount) >= 100
                                            " style="
                        font-weight: 900;
                        font-size: 22px;
                        pointer-events: none;
                        background-color: #eee;
                        border: 1px solid #ccc;
                      ">
                                            {{ dataPage.amount | formatAmount }}
                                        </div>

                                        <input v-if="dataPage.amount == ''" :disabled="dataPage.amount !== '' &&
                                            parseFloat(dataPage.amount) >= 100
                                            " maxlength="9" v-model="montantFormateQuick" type="tel"
                                               v-on:keypress="numbersOnly" class="element-is-input" name="montant"
                                               v-mask="currencyMask" v-b-tooltip.hover :title="(montantFormateQuick.replace(/\s/g, '') !== '' &&
                                                montantFormateQuick.replace(/\s/g, '') < 100) ||
                                                montantFormateQuick.replace(/\s/g, '') > 1000000
                                                ? 'Vous pouvez payer au minimum 100 F et au maximum 1 000 000 F'
                                                : ''
                                                " :style="(montantFormateQuick.replace(/\s/g, '') !== '' &&
        montantFormateQuick.replace(/\s/g, '') < 100) ||
        montantFormateQuick.replace(/\s/g, '') > 1000000
        ? 'border: 1px solid red; font-size: 22px; font-weight: 900;'
        : 'font-weight: 900; font-size: 22px'
        "/>
                                        <a href="javascript:void(0);" @click="openCalculator" v-if="!(
                                            dataPage.amount !== '' &&
                                            parseFloat(dataPage.amount) >= 100
                                        )
                                            " style="
                        font-size: 18px;
                        color: #0e2abd;
                        margin-left: -35px;
                      ">
                                            <i class="fas fa-calculator"></i>
                                        </a>
                                    </div>

                                    <div class="element-is-input" v-else style="
                      font-weight: 900;
                      font-size: 22px;
                      pointer-events: none;
                      background-color: rgb(238, 238, 238);
                      border: 1px solid rgb(204, 204, 204);
                    ">
                                        {{ montantFormateQuick }}
                                    </div>
                                </div>
                                <div style="font-size: 20px; font-weight: 800; white-space: nowrap">
                                    F CFA
                                </div>
                            </div>
                        </div>
                        <!-- Montant a payer -->

                        <!-- Pourboire -->
                        <div class="element-pair" v-if="dataPage.pourboire == '1'">
                            <div class="element-label">
                                <span>
                                    {{ $t("text_pourboire.value") }}
                                </span>
                            </div>
                            <div class="element-pair-container">
                                <label :style="pourboire !== '0'
                                    ? 'background: #eeeeee; border: #eeeeee; color: rgb(119 119 119);width: 90px; display: flex; align-items: center; border-radius: 10px; height: 30px; justify-content: center;'
                                    : 'width: 90px; display: flex; align-items: center; border-radius: 10px; height: 30px; justify-content: center;'
                                    " class="btn btn-dark btn-sm ml-1 mr-1">
                                    <input type="radio" value="0" v-model="pourboire" style="display: none"/><span
                                        class="font-12" style="z-index: 200">0 F</span>
                                </label>

                                <label :style="pourboire !== '500'
                                    ? 'background: #eeeeee; border: #eeeeee; color: rgb(119 119 119);width: 90px; display: flex; align-items: center; border-radius: 10px; height: 30px; justify-content: center;'
                                    : 'width: 90px; display: flex; align-items: center; border-radius: 10px; height: 30px; justify-content: center;'
                                    " class="btn btn-dark btn-sm ml-1 mr-1">
                                    <input type="radio" value="500" v-model="pourboire" style="display: none"/><span
                                        class="font-12" style="z-index: 200">500 F</span>
                                </label>

                                <label :style="pourboire !== '1000'
                                    ? 'background: #eeeeee; border: #eeeeee; color: rgb(119 119 119);width: 90px; display: flex; align-items: center; border-radius: 10px; height: 30px; justify-content: center;'
                                    : 'width: 90px; display: flex; align-items: center; border-radius: 10px; height: 30px; justify-content: center;'
                                    " class="btn btn-dark btn-sm ml-1 mr-1">
                                    <input type="radio" value="1000" v-model="pourboire" style="display: none"/>
                                    <span class="font-12" style="z-index: 200">1000 F</span>
                                </label>

                                <label :style="pourboire !== 'autre'
                                    ? 'background: #eeeeee; border: #eeeeee; color: rgb(119 119 119);width: 90px; display: flex; align-items: center; border-radius: 10px; height: 30px; justify-content: center;'
                                    : 'width: 90px; display: flex; align-items: center; border-radius: 10px; height: 30px; justify-content: center;'
                                    " class="btn btn-dark btn-sm ml-1 mr-1">
                                    <input type="radio" name="pourboire" value="autre" v-model="pourboire"
                                           style="display: none"/><span class="font-12" style="z-index: 200">
                                        {{ $t("text_pourboire2.value") }}
                                    </span>
                                </label>
                            </div>

                            <div class="element-pair-container" style="align-items: center"
                                 :hidden="pourboire !== 'autre'">
                                <div class="element-wrap element-wrap-on-right">
                                    <input v-model="dataFormulaireQuick.amountPourboire" type="tel"
                                           v-on:keypress="numbersOnly" class="element-is-input" name="montant"
                                           maxlength="5"
                                           v-b-tooltip.hover :title="dataFormulaireQuick.amountPourboire < 10 ||
                                            dataFormulaireQuick.montant > 20000
                                            ? 'Vous pouvez payé au minimum 10 F et au maximum 20 000 F'
                                            : ''
                                            "/>
                                </div>
                                <div>F CFA</div>
                            </div>
                        </div>
                        <!-- Pourboire -->

                        <!-- Numero de telephone Yango -->
                        <div class="element-pair" style="margin-top: 15px">
                            <div class="element-label">
                                <span v-if="dataPage.deleg_pdci !== '3'">
                                    {{ $t("text_form.number.value") }}
                                </span>
                                <span v-if="dataPage.yango === 1">
                                    {{ $t("text_form.number_driver.value") }}
                                </span>
                            </div>
                            <div class="element-pair-container">
                                <div class="element-wrap element-wrap-on-left">
                                    <multiselect v-model="indice.i" :options="options" :multiple="false"
                                                 placeholder="Indicatif" tag-placeholder="Indicatif" track-by="code"
                                                 label="country"
                                                 :searchable="true" :select-label="''" :deselect-label="''"
                                                 :selected-label="'✓'"
                                                 :allow-empty="false">
                                        <template slot="singleLabel" slot-scope="{ option }">{{
                                                option.country_code
                                            }}
                                        </template>
                                        <span slot="noResult"></span>
                                    </multiselect>
                                </div>
                                <div class="element-wrap element-wrap-on-right">
                                    <input class="element-is-input" type="tel" v-mask="'## ## ## ## ##'"
                                           v-model="dataFormulaireQuick.telephone" name="telephone"
                                           style="font-weight: 900; font-size: 22px"/>
                                </div>
                            </div>
                        </div>
                        <!-- Numero de telephone Yango -->

                        <!-- Numero de telephone -->
                        <div class="element-pair" style="margin-top: 15px" v-if="dataPage.yango === 1">
                            <div class="element-label">
                                <span>
                                    {{ $t("text_form.number.value") }}
                                </span>
                            </div>
                            <div class="element-pair-container">
                                <div class="element-wrap element-wrap-on-left">
                                    <multiselect v-model="indice.i" :options="options" :multiple="false"
                                                 placeholder="Indicatif" tag-placeholder="Indicatif" track-by="code"
                                                 label="country"
                                                 :searchable="true" :select-label="''" :deselect-label="''"
                                                 :selected-label="'✓'">
                                        <template slot="singleLabel" slot-scope="{ option }">{{
                                                option.country_code
                                            }}
                                        </template>
                                        <span slot="noResult"></span>
                                    </multiselect>
                                </div>
                                <div class="element-wrap element-wrap-on-right">
                                    <input class="element-is-input" type="tel" v-mask="'## ## ## ## ##'"
                                           v-model="dataFormulaireQuick.telephone2" name="telephone"
                                           style="font-weight: 900; font-size: 22px"/>
                                </div>
                            </div>
                        </div>
                        <!-- Numero de telephone -->

                        <!-- Succursale -->
                        <div class="element-pair-container" v-if="dataPage.boutiques.length > 0">
                            <div class="element-wrap element-wrap-for-select">
                                <div class="element-label">
                                    <span> {{ $t("text_form.branch_store.value") }} </span>
                                    {{ $t("text_form.optional.value") }}
                                </div>
                                <select v-model="dataFormulaireQuick.boutique" type="text" class="element-is-select"
                                        name="succursale">
                                    <option value="" selected>
                                        {{ $t("text_form.branch_store_option.value") }}
                                    </option>
                                    <option :value="boutique" v-for="(boutique, index) in dataPage.boutiques"
                                            :key="index"
                                            :style="boutique.terminal_id == ''
                                            ? 'text-transform: uppercase;'
                                            : ''
                                            ">
                                        {{ boutique.nom_boutique }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <!-- Succursale -->

                        <!-- Motif -->
                        <div v-if="dataPage.custom_fields !== '1' && dataPage.merchant !== '24823' && dataPage.merchant !== '24011'
                            " class="element-pair-container">
                            <div class="element-wrap">
                                <div class="element-label">
                                    <span>{{ $t("text_form.reason.value") }} </span>
                                </div>

                                <input v-model="dataFormulaireQuick.motif" type="text" class="element-is-input"
                                       name="motif"
                                       maxlength="40" v-if="dataPage.eticket.length <= 0"/>
                                <div class="element-is-input" v-else style="
                    font-weight: 900;
                    pointer-events: none;
                    background-color: rgb(238, 238, 238);
                    border: 1px solid rgb(204, 204, 204);
                  ">
                                    {{ dataFormulaireQuick.motif }}
                                </div>
                            </div>
                        </div>

                        <div v-if="dataPage.merchant == '24011'" class="element-pair-container">
                            <div class="element-wrap element-wrap-for-select element-wrap-on-left">
                                <div class="element-label">
                                    <span>{{ $t("text_form.reason.value") }} </span>
                                </div>

                                <select v-model="dataFormulaireQuick.motif" type="text" class="element-is-select"
                                        name="motif">
                                    <option value="" selected>
                                        {{ $t("text_form.select_reason.value") }}
                                    </option>
                                    <option value="Petit déjeuner">Petit déjeuner</option>
                                    <option value="Repas complet">Repas complet</option>
                                    <option value="Diverses boissons">Diverses boissons</option>
                                    <option value="Bon payé">Bon payé</option>
                                </select>
                            </div>
                        </div>
                        <!-- Motif -->

                        <!-- Nom/Prenom(s)/E-mail -->
                        <div class="element-pair" v-if="dataPage.merchant === '24823'">
                            <div class="element-pair-container">
                                <div class="element-wrap element-wrap-on-left">
                                    <div class="element-label">
                                        <span v-if="dataPage.deleg_pdci !== '3'">
                                            {{ $t("text_form.name.value") }}
                                        </span>
                                        <span v-if="dataPage.yango === 1">
                                            {{ $t("text_form.name_driver.value") }}
                                        </span>
                                    </div>
                                    <input v-model="dataFormulaireQuick.nom" type="text" class="element-is-input"
                                           name="name"/>
                                </div>
                                <div class="element-wrap element-wrap-on-right">
                                    <div class="element-label">
                                        <span v-if="dataPage.deleg_pdci !== '3'">
                                            {{ $t("text_form.surname.value") }}
                                        </span>
                                        <span v-if="dataPage.yango === 1">
                                            {{ $t("text_form.surname_driver.value") }}
                                        </span>
                                    </div>
                                    <input v-model="dataFormulaireQuick.prenom" type="text" class="element-is-input"
                                           name="prenom"/>
                                </div>
                            </div>
                            <!-- E-mail -->
                            <div class="element-pair-container">
                                <div class="element-wrap">
                                    <div class="element-label">
                                        <span v-if="dataPage.deleg_pdci !== '3'">{{ $t("text_form.email.value") }}
                                        </span>
                                        <span v-if="dataPage.yango === 1">{{ $t("text_form.email_driver.value") }}
                                        </span>
                                        <!-- {{ $t('text_form.optional.value') }} -->
                                    </div>
                                    <input v-model="dataFormulaireQuick.email" type="email" class="element-is-input"
                                           name="email"/>
                                </div>
                            </div>
                            <!-- E-mail -->
                        </div>
                        <!-- Nom/Prenom(s)/E-mail -->

                        <!-- Infos supplémentaires -->
                        <div v-if="dataPage.show_fields_by_default !== '1' && dataPage.merchant !== '24823'">
                            <div class="mt-4 mb-3">
                                <a href="javascript:;" style="font-size: 14px; color: #0e2abd" @click="showInfosSup">
                                    <i :class="infosSup ? 'fas fa-plus' : 'fas fa-minus'"></i>
                                    {{ $t("text_form.additional_data.value") }}
                                </a>
                            </div>

                            <div :hidden="infosSup">
                                <!-- Nom/Prenom(s) -->
                                <div class="element-pair">
                                    <div class="element-pair-container">
                                        <div class="element-wrap element-wrap-on-left">
                                            <div class="element-label">
                                                <span v-if="dataPage.deleg_pdci !== '3'">
                                                    {{ $t("text_form.name.value") }}
                                                </span>
                                                <span v-if="dataPage.yango === 1">
                                                    {{ $t("text_form.name_driver.value") }}
                                                </span>
                                            </div>
                                            <input v-model="dataFormulaireQuick.nom" type="text"
                                                   class="element-is-input"
                                                   name="name"/>
                                        </div>
                                        <div class="element-wrap element-wrap-on-right">
                                            <div class="element-label">
                                                <span v-if="dataPage.deleg_pdci !== '3'">
                                                    {{ $t("text_form.surname.value") }}
                                                </span>
                                                <span v-if="dataPage.yango === 1">
                                                    {{ $t("text_form.surname_driver.value") }}
                                                </span>
                                            </div>
                                            <input v-model="dataFormulaireQuick.prenom" type="text"
                                                   class="element-is-input"
                                                   name="prenom"/>
                                        </div>
                                    </div>
                                </div>
                                <!-- Nom/Prenom(s) -->

                                <div class="element-pair">
                                    <!-- Society -->
                                    <div class="element-wrap mb-3">
                                        <div class="element-label">
                                            <span v-if="dataPage.deleg_pdci !== '3'">{{
                                                    $t("text_form.society.value")
                                                }}</span>
                                            <span v-if="dataPage.yango === 1">{{
                                                    $t("text_form.society_driver.value")
                                                }}</span>
                                            <!-- {{$t('text_form.optional.value')}} -->
                                        </div>
                                        <input v-model="dataFormulaireQuick.society" type="text"
                                               class="element-is-input"
                                               name="societe"/>
                                    </div>
                                    <!-- Society -->

                                    <!-- E-mail -->
                                    <div class="element-pair-container">
                                        <div class="element-wrap">
                                            <div class="element-label">
                                                <span v-if="dataPage.deleg_pdci !== '3'">{{
                                                        $t("text_form.email.value")
                                                    }}
                                                </span>
                                                <span v-if="dataPage.yango === 1">{{
                                                        $t("text_form.email_driver.value")
                                                    }}
                                                </span>
                                                <!-- {{ $t('text_form.optional.value') }} -->
                                            </div>
                                            <input v-model="dataFormulaireQuick.email" type="email"
                                                   class="element-is-input"
                                                   name="email"/>
                                        </div>
                                    </div>
                                    <!-- E-mail -->

                                    <!-- Choix notification client -->
                                    <!-- <div class="element-pair">

                                        <div class="element-label">
                                            <span class="font-13" style="font-weight: lighter;">
                                                {{ $t("text_receive_notif.value") }}
                                            </span>
                                        </div>

                                        <div class="element-pair-container">

                                            <label
                                                :style="
                                                    sendNotif !== 'whatsapp' ?
                                                    'background: #eeeeee; border: #eeeeee; color: #cdcccc;width: 90px; display: flex; align-items: center; border-radius: 10px; height: 30px; justify-content: center; z-index: 1' :
                                                    'width: 90px; display: flex; align-items: center; border-radius: 10px; height: 30px; justify-content: center; z-index: 1'
                                                "
                                                class="btn btn-primary btn-sm ml-1 mr-1"
                                            >
                                                <input type="radio" value="whatsapp" v-model="sendNotif" style="display:none;">
                                                <span class="font-12" style="z-index: 200">
                                                    <i class="fab fa-whatsapp"></i> WhatsApp
                                                </span>
                                            </label>

                                            <label
                                                :style="
                                                    sendNotif !== 'sms' ?
                                                    'background: #eeeeee; border: #eeeeee; color: #cdcccc;width: 90px; display: flex; align-items: center; border-radius: 10px; height: 30px; justify-content: center; z-index: 1' :
                                                    'width: 90px; display: flex; align-items: center; border-radius: 10px; height: 30px; justify-content: center; z-index: 1'
                                                "
                                                class="btn btn-primary btn-sm ml-1 mr-1">
                                                <input type="radio" value="sms" v-model="sendNotif" style="display:none;">
                                                <span class="font-12" style="z-index: 200"><i class="fas fa-envelope"></i> SMS</span>
                                            </label>
                                        </div>
                                    </div> -->
                                    <!-- Choix notification client -->
                                </div>
                            </div>
                        </div>

                        <div v-if="dataPage.show_fields_by_default == '1'">
                            <!-- Nom/Prenom(s) -->
                            <div class="element-pair">
                                <div class="element-pair-container">
                                    <div class="element-wrap element-wrap-on-left">
                                        <div class="element-label">
                                            <span v-if="dataPage.deleg_pdci !== '3'">
                                                {{ $t("text_form.name.value") }}
                                            </span>
                                            <span v-if="dataPage.yango === 1">
                                                {{ $t("text_form.name_driver.value") }}
                                            </span>
                                        </div>
                                        <input v-model="dataFormulaireQuick.nom" type="text" class="element-is-input"
                                               name="name"/>
                                    </div>
                                    <div class="element-wrap element-wrap-on-right">
                                        <div class="element-label">
                                            <span v-if="dataPage.deleg_pdci !== '3'">
                                                {{ $t("text_form.surname.value") }}
                                            </span>
                                            <span v-if="dataPage.yango === 1">
                                                {{ $t("text_form.surname_driver.value") }}
                                            </span>
                                        </div>
                                        <input v-model="dataFormulaireQuick.prenom" type="text" class="element-is-input"
                                               name="prenom"/>
                                    </div>
                                </div>
                            </div>
                            <!-- Nom/Prenom(s) -->

                            <div class="element-pair">
                                <!-- Society -->
                                <div class="element-wrap mb-3">
                                    <div class="element-label">
                                        <span v-if="dataPage.deleg_pdci !== '3'">{{
                                                $t("text_form.society.value")
                                            }}</span>
                                        <span v-if="dataPage.yango === 1">{{
                                                $t("text_form.society_driver.value")
                                            }}</span>
                                    </div>
                                    <input v-model="dataFormulaireQuick.society" type="text" class="element-is-input"
                                           name="societe"/>
                                </div>
                                <!-- Society -->

                                <!-- E-mail -->
                                <div class="element-pair-container">
                                    <div class="element-wrap">
                                        <div class="element-label">
                                            <span v-if="dataPage.deleg_pdci !== '3'">{{ $t("text_form.email.value") }}
                                            </span>
                                            <span v-if="dataPage.yango === 1">{{ $t("text_form.email_driver.value") }}
                                            </span>
                                        </div>
                                        <input v-model="dataFormulaireQuick.email" type="email" class="element-is-input"
                                               name="email"/>
                                    </div>
                                </div>
                                <!-- E-mail -->

                                <!-- Choix notification client -->
                                <!-- <div class="element-pair">

                                    <div class="element-label">
                                        <span class="font-13" style="font-weight: lighter;">
                                            {{ $t("text_receive_notif.value") }}
                                        </span>
                                    </div>

                                    <div class="element-pair-container">

                                        <label
                                            :style="
                                                sendNotif !== 'whatsapp' ?
                                                'background: #eeeeee; border: #eeeeee; color: #cdcccc;width: 90px; display: flex; align-items: center; border-radius: 10px; height: 30px; justify-content: center; z-index: 1' :
                                                'width: 90px; display: flex; align-items: center; border-radius: 10px; height: 30px; justify-content: center; z-index: 1'
                                            "
                                            class="btn btn-primary btn-sm ml-1 mr-1"
                                        >
                                            <input type="radio" value="whatsapp" v-model="sendNotif" style="display:none;">
                                            <span class="font-12" style="z-index: 200">
                                                <i class="fab fa-whatsapp"></i> WhatsApp
                                            </span>
                                        </label>

                                        <label
                                            :style="
                                                sendNotif !== 'sms' ?
                                                'background: #eeeeee; border: #eeeeee; color: #cdcccc;width: 90px; display: flex; align-items: center; border-radius: 10px; height: 30px; justify-content: center; z-index: 1' :
                                                'width: 90px; display: flex; align-items: center; border-radius: 10px; height: 30px; justify-content: center; z-index: 1'
                                            "
                                            class="btn btn-primary btn-sm ml-1 mr-1">
                                            <input type="radio" value="sms" v-model="sendNotif" style="display:none;">
                                            <span class="font-12" style="z-index: 200"><i class="fas fa-envelope"></i> SMS</span>
                                        </label>

                                    </div>

                                </div> -->
                                <!-- Choix notification client -->
                            </div>
                        </div>
                        <!-- Infos supplémentaires -->

                        <!-- Button submit form -->
                        <div class="element-wrap element-wrap-for-submit" v-if="dataPage.merchant !== '24823'">
                            <div>
                                <!-- ************************************ -->
                                <!-- ************************************ -->
                                <!-- ************************************ -->
                                <button v-if="dataPage.show_fields_by_default !== '1' &&
                                    dataPage.custom_fields !== '1' &&
                                    dataPage.eticket.length <= 0
                                    " type="button" @click="payerQuickly" :class="!dataFormulaireQuick.motif ||
        !dataFormulaireQuick.telephone ||
        dataFormulaireQuick.telephone.length < 11 ||
        !montantFormateQuick.replace(/\s/g, '') ||
        montantFormateQuick.replace(/\s/g, '') < 100 ||
        montantFormateQuick.replace(/\s/g, '') > 1000000
        ? 'btn btn-dark btn-disabled-custom btn-block'
        : 'btn btn-dark btn-block'
        " :disabled="!dataFormulaireQuick.motif ||
        !dataFormulaireQuick.telephone ||
        dataFormulaireQuick.telephone.length < 11 ||
        !montantFormateQuick.replace(/\s/g, '') ||
        montantFormateQuick.replace(/\s/g, '') < 100 ||
        montantFormateQuick.replace(/\s/g, '') > 1000000
        ">
                                    {{ $t("text_form.button.value") }}
                                    {{
                                        montantFormateQuick.replace(/\s/g, "") !== ""
                                                ? new Intl.NumberFormat("fr-FR", {
                                                    style: "currency",
                                                    currency: "XOF",
                                                }).format(
                                                        parseFloat(montantFormateQuick.replace(/\s/g, ""))
                                                )
                                                : ""
                                    }}
                                </button>

                                <!-- On a l'option eticket activée -->
                                <button v-if="dataPage.show_fields_by_default !== '1' &&
                                        dataPage.custom_fields !== '1' &&
                                        dataPage.eticket.length > 0
                                        " type="button" @click="payerQuickly" :class="!dataFormulaireQuick.motif ||
            !dataFormulaireQuick.telephone ||
            !dataFormulaireQuick.formule ||
            dataFormulaireQuick.telephone.length < 11 ||
            !montantFormateQuick.replace(/\s/g, '') ||
            montantFormateQuick.replace(/\s/g, '') < 100 ||
            montantFormateQuick.replace(/\s/g, '') > 1000000
            ? 'btn btn-dark btn-disabled-custom btn-block'
            : 'btn btn-dark btn-block'
            " :disabled="!dataFormulaireQuick.motif ||
        !dataFormulaireQuick.telephone ||
        !dataFormulaireQuick.formule ||
        dataFormulaireQuick.telephone.length < 11 ||
        !montantFormateQuick.replace(/\s/g, '') ||
        montantFormateQuick.replace(/\s/g, '') < 100 ||
        montantFormateQuick.replace(/\s/g, '') > 1000000
        ">
                                    {{ $t("text_form.button.value") }}
                                    {{
                                        montantFormateQuick.replace(/\s/g, "") !== ""
                                                ? new Intl.NumberFormat("fr-FR", {
                                                    style: "currency",
                                                    currency: "XOF",
                                                }).format(
                                                        parseFloat(montantFormateQuick.replace(/\s/g, ""))
                                                )
                                                : ""
                                    }}
                                </button>
                                <!-- ************************************ -->
                                <!-- ************************************ -->
                                <!-- ************************************ -->

                                <!-- dataPage.eticket.length -->
                                <!-- ************************************ -->
                                <!-- ************************************ -->
                                <!-- ************************************ -->
                                <!-- Les champs immatriculation et service sont obligatoires -->
                                <button v-if="dataPage.show_fields_by_default !== '1' &&
                                        dataPage.custom_fields == '1' &&
                                        dataPage.eticket.length <= 0
                                        " type="button" @click="payerQuickly" :class="!dataFormulaireQuick.immatriculation ||
            !dataFormulaireQuick.service ||
            !dataFormulaireQuick.telephone ||
            dataFormulaireQuick.telephone.length < 11 ||
            !montantFormateQuick.replace(/\s/g, '') ||
            montantFormateQuick.replace(/\s/g, '') < 100 ||
            montantFormateQuick.replace(/\s/g, '') > 1000000
            ? 'btn btn-dark btn-disabled-custom btn-block'
            : 'btn btn-dark btn-block'
            " :disabled="!dataFormulaireQuick.immatriculation ||
        !dataFormulaireQuick.service ||
        !dataFormulaireQuick.telephone ||
        dataFormulaireQuick.telephone.length < 11 ||
        !montantFormateQuick.replace(/\s/g, '') ||
        montantFormateQuick.replace(/\s/g, '') < 100 ||
        montantFormateQuick.replace(/\s/g, '') > 1000000
        ">
                                    {{ $t("text_form.button.value") }}
                                    {{
                                        montantFormateQuick.replace(/\s/g, "") !== ""
                                                ? new Intl.NumberFormat("fr-FR", {
                                                    style: "currency",
                                                    currency: "XOF",
                                                }).format(
                                                        parseFloat(montantFormateQuick.replace(/\s/g, ""))
                                                )
                                                : ""
                                    }}
                                </button>

                                <button v-if="dataPage.show_fields_by_default !== '1' &&
                                        dataPage.custom_fields == '1' &&
                                        dataPage.eticket.length > 0
                                        " type="button" @click="payerQuickly" :class="!dataFormulaireQuick.formule ||
            !dataFormulaireQuick.immatriculation ||
            !dataFormulaireQuick.service ||
            !dataFormulaireQuick.telephone ||
            dataFormulaireQuick.telephone.length < 11 ||
            !montantFormateQuick.replace(/\s/g, '') ||
            montantFormateQuick.replace(/\s/g, '') < 100 ||
            montantFormateQuick.replace(/\s/g, '') > 1000000
            ? 'btn btn-dark btn-disabled-custom btn-block'
            : 'btn btn-dark btn-block'
            " :disabled="!dataFormulaireQuick.formule ||
        !dataFormulaireQuick.immatriculation ||
        !dataFormulaireQuick.service ||
        !dataFormulaireQuick.telephone ||
        dataFormulaireQuick.telephone.length < 11 ||
        !montantFormateQuick.replace(/\s/g, '') ||
        montantFormateQuick.replace(/\s/g, '') < 100 ||
        montantFormateQuick.replace(/\s/g, '') > 1000000
        ">
                                    {{ $t("text_form.button.value") }}
                                    {{
                                        montantFormateQuick.replace(/\s/g, "") !== ""
                                                ? new Intl.NumberFormat("fr-FR", {
                                                    style: "currency",
                                                    currency: "XOF",
                                                }).format(
                                                        parseFloat(montantFormateQuick.replace(/\s/g, ""))
                                                )
                                                : ""
                                    }}
                                </button>
                                <!-- ************************************ -->
                                <!-- ************************************ -->
                                <!-- ************************************ -->

                                <!-- ************************************ -->
                                <!-- ************************************ -->
                                <!-- ************************************ -->
                                <!-- Le champ email est obligatoire -->
                                <button v-if="dataPage.show_fields_by_default == '1' &&
                                        dataPage.custom_fields !== '1' &&
                                        dataPage.eticket.length <= 0
                                        " type="button" @click="payerQuickly" :class="!dataFormulaireQuick.motif ||
            !dataFormulaireQuick.email ||
            !dataFormulaireQuick.society ||
            !dataFormulaireQuick.prenom ||
            !dataFormulaireQuick.nom ||
            !dataFormulaireQuick.telephone ||
            dataFormulaireQuick.telephone.length < 11 ||
            !montantFormateQuick.replace(/\s/g, '') ||
            montantFormateQuick.replace(/\s/g, '') < 100 ||
            montantFormateQuick.replace(/\s/g, '') > 1000000
            ? 'btn btn-dark btn-disabled-custom btn-block'
            : 'btn btn-dark btn-block'
            " :disabled="!dataFormulaireQuick.motif ||
        !dataFormulaireQuick.email ||
        !dataFormulaireQuick.society ||
        !dataFormulaireQuick.prenom ||
        !dataFormulaireQuick.nom ||
        !dataFormulaireQuick.telephone ||
        dataFormulaireQuick.telephone.length < 11 ||
        !montantFormateQuick.replace(/\s/g, '') ||
        montantFormateQuick.replace(/\s/g, '') < 100 ||
        montantFormateQuick.replace(/\s/g, '') > 1000000
        ">
                                    {{ $t("text_form.button.value") }}
                                    {{
                                        montantFormateQuick.replace(/\s/g, "") !== ""
                                                ? new Intl.NumberFormat("fr-FR", {
                                                    style: "currency",
                                                    currency: "XOF",
                                                }).format(
                                                        parseFloat(montantFormateQuick.replace(/\s/g, ""))
                                                )
                                                : ""
                                    }}
                                </button>

                                <button v-if="dataPage.show_fields_by_default == '1' &&
                                        dataPage.custom_fields !== '1' &&
                                        dataPage.eticket.length > 0
                                        " type="button" @click="payerQuickly" :class="!dataFormulaireQuick.formule ||
            !dataFormulaireQuick.motif ||
            !dataFormulaireQuick.email ||
            !dataFormulaireQuick.society ||
            !dataFormulaireQuick.prenom ||
            !dataFormulaireQuick.nom ||
            !dataFormulaireQuick.telephone ||
            dataFormulaireQuick.telephone.length < 11 ||
            !montantFormateQuick.replace(/\s/g, '') ||
            montantFormateQuick.replace(/\s/g, '') < 100 ||
            montantFormateQuick.replace(/\s/g, '') > 1000000
            ? 'btn btn-dark btn-disabled-custom btn-block'
            : 'btn btn-dark btn-block'
            " :disabled="!dataFormulaireQuick.formule ||
        !dataFormulaireQuick.motif ||
        !dataFormulaireQuick.email ||
        !dataFormulaireQuick.society ||
        !dataFormulaireQuick.prenom ||
        !dataFormulaireQuick.nom ||
        !dataFormulaireQuick.telephone ||
        dataFormulaireQuick.telephone.length < 11 ||
        !montantFormateQuick.replace(/\s/g, '') ||
        montantFormateQuick.replace(/\s/g, '') < 100 ||
        montantFormateQuick.replace(/\s/g, '') > 1000000
        ">
                                    {{ $t("text_form.button.value") }}
                                    {{
                                        montantFormateQuick.replace(/\s/g, "") !== ""
                                                ? new Intl.NumberFormat("fr-FR", {
                                                    style: "currency",
                                                    currency: "XOF",
                                                }).format(
                                                        parseFloat(montantFormateQuick.replace(/\s/g, ""))
                                                )
                                                : ""
                                    }}
                                </button>
                                <!-- ************************************ -->
                                <!-- ************************************ -->
                                <!-- ************************************ -->
                            </div>
                        </div>
                        <!-- Button submit form -->
                        <!-- Button submit form -->
                        <div class="element-wrap element-wrap-for-submit" v-else>
                            <div>
                                <!-- ************************************ -->
                                <!-- ************************************ -->
                                <!-- ************************************ -->
                                <button type="button" @click="payerQuickly" :class="!dataFormulaireQuick.nom ||
                                            !dataFormulaireQuick.telephone || !dataFormulaireQuick.prenom ||
                                            !dataFormulaireQuick.email ||
                                            dataFormulaireQuick.telephone.length < 11 ||
                                            !montantFormateQuick.replace(/\s/g, '') ||
                                            montantFormateQuick.replace(/\s/g, '') < 100 ||
                                            montantFormateQuick.replace(/\s/g, '') > 1000000
                                            ? 'btn btn-dark btn-disabled-custom btn-block'
                                            : 'btn btn-dark btn-block'
                                        " :disabled="!dataFormulaireQuick.nom ||
            !dataFormulaireQuick.telephone || !dataFormulaireQuick.prenom ||
            !dataFormulaireQuick.email ||
            dataFormulaireQuick.telephone.length < 11 ||
            !montantFormateQuick.replace(/\s/g, '') ||
            montantFormateQuick.replace(/\s/g, '') < 100 ||
            montantFormateQuick.replace(/\s/g, '') > 1000000
            ">
                                    {{ $t("text_form.button.value") }}
                                    {{
                                        montantFormateQuick.replace(/\s/g, "") !== ""
                                                ? new Intl.NumberFormat("fr-FR", {
                                                    style: "currency",
                                                    currency: "XOF",
                                                }).format(
                                                        parseFloat(montantFormateQuick.replace(/\s/g, ""))
                                                )
                                                : ""
                                    }}
                                </button>

                                <!-- ************************************ -->
                                <!-- ************************************ -->
                                <!-- ************************************ -->
                            </div>
                        </div>
                        <!-- Button submit form -->
                    </form>

                    <!-- ******** -->
                    <!-- Bloc success payment -->
                    <!-- ******** -->
                    <success-page :hideRecu="hideRecu" :logoSmallDevice="logoSmallDevice"
                                  :montant="dataFormulaireQuick.montant" :nom="dataFormulaireQuick.nom"
                                  :prenom="dataFormulaireQuick.prenom" :devise="dataFormulaireQuick.devise"
                                  :motif="dataFormulaireQuick.motif" :refPayment="refPayment"
                                  :nombre_transactions="nombre_transactions">
                    </success-page>
                    <!-- ******** -->
                    <!-- Bloc success payment -->
                    <!-- ******** -->
                </div>
                <!-- Formulaire -->
            </div>
        </div>
        <!-- Form Quickly Payment -->

        <!-- Form SuperQuickly Payment - Paye vite avec seulement le montant et le numéro de téléphone comme champs obligatoires -->
        <div class="apaympro-wrapper animated fadeIn container" :hidden="!formSuperQuicklyPayment" style="width: 500px">
            <div id="payment-page-pay" class="apaympro-page">
                <!-- Informations du marchand -->
                <div class="apaympro-header" style="margin-top: 15px">
                    <div class="company-logo" v-if="dataPage.business_logo"
                         style="width: 150px !important; justify-content: center">
                        <img id="company-logo-img" :src="dataPage.business_logo" alt="Business Logo"/>
                    </div>

                    <div class="page-info">
                        <h3 id="page-name" class="page-name font-16" v-if="dataPage.partner !== '2'">
                            {{
                                dataPage.type == "ONG" || dataPage.type == "PERSONNALITE"
                                        ? $t("text_header.donation.value")
                                        : $t("text_header.pay.value")
                            }}
                            <strong style="text-transform: uppercase">
                                {{ dataPage.business_name }}
                                <img src="../assets/img/badge-check.png" v-b-tooltip.hover
                                     :title="$t('text_certified_account.value')" v-if="dataPage.verified == '1'"
                                     width="15"
                                     style="margin-top: -3px"/>
                            </strong>
                            <!-- <p class="company-name mb-2" style="font-weight: 500; font-size: 11px">{{ dataPage.ville }}</p> -->
                            <p class="company-name mb-2" style="font-weight: 500; font-size: 11px">
                                <img v-if="dataPage.merchant == '21449'" src="../assets/img/commerce.png"
                                     alt="Carte du commercant" width="30" style="cursor: pointer" v-b-tooltip.hover
                                     title="Marchand ayant la carte du commerçant"/>
                                {{ dataPage.ville }} / {{ dataPage.type }}
                            </p>
                        </h3>

                        <!-- Yango -->
                        <h3 id="page-name" class="page-name font-16" v-if="dataPage.partner == '2'">
                            <strong style="text-transform: uppercase">
                                YANGO
                                <img src="../assets/img/badge-check.png" v-b-tooltip.hover
                                     :title="$t('text_certified_account.value')" v-if="dataPage.verified == '1'"
                                     width="15"
                                     style="margin-top: -3px"/>
                            </strong>
                            <p class="company-name mb-2" style="font-weight: 500; font-size: 11px">
                                {{ dataPage.ville }}
                            </p>
                        </h3>
                        <!-- Yango -->

                        <p class="company-name text-dark" v-if="dataPage.type == 'SP'">
                            {{ $t("text_header.by2.value") }}
                            <span style="font-weight: 600">{{ dataPage.nom }}</span> <br/>
                            <span class="font-12" style="text-transform: none">{{
                                    $t("text_header.enterprising.value")
                                }}</span>
                        </p>
                        <p class="company-name" v-if="dataPage.type !== 'SP' && dataPage.type !== 'AUTRE'">
                            <span style="font-weight: 500; font-size: 11px">
                                <!-- {{ dataPage.type }}  -->
                                {{
                                    dataPage.rcc !== "0" || dataPage.rcc.includes("---") == "-1"
                                            ? dataPage.rcc !== ""
                                                    ? dataPage.rcc
                                                    : ""
                                            : ""
                                }}
                            </span>
                        </p>
                    </div>

                    <div class="page-description" v-if="dataPage.description_page">
                        <span id="tooltip-description-page-quickly" style="cursor: pointer">
                            {{ dataPage.description_page }}
                        </span>
                        <b-tooltip target="tooltip-description-page-quickly" triggers="hover">
                            {{ dataPage.description_page_2 }}
                        </b-tooltip>
                    </div>

                    <!-- Social medias -->
                    <div class="company-name" style="text-transform: none">
                        <span class="mr-1 ml-1">
                            <a class="btn btn-lg btn-link btn-facebook" v-b-modal.openQrCode style="padding: 0">
                                <i class="fas fa-solid fa-qrcode text-primary"></i>
                            </a>
                        </span>
                        <span v-if="dataPage.facebook" class="mr-1 ml-1">
                            <a class="btn btn-lg btn-link btn-facebook" :href="dataPage.facebook" target="_blank"
                               style="padding: 0">
                                <i class="fab fa-facebook-f"></i>
                            </a>
                        </span>
                        <span v-if="dataPage.instagram" class="mr-1 ml-1">
                            <a class="btn btn-lg btn-link btn-instagram" :href="dataPage.instagram" target="_blank"
                               style="padding: 0">
                                <i class="fab fa-instagram"></i>
                            </a>
                        </span>
                        <span v-if="dataPage.whatsapp" class="mr-1 ml-1">
                            <a class="btn btn-lg btn-link btn-whatsapp" :href="dataPage.whatsapp" target="_blank"
                               style="padding: 0">
                                <i class="fab fa-whatsapp"></i>
                            </a>
                        </span>
                        <span v-if="dataPage.youtube" class="mr-1 ml-1">
                            <a class="btn btn-lg btn-link btn-youtube" :href="dataPage.youtube" target="_blank"
                               style="padding: 0">
                                <i class="fab fa-youtube"></i>
                            </a>
                        </span>
                        <span v-if="dataPage.twitter" class="mr-1 ml-1">
                            <a class="btn btn-lg btn-link btn-twitter" :href="dataPage.twitter" target="_blank"
                               style="padding: 0">
                                <i class="fab fa-twitter"></i>
                            </a>
                        </span>
                        <span v-if="dataPage.site" class="mr-1 ml-1">
                            <a class="btn btn-lg btn-link btn-site" :href="dataPage.site" target="_blank"
                               style="padding: 0">
                                <i class="fas fa-solid fa-globe"></i>
                            </a>
                        </span>
                    </div>
                    <!-- Social medias -->

                    <!-- Collected Amount -->
                    <div v-if="dataPage.collected_amount !== 0">
                        <h3 class="font-16 collected-transactions" style="margin-top: 10px;">
                            <div>
                                {{ $t("text_header.collected.value") }} :
                                <strong>
                                    {{
                                        new Intl.NumberFormat("fr-FR", {
                                            style: "currency",
                                            currency: "XOF",
                                        }).format(dataPage.collected_amount)
                                    }}
                                </strong>
                            </div>
                            <div>
                                {{ $t("text_header.transactions.value") }} :
                                <strong> {{ dataPage.collected_qty }} </strong>
                            </div>
                        </h3>
                        <!-- <h3 class="font-16" style="margin-top: -5px;">
                            Transactions : <strong> {{  dataPage.collected_qty }} </strong>
                        </h3> -->
                    </div>
                    <!-- Collected Amount -->

                    <!-- Bouton pour la video Yango -->
                    <div class="company-name mb-3" style="text-transform: none" v-if="dataPage.yango === 1">
                        <button class="btn btn-light btn-sm btn-rounded" type="button" style="line-height: 1.6"
                                v-b-modal.openYangoVideo>
                            <i class="fas fa-play"></i> Video Tuto
                        </button>
                    </div>
                    <!-- Bouton pour la video Yango -->


                    <div style="display:flex; justify-content: center; gap: 5px;" class="mb-3 mt-2" v-if="hideRecu">
                        <div v-for="mode in modesPaiementDispo2" :key="mode.mode" v-show="mode.actif == 'oui'">

                            <img :src="require(`../assets/img/menu/${mode.img}`)" alt="Logo Operateur" width="50"/>
                        </div>
                    </div>

                </div>
                <!-- Informations du marchand -->

                <!-- Formulaire -->
                <div class="body">
                    <form name="payment-form" :class="hideRecu == true ? 'payment-form' : 'payment-form hidden'">
                        <!-- Formules e-ticket -->
                        <div class="element-pair" v-if="dataPage.eticket.length > 0">
                            <div v-if="dataPage.eticket[0].programme.img_event !== null">
                                <div class="element-label" style="line-height: inherit !important;">
                                    <span style="text-transform: uppercase">{{
                                            dataPage.eticket[0].programme.libelle
                                        }}</span>
                                </div>
                                <!-- Image du programme -->
                                <div class="image-container">
                                    <img :src="`https://carte.abidjan.net/apaym_pro/apis/dashboard-merchant/events/${dataPage.eticket[0].programme.img_event}`"
                                         style="width: 100%; object-fit: contain"/>
                                </div>
                                <!-- Image du programme -->
                            </div>

                            <div class="element-label" :style="dataPage.eticket[0].programme.img_event !== null
                                ? 'margin-top: 20px;'
                                : ''
                                ">
                                <span v-if="dataPage.eticket[0].programme.img_event === null">
                                    {{ $t("text_form.formulas.value") }} -
                                    <span style="text-transform: uppercase">{{
                                            dataPage.eticket[0].programme.libelle
                                        }}</span>
                                </span>
                                <span v-else>
                                    {{ $t("text_form.formulas.value") }}
                                </span>
                            </div>

                            <div class="element-pair-container">
                                <b-form-group>
                                    <div class="formules-container">
                                        <div v-for="(formule, index) in dataPage.eticket" :key="index"
                                             :id="formule.libelle">
                                            <div class="formule-bloc" :style="dataFormulaireQuick.formule == formule
                                                ? 'background-color: #f6f6f6; border: 1px solid #005CC8'
                                                : 'background-color: #fdfdfd; ; border: 1px solid #b2bec3'
                                                " v-if="formule.quantite_indisponible === false &&
        formule.expiration_formule === false
        " @click="() => {
        dataFormulaireQuick.formule = formule;
        dataFormulaireQuick.motif = formule.libelle;
        dataFormulaireQuick.quantite_indisponbile =
            formule.quantite_indisponbile;
        getMontantFormule(
            typeForm,
            dataFormulaireQuick.formule
        );
    }
        ">
                                                <div style="
                            display: flex;
                            justify-content: flex-end;
                            align-items: center;
                          ">
                                                    <input type="radio" v-model="dataFormulaireQuick.formule" @change="
                                                        getMontantFormule(
                                                            typeForm,
                                                            dataFormulaireQuick.formule
                                                        )
                                                        " :name="formule.libelle" :value="formule"
                                                           style="cursor: pointer; width: 18px; height: 18px"/>
                                                </div>
                                                <div style="
                            padding: 1px;
                            margin-top: 5px;
                            display: block;
                            line-height: 15px;
                          ">
                                                    <span style="">
                                                        {{ formule.libelle }}
                                                    </span>
                                                </div>

                                                <div style="
                            position: absolute;
                            bottom: 3px;
                            margin-top: 5px;
                          ">
                                                    <span class="" :style="formule.quantite_indisponbile === true
                                                        ? 'font-size: 14px; color: #1e017d; font-weight: bolder'
                                                        : 'font-size: 14px; color: #1b1e21; font-weight: bolder'
                                                        ">
                                                        {{
                                                            new Intl.NumberFormat("fr-FR", {
                                                                style: "currency",
                                                                currency: "XOF",
                                                            }).format(formule.montant)
                                                        }}
                                                    </span>
                                                </div>
                                            </div>

                                            <div class="formule-bloc-disabled" :style="dataFormulaireQuick.formule == formule
                                                ? 'background-color: #f6f6f6; border: 1px solid #005CC8'
                                                : 'background-color: #fdfdfd; ; border: 1px solid #b2bec3'
                                                " v-if="formule.expiration_formule === true">
                                                <div style="
                            width: 100%;
                            display: flex;
                            justify-content: right;
                          ">
                                                    <div style="
                              display: flex;
                              justify-content: space-around;
                              align-items: center;
                              padding: 2px;
                              border-radius: 5px;
                              background-color: #fff3cd;
                              color: #f77f00;
                              width: 60px;
                              border: 1px solid rgb(247 127 0 / 25%);
                            ">
                                                        <span class="font-11">{{
                                                                $t("text_form.formulas_expired.value")
                                                            }}</span>
                                                        <i class="fa fa-clock font-11"></i>
                                                    </div>
                                                </div>
                                                <div style="
                            padding: 1px;
                            margin-top: 5px;
                            display: block;
                            line-height: 15px;
                          ">
                                                    <span style="">
                                                        {{ formule.libelle }}
                                                    </span>
                                                </div>

                                                <div style="
                            position: absolute;
                            bottom: 3px;
                            margin-top: 5px;
                          ">
                                                    <span class="" :style="formule.quantite_indisponbile === true
                                                        ? 'font-size: 14px; color: #1e017d; font-weight: bolder'
                                                        : 'font-size: 14px; color: #1b1e21; font-weight: bolder'
                                                        ">
                                                        {{
                                                            new Intl.NumberFormat("fr-FR", {
                                                                style: "currency",
                                                                currency: "XOF",
                                                            }).format(formule.montant)
                                                        }}
                                                    </span>

                                                    <br/>
                                                </div>
                                            </div>

                                            <div class="formule-bloc-disabled" :style="dataFormulaireQuick.formule == formule
                                                ? 'background-color: #f6f6f6; border: 1px solid #005CC8'
                                                : 'background-color: #fdfdfd; ; border: 1px solid #b2bec3'
                                                " v-if="formule.quantite_indisponible === true &&
            formule.expiration_formule === false
            ">
                                                <div style="
                            width: 100%;
                            display: flex;
                            justify-content: right;
                          ">
                                                    <div style="
                              display: flex;
                              justify-content: space-around;
                              align-items: center;
                              padding: 2px;
                              border-radius: 5px;
                              background-color: #f8d7db;
                              color: #dc3545;
                              width: 60px;
                            ">
                                                        <span class="font-11">{{
                                                                $t("text_form.sold_out.value")
                                                            }}</span>
                                                        <i class="fa fa-lock font-11"></i>
                                                    </div>
                                                </div>
                                                <div style="
                            padding: 1px;
                            margin-top: 5px;
                            display: block;
                            line-height: 15px;
                          ">
                                                    <span style="">
                                                        {{ formule.libelle }}
                                                    </span>
                                                </div>

                                                <div style="
                            position: absolute;
                            bottom: 3px;
                            margin-top: 5px;
                          ">
                                                    <span class="" :style="formule.quantite_indisponbile === true
                                                        ? 'font-size: 14px; color: #1e017d; font-weight: bolder'
                                                        : 'font-size: 14px; color: #1b1e21; font-weight: bolder'
                                                        ">
                                                        {{
                                                            new Intl.NumberFormat("fr-FR", {
                                                                style: "currency",
                                                                currency: "XOF",
                                                            }).format(formule.montant)
                                                        }}
                                                    </span>

                                                    <br/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div v-if="dataFormulaireQuick.formule" class="formule-info">
                                        {{ dataFormulaireQuick.formule.description }}
                                    </div>
                                </b-form-group>
                            </div>
                        </div>
                        <!-- Formules e-ticket -->

                        <!-- Montant a payer -->
                        <div class="element-pair">
                            <div class="element-label">
                                <span>{{ $t("text_form.amount.value") }}</span>
                                <!--|-->
                                <!--<a href="javascript:;" class="none-outline" style="color: rgb(14, 42, 189)" v-b-modal.convertAmount>
                                    {{ $t("text_form.convert_amount.value") }} &nbsp; <i class="fas fa-euro-sign"></i> &nbsp; <i class="fas fa-dollar-sign"></i>
                                </a>-->
                            </div>
                            <div class="element-pair-container" style="align-items: center">
                                <div class="element-wrap element-wrap-on-right">
                                    <div style="
                      display: flex;
                      flex-direction: row;
                      align-items: center;
                    " v-if="dataPage.eticket.length <= 0">
                                        <input :disabled="(dataPage.amount !== '' &&
                                            parseFloat(dataPage.amount) >= 100) ||
                                            dataPage.eticket.length > 0
                                            " v-model="montantFormateQuick" type="tel" v-mask="currencyMask"
                                               v-on:keypress="numbersOnly" class="element-is-input" name="montant"
                                               v-b-tooltip.hover :title="(montantFormateQuick.replace(/\s/g, '') !== '' &&
                                                montantFormateQuick.replace(/\s/g, '') < 100) ||
                                                montantFormateQuick.replace(/\s/g, '') > 1000000
                                                ? 'Vous pouvez payer au minimum 100 F et au maximum 1 000 000 F'
                                                : ''
                                                " :style="(montantFormateQuick.replace(/\s/g, '') !== '' &&
        montantFormateQuick.replace(/\s/g, '') < 100) ||
        montantFormateQuick.replace(/\s/g, '') > 1000000
        ? 'border: 1px solid red; font-size: 22px; font-weight: 900;'
        : 'font-weight: 900; font-size: 22px'
        " ref="montantRef"/>
                                        <a href="javascript:void(0);" @click="openCalculator" v-if="!(
                                            dataPage.amount !== '' &&
                                            parseFloat(dataPage.amount) >= 100
                                        )
                                            " style="
                        font-size: 18px;
                        color: #0e2abd;
                        margin-left: -35px;
                      ">
                                            <i class="fas fa-calculator"></i>
                                        </a>
                                    </div>
                                    <div class="element-is-input" v-else style="
                      font-weight: 900;
                      font-size: 22px;
                      pointer-events: none;
                      background-color: rgb(238, 238, 238);
                      border: 1px solid rgb(204, 204, 204);
                    ">
                                        {{ montantFormateQuick }}
                                    </div>
                                </div>
                                <div style="font-size: 20px; font-weight: 800; white-space: nowrap">
                                    F CFA
                                </div>
                            </div>
                        </div>
                        <!-- Montant a payer -->

                        <!-- Infos supplémentaires -->
                        <div v-if="dataPage.show_fields_by_default !== '1'">
                            <div class="mt-4 mb-3">
                                <a href="javascript:;" style="font-size: 14px; color: #0e2abd" @click="showInfosSup">
                                    <i :class="infosSup ? 'fas fa-plus' : 'fas fa-minus'"></i>
                                    {{ $t("text_form.additional_data.value") }}
                                </a>
                            </div>

                            <div :hidden="infosSup">
                                <!-- Immatriculation -->
                                <div class="element-pair" v-if="dataPage.custom_fields == '1'">
                                    <div class="element-pair-container">
                                        <div class="element-wrap element-wrap-on-left">
                                            <div class="element-label">
                                                <span>{{
                                                        $t("text_form.registration_number.value")
                                                    }}</span>
                                            </div>
                                            <input v-model="dataFormulaireQuick.immatriculation" type="text"
                                                   class="element-is-input" name="immatriculation"
                                                   style="text-transform: uppercase !important"/>
                                        </div>
                                    </div>
                                </div>
                                <!-- Immatriculation -->

                                <!-- Types de service -->
                                <div class="element-pair" v-if="dataPage.custom_fields == '1'">
                                    <div class="element-pair-container">
                                        <div class="element-wrap element-wrap-for-select">
                                            <div class="element-label">
                                                <span>{{ $t("text_form.type_service.value") }}</span>
                                            </div>
                                            <select v-model="dataFormulaireQuick.service" type="text"
                                                    class="element-is-select" name="service">
                                                <option value="" selected>
                                                    {{ $t("text_form.service_option.value") }}
                                                </option>
                                                <option :value="service.nom_service"
                                                        v-for="(service, index) in typeServices" :key="index">
                                                    {{ service.nom_service }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <!-- Types de service -->

                                <!-- Pourboire -->
                                <div class="element-pair" v-if="dataPage.pourboire == '1'">
                                    <div class="element-label">
                                        <span>
                                            {{ $t("text_pourboire.value") }}
                                        </span>
                                    </div>
                                    <div class="element-pair-container">
                                        <label :style="pourboire !== '0'
                                            ? 'background: #eeeeee; border: #eeeeee; color: rgb(119 119 119);width: 90px; display: flex; align-items: center; border-radius: 10px; height: 30px; justify-content: center;'
                                            : 'width: 90px; display: flex; align-items: center; border-radius: 10px; height: 30px; justify-content: center;'
                                            " class="btn btn-dark btn-sm ml-1 mr-1">
                                            <input type="radio" value="0" v-model="pourboire"
                                                   style="display: none"/><span
                                                class="font-12" style="z-index: 200">0 F</span>
                                        </label>

                                        <label :style="pourboire !== '500'
                                            ? 'background: #eeeeee; border: #eeeeee; color: rgb(119 119 119);width: 90px; display: flex; align-items: center; border-radius: 10px; height: 30px; justify-content: center;'
                                            : 'width: 90px; display: flex; align-items: center; border-radius: 10px; height: 30px; justify-content: center;'
                                            " class="btn btn-dark btn-sm ml-1 mr-1">
                                            <input type="radio" value="500" v-model="pourboire"
                                                   style="display: none"/><span class="font-12" style="z-index: 200">500
                                                F</span>
                                        </label>

                                        <label :style="pourboire !== '1000'
                                            ? 'background: #eeeeee; border: #eeeeee; color: rgb(119 119 119);width: 90px; display: flex; align-items: center; border-radius: 10px; height: 30px; justify-content: center;'
                                            : 'width: 90px; display: flex; align-items: center; border-radius: 10px; height: 30px; justify-content: center;'
                                            " class="btn btn-dark btn-sm ml-1 mr-1">
                                            <input type="radio" value="1000" v-model="pourboire" style="display: none"/>
                                            <span class="font-12" style="z-index: 200">1000 F</span>
                                        </label>

                                        <label :style="pourboire !== 'autre'
                                            ? 'background: #eeeeee; border: #eeeeee; color: rgb(119 119 119);width: 90px; display: flex; align-items: center; border-radius: 10px; height: 30px; justify-content: center;'
                                            : 'width: 90px; display: flex; align-items: center; border-radius: 10px; height: 30px; justify-content: center;'
                                            " class="btn btn-dark btn-sm ml-1 mr-1">
                                            <input type="radio" name="pourboire" value="autre" v-model="pourboire"
                                                   style="display: none"/><span class="font-12" style="z-index: 200">
                                                {{ $t("text_pourboire2.value") }}
                                            </span>
                                        </label>
                                    </div>

                                    <div class="element-pair-container" style="align-items: center"
                                         :hidden="pourboire !== 'autre'">
                                        <div class="element-wrap element-wrap-on-right">
                                            <input v-model="dataFormulaireQuick.amountPourboire" type="tel"
                                                   v-on:keypress="numbersOnly" class="element-is-input" name="montant"
                                                   maxlength="5" v-b-tooltip.hover :title="dataFormulaireQuick.amountPourboire < 10 ||
                                                    dataFormulaireQuick.montant > 20000
                                                    ? 'Vous pouvez payé au minimum 10 F et au maximum 20 000 F'
                                                    : ''
                                                    "/>
                                        </div>
                                        <div>F CFA</div>
                                    </div>
                                </div>
                                <!-- Pourboire -->

                                <!-- Numero de telephone Yango -->
                                <div class="element-pair" style="margin-top: 15px">
                                    <div class="element-label">
                                        <span v-if="dataPage.deleg_pdci !== '3'">
                                            {{ $t("text_form.number.value") }}
                                        </span>
                                        <span v-if="dataPage.yango === 1">
                                            {{ $t("text_form.number_driver.value") }}
                                        </span>
                                    </div>
                                    <div class="element-pair-container">
                                        <div class="element-wrap element-wrap-on-left">
                                            <multiselect v-model="indice.i" :options="options" :multiple="false"
                                                         placeholder="Indicatif" tag-placeholder="Indicatif"
                                                         track-by="code"
                                                         label="country" :searchable="true" :select-label="''"
                                                         :deselect-label="''"
                                                         :selected-label="'✓'" :allow-empty="false">
                                                <template slot="singleLabel" slot-scope="{ option }">{{
                                                        option.country_code
                                                    }}
                                                </template>
                                                <span slot="noResult"></span>
                                            </multiselect>
                                        </div>
                                        <div class="element-wrap element-wrap-on-right">
                                            <input class="element-is-input" type="tel" v-mask="'## ## ## ## ##'"
                                                   v-model="dataFormulaireQuick.telephone" name="telephone"
                                                   style="font-weight: 900; font-size: 22px"/>
                                        </div>
                                    </div>
                                </div>
                                <!-- Numero de telephone Yango -->

                                <!-- Numero de telephone -->
                                <div class="element-pair" style="margin-top: 15px" v-if="dataPage.yango === 1">
                                    <div class="element-label">
                                        <span>
                                            {{ $t("text_form.number.value") }}
                                        </span>
                                    </div>
                                    <div class="element-pair-container">
                                        <div class="element-wrap element-wrap-on-left">
                                            <multiselect v-model="indice.i" :options="options" :multiple="false"
                                                         placeholder="Indicatif" tag-placeholder="Indicatif"
                                                         track-by="code"
                                                         label="country" :searchable="true" :select-label="''"
                                                         :deselect-label="''"
                                                         :selected-label="'✓'">
                                                <template slot="singleLabel" slot-scope="{ option }">{{
                                                        option.country_code
                                                    }}
                                                </template>
                                                <span slot="noResult"></span>
                                            </multiselect>
                                        </div>
                                        <div class="element-wrap element-wrap-on-right">
                                            <input class="element-is-input" type="tel" v-mask="'## ## ## ## ##'"
                                                   v-model="dataFormulaireQuick.telephone2" name="telephone"
                                                   style="font-weight: 900; font-size: 22px"/>
                                        </div>
                                    </div>
                                </div>
                                <!-- Numero de telephone -->

                                <!-- Succursale -->
                                <div class="element-pair-container" v-if="dataPage.boutiques.length > 0">
                                    <div class="element-wrap element-wrap-for-select">
                                        <div class="element-label">
                                            <span> {{ $t("text_form.branch_store.value") }} </span>
                                            {{ $t("text_form.optional.value") }}
                                        </div>
                                        <select v-model="dataFormulaireQuick.boutique" type="text"
                                                class="element-is-select"
                                                name="succursale">
                                            <option value="" selected>
                                                {{ $t("text_form.branch_store_option.value") }}
                                            </option>
                                            <option :value="boutique" v-for="(boutique, index) in dataPage.boutiques"
                                                    :key="index" :style="boutique.terminal_id == ''
                                                    ? 'text-transform: uppercase;'
                                                    : ''
                                                    ">
                                                {{ boutique.nom_boutique }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <!-- Succursale -->

                                <!-- Motif -->
                                <div v-if="dataPage.custom_fields !== '1'" class="element-pair-container">
                                    <div class="element-wrap">
                                        <div class="element-label">
                                            <span>{{ $t("text_form.reason.value") }} </span>
                                        </div>

                                        <input v-model="dataFormulaireQuick.motif" type="text" class="element-is-input"
                                               name="motif" maxlength="40" v-if="dataPage.eticket.length <= 0"/>
                                        <div class="element-is-input" v-else style="
                        font-weight: 900;
                        pointer-events: none;
                        background-color: rgb(238, 238, 238);
                        border: 1px solid rgb(204, 204, 204);
                      ">
                                            {{ dataFormulaireQuick.motif }}
                                        </div>
                                    </div>
                                </div>
                                <!-- Motif -->

                                <!-- Nom/Prenom(s) -->
                                <div class="element-pair">
                                    <div class="element-pair-container">
                                        <div class="element-wrap element-wrap-on-left">
                                            <div class="element-label">
                                                <span v-if="dataPage.deleg_pdci !== '3'">
                                                    {{ $t("text_form.name.value") }}
                                                </span>
                                                <span v-if="dataPage.yango === 1">
                                                    {{ $t("text_form.name_driver.value") }}
                                                </span>
                                            </div>
                                            <input v-model="dataFormulaireQuick.nom" type="text"
                                                   class="element-is-input"
                                                   name="name"/>
                                        </div>
                                        <div class="element-wrap element-wrap-on-right">
                                            <div class="element-label">
                                                <span v-if="dataPage.deleg_pdci !== '3'">
                                                    {{ $t("text_form.surname.value") }}
                                                </span>
                                                <span v-if="dataPage.yango === 1">
                                                    {{ $t("text_form.surname_driver.value") }}
                                                </span>
                                            </div>
                                            <input v-model="dataFormulaireQuick.prenom" type="text"
                                                   class="element-is-input"
                                                   name="prenom"/>
                                        </div>
                                    </div>
                                </div>
                                <!-- Nom/Prenom(s) -->

                                <div class="element-pair">
                                    <!-- Society -->
                                    <div class="element-wrap mb-3">
                                        <div class="element-label">
                                            <span v-if="dataPage.deleg_pdci !== '3'">{{
                                                    $t("text_form.society.value")
                                                }}</span>
                                            <span v-if="dataPage.yango === 1">{{
                                                    $t("text_form.society_driver.value")
                                                }}</span>
                                            <!-- {{$t('text_form.optional.value')}} -->
                                        </div>
                                        <input v-model="dataFormulaireQuick.society" type="text"
                                               class="element-is-input"
                                               name="societe"/>
                                    </div>
                                    <!-- Society -->

                                    <!-- E-mail -->
                                    <div class="element-pair-container">
                                        <div class="element-wrap">
                                            <div class="element-label">
                                                <span v-if="dataPage.deleg_pdci !== '3'">{{
                                                        $t("text_form.email.value")
                                                    }}
                                                </span>
                                                <span v-if="dataPage.yango === 1">{{
                                                        $t("text_form.email_driver.value")
                                                    }}
                                                </span>
                                                <!-- {{ $t('text_form.optional.value') }} -->
                                            </div>
                                            <input v-model="dataFormulaireQuick.email" type="email"
                                                   class="element-is-input"
                                                   name="email"/>
                                        </div>
                                    </div>
                                    <!-- E-mail -->
                                </div>
                            </div>
                        </div>
                        <!-- Infos supplémentaires -->

                        <!-- Button submit form -->
                        <div class="element-wrap element-wrap-for-submit">
                            <div>
                                <button type="button" @click="payerJusteLeMontant" v-if="dataPage.eticket.length > 0"
                                        :class="!montantFormateQuick.replace(/\s/g, '') ||
                                        montantFormateQuick.replace(/\s/g, '') < 100 ||
                                        montantFormateQuick.replace(/\s/g, '') > 1000000 ||
                                        dataFormulaireQuick.formule == ''
                                        ? 'btn btn-dark btn-disabled-custom btn-block'
                                        : 'btn btn-dark btn-block'
                                        " :disabled="!montantFormateQuick.replace(/\s/g, '') ||
        montantFormateQuick.replace(/\s/g, '') < 100 ||
        montantFormateQuick.replace(/\s/g, '') > 1000000 ||
        dataFormulaireQuick.formule == ''
        ">
                                    {{ $t("text_form.button.value") }}
                                    {{
                                        montantFormateQuick.replace(/\s/g, "") !== ""
                                                ? new Intl.NumberFormat("fr-FR", {
                                                    style: "currency",
                                                    currency: "XOF",
                                                }).format(
                                                        parseFloat(montantFormateQuick.replace(/\s/g, ""))
                                                )
                                                : ""
                                    }}
                                </button>

                                <button type="button" @click="payerJusteLeMontant" v-else :class="!montantFormateQuick.replace(/\s/g, '') ||
                                            montantFormateQuick.replace(/\s/g, '') < 100 ||
                                            montantFormateQuick.replace(/\s/g, '') > 1000000
                                            ? 'btn btn-dark btn-disabled-custom btn-block'
                                            : 'btn btn-dark btn-block'
                                        " :disabled="!montantFormateQuick.replace(/\s/g, '') ||
            montantFormateQuick.replace(/\s/g, '') < 100 ||
            montantFormateQuick.replace(/\s/g, '') > 1000000
            ">
                                    {{ $t("text_form.button.value") }}
                                    {{
                                        montantFormateQuick.replace(/\s/g, "") !== ""
                                                ? new Intl.NumberFormat("fr-FR", {
                                                    style: "currency",
                                                    currency: "XOF",
                                                }).format(
                                                        parseFloat(montantFormateQuick.replace(/\s/g, ""))
                                                )
                                                : ""
                                    }}
                                </button>
                            </div>
                        </div>
                        <!-- Button submit form -->
                    </form>

                    <!-- ******** -->
                    <!-- Bloc success payment -->
                    <!-- ******** -->
                    <success-page :hideRecu="hideRecu" :logoSmallDevice="logoSmallDevice"
                                  :montant="dataFormulaireQuick.montant" :nom="dataFormulaireQuick.nom"
                                  :prenom="dataFormulaireQuick.prenom" :devise="dataFormulaireQuick.devise"
                                  :motif="dataFormulaireQuick.motif" :refPayment="refPayment"
                                  :nombre_transactions="nombre_transactions">
                    </success-page>
                    <!-- ******** -->
                    <!-- Bloc success payment -->
                    <!-- ******** -->
                </div>
                <!-- Formulaire -->
            </div>
        </div>
        <!-- Form Quickly Payment -->


        <!-- Search Merchant Section -->
        <search-merchant :showErrorPage="showErrorPage"></search-merchant>
        <!-- Search Merchant Section -->

        <!-- ****************************************************** -->
        <!-- ****************************************************** -->
        <!-- Section du paiement | Affichage des moyens de paiement -->
        <!-- ****************************************************** -->
        <!-- ****************************************************** -->
        <div class="apaympro-wrapper animated fadeIn container mb-5" v-if="showPaymentSection" style="width: 500px">
            <div class="apaympro-page mb-4">
                <!-- Header -->
                <div class="apaympro-header">
                    <div class="mb-1 mt-4" style="display: flex; flex-direction: column; width: 100%">
                        <div class="text-modal">
                            <!-- <img v-if="!dataPage.business_logo" src="../assets/apaym-pay.png" width="140" alt="Logo APAYM Pay" /> -->
                            <div class="company-logo mb-3" style="width: 150px" v-if="dataPage.business_logo">
                                <img :src="dataPage.business_logo" alt="Business Logo" width="140"/>
                            </div>
                        </div>

                        <!-- Montant -->
                        <div v-if="showMenu" style="display: contents">
                            <div v-if="typeForm == 'normal' && dataPage.merchant !== '25010'"
                                 style="text-align: center; display: flex; flex-direction: column;">
                                <span style="font-size: 25px; font-weight: 600; letter-spacing: 1.2px;">
                                    {{
                                        new Intl.NumberFormat("fr-FR", {
                                            style: "currency",
                                            currency: "XOF",
                                        }).format(parseFloat(montantSave))
                                    }}
                                </span>
                            </div>
                            <div v-if="typeForm == 'quickly' && dataPage.merchant !== '25010'"
                                 style="text-align: center; display: flex; flex-direction: column;">
                                <span style="font-size: 25px; font-weight: 600; letter-spacing: 1.2px;">
                                    {{
                                        new Intl.NumberFormat("fr-FR", {
                                            style: "currency",
                                            currency: "XOF",
                                        }).format(parseFloat(montantSave))
                                    }}
                                    <!-- new Intl.NumberFormat('fr-FR', { style: 'currency', currency: dataFormulaireQuick.devise }).format(montantSave) -->
                                </span>
                            </div>
                            <div v-if="typeForm == 'superQuickly' && dataPage.merchant !== '25010'"
                                 style="text-align: center; display: flex; flex-direction: column;">
                                <span style="font-size: 25px; font-weight: 600; letter-spacing: 1.2px;">
                                    {{
                                        new Intl.NumberFormat("fr-FR", {
                                            style: "currency",
                                            currency: "XOF",
                                        }).format(parseFloat(montantSave))
                                    }}
                                    <!-- new Intl.NumberFormat('fr-FR', { style: 'currency', currency: dataFormulaireQuick.devise }).format(montantSave) -->
                                </span>
                            </div>
                        </div>
                        <!-- Montant -->

                        <!-- Type Marchand : Personnalité, SA, SARL, etc. -->
                        <div class="text-modal" style="padding-top: 5px">
                            {{
                                dataPage.type == "ONG" || dataPage.type == "PERSONNALITE"
                                        ? $t("text_header.donation.value")
                                        : $t("text_header.pay.value")
                            }}
                            <strong style="text-transform: uppercase">
                                {{ dataPage.business_name }}
                                <img src="../assets/img/badge-check.png" v-b-tooltip.hover
                                     :title="$t('text_certified_account.value')" v-if="dataPage.verified == '1'"
                                     width="15"
                                     style="margin-top: -3px"/>
                            </strong>
                            <p>
                                {{ $t("text_header.by.value") }}
                            </p>
                        </div>
                        <!-- Type Marchand : Personnalité, SA, SARL, etc. -->
                    </div>
                </div>
                <!-- Header -->

                <!-- Body -->
                <div class="body">
                    <button class="btn btn-link btn-close-modal cancel-payment" @click="hideSectionPayment" style="
              display: flex;
              align-items: center;
              height: 40px;
              width: auto;
              font-size: initial;
            ">
                        <i class="fas fa-times mr-1"></i>
                        {{ $i18n.locale == "fr" ? "Annuler" : "Cancel" }}
                    </button>

                    <form class="payment-form" :style="dataPage.frais == '1' ||
                        dataPage.frais == '2' ||
                        dataPage.frais == '3' ||
                        dataPage.pourboire == '1'
                        ? 'padding: 25px 15px 0px; padding-bottom: 0;'
                        : ''
                        ">
                        <div>
                            <!-- **************************** -->
                            <!-- **************************** -->
                            <!-- Menu -->
                            <!-- **************************** -->
                            <!-- **************************** -->
                            <div class="container" style="
                  padding: 10px;
                  padding-top: 0px !important;
                  padding-bottom: 0px !important;
                ">
                                <div class="row mt-3" v-if="showMenu">
                                    <div class="row">
                                        <div class="col-4 mb-4" v-for="mode in modesPaiementDispo" :key="mode.mode"
                                             v-show="mode.actif == 'oui'">
                                            <a @click="selectMenu(mode.libelle)" href="javascript:void(0);"
                                               v-if="mode.actif == 'oui'">
                                                <clazy-load :src="require(`../assets/img/menu/${mode.img}`)">
                                                    <img :src="require(`../assets/img/menu/${mode.img}`)"
                                                         alt="Logo Operateur"/>
                                                    <div class="preloader" slot="placeholder">
                                                        <i class="fa fa-spin fa-spinner font-18 text-primary"></i>
                                                    </div>
                                                </clazy-load>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- **************************** -->
                            <!-- **************************** -->
                            <!-- Menu -->
                            <!-- **************************** -->
                            <!-- **************************** -->

                            <!-- **************************** -->
                            <!-- **************************** -->
                            <!-- Change Menu -->
                            <!-- **************************** -->
                            <!-- **************************** -->
                            <div style=" display: flex; flex-direction: row; z-index: 1; align-items: center; width: 100%; padding: 15px; height: 60px; justify-content: center; background: #f4f4f4; border-radius: 10px; "
                                 v-if="showContent">
                                <div
                                        style=" display: flex; flex-direction: row; z-index: 1; align-items: center; width: 100%; justify-content: space-between; ">
                                    <span class="font-16" style="font-weight: 800; text-transform: uppercase">
                                        {{
                                            menuSelected == "VISA" || menuSelected == "MASTERCARD"
                                                    ? typeCarte
                                                    : menuSelected
                                        }}
                                    </span>
                                    <a href="javascript:void(0);"
                                       style="color: #0e2abd; font-weight: 600; font-size: 18px"
                                       @click="
                                        showHeader = showContent = false;
                                        showMenu = true;
                                        scrollToTop();
                                        ">
                                        <i class="fas fa-exchange-alt" style="transform: rotate(-76deg)"></i>
                                        {{ $t("payment_modal.change_menu.value") }}
                                    </a>
                                </div>
                            </div>
                            <!-- **************************** -->
                            <!-- **************************** -->
                            <!-- Change Menu -->
                            <!-- **************************** -->
                            <!-- **************************** -->

                            <!-- **************************** -->
                            <!-- **************************** -->
                            <!-- Header -->
                            <!-- **************************** -->
                            <!-- **************************** -->
                            <div style=" display: flex; flex-direction: row; z-index: 1; align-items: center; width: 100%;  padding-top: 10px; padding-bottom: 10px; justify-content: space-between; margin-bottom: 12px; "
                                 v-if="showHeader">
                                <!-- rgb(255, 159, 0) -->
                                <img :src="require(`../assets/img/menu/${logoSmallDevice}`)" width="75"
                                     alt="Logo moyen de paiement"/>
                                <div
                                        style=" display: flex; flex-direction: column; top: 5px;  z-index: 1; align-items: flex-end; padding: 5px; ">
                                    <div v-if="typeForm == 'normal'"
                                         style=" text-align: end; display: flex; flex-direction: column; ">
                                        <span v-if="dataFormulaire.society">
                                            {{ dataFormulaire.society }}
                                        </span>
                                        <span v-if="dataFormulaire.prenom && dataFormulaire.nom">
                                            {{ dataFormulaire.prenom }} {{ dataFormulaire.nom }}
                                        </span>
                                        <span v-if="dataFormulaire.email">
                                            {{ dataFormulaire.email }}
                                        </span>

                                        <span v-if="dataFormulaire.telephone">
                                            {{ indiceToShow }} {{ dataFormulaire.telephone }}
                                            <span v-if="nombre_transactions !== ''">
                                                <img :src="require(`../assets/img/badges/${imgStatus}`)" width="20"
                                                     style="position: relative; top: -3px"/>
                                                {{ statusClient }}
                                            </span>
                                        </span>
                                        <span style="font-size: 25px; font-weight: 600; letter-spacing: 1.2px;"
                                              v-if="dataPage.merchant !== '25010'">
                                            {{
                                                new Intl.NumberFormat("fr-FR", {
                                                    style: "currency",
                                                    currency: "XOF",
                                                }).format(parseFloat(dataFormulaire.montant))
                                            }}
                                            <!-- new Intl.NumberFormat('fr-FR', { style: 'currency', currency: dataFormulaire.devise }).format(
                                                dataFormulaire.montant
                                            )  -->
                                        </span>
                                        <span v-if="dataFormulaire.motif && dataPage.merchant !== '25010'">
                                            {{ dataFormulaire.motif.trim() }}
                                        </span>
                                    </div>

                                    <div v-if="typeForm == 'quickly' || typeForm == 'superQuickly'"
                                         style=" text-align: end; display: flex; flex-direction: column; ">
                                        <span v-if="dataFormulaireQuick.society">
                                            {{ dataFormulaireQuick.society }}
                                        </span>
                                        <span v-if="dataFormulaireQuick.prenom || dataFormulaireQuick.nom
                                            ">{{ dataFormulaireQuick.prenom }}
                                            {{ dataFormulaireQuick.nom }}</span>
                                        <span v-if="dataFormulaireQuick.telephone">
                                            {{ indiceToShow }} {{ dataFormulaireQuick.telephone }}
                                            <span v-if="nombre_transactions !== ''">
                                                <img :src="require(`../assets/img/badges/${imgStatus}`)" width="20"
                                                     style="position: relative; top: -3px"/>
                                                {{ statusClient }}
                                            </span>
                                        </span>
                                        <span style=" font-size: 25px; font-weight: 600; letter-spacing: 1.2px; "
                                              v-if="dataPage.merchant !== '25010'">
                                            {{
                                                new Intl.NumberFormat("fr-FR", {
                                                    style: "currency",
                                                    currency: "XOF",
                                                }).format(parseFloat(dataFormulaireQuick.montant))
                                            }}
                                            <!-- new Intl.NumberFormat('fr-FR', { style: 'currency', currency: dataFormulaireQuick.devise }).format(
                                                dataFormulaireQuick.montant
                                            )  -->
                                        </span>
                                        <span v-if="dataFormulaireQuick.motif && dataPage.merchant !== '25010'">{{
                                                dataFormulaireQuick.motif.trim()
                                            }}</span>
                                    </div>
                                </div>
                            </div>
                            <!-- **************************** -->
                            <!-- **************************** -->
                            <!-- Header -->
                            <!-- **************************** -->
                            <!-- **************************** -->

                            <!-- **************************** -->
                            <!-- **************************** -->
                            <!-- ********** Content ********* -->
                            <!-- **************************** -->
                            <!-- **************************** -->
                            <div v-if="showContent">
                                <!-- Visa QR -->
                                <div v-if="menuSelected == 'Visa QR'">
                                    <!-- Loader -->
                                    <loading :active.sync="isLoadingQr" :can-cancel="true" :on-cancel="onCancelQr"
                                             :is-full-page="fullPageQr" loader="bars" :opacity="1"></loading>
                                    <!-- Loader -->

                                    <!-- Content -->
                                    <div class="checkout__stage">
                                        <section>
                                            <div class="visa-qr-container">
                                                <div>
                                                    <div class="visa-qr-container__header">
                                                        <p class="instruction mt-3">
                                                            {{ $t("payment_modal.visa_qr.text.value") }}
                                                        </p>
                                                    </div>
                                                    <a download="visa-qr-code.png" class="btn btn-light mt-2 mb-2"
                                                       :href="qrCodeUri" style="
                              height: 35px;
                              line-height: 1.4;
                              background: #f4f4f4;
                              border: #f4f4f4;
                            ">
                                                        {{ $t("payment_modal.download_visa_qr.value") }}
                                                    </a>

                                                    <!-- Recapitulatif Montant à payer, pourboire, frais -->
                                                    <div class="mt-1" v-if="dataPage.merchant !== '25010'">
                                                        <!-- Fees -->
                                                        <div class="container d-flex justify-content-between mt-2 mb-3"
                                                             v-if="dataPage.frais == '1' ||
                                                                dataPage.frais == '2' ||
                                                                dataPage.frais == '3'
                                                                ">
                                                            <div>
                                                                <h6 v-if="dataPage.frais == '1'">
                                                                    {{ $t("text_fees_payment.value") }}
                                                                </h6>
                                                                <h6 v-if="dataPage.frais == '2' ||
                                                                    dataPage.frais == '3'
                                                                    ">
                                                                    {{ $t("text_fees_forced_payment.value") }}
                                                                </h6>
                                                            </div>
                                                            <label class="ui-switch switch-solid"
                                                                   v-if="dataPage.frais == '1'">
                                                                <input type="checkbox" :checked="checkedFeesApaym"
                                                                       @click="feeAmountQr"/>
                                                                <span style="left: 20px"></span>
                                                            </label>
                                                            <div class="ui-switch switch-solid" v-if="dataPage.frais == '2' || dataPage.frais == '3'
                                                                ">
                                                                <input type="checkbox" :checked="checkedFeesApaym"
                                                                       @click="feeAmountQr"/>
                                                                <span style="left: 20px"></span>
                                                            </div>
                                                        </div>
                                                        <div class="container d-flex justify-content-between" v-if="(dataPage.frais == '1' ||
                                                            dataPage.frais == '2' ||
                                                            dataPage.frais == '3') &&
                                                            checkedFeesApaym
                                                            ">
                                                            <div>
                                                                <span class="font-12">{{
                                                                        $t("text_fees.value")
                                                                    }}</span>
                                                            </div>
                                                            <div>
                                                                <span class="font-12">
                                                                    {{
                                                                        dataPage.frais == "3"
                                                                                ? new Intl.NumberFormat("fr-FR", {
                                                                                    style: "currency",
                                                                                    currency: "XOF",
                                                                                }).format(
                                                                                        montantSave
                                                                                                ? parseFloat(montantSave) *
                                                                                                (parseFloat(
                                                                                                                dataPage.taux_apaym / 2
                                                                                                        ) /
                                                                                                        100)
                                                                                                : 0
                                                                                )
                                                                                : new Intl.NumberFormat("fr-FR", {
                                                                                    style: "currency",
                                                                                    currency: "XOF",
                                                                                }).format(
                                                                                        montantSave
                                                                                                ? parseFloat(montantSave) *
                                                                                                (parseFloat(
                                                                                                                dataPage.taux_apaym
                                                                                                        ) /
                                                                                                        100)
                                                                                                : 0
                                                                                )
                                                                    }}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <!-- Fees -->

                                                        <!-- Pourboire -->
                                                        <!-- Type formulaire normal -->
                                                        <div v-if="typeForm == 'normal'">
                                                            <div class="container d-flex justify-content-between" v-if="dataPage.pourboire == '1' &&
                                                                pourboire &&
                                                                (pourboire == 'autre'
                                                                    ? parseFloat(
                                                                        dataFormulaire.amountPourboire
                                                                    ) > 0
                                                                    : parseFloat(pourboire) > 0)
                                                                ">
                                                                <div>
                                                                    <span class="font-12">
                                                                        {{ $t("text_pourboire.value") }}
                                                                    </span>
                                                                </div>
                                                                <div>
                                                                    <span class="font-12">
                                                                        {{
                                                                            new Intl.NumberFormat("fr-FR", {
                                                                                style: "currency",
                                                                                currency: "XOF",
                                                                            }).format(
                                                                                    pourboire == "autre"
                                                                                            ? dataFormulaire.amountPourboire
                                                                                                    ? parseFloat(
                                                                                                            dataFormulaire.amountPourboire
                                                                                                    )
                                                                                                    : 0
                                                                                            : parseFloat(pourboire)
                                                                            )
                                                                        }}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <!-- Type formulaire paie vite -->
                                                        <div v-if="typeForm == 'quickly'">
                                                            <div class="container d-flex justify-content-between" v-if="dataPage.pourboire == '1' &&
                                                                pourboire &&
                                                                (pourboire == 'autre'
                                                                    ? parseFloat(
                                                                        dataFormulaireQuick.amountPourboire
                                                                    ) > 0
                                                                    : parseFloat(pourboire) > 0)
                                                                ">
                                                                <div>
                                                                    <span class="font-12">
                                                                        {{ $t("text_pourboire.value") }}
                                                                    </span>
                                                                </div>
                                                                <div>
                                                                    <span class="font-12">
                                                                        {{
                                                                            new Intl.NumberFormat("fr-FR", {
                                                                                style: "currency",
                                                                                currency: "XOF",
                                                                            }).format(
                                                                                    pourboire == "autre"
                                                                                            ? dataFormulaireQuick.amountPourboire
                                                                                                    ? parseFloat(
                                                                                                            dataFormulaireQuick.amountPourboire
                                                                                                    )
                                                                                                    : 0
                                                                                            : parseFloat(pourboire)
                                                                            )
                                                                        }}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <!-- Pourboire -->

                                                        <!-- Montant à payer -->
                                                        <div class="container d-flex justify-content-between" v-if="montantAPayer &&
                                                                (dataPage.frais == '1' ||
                                                                    dataPage.frais == '2' ||
                                                                    dataPage.frais == '3' ||
                                                                    dataPage.pourboire == '1')
                                                                ">
                                                            <div>
                                                                <span class="font-12">{{
                                                                        $t("text_amount_to_pay.value")
                                                                    }}</span>
                                                            </div>

                                                            <div>
                                                                <span class="font-12">
                                                                    {{
                                                                        new Intl.NumberFormat("fr-FR", {
                                                                            style: "currency",
                                                                            currency: "XOF",
                                                                        }).format(parseFloat(montantAPayer))
                                                                    }}
                                                                </span>
                                                            </div>
                                                        </div>

                                                        <!-- Montant total -->
                                                        <div class="container d-flex justify-content-between mb-3"
                                                             v-if="typeForm == 'quickly' &&
                                                                (dataPage.frais == '1' ||
                                                                    dataPage.frais == '2' ||
                                                                    dataPage.frais == '3' ||
                                                                    dataPage.pourboire == '1')
                                                                ">
                                                            <div>
                                                                <span class=""
                                                                      style="font-weight: 800; font-size: 22px">TOTAL</span>
                                                            </div>
                                                            <div>
                                                                <span class="" style="font-weight: 800; font-size: 22px"
                                                                      v-if="dataFormulaireQuick.montant">
                                                                    {{
                                                                        new Intl.NumberFormat("fr-FR", {
                                                                            style: "currency",
                                                                            currency: "XOF",
                                                                        }).format(
                                                                                parseFloat(dataFormulaireQuick.montant)
                                                                        )
                                                                    }}

                                                                    <!-- new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'XOF' }).format(
                                                                        parseFloat(dataFormulaireQuick.montant)
                                                                    ) -->
                                                                </span>
                                                                <span class="" style="font-weight: 800; font-size: 22px"
                                                                      v-if="!dataFormulaireQuick.montant">
                                                                    {{
                                                                        new Intl.NumberFormat("fr-FR", {
                                                                            style: "currency",
                                                                            currency: "XOF",
                                                                        }).format(0)
                                                                    }}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div class="container d-flex justify-content-between mb-3"
                                                             v-if="typeForm == 'normal' &&
                                                                (dataPage.frais == '1' ||
                                                                    dataPage.frais == '2' ||
                                                                    dataPage.frais == '3' ||
                                                                    dataPage.pourboire == '1')
                                                                ">
                                                            <div>
                                                                <span class=""
                                                                      style="font-weight: 800; font-size: 22px">TOTAL</span>
                                                            </div>
                                                            <div>
                                                                <span class="" style="font-weight: 800; font-size: 22px"
                                                                      v-if="dataFormulaire.montant">
                                                                    {{
                                                                        new Intl.NumberFormat("fr-FR", {
                                                                            style: "currency",
                                                                            currency: "XOF",
                                                                        }).format(
                                                                                parseFloat(dataFormulaire.montant)
                                                                        )
                                                                    }}
                                                                    <!-- new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'XOF' }).format(
                                                                        parseFloat(dataFormulaire.montant)
                                                                    ) -->
                                                                </span>
                                                                <span class="" style="font-weight: 800; font-size: 22px"
                                                                      v-if="!dataFormulaire.montant">
                                                                    {{
                                                                        new Intl.NumberFormat("fr-FR", {
                                                                            style: "currency",
                                                                            currency: "XOF",
                                                                        }).format(0)
                                                                    }}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <!-- Montant total -->
                                                    </div>
                                                    <!-- Recapitulatif Montant à payer, pourboire, frais -->

                                                    <div class="qr__wrapper">
                                                        <div class="qr__code">
                                                            <img v-if="qrCodeUri" name="qrCodeImage"
                                                                 alt="APAYM Pay QR Code"
                                                                 :src="qrCodeUri" id="qrCodeImage" style="width: 68%"/>
                                                        </div>
                                                    </div>
                                                    <div class="qr__wrapper mt-2 mb-4">
                                                        <div class="qr__code">
                                                            <i class="fas fa-spinner fa-spin" style="font-size: 20px"
                                                               v-if="qrCodeUri"></i>
                                                        </div>
                                                        <div class="mt-2">
                                                            {{ $t("text_visa_qr_waiting.value") }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                    <!-- Content -->
                                </div>
                                <!-- Visa QR -->

                                <!-- Visa / Mastercard -->
                                <div v-if="menuSelected == 'VISA' || menuSelected == 'MASTERCARD'">
                                    <div class="checkout__stage">
                                        <section>
                                            <div class="visa-qr-container">
                                                <div>
                                                    <!-- Loader -->
                                                    <div class="mt-3">
                                                        <loading :active.sync="isLoadingBicici" :can-cancel="false"
                                                                 :on-cancel="onCancelBicici"
                                                                 :is-full-page="fullPageBicici"
                                                                 loader="bars" :opacity="1"></loading>
                                                    </div>
                                                    <!-- Loader -->

                                                    <div class="visa-qr-container__header">
                                                        <p class="instruction mt-3" v-if="!isBicici &&
                                                            !dataPage.subMerchant &&
                                                            dataPage.merchant !== '21449'
                                                            ">
                                                            <!-- {{ $t('payment_modal.visa.text_orabank.value') }} -->
                                                            {{ $t("payment_modal.visa.text.value") }}
                                                        </p>

                                                        <div class="displayerror__details mt-3" v-if="biciciNOkay">
                                                            <div>
                                                                <p class="paiement-failed">
                                                                    {{
                                                                        $t(
                                                                                "payment_modal.bicici_payment_failure_title.value"
                                                                        )
                                                                    }}
                                                                </p>
                                                                <div class="error-information">
                                                                    {{
                                                                        $t(
                                                                                "payment_modal.bicici_payment_failure_details.value"
                                                                        )
                                                                    }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <!-- Orabank -->
                                                    <div class="qr__wrapper container" v-if="dataPage.subMerchant ||
                                                            dataPage.merchant == '21449' ||
                                                            dataPage.merchant == '24545' ||
                                                            dataPage.merchant == '25695' ||
                                                            dataPage.merchant == '25764' ||
                                                            dataPage.merchant == '24831' ||
                                                            dataPage.merchant == '25682' ||
                                                            dataPage.merchant == '21040'
                                                            ">
                                                        <!-- ************************** -->
                                                        <!-- Formulaire Visa/Mastercard -->
                                                        <!-- ************************** -->

                                                        <!-- ************************** -->
                                                        <!-- Formulaire-->
                                                        <!-- ************************** -->

                                                        <!-- Numéro de la carte -->
                                                        <div class="element-pair text-left">
                                                            <div class="element-label">
                                                                <span>
                                                                    {{
                                                                        $t("payment_modal.visa.card_number.value")
                                                                    }}
                                                                </span>
                                                            </div>
                                                            <div class="element-pair-container">
                                                                <div class="element-wrap element-wrap-on-left">
                                                                    <div class="input-group-icon input-group-icon-right">
                                                                        <span class="input-icon input-icon-right">
                                                                            <i :class="menuSelected == 'VISA'
                                                                                        ? 'fab fa-cc-visa font-20'
                                                                                        : 'fab fa-cc-mastercard font-20'
                                                                                    "></i>
                                                                        </span>
                                                                        <!-- <input v-model="dataVisaCard.pan"
                                                                            class="element-is-input" type="tel"
                                                                            name="cardNumber" @keypress="numbersOnly"
                                                                            v-mask="'####  ####  ####  ####'"
                                                                            placeholder="xxxx  xxxx  xxxx  xxxx"
                                                                            style="font-weight: 800; font-size: 18px"
                                                                            @input="checkPan($event)" /> -->
                                                                        <input v-model="dataVisaCard.pan"
                                                                               class="element-is-input" type="tel"
                                                                               name="cardNumber" @keypress="numbersOnly"
                                                                               v-mask="'####  ####  ####  ####'"
                                                                               placeholder="xxxx  xxxx  xxxx  xxxx"
                                                                               style="font-weight: 800; font-size: 18px"/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <!-- Numéro de la carte -->

                                                        <!-- Nom de la banque -->
                                                        <div class="element-pair text-left" v-if="formRechargement.issuer_name &&
                                                            dataVisaCard.pan &&
                                                            dataVisaCard.pan.replace(/\s/g, '').length == 16
                                                            ">
                                                            <div class="element-pair-container">
                                                                <div class="element-wrap">
                                                                    <div class="element-label font-14"
                                                                         style="line-height: 1.6rem">
                                                                        <strong>{{
                                                                                formRechargement.issuer_name
                                                                            }}</strong>
                                                                        <br/>
                                                                        Carte
                                                                        {{ formRechargement.card_type_description }}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <!-- Nom de la banque -->

                                                        <!-- Date d'expiration et CVV -->
                                                        <div style="
                                display: flex;
                                justify-content: space-between;
                                margin-top: 15px;
                              ">
                                                            <!-- Date d'expiration -->
                                                            <div class="element-pair text-left" style="width: 75%">
                                                                <div class="element-label">
                                                                    <span>
                                                                        {{
                                                                            $t(
                                                                                    "payment_modal.visa.expiration_date.value"
                                                                            )
                                                                        }}
                                                                    </span>
                                                                </div>
                                                                <div class="element-pair-container">
                                                                    <!-- Mois -->
                                                                    <div
                                                                            class="element-wrap element-wrap-for-select element-wrap-on-left">
                                                                        <select v-model="dataVisaCard.expirationMonth"
                                                                                class="element-is-select"
                                                                                style="font-weight: 500" name="month">
                                                                            <option value="" selected disabled>
                                                                                {{
                                                                                    $t(
                                                                                            "payment_modal.visa.expiration_month.value"
                                                                                    )
                                                                                }}
                                                                            </option>
                                                                            <option v-for="month in expirationMonth"
                                                                                    :key="month.id"
                                                                                    :value="month.month">
                                                                                {{ month.month }} -
                                                                                {{
                                                                                    $i18n.locale == "fr"
                                                                                            ? month.libelle
                                                                                            : month.libelleEn
                                                                                }}
                                                                            </option>
                                                                        </select>
                                                                    </div>
                                                                    <!-- Mois -->

                                                                    <!-- Année -->
                                                                    <div
                                                                            class="element-wrap element-wrap-for-select element-wrap-on-right">
                                                                        <select v-model="dataVisaCard.expirationYear"
                                                                                class="element-is-select"
                                                                                style="font-weight: 500" name="year">
                                                                            <option value="" selected disabled>
                                                                                {{
                                                                                    $t(
                                                                                            "payment_modal.visa.expiration_year.value"
                                                                                    )
                                                                                }}
                                                                            </option>
                                                                            <option :value="$index + minCardYear"
                                                                                    v-for="(n, $index) in 19" :key="n">
                                                                                {{ $index + minCardYear }}
                                                                            </option>
                                                                        </select>
                                                                    </div>
                                                                    <!-- Année -->
                                                                </div>
                                                            </div>
                                                            <!-- Date d'expiration -->

                                                            <!-- CVV -->
                                                            <div class="element-pair text-left" style="width: 20%">
                                                                <div class="element-label">
                                                                    <span> CVV </span>
                                                                </div>
                                                                <div class="element-pair-container">
                                                                    <div class="element-wrap element-wrap-on-right">
                                                                        <input v-model="dataVisaCard.cvv"
                                                                               class="element-is-input" type="tel"
                                                                               name="cvv"
                                                                               v-mask="'###'" @keypress="numbersOnly"
                                                                               style="font-weight: 500"/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <!-- cvv -->
                                                        </div>
                                                        <!-- Date d'expiration et CVV -->

                                                        <!-- Nom du porteur de la carte -->
                                                        <div class="element-pair text-left mt-3">
                                                            <div class="element-label">
                                                                <span>
                                                                    {{
                                                                        $t(
                                                                                "payment_modal.visa.card_holder_name.value"
                                                                        )
                                                                    }}
                                                                </span>
                                                            </div>
                                                            <div class="element-pair-container">
                                                                <div class="element-wrap element-wrap-on-left">
                                                                    <input v-model="dataVisaCard.cardHolderName"
                                                                           class="element-is-input" type="text"
                                                                           name="cardHolderName"
                                                                           style="font-weight: 500"/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <!-- Nom du porteur de la carte -->

                                                        <!-- ************************** -->
                                                        <!-- Formulaire Visa/Mastercard -->
                                                        <!-- ************************** -->

                                                        <div class="element-wrap element-wrap-for-submit" :style="dataPage.frais == '1' ||
                                                            dataPage.frais == '2' ||
                                                            dataPage.frais == '3' ||
                                                            dataPage.pourboire == '1'
                                                            ? 'background: #f5f5f5; margin: 40px -30px 0px;'
                                                            : ''
                                                            ">
                                                            <img src="../assets/img/trait-recap.png"
                                                                 alt="Illustration zigzag recap"
                                                                 style="position: relative; top: -10px" v-if="dataPage.frais == '1' ||
                                                                    dataPage.frais == '2' ||
                                                                    dataPage.frais == '3' ||
                                                                    dataPage.pourboire == '1'
                                                                    "/>

                                                            <!-- Recapitulatif Montant à payer, pourboire, frais -->
                                                            <div class="mt-1" v-if="dataPage.merchant !== '25010'">
                                                                <!-- Fees -->
                                                                <div class="container d-flex justify-content-between mt-2 mb-3"
                                                                     :style="dataPage.frais == '1' ||
                                                                        dataPage.frais == '2' ||
                                                                        dataPage.frais == '3' ||
                                                                        dataPage.pourboire == '1'
                                                                        ? 'padding: 0px 30px 0px 30px; top: -20px; position: relative;'
                                                                        : ''
                                                                        " v-if="dataPage.frais == '1' ||
        dataPage.frais == '2' ||
        dataPage.frais == '3'
        ">
                                                                    <div>
                                                                        <h6 v-if="dataPage.frais == '1'">
                                                                            {{ $t("text_fees_payment.value") }}
                                                                        </h6>
                                                                        <h6 v-if="dataPage.frais == '2' ||
                                                                            dataPage.frais == '3'
                                                                            ">
                                                                            {{ $t("text_fees_forced_payment.value") }}
                                                                        </h6>
                                                                    </div>
                                                                    <label class="ui-switch switch-solid"
                                                                           v-if="dataPage.frais == '1'">
                                                                        <input type="checkbox" :checked="checkedFeesCb"
                                                                               @click="tauxMontantCb"/>
                                                                        <span style="left: 20px"></span>
                                                                    </label>
                                                                    <div class="ui-switch switch-solid" v-if="dataPage.frais == '2' ||
                                                                        dataPage.frais == '3'
                                                                        ">
                                                                        <input type="checkbox" :checked="checkedFeesCb"
                                                                               @click="tauxMontantCb"/>
                                                                        <span style="left: 20px"></span>
                                                                    </div>
                                                                </div>
                                                                <div class="container d-flex justify-content-between"
                                                                     :style="dataPage.frais == '1' ||
                                                                        dataPage.frais == '2' ||
                                                                        dataPage.frais == '3' ||
                                                                        dataPage.pourboire == '1'
                                                                        ? 'padding: 0px 30px 0px 30px; top: -20px; position: relative;'
                                                                        : ''
                                                                        " v-if="(dataPage.frais == '1' ||
        dataPage.frais == '2' ||
        dataPage.frais == '3') &&
        checkedFeesCb
        ">
                                                                    <div>
                                                                        <span class="font-12">{{
                                                                                $t("text_fees.value")
                                                                            }}</span>
                                                                    </div>
                                                                    <div>
                                                                        <span class="font-12">
                                                                            {{
                                                                                dataPage.frais == "3"
                                                                                        ? new Intl.NumberFormat("fr-FR", {
                                                                                            style: "currency",
                                                                                            currency: "XOF",
                                                                                        }).format(
                                                                                                montantSave
                                                                                                        ? parseFloat(montantSave) *
                                                                                                        (parseFloat(
                                                                                                                        dataPage.taux_cb / 2
                                                                                                                ) /
                                                                                                                100)
                                                                                                        : 0
                                                                                        )
                                                                                        : new Intl.NumberFormat("fr-FR", {
                                                                                            style: "currency",
                                                                                            currency: "XOF",
                                                                                        }).format(
                                                                                                montantSave
                                                                                                        ? parseFloat(montantSave) *
                                                                                                        (parseFloat(
                                                                                                                        dataPage.taux_cb
                                                                                                                ) /
                                                                                                                100)
                                                                                                        : 0
                                                                                        )
                                                                            }}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <!-- Fees -->

                                                                <!-- Pourboire -->
                                                                <!-- Type formulaire normal -->
                                                                <div v-if="typeForm == 'normal'">
                                                                    <div class="container d-flex justify-content-between"
                                                                         :style="dataPage.frais == '1' ||
                                                                            dataPage.frais == '2' ||
                                                                            dataPage.frais == '3' ||
                                                                            dataPage.pourboire == '1'
                                                                            ? 'padding: 0px 30px 0px 30px; top: -20px; position: relative;'
                                                                            : ''
                                                                            " v-if="dataPage.pourboire == '1' &&
        pourboire &&
        (pourboire == 'autre'
            ? parseFloat(
                dataFormulaire.amountPourboire
            ) > 0
            : parseFloat(pourboire) > 0)
        ">
                                                                        <div>
                                                                            <span class="font-12">
                                                                                {{ $t("text_pourboire.value") }}
                                                                            </span>
                                                                        </div>
                                                                        <div>
                                                                            <span class="font-12">
                                                                                {{
                                                                                    new Intl.NumberFormat("fr-FR", {
                                                                                        style: "currency",
                                                                                        currency: "XOF",
                                                                                    }).format(
                                                                                            pourboire == "autre"
                                                                                                    ? dataFormulaire.amountPourboire
                                                                                                            ? parseFloat(
                                                                                                                    dataFormulaire.amountPourboire
                                                                                                            )
                                                                                                            : 0
                                                                                                    : parseFloat(pourboire)
                                                                                    )
                                                                                }}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <!-- Type formulaire paie vite -->
                                                                <div v-if="typeForm == 'quickly' ||
                                                                        typeForm == 'superQuickly'
                                                                        ">
                                                                    <div class="container d-flex justify-content-between"
                                                                         :style="dataPage.frais == '1' ||
                                                                            dataPage.frais == '2' ||
                                                                            dataPage.frais == '3' ||
                                                                            dataPage.pourboire == '1'
                                                                            ? 'padding: 0px 30px 0px 30px; top: -20px; position: relative;'
                                                                            : ''
                                                                            " v-if="dataPage.pourboire == '1' &&
        pourboire &&
        (pourboire == 'autre'
            ? parseFloat(
                dataFormulaireQuick.amountPourboire
            ) > 0
            : parseFloat(pourboire) > 0)
        ">
                                                                        <div>
                                                                            <span class="font-12">
                                                                                {{ $t("text_pourboire.value") }}
                                                                            </span>
                                                                        </div>
                                                                        <div>
                                                                            <span class="font-12">
                                                                                {{
                                                                                    new Intl.NumberFormat("fr-FR", {
                                                                                        style: "currency",
                                                                                        currency: "XOF",
                                                                                    }).format(
                                                                                            pourboire == "autre"
                                                                                                    ? dataFormulaireQuick.amountPourboire
                                                                                                            ? parseFloat(
                                                                                                                    dataFormulaireQuick.amountPourboire
                                                                                                            )
                                                                                                            : 0
                                                                                                    : parseFloat(pourboire)
                                                                                    )
                                                                                }}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <!-- Pourboire -->

                                                                <!-- Montant à payer -->
                                                                <div class="container d-flex justify-content-between"
                                                                     :style="dataPage.frais == '1' ||
                                                                        dataPage.frais == '2' ||
                                                                        dataPage.frais == '3' ||
                                                                        dataPage.pourboire == '1'
                                                                        ? 'padding: 0px 30px 0px 30px; top: -20px; position: relative;'
                                                                        : ''
                                                                        " v-if="montantAPayer &&
            (dataPage.frais == '1' ||
                dataPage.frais == '2' ||
                dataPage.frais == '3' ||
                dataPage.pourboire == '1')
            ">
                                                                    <div>
                                                                        <span class="font-12">{{
                                                                                $t("text_amount_to_pay.value")
                                                                            }}</span>
                                                                    </div>

                                                                    <div>
                                                                        <span class="font-12">
                                                                            {{
                                                                                new Intl.NumberFormat("fr-FR", {
                                                                                    style: "currency",
                                                                                    currency: "XOF",
                                                                                }).format(parseFloat(montantAPayer))
                                                                            }}
                                                                        </span>
                                                                    </div>
                                                                </div>

                                                                <!-- Montant total -->
                                                                <div class="container d-flex justify-content-between mb-3"
                                                                     :style="dataPage.frais == '1' ||
                                                                        dataPage.frais == '2' ||
                                                                        dataPage.frais == '3' ||
                                                                        dataPage.pourboire == '1'
                                                                        ? 'padding: 0px 30px 0px 30px; top: -20px; position: relative;'
                                                                        : ''
                                                                        " v-if="(typeForm == 'quickly' ||
                typeForm == 'superQuickly') &&
            (dataPage.frais == '1' ||
                dataPage.frais == '2' ||
                dataPage.frais == '3' ||
                dataPage.pourboire == '1')
            ">
                                                                    <div>
                                                                        <span
                                                                                style="font-weight: 800; font-size: 22px">TOTAL</span>
                                                                    </div>
                                                                    <div>
                                                                        <span style="font-weight: 800; font-size: 22px"
                                                                              v-if="dataFormulaireQuick.montant">
                                                                            {{
                                                                                new Intl.NumberFormat("fr-FR", {
                                                                                    style: "currency",
                                                                                    currency: "XOF",
                                                                                }).format(
                                                                                        parseFloat(
                                                                                                dataFormulaireQuick.montant
                                                                                        )
                                                                                )
                                                                            }}
                                                                        </span>
                                                                        <span style="font-weight: 800; font-size: 22px"
                                                                              v-if="!dataFormulaireQuick.montant">
                                                                            {{
                                                                                new Intl.NumberFormat("fr-FR", {
                                                                                    style: "currency",
                                                                                    currency: "XOF",
                                                                                }).format(0)
                                                                            }}
                                                                        </span>
                                                                    </div>
                                                                </div>

                                                                <div class="container d-flex justify-content-between mb-3"
                                                                     :style="dataPage.frais == '1' ||
                                                                        dataPage.frais == '2' ||
                                                                        dataPage.frais == '3' ||
                                                                        dataPage.pourboire == '1'
                                                                        ? 'padding: 0px 30px 0px 30px; top: -20px; position: relative;'
                                                                        : ''
                                                                        " v-if="typeForm == 'normal' &&
            (dataPage.frais == '1' ||
                dataPage.frais == '2' ||
                dataPage.frais == '3' ||
                dataPage.pourboire == '1')
            ">
                                                                    <div>
                                                                        <span
                                                                                style="font-weight: 800; font-size: 22px">TOTAL</span>
                                                                    </div>
                                                                    <div>
                                                                        <span style="font-weight: 800; font-size: 22px"
                                                                              v-if="dataFormulaire.montant">
                                                                            {{
                                                                                new Intl.NumberFormat("fr-FR", {
                                                                                    style: "currency",
                                                                                    currency: "XOF",
                                                                                }).format(
                                                                                        parseFloat(dataFormulaire.montant)
                                                                                )
                                                                            }}
                                                                        </span>
                                                                        <span style="font-weight: 800; font-size: 22px"
                                                                              v-if="!dataFormulaire.montant">
                                                                            {{
                                                                                new Intl.NumberFormat("fr-FR", {
                                                                                    style: "currency",
                                                                                    currency: "XOF",
                                                                                }).format(0)
                                                                            }}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <!-- Montant total -->
                                                            </div>
                                                            <!-- Recapitulatif Montant à payer, pourboire, frais -->

                                                            <div :style="dataPage.frais == '1' ||
                                                                        dataPage.frais == '2' ||
                                                                        dataPage.frais == '3' ||
                                                                        dataPage.pourboire == '1'
                                                                        ? 'padding: 5px 25px 15px 25px; top: -20px; position: relative;'
                                                                        : ''
                                                                    ">
                                                                <button type="button" :class="!cardType
                                                                    ? 'btn btn-dark btn-disabled-custom btn-block'
                                                                    : 'btn btn-dark btn-block'
                                                                    " @click="sendCardPayment" :disabled="!cardType">
                                                                    {{ $t("payment_modal.visa.button.value") }}
                                                                    {{ typeCarte }}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <!-- UBA -->
                                                    <div class="qr__wrapper container" v-else>
                                                        <div class="element-wrap element-wrap-for-submit" :style="dataPage.frais == '1' ||
                                                            dataPage.frais == '2' ||
                                                            dataPage.frais == '3' ||
                                                            dataPage.pourboire == '1'
                                                            ? 'background: #f5f5f5; margin: 40px -30px 0px;'
                                                            : ''
                                                            ">
                                                            <img src="../assets/img/trait-recap.png"
                                                                 alt="Illustration zigzag recap"
                                                                 style="position: relative; top: -10px" v-if="dataPage.frais == '1' ||
                                                                    dataPage.frais == '2' ||
                                                                    dataPage.frais == '3' ||
                                                                    dataPage.pourboire == '1'
                                                                    "/>

                                                            <!-- Recapitulatif Montant à payer, pourboire, frais -->
                                                            <div class="mt-1" v-if="dataPage.merchant !== '25010'">
                                                                <!-- Fees -->
                                                                <div class="container d-flex justify-content-between mt-2 mb-3"
                                                                     :style="dataPage.frais == '1' ||
                                                                        dataPage.frais == '2' ||
                                                                        dataPage.frais == '3' ||
                                                                        dataPage.pourboire == '1'
                                                                        ? 'padding: 0px 30px 0px 30px; top: -20px; position: relative;'
                                                                        : ''
                                                                        " v-if="dataPage.frais == '1' ||
        dataPage.frais == '2' ||
        dataPage.frais == '3'
        ">
                                                                    <div>
                                                                        <h6 v-if="dataPage.frais == '1'">
                                                                            {{ $t("text_fees_payment.value") }}
                                                                        </h6>
                                                                        <h6 v-if="dataPage.frais == '2' ||
                                                                            dataPage.frais == '3'
                                                                            ">
                                                                            {{ $t("text_fees_forced_payment.value") }}
                                                                        </h6>
                                                                    </div>
                                                                    <label class="ui-switch switch-solid"
                                                                           v-if="dataPage.frais == '1'">
                                                                        <input type="checkbox" :checked="checkedFeesCb"
                                                                               @click="tauxMontantCb"/>
                                                                        <span style="left: 20px"></span>
                                                                    </label>
                                                                    <div class="ui-switch switch-solid" v-if="dataPage.frais == '2' ||
                                                                        dataPage.frais == '3'
                                                                        ">
                                                                        <input type="checkbox" :checked="checkedFeesCb"
                                                                               @click="tauxMontantCb"/>
                                                                        <span style="left: 20px"></span>
                                                                    </div>
                                                                </div>
                                                                <div class="container d-flex justify-content-between"
                                                                     :style="dataPage.frais == '1' ||
                                                                        dataPage.frais == '2' ||
                                                                        dataPage.frais == '3' ||
                                                                        dataPage.pourboire == '1'
                                                                        ? 'padding: 0px 30px 0px 30px; top: -20px; position: relative;'
                                                                        : ''
                                                                        " v-if="(dataPage.frais == '1' ||
        dataPage.frais == '2' ||
        dataPage.frais == '3') &&
        checkedFeesCb
        ">
                                                                    <div>
                                                                        <span class="font-12">{{
                                                                                $t("text_fees.value")
                                                                            }}</span>
                                                                    </div>
                                                                    <div>
                                                                        <span class="font-12">
                                                                            {{
                                                                                dataPage.frais == "3"
                                                                                        ? new Intl.NumberFormat("fr-FR", {
                                                                                            style: "currency",
                                                                                            currency: "XOF",
                                                                                        }).format(
                                                                                                montantSave
                                                                                                        ? parseFloat(montantSave) *
                                                                                                        (parseFloat(
                                                                                                                        dataPage.taux_cb / 2
                                                                                                                ) /
                                                                                                                100)
                                                                                                        : 0
                                                                                        )
                                                                                        : new Intl.NumberFormat("fr-FR", {
                                                                                            style: "currency",
                                                                                            currency: "XOF",
                                                                                        }).format(
                                                                                                montantSave
                                                                                                        ? parseFloat(montantSave) *
                                                                                                        (parseFloat(
                                                                                                                        dataPage.taux_cb
                                                                                                                ) /
                                                                                                                100)
                                                                                                        : 0
                                                                                        )
                                                                            }}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <!-- Fees -->

                                                                <!-- Pourboire -->
                                                                <!-- Type formulaire normal -->
                                                                <div v-if="typeForm == 'normal'">
                                                                    <div class="container d-flex justify-content-between"
                                                                         :style="dataPage.frais == '1' ||
                                                                            dataPage.frais == '2' ||
                                                                            dataPage.frais == '3' ||
                                                                            dataPage.pourboire == '1'
                                                                            ? 'padding: 0px 30px 0px 30px; top: -20px; position: relative;'
                                                                            : ''
                                                                            " v-if="dataPage.pourboire == '1' &&
        pourboire &&
        (pourboire == 'autre'
            ? parseFloat(
                dataFormulaire.amountPourboire
            ) > 0
            : parseFloat(pourboire) > 0)
        ">
                                                                        <div>
                                                                            <span class="font-12">
                                                                                {{ $t("text_pourboire.value") }}
                                                                            </span>
                                                                        </div>
                                                                        <div>
                                                                            <span class="font-12">
                                                                                {{
                                                                                    new Intl.NumberFormat("fr-FR", {
                                                                                        style: "currency",
                                                                                        currency: "XOF",
                                                                                    }).format(
                                                                                            pourboire == "autre"
                                                                                                    ? dataFormulaire.amountPourboire
                                                                                                            ? parseFloat(
                                                                                                                    dataFormulaire.amountPourboire
                                                                                                            )
                                                                                                            : 0
                                                                                                    : parseFloat(pourboire)
                                                                                    )
                                                                                }}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <!-- Type formulaire paie vite -->
                                                                <div v-if="typeForm == 'quickly'">
                                                                    <div class="container d-flex justify-content-between"
                                                                         :style="dataPage.frais == '1' ||
                                                                            dataPage.frais == '2' ||
                                                                            dataPage.frais == '3' ||
                                                                            dataPage.pourboire == '1'
                                                                            ? 'padding: 0px 30px 0px 30px; top: -20px; position: relative;'
                                                                            : ''
                                                                            " v-if="dataPage.pourboire == '1' &&
        pourboire &&
        (pourboire == 'autre'
            ? parseFloat(
                dataFormulaireQuick.amountPourboire
            ) > 0
            : parseFloat(pourboire) > 0)
        ">
                                                                        <div>
                                                                            <span class="font-12">
                                                                                {{ $t("text_pourboire.value") }}
                                                                            </span>
                                                                        </div>
                                                                        <div>
                                                                            <span class="font-12">
                                                                                {{
                                                                                    new Intl.NumberFormat("fr-FR", {
                                                                                        style: "currency",
                                                                                        currency: "XOF",
                                                                                    }).format(
                                                                                            pourboire == "autre"
                                                                                                    ? dataFormulaireQuick.amountPourboire
                                                                                                            ? parseFloat(
                                                                                                                    dataFormulaireQuick.amountPourboire
                                                                                                            )
                                                                                                            : 0
                                                                                                    : parseFloat(pourboire)
                                                                                    )
                                                                                }}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <!-- Pourboire -->

                                                                <!-- Montant à payer -->
                                                                <div class="container d-flex justify-content-between"
                                                                     :style="dataPage.frais == '1' ||
                                                                        dataPage.frais == '2' ||
                                                                        dataPage.frais == '3' ||
                                                                        dataPage.pourboire == '1'
                                                                        ? 'padding: 0px 30px 0px 30px; top: -20px; position: relative;'
                                                                        : ''
                                                                        " v-if="montantAPayer &&
            (dataPage.frais == '1' ||
                dataPage.frais == '2' ||
                dataPage.frais == '3' ||
                dataPage.pourboire == '1')
            ">
                                                                    <div>
                                                                        <span class="font-12">{{
                                                                                $t("text_amount_to_pay.value")
                                                                            }}</span>
                                                                    </div>

                                                                    <div>
                                                                        <span class="font-12">
                                                                            {{
                                                                                new Intl.NumberFormat("fr-FR", {
                                                                                    style: "currency",
                                                                                    currency: "XOF",
                                                                                }).format(parseFloat(montantAPayer))
                                                                            }}
                                                                        </span>
                                                                    </div>
                                                                </div>

                                                                <!-- Montant total -->
                                                                <div class="container d-flex justify-content-between mb-3"
                                                                     :style="dataPage.frais == '1' ||
                                                                        dataPage.frais == '2' ||
                                                                        dataPage.frais == '3' ||
                                                                        dataPage.pourboire == '1'
                                                                        ? 'padding: 0px 30px 0px 30px; top: -20px; position: relative;'
                                                                        : ''
                                                                        " v-if="typeForm == 'quickly' &&
            (dataPage.frais == '1' ||
                dataPage.frais == '2' ||
                dataPage.frais == '3' ||
                dataPage.pourboire == '1')
            ">
                                                                    <div>
                                                                        <span
                                                                                style="font-weight: 800; font-size: 22px">TOTAL</span>
                                                                    </div>
                                                                    <div>
                                                                        <span style="font-weight: 800; font-size: 22px"
                                                                              v-if="dataFormulaireQuick.montant">
                                                                            {{
                                                                                new Intl.NumberFormat("fr-FR", {
                                                                                    style: "currency",
                                                                                    currency: "XOF",
                                                                                }).format(
                                                                                        parseFloat(
                                                                                                dataFormulaireQuick.montant
                                                                                        )
                                                                                )
                                                                            }}
                                                                        </span>
                                                                        <span style="font-weight: 800; font-size: 22px"
                                                                              v-if="!dataFormulaireQuick.montant">
                                                                            {{
                                                                                new Intl.NumberFormat("fr-FR", {
                                                                                    style: "currency",
                                                                                    currency: "XOF",
                                                                                }).format(0)
                                                                            }}
                                                                        </span>
                                                                    </div>
                                                                </div>

                                                                <div class="container d-flex justify-content-between mb-3"
                                                                     :style="dataPage.frais == '1' ||
                                                                        dataPage.frais == '2' ||
                                                                        dataPage.frais == '3' ||
                                                                        dataPage.pourboire == '1'
                                                                        ? 'padding: 0px 30px 0px 30px; top: -20px; position: relative;'
                                                                        : ''
                                                                        " v-if="typeForm == 'normal' &&
            (dataPage.frais == '1' ||
                dataPage.frais == '2' ||
                dataPage.frais == '3' ||
                dataPage.pourboire == '1')
            ">
                                                                    <div>
                                                                        <span
                                                                                style="font-weight: 800; font-size: 22px">TOTAL</span>
                                                                    </div>
                                                                    <div>
                                                                        <span style="font-weight: 800; font-size: 22px"
                                                                              v-if="dataFormulaire.montant">
                                                                            {{
                                                                                new Intl.NumberFormat("fr-FR", {
                                                                                    style: "currency",
                                                                                    currency: "XOF",
                                                                                }).format(
                                                                                        parseFloat(dataFormulaire.montant)
                                                                                )
                                                                            }}
                                                                        </span>
                                                                        <span style="font-weight: 800; font-size: 22px"
                                                                              v-if="!dataFormulaire.montant">
                                                                            {{
                                                                                new Intl.NumberFormat("fr-FR", {
                                                                                    style: "currency",
                                                                                    currency: "XOF",
                                                                                }).format(0)
                                                                            }}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <!-- Montant total -->
                                                            </div>
                                                            <!-- Recapitulatif Montant à payer, pourboire, frais -->

                                                            <div :style="dataPage.frais == '1' ||
                                                                        dataPage.frais == '2' ||
                                                                        dataPage.frais == '3' ||
                                                                        dataPage.pourboire == '1'
                                                                        ? 'padding: 5px 25px 15px 25px; top: -20px; position: relative;'
                                                                        : ''
                                                                    ">
                                                                <button type="button" :class="!cardType
                                                                    ? 'btn btn-dark btn-disabled-custom btn-block'
                                                                    : 'btn btn-dark btn-block'
                                                                    " @click="sendCardPaymentUba"
                                                                        :disabled="!cardType">
                                                                    {{ $t("payment_modal.visa.button.value") }}
                                                                    {{ typeCarte }}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                                <!-- Visa / Mastercard -->

                                <!-- Orange Money -->
                                <div v-if="menuSelected == 'Orange Money'">
                                    <div class="checkout__stage">
                                        <!-- Loader -->
                                        <loading :active.sync="isLoadingOrange" :can-cancel="false"
                                                 :on-cancel="onCancelOrange" :is-full-page="fullPageOrange"
                                                 loader="bars"
                                                 :opacity="1"></loading>
                                        <!-- Loader -->

                                        <!-- Content -->
                                        <section>
                                            <div class="visa-qr-container" style="">
                                                <div>
                                                    <div class="qr__wrapper container">
                                                        <div class="element-wrap element-wrap-for-submit" :style="dataPage.frais == '1' ||
                                                            dataPage.frais == '2' ||
                                                            dataPage.frais == '3' ||
                                                            dataPage.pourboire == '1'
                                                            ? 'background: #f5f5f5; margin: 40px -30px 0px;'
                                                            : ''
                                                            ">
                                                            <img src="../assets/img/trait-recap.png"
                                                                 alt="Illustration zigzag recap"
                                                                 style="position: relative; top: -10px" v-if="dataPage.frais == '1' ||
                                                                    dataPage.frais == '2' ||
                                                                    dataPage.frais == '3' ||
                                                                    dataPage.pourboire == '1'
                                                                    "/>

                                                            <!-- Recapitulatif Montant à payer, pourboire, frais -->
                                                            <div class="mt-1" v-if="dataPage.merchant !== '25010'">
                                                                <!-- Fees -->
                                                                <div class="container d-flex justify-content-between mt-2 mb-3"
                                                                     :style="dataPage.frais == '1' ||
                                                                        dataPage.frais == '2' ||
                                                                        dataPage.frais == '3' ||
                                                                        dataPage.pourboire == '1'
                                                                        ? 'padding: 0px 30px 0px 30px; top: -20px; position: relative;'
                                                                        : ''
                                                                        " v-if="dataPage.frais == '1' ||
        dataPage.frais == '2' ||
        dataPage.frais == '3'
        ">
                                                                    <div>
                                                                        <h6 v-if="dataPage.frais == '1'">
                                                                            {{ $t("text_fees_payment.value") }}
                                                                        </h6>
                                                                        <h6 v-if="dataPage.frais == '2' ||
                                                                            dataPage.frais == '3'
                                                                            ">
                                                                            {{ $t("text_fees_forced_payment.value") }}
                                                                        </h6>
                                                                    </div>
                                                                    <label class="ui-switch switch-solid"
                                                                           v-if="dataPage.frais == '1'">
                                                                        <input type="checkbox" :checked="checkedFeesOm"
                                                                               @click="tauxMontantOm"/>
                                                                        <span style="left: 20px"></span>
                                                                    </label>
                                                                    <div class="ui-switch switch-solid" v-if="dataPage.frais == '2' ||
                                                                        dataPage.frais == '3'
                                                                        ">
                                                                        <input type="checkbox" :checked="checkedFeesOm"
                                                                               @click="tauxMontantOm"/>
                                                                        <span style="left: 20px"></span>
                                                                    </div>
                                                                </div>
                                                                <div class="container d-flex justify-content-between"
                                                                     :style="dataPage.frais == '1' ||
                                                                        dataPage.frais == '2' ||
                                                                        dataPage.frais == '3' ||
                                                                        dataPage.pourboire == '1'
                                                                        ? 'padding: 0px 30px 0px 30px; top: -20px; position: relative;'
                                                                        : ''
                                                                        " v-if="(dataPage.frais == '1' ||
        dataPage.frais == '2' ||
        dataPage.frais == '3') &&
        checkedFeesOm
        ">
                                                                    <div>
                                                                        <span class="font-12">{{
                                                                                $t("text_fees.value")
                                                                            }}</span>
                                                                    </div>
                                                                    <div>
                                                                        <span class="font-12">
                                                                            {{
                                                                                dataPage.frais == "3"
                                                                                        ? new Intl.NumberFormat("fr-FR", {
                                                                                            style: "currency",
                                                                                            currency: "XOF",
                                                                                        }).format(
                                                                                                montantSave
                                                                                                        ? parseFloat(montantSave) *
                                                                                                        (parseFloat(
                                                                                                                        dataPage.taux_om / 2
                                                                                                                ) /
                                                                                                                100)
                                                                                                        : 0
                                                                                        )
                                                                                        : new Intl.NumberFormat("fr-FR", {
                                                                                            style: "currency",
                                                                                            currency: "XOF",
                                                                                        }).format(
                                                                                                montantSave
                                                                                                        ? parseFloat(montantSave) *
                                                                                                        (parseFloat(
                                                                                                                        dataPage.taux_om
                                                                                                                ) /
                                                                                                                100)
                                                                                                        : 0
                                                                                        )
                                                                            }}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <!-- Fees -->

                                                                <!-- Pourboire -->
                                                                <!-- Type formulaire normal -->
                                                                <div v-if="typeForm == 'normal'">
                                                                    <div class="container d-flex justify-content-between"
                                                                         :style="dataPage.frais == '1' ||
                                                                            dataPage.frais == '2' ||
                                                                            dataPage.frais == '3' ||
                                                                            dataPage.pourboire == '1'
                                                                            ? 'padding: 0px 30px 0px 30px; top: -20px; position: relative;'
                                                                            : ''
                                                                            " v-if="dataPage.pourboire == '1' &&
        pourboire &&
        (pourboire == 'autre'
            ? parseFloat(
                dataFormulaire.amountPourboire
            ) > 0
            : parseFloat(pourboire) > 0)
        ">
                                                                        <div>
                                                                            <span class="font-12">
                                                                                {{ $t("text_pourboire.value") }}
                                                                            </span>
                                                                        </div>
                                                                        <div>
                                                                            <span class="font-12">
                                                                                {{
                                                                                    new Intl.NumberFormat("fr-FR", {
                                                                                        style: "currency",
                                                                                        currency: "XOF",
                                                                                    }).format(
                                                                                            pourboire == "autre"
                                                                                                    ? dataFormulaire.amountPourboire
                                                                                                            ? parseFloat(
                                                                                                                    dataFormulaire.amountPourboire
                                                                                                            )
                                                                                                            : 0
                                                                                                    : parseFloat(pourboire)
                                                                                    )
                                                                                }}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <!-- Type formulaire paie vite -->
                                                                <div v-if="typeForm == 'quickly'">
                                                                    <div class="container d-flex justify-content-between"
                                                                         :style="dataPage.frais == '1' ||
                                                                            dataPage.frais == '2' ||
                                                                            dataPage.frais == '3' ||
                                                                            dataPage.pourboire == '1'
                                                                            ? 'padding: 0px 30px 0px 30px; top: -20px; position: relative;'
                                                                            : ''
                                                                            " v-if="dataPage.pourboire == '1' &&
        pourboire &&
        (pourboire == 'autre'
            ? parseFloat(
                dataFormulaireQuick.amountPourboire
            ) > 0
            : parseFloat(pourboire) > 0)
        ">
                                                                        <div>
                                                                            <span class="font-12">
                                                                                {{ $t("text_pourboire.value") }}
                                                                            </span>
                                                                        </div>
                                                                        <div>
                                                                            <span class="font-12">
                                                                                {{
                                                                                    new Intl.NumberFormat("fr-FR", {
                                                                                        style: "currency",
                                                                                        currency: "XOF",
                                                                                    }).format(
                                                                                            pourboire == "autre"
                                                                                                    ? dataFormulaireQuick.amountPourboire
                                                                                                            ? parseFloat(
                                                                                                                    dataFormulaireQuick.amountPourboire
                                                                                                            )
                                                                                                            : 0
                                                                                                    : parseFloat(pourboire)
                                                                                    )
                                                                                }}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <!-- Pourboire -->

                                                                <!-- Montant à payer -->
                                                                <div class="container d-flex justify-content-between"
                                                                     :style="dataPage.frais == '1' ||
                                                                        dataPage.frais == '2' ||
                                                                        dataPage.frais == '3' ||
                                                                        dataPage.pourboire == '1'
                                                                        ? 'padding: 0px 30px 0px 30px; top: -20px; position: relative;'
                                                                        : ''
                                                                        " v-if="montantAPayer &&
            (dataPage.frais == '1' ||
                dataPage.frais == '2' ||
                dataPage.frais == '3' ||
                dataPage.pourboire == '1')
            ">
                                                                    <div>
                                                                        <span class="font-12">{{
                                                                                $t("text_amount_to_pay.value")
                                                                            }}</span>
                                                                    </div>

                                                                    <div>
                                                                        <span class="font-12">
                                                                            {{
                                                                                new Intl.NumberFormat("fr-FR", {
                                                                                    style: "currency",
                                                                                    currency: "XOF",
                                                                                }).format(parseFloat(montantAPayer))
                                                                            }}
                                                                        </span>
                                                                    </div>
                                                                </div>

                                                                <!-- Montant total -->
                                                                <div class="container d-flex justify-content-between mb-3"
                                                                     :style="dataPage.frais == '1' ||
                                                                        dataPage.frais == '2' ||
                                                                        dataPage.frais == '3' ||
                                                                        dataPage.pourboire == '1'
                                                                        ? 'padding: 0px 30px 0px 30px; top: -20px; position: relative;'
                                                                        : ''
                                                                        " v-if="typeForm == 'quickly' &&
            (dataPage.frais == '1' ||
                dataPage.frais == '2' ||
                dataPage.frais == '3' ||
                dataPage.pourboire == '1')
            ">
                                                                    <div>
                                                                        <span
                                                                                style="font-weight: 800; font-size: 22px">TOTAL</span>
                                                                    </div>
                                                                    <div>
                                                                        <span style="font-weight: 800; font-size: 22px"
                                                                              v-if="dataFormulaireQuick.montant">
                                                                            {{
                                                                                new Intl.NumberFormat("fr-FR", {
                                                                                    style: "currency",
                                                                                    currency: "XOF",
                                                                                }).format(
                                                                                        parseFloat(
                                                                                                dataFormulaireQuick.montant
                                                                                        )
                                                                                )
                                                                            }}
                                                                        </span>
                                                                        <span style="font-weight: 800; font-size: 22px"
                                                                              v-if="!dataFormulaireQuick.montant">
                                                                            {{
                                                                                new Intl.NumberFormat("fr-FR", {
                                                                                    style: "currency",
                                                                                    currency: "XOF",
                                                                                }).format(0)
                                                                            }}
                                                                        </span>
                                                                    </div>
                                                                </div>

                                                                <div class="container d-flex justify-content-between mb-3"
                                                                     :style="dataPage.frais == '1' ||
                                                                        dataPage.frais == '2' ||
                                                                        dataPage.frais == '3' ||
                                                                        dataPage.pourboire == '1'
                                                                        ? 'padding: 0px 30px 0px 30px; top: -20px; position: relative;'
                                                                        : ''
                                                                        " v-if="typeForm == 'normal' &&
            (dataPage.frais == '1' ||
                dataPage.frais == '2' ||
                dataPage.frais == '3' ||
                dataPage.pourboire == '1')
            ">
                                                                    <div>
                                                                        <span
                                                                                style="font-weight: 800; font-size: 22px">TOTAL</span>
                                                                    </div>
                                                                    <div>
                                                                        <span style="font-weight: 800; font-size: 22px"
                                                                              v-if="dataFormulaire.montant">
                                                                            {{
                                                                                new Intl.NumberFormat("fr-FR", {
                                                                                    style: "currency",
                                                                                    currency: "XOF",
                                                                                }).format(
                                                                                        parseFloat(dataFormulaire.montant)
                                                                                )
                                                                            }}
                                                                        </span>
                                                                        <span style="font-weight: 800; font-size: 22px"
                                                                              v-if="!dataFormulaire.montant">
                                                                            {{
                                                                                new Intl.NumberFormat("fr-FR", {
                                                                                    style: "currency",
                                                                                    currency: "XOF",
                                                                                }).format(0)
                                                                            }}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <!-- Montant total -->
                                                            </div>
                                                            <!-- Recapitulatif Montant à payer, pourboire, frais -->

                                                            <div :style="dataPage.frais == '1' ||
                                                                dataPage.frais == '2' ||
                                                                dataPage.frais == '3' ||
                                                                dataPage.pourboire == '1'
                                                                ? 'padding: 5px 25px 15px 25px; top: -20px; position: relative;'
                                                                : ''
                                                                " v-if="dataPage.yango === 1">
                                                                <button v-if="mobileDevice" type="button" id="pay-btn"
                                                                        @click="sendOM('app')"
                                                                        class="btn btn-dark btn-block mb-3">
                                                                    {{ $t("payment_modal.om.button2.value") }}
                                                                </button>

                                                                <span class="text-dark mt-5 mb-5"
                                                                      style="font-weight: 900"
                                                                      v-if="mobileDevice">
                                                                    {{ $t("payment_modal.om.or_om.value") }}
                                                                </span>

                                                                <button type="button" id="pay-btn"
                                                                        @click="sendOM('ussd')"
                                                                        :class="mobileDevice
                                                                        ? 'btn btn-outline-dark btn-block mt-3'
                                                                        : 'btn btn-dark btn-block mt-3'
                                                                        " style>
                                                                    {{ $t("payment_modal.om.button.value") }}
                                                                </button>
                                                            </div>

                                                            <div :style="dataPage.frais == '1' ||
                                                                dataPage.frais == '2' ||
                                                                dataPage.frais == '3' ||
                                                                dataPage.pourboire == '1'
                                                                ? 'padding: 5px 25px 15px 25px; top: -20px; position: relative;'
                                                                : ''
                                                                " v-if="dataPage.yango !== 1">
                                                                <button v-if="mobileDevice" type="button" id="pay-btn"
                                                                        @click="sendOM('app')"
                                                                        class="btn btn-dark btn-block mb-3">
                                                                    {{ $t("payment_modal.om.button2.value") }}
                                                                </button>

                                                                <span class="text-dark mt-5 mb-5"
                                                                      style="font-weight: 900"
                                                                      v-if="mobileDevice">
                                                                    {{ $t("payment_modal.om.or_om.value") }}
                                                                </span>

                                                                <button type="button" id="pay-btn"
                                                                        @click="sendOM('ussd')"
                                                                        :class="mobileDevice
                                                                        ? 'btn btn-outline-dark btn-block mt-3'
                                                                        : 'btn btn-dark btn-block mt-3'
                                                                        " style>
                                                                    {{ $t("payment_modal.om.button.value") }}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="visa-qr-container__header mt-2">
                                                        <p class="instruction mt-3" v-if="!mobileDevice">
                                                            {{ $t("payment_modal.om.text.value") }}
                                                        </p>
                                                        <p class="instruction mt-3" v-if="mobileDevice">
                                                            {{ $t("payment_modal.om.text2.value") }}
                                                        </p>

                                                        <div class="displayerror__details mt-3" v-if="orangeNOkay">
                                                            <div>
                                                                <p class="paiement-failed">
                                                                    {{
                                                                        $t(
                                                                                "payment_modal.om.payment_failure_title.value"
                                                                        )
                                                                    }}
                                                                </p>
                                                                <div class="error-information">
                                                                    {{
                                                                        $t(
                                                                                "payment_modal.om.payment_failure_details.value"
                                                                        )
                                                                    }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                        <!-- Content -->
                                    </div>
                                </div>
                                <!-- Orange Money -->

                                <!-- Wave -->
                                <div v-if="menuSelected == 'Wave' && source_pay === 'link' ">
                                    <div class="checkout__stage">
                                        <loading :active.sync="isLoadingWave" :can-cancel="false"
                                                 :on-cancel="onCancelWave"
                                                 :is-full-page="fullPageWave" loader="bars" :opacity="1"></loading>
                                        <section>
                                            <div class="visa-qr-container" style="">
                                                <div>
                                                    <div class="qr__wrapper container">
                                                        <div class="element-wrap element-wrap-for-submit" :style="dataPage.frais == '1' ||
                                                            dataPage.frais == '2' ||
                                                            dataPage.frais == '3' ||
                                                            dataPage.pourboire == '1'
                                                            ? 'background: #f5f5f5; margin: 40px -30px 0px;'
                                                            : ''
                                                            ">
                                                            <img src="../assets/img/trait-recap.png"
                                                                 alt="Illustration zigzag recap"
                                                                 style="position: relative; top: -10px" v-if="dataPage.frais == '1' ||
                                                                    dataPage.frais == '2' ||
                                                                    dataPage.frais == '3' ||
                                                                    dataPage.pourboire == '1'
                                                                    "/>

                                                            <!-- Recapitulatif Montant à payer, pourboire, frais -->
                                                            <div class="mt-1" v-if="dataPage.merchant !== '25010'">
                                                                <!-- Fees -->
                                                                <div class="container d-flex justify-content-between mt-2 mb-3"
                                                                     :style="dataPage.frais == '1' ||
                                                                        dataPage.frais == '2' ||
                                                                        dataPage.frais == '3' ||
                                                                        dataPage.pourboire == '1'
                                                                        ? 'padding: 0px 30px 0px 30px; top: -20px; position: relative;'
                                                                        : ''
                                                                        " v-if="dataPage.frais == '1' ||
        dataPage.frais == '2' ||
        dataPage.frais == '3'
        ">
                                                                    <div>
                                                                        <h6 v-if="dataPage.frais == '1'">
                                                                            {{ $t("text_fees_payment.value") }}
                                                                        </h6>
                                                                        <h6 v-if="dataPage.frais == '2' ||
                                                                            dataPage.frais == '3'
                                                                            ">
                                                                            {{ $t("text_fees_forced_payment.value") }}
                                                                        </h6>
                                                                    </div>
                                                                    <label class="ui-switch switch-solid"
                                                                           v-if="dataPage.frais == '1'">
                                                                        <input type="checkbox"
                                                                               :checked="checkedFeesWave"
                                                                               @click="tauxMontantWave"/>
                                                                        <span style="left: 20px"></span>
                                                                    </label>
                                                                    <div class="ui-switch switch-solid" v-if="dataPage.frais == '2' ||
                                                                        dataPage.frais == '3'
                                                                        ">
                                                                        <input type="checkbox"
                                                                               :checked="checkedFeesWave"
                                                                               @click="tauxMontantWave"/>
                                                                        <span style="left: 20px"></span>
                                                                    </div>
                                                                </div>
                                                                <div class="container d-flex justify-content-between"
                                                                     :style="dataPage.frais == '1' ||
                                                                        dataPage.frais == '2' ||
                                                                        dataPage.frais == '3' ||
                                                                        dataPage.pourboire == '1'
                                                                        ? 'padding: 0px 30px 0px 30px; top: -20px; position: relative;'
                                                                        : ''
                                                                        " v-if="(dataPage.frais == '1' ||
        dataPage.frais == '2' ||
        dataPage.frais == '3') &&
        checkedFeesWave
        ">
                                                                    <div>
                                                                        <span class="font-12">{{
                                                                                $t("text_fees.value")
                                                                            }}</span>
                                                                    </div>
                                                                    <div>
                                                                        <span class="font-12">
                                                                            {{
                                                                                dataPage.frais == "3"
                                                                                        ? new Intl.NumberFormat("fr-FR", {
                                                                                            style: "currency",
                                                                                            currency: "XOF",
                                                                                        }).format(
                                                                                                montantSave
                                                                                                        ? parseFloat(montantSave) *
                                                                                                        (parseFloat(
                                                                                                                        dataPage.taux_wave / 2
                                                                                                                ) /
                                                                                                                100)
                                                                                                        : 0
                                                                                        )
                                                                                        : new Intl.NumberFormat("fr-FR", {
                                                                                            style: "currency",
                                                                                            currency: "XOF",
                                                                                        }).format(
                                                                                                montantSave
                                                                                                        ? parseFloat(montantSave) *
                                                                                                        (parseFloat(
                                                                                                                        dataPage.taux_wave
                                                                                                                ) /
                                                                                                                100)
                                                                                                        : 0
                                                                                        )
                                                                            }}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <!-- Fees -->

                                                                <!-- Pourboire -->
                                                                <!-- Type formulaire normal -->
                                                                <div v-if="typeForm == 'normal'">
                                                                    <div class="container d-flex justify-content-between"
                                                                         :style="dataPage.frais == '1' ||
                                                                            dataPage.frais == '2' ||
                                                                            dataPage.frais == '3' ||
                                                                            dataPage.pourboire == '1'
                                                                            ? 'padding: 0px 30px 0px 30px; top: -20px; position: relative;'
                                                                            : ''
                                                                            " v-if="dataPage.pourboire == '1' &&
        pourboire &&
        (pourboire == 'autre'
            ? parseFloat(
                dataFormulaire.amountPourboire
            ) > 0
            : parseFloat(pourboire) > 0)
        ">
                                                                        <div>
                                                                            <span class="font-12">
                                                                                {{ $t("text_pourboire.value") }}
                                                                            </span>
                                                                        </div>
                                                                        <div>
                                                                            <span class="font-12">
                                                                                {{
                                                                                    new Intl.NumberFormat("fr-FR", {
                                                                                        style: "currency",
                                                                                        currency: "XOF",
                                                                                    }).format(
                                                                                            pourboire == "autre"
                                                                                                    ? dataFormulaire.amountPourboire
                                                                                                            ? parseFloat(
                                                                                                                    dataFormulaire.amountPourboire
                                                                                                            )
                                                                                                            : 0
                                                                                                    : parseFloat(pourboire)
                                                                                    )
                                                                                }}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <!-- Type formulaire paie vite -->
                                                                <div v-if="typeForm == 'quickly'">
                                                                    <div class="container d-flex justify-content-between"
                                                                         :style="dataPage.frais == '1' ||
                                                                            dataPage.frais == '2' ||
                                                                            dataPage.frais == '3' ||
                                                                            dataPage.pourboire == '1'
                                                                            ? 'padding: 0px 30px 0px 30px; top: -20px; position: relative;'
                                                                            : ''
                                                                            " v-if="dataPage.pourboire == '1' &&
        pourboire &&
        (pourboire == 'autre'
            ? parseFloat(
                dataFormulaireQuick.amountPourboire
            ) > 0
            : parseFloat(pourboire) > 0)
        ">
                                                                        <div>
                                                                            <span class="font-12">
                                                                                {{ $t("text_pourboire.value") }}
                                                                            </span>
                                                                        </div>
                                                                        <div>
                                                                            <span class="font-12">
                                                                                {{
                                                                                    new Intl.NumberFormat("fr-FR", {
                                                                                        style: "currency",
                                                                                        currency: "XOF",
                                                                                    }).format(
                                                                                            pourboire == "autre"
                                                                                                    ? dataFormulaireQuick.amountPourboire
                                                                                                            ? parseFloat(
                                                                                                                    dataFormulaireQuick.amountPourboire
                                                                                                            )
                                                                                                            : 0
                                                                                                    : parseFloat(pourboire)
                                                                                    )
                                                                                }}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <!-- Pourboire -->

                                                                <!-- Montant à payer -->
                                                                <div class="container d-flex justify-content-between"
                                                                     :style="dataPage.frais == '1' ||
                                                                        dataPage.frais == '2' ||
                                                                        dataPage.frais == '3' ||
                                                                        dataPage.pourboire == '1'
                                                                        ? 'padding: 0px 30px 0px 30px; top: -20px; position: relative;'
                                                                        : ''
                                                                        " v-if="montantAPayer &&
            (dataPage.frais == '1' ||
                dataPage.frais == '2' ||
                dataPage.frais == '3' ||
                dataPage.pourboire == '1')
            ">
                                                                    <div>
                                                                        <span class="font-12">{{
                                                                                $t("text_amount_to_pay.value")
                                                                            }}</span>
                                                                    </div>

                                                                    <div>
                                                                        <span class="font-12">
                                                                            {{
                                                                                new Intl.NumberFormat("fr-FR", {
                                                                                    style: "currency",
                                                                                    currency: "XOF",
                                                                                }).format(parseFloat(montantAPayer))
                                                                            }}
                                                                        </span>
                                                                    </div>
                                                                </div>

                                                                <!-- Montant total -->
                                                                <div class="container d-flex justify-content-between mb-3"
                                                                     :style="dataPage.frais == '1' ||
                                                                        dataPage.frais == '2' ||
                                                                        dataPage.frais == '3' ||
                                                                        dataPage.pourboire == '1'
                                                                        ? 'padding: 0px 30px 0px 30px; top: -20px; position: relative;'
                                                                        : ''
                                                                        " v-if="typeForm == 'quickly' &&
            (dataPage.frais == '1' ||
                dataPage.frais == '2' ||
                dataPage.frais == '3' ||
                dataPage.pourboire == '1')
            ">
                                                                    <div>
                                                                        <span
                                                                                style="font-weight: 800; font-size: 22px">TOTAL</span>
                                                                    </div>
                                                                    <div>
                                                                        <span style="font-weight: 800; font-size: 22px"
                                                                              v-if="dataFormulaireQuick.montant">
                                                                            {{
                                                                                new Intl.NumberFormat("fr-FR", {
                                                                                    style: "currency",
                                                                                    currency: "XOF",
                                                                                }).format(
                                                                                        parseFloat(
                                                                                                dataFormulaireQuick.montant
                                                                                        )
                                                                                )
                                                                            }}
                                                                        </span>
                                                                        <span style="font-weight: 800; font-size: 22px"
                                                                              v-if="!dataFormulaireQuick.montant">
                                                                            {{
                                                                                new Intl.NumberFormat("fr-FR", {
                                                                                    style: "currency",
                                                                                    currency: "XOF",
                                                                                }).format(0)
                                                                            }}
                                                                        </span>
                                                                    </div>
                                                                </div>

                                                                <div class="container d-flex justify-content-between mb-3"
                                                                     :style="dataPage.frais == '1' ||
                                                                        dataPage.frais == '2' ||
                                                                        dataPage.frais == '3' ||
                                                                        dataPage.pourboire == '1'
                                                                        ? 'padding: 0px 30px 0px 30px; top: -20px; position: relative;'
                                                                        : ''
                                                                        " v-if="typeForm == 'normal' &&
            (dataPage.frais == '1' ||
                dataPage.frais == '2' ||
                dataPage.frais == '3' ||
                dataPage.pourboire == '1')
            ">
                                                                    <div>
                                                                        <span
                                                                                style="font-weight: 800; font-size: 22px">TOTAL</span>
                                                                    </div>
                                                                    <div>
                                                                        <span style="font-weight: 800; font-size: 22px"
                                                                              v-if="dataFormulaire.montant">
                                                                            {{
                                                                                new Intl.NumberFormat("fr-FR", {
                                                                                    style: "currency",
                                                                                    currency: "XOF",
                                                                                }).format(
                                                                                        parseFloat(dataFormulaire.montant)
                                                                                )
                                                                            }}
                                                                        </span>
                                                                        <span style="font-weight: 800; font-size: 22px"
                                                                              v-if="!dataFormulaire.montant">
                                                                            {{
                                                                                new Intl.NumberFormat("fr-FR", {
                                                                                    style: "currency",
                                                                                    currency: "XOF",
                                                                                }).format(0)
                                                                            }}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <!-- Montant total -->
                                                            </div>
                                                            <!-- Recapitulatif Montant à payer, pourboire, frais -->

                                                            <!-- Wave button -->
                                                            <div :style="dataPage.frais == '1' ||
                                                                        dataPage.frais == '2' ||
                                                                        dataPage.frais == '3' ||
                                                                        dataPage.pourboire == '1'
                                                                        ? 'padding: 5px 25px 15px 25px; top: -20px; position: relative;'
                                                                        : ''
                                                                    ">
                                                                <!-- Payer avec Wave / QR Code -->
                                                                <button type="button" id="pay-btn" v-if="!mobileDevice"
                                                                        @click="sendWave()"
                                                                        class="btn btn-dark btn-block mt-3">
                                                                    {{ $t("payment_modal.wave.button.value") }}
                                                                </button>
                                                                <!-- Payer avec Wave / QR Code -->

                                                                <!-- Payer avec l'application Wave -->
                                                                <button type="button" id="pay-btn" v-if="mobileDevice"
                                                                        @click="sendWave()"
                                                                        class="btn btn-dark btn-block mt-3">
                                                                    {{ $t("payment_modal.wave.button_2.value") }}
                                                                </button>
                                                                <!-- Payer avec l'application Wave -->
                                                            </div>
                                                            <!-- Wave button -->
                                                        </div>
                                                    </div>

                                                    <div class="visa-qr-container__header">
                                                        <p class="instruction mt-3" v-if="!mobileDevice">
                                                            {{ $t("payment_modal.wave.text.value") }}
                                                        </p>

                                                        <p class="instruction mt-3" v-if="mobileDevice">
                                                            {{ $t("payment_modal.wave.text_2.value") }}
                                                        </p>

                                                        <!-- Display waiting message -->
                                                        <div v-if="waveOkay" class="displayerror__details mt-3"
                                                             style="padding: 10px 5px 2px 5px">
                                                            <div class="qr__code">
                                                                <i class="fas fa-spinner fa-spin"
                                                                   style="font-size: 20px"></i>
                                                            </div>
                                                            <div>
                                                                <p class="paiement-failed"
                                                                   style="font-weight: 600; color: #261b64">
                                                                    {{ $t("payment_modal.wave.waiting.value") }}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <!-- Display waiting message -->

                                                        <!-- Display error message -->
                                                        <div class="displayerror__details mt-3" v-if="waveNOkay">
                                                            <div>
                                                                <p class="paiement-failed">
                                                                    {{
                                                                        $t(
                                                                                "payment_modal.wave.payment_failure_title.value"
                                                                        )
                                                                    }}
                                                                </p>
                                                                <div class="error-information">
                                                                    {{
                                                                        $t(
                                                                                "payment_modal.wave.payment_failure_details.value"
                                                                        )
                                                                    }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <!-- Display error message -->
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                                <!-- Wave -->

                                <!-- MTN Money -->
                                <div v-if="menuSelected == 'MTN Money'">
                                    <div class="checkout__stage">
                                        <section>
                                            <div class="visa-qr-container">
                                                <div class="mt-5">
                                                    <loading :active.sync="isLoadingMomo" :can-cancel="false"
                                                             :on-cancel="onCancelMomo" :is-full-page="fullPageMomo"
                                                             loader="bars"
                                                             :opacity="1"></loading>
                                                </div>
                                                <div>
                                                    <div class="qr__wrapper container">
                                                        <div class="element-pair-container mt-2 mb-3">
                                                            <div class="element-wrap element-wrap-on-left">
                                                                <multiselect v-model="indiceMobileMoney"
                                                                             :options="options"
                                                                             :multiple="false" placeholder="Indicatif"
                                                                             tag-placeholder="Indicatif" track-by="code"
                                                                             label="country" :searchable="true"
                                                                             disabled>
                                                                    <template slot="singleLabel"
                                                                              slot-scope="{ option }">{{
                                                                            option.country_code
                                                                        }}
                                                                    </template>
                                                                    <span slot="noResult"></span>
                                                                </multiselect>
                                                            </div>
                                                            <div class="element-wrap element-wrap-on-right">
                                                                <input type="tel" v-on:keypress="numbersOnly"
                                                                       :placeholder="$t('payment_modal.mtn.placeholder.value')
                                                                        " class="element-is-input"
                                                                       v-model="dataMoMo.numero"
                                                                       v-mask="'## ## ## ## ##'"/>
                                                            </div>
                                                        </div>

                                                        <div class="element-wrap element-wrap-for-submit" :style="dataPage.frais == '1' ||
                                                            dataPage.frais == '2' ||
                                                            dataPage.frais == '3' ||
                                                            dataPage.pourboire == '1'
                                                            ? 'background: #f5f5f5; margin: 40px -30px 0px;'
                                                            : ''
                                                            ">
                                                            <img src="../assets/img/trait-recap.png"
                                                                 alt="Illustration zigzag recap"
                                                                 style="position: relative; top: -10px" v-if="dataPage.frais == '1' ||
                                                                    dataPage.frais == '2' ||
                                                                    dataPage.frais == '3' ||
                                                                    dataPage.pourboire == '1'
                                                                    "/>

                                                            <!-- Recapitulatif Montant à payer, pourboire, frais -->
                                                            <div class="mt-1" v-if="dataPage.merchant !== '25010'">
                                                                <!-- Fees -->
                                                                <div class="container d-flex justify-content-between mt-2 mb-3"
                                                                     :style="dataPage.frais == '1' ||
                                                                        dataPage.frais == '2' ||
                                                                        dataPage.frais == '3' ||
                                                                        dataPage.pourboire == '1'
                                                                        ? 'padding: 0px 30px 0px 30px; top: -20px; position: relative;'
                                                                        : ''
                                                                        " v-if="dataPage.frais == '1' ||
        dataPage.frais == '2' ||
        dataPage.frais == '3'
        ">
                                                                    <div>
                                                                        <h6 v-if="dataPage.frais == '1'">
                                                                            {{ $t("text_fees_payment.value") }}
                                                                        </h6>
                                                                        <h6 v-if="dataPage.frais == '2' ||
                                                                            dataPage.frais == '3'
                                                                            ">
                                                                            {{ $t("text_fees_forced_payment.value") }}
                                                                        </h6>
                                                                    </div>
                                                                    <label class="ui-switch switch-solid"
                                                                           v-if="dataPage.frais == '1'">
                                                                        <input type="checkbox" :checked="checkedFeesMtn"
                                                                               @click="tauxMontantMtn"/>
                                                                        <span style="left: 20px"></span>
                                                                    </label>
                                                                    <div class="ui-switch switch-solid" v-if="dataPage.frais == '2' ||
                                                                        dataPage.frais == '3'
                                                                        ">
                                                                        <input type="checkbox" :checked="checkedFeesMtn"
                                                                               @click="tauxMontantMtn"/>
                                                                        <span style="left: 20px"></span>
                                                                    </div>
                                                                </div>
                                                                <div class="container d-flex justify-content-between"
                                                                     :style="dataPage.frais == '1' ||
                                                                        dataPage.frais == '2' ||
                                                                        dataPage.frais == '3' ||
                                                                        dataPage.pourboire == '1'
                                                                        ? 'padding: 0px 30px 0px 30px; top: -20px; position: relative;'
                                                                        : ''
                                                                        " v-if="(dataPage.frais == '1' ||
        dataPage.frais == '2' ||
        dataPage.frais == '3') &&
        checkedFeesMtn
        ">
                                                                    <div>
                                                                        <span class="font-12">{{
                                                                                $t("text_fees.value")
                                                                            }}</span>
                                                                    </div>
                                                                    <div>
                                                                        <span class="font-12">
                                                                            {{
                                                                                dataPage.frais == "3"
                                                                                        ? new Intl.NumberFormat("fr-FR", {
                                                                                            style: "currency",
                                                                                            currency: "XOF",
                                                                                        }).format(
                                                                                                montantSave
                                                                                                        ? parseFloat(montantSave) *
                                                                                                        (parseFloat(
                                                                                                                        dataPage.taux_mtn / 2
                                                                                                                ) /
                                                                                                                100)
                                                                                                        : 0
                                                                                        )
                                                                                        : new Intl.NumberFormat("fr-FR", {
                                                                                            style: "currency",
                                                                                            currency: "XOF",
                                                                                        }).format(
                                                                                                montantSave
                                                                                                        ? parseFloat(montantSave) *
                                                                                                        (parseFloat(
                                                                                                                        dataPage.taux_mtn
                                                                                                                ) /
                                                                                                                100)
                                                                                                        : 0
                                                                                        )
                                                                            }}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <!-- Fees -->

                                                                <!-- Pourboire -->
                                                                <!-- Type formulaire normal -->
                                                                <div v-if="typeForm == 'normal'">
                                                                    <div class="container d-flex justify-content-between"
                                                                         :style="dataPage.frais == '1' ||
                                                                            dataPage.frais == '2' ||
                                                                            dataPage.frais == '3' ||
                                                                            dataPage.pourboire == '1'
                                                                            ? 'padding: 0px 30px 0px 30px; top: -20px; position: relative;'
                                                                            : ''
                                                                            " v-if="dataPage.pourboire == '1' &&
        pourboire &&
        (pourboire == 'autre'
            ? parseFloat(
                dataFormulaire.amountPourboire
            ) > 0
            : parseFloat(pourboire) > 0)
        ">
                                                                        <div>
                                                                            <span class="font-12">
                                                                                {{ $t("text_pourboire.value") }}
                                                                            </span>
                                                                        </div>
                                                                        <div>
                                                                            <span class="font-12">
                                                                                {{
                                                                                    new Intl.NumberFormat("fr-FR", {
                                                                                        style: "currency",
                                                                                        currency: "XOF",
                                                                                    }).format(
                                                                                            pourboire == "autre"
                                                                                                    ? dataFormulaire.amountPourboire
                                                                                                            ? parseFloat(
                                                                                                                    dataFormulaire.amountPourboire
                                                                                                            )
                                                                                                            : 0
                                                                                                    : parseFloat(pourboire)
                                                                                    )
                                                                                }}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <!-- Type formulaire paie vite -->
                                                                <div v-if="typeForm == 'quickly'">
                                                                    <div class="container d-flex justify-content-between"
                                                                         :style="dataPage.frais == '1' ||
                                                                            dataPage.frais == '2' ||
                                                                            dataPage.frais == '3' ||
                                                                            dataPage.pourboire == '1'
                                                                            ? 'padding: 0px 30px 0px 30px; top: -20px; position: relative;'
                                                                            : ''
                                                                            " v-if="dataPage.pourboire == '1' &&
        pourboire &&
        (pourboire == 'autre'
            ? parseFloat(
                dataFormulaireQuick.amountPourboire
            ) > 0
            : parseFloat(pourboire) > 0)
        ">
                                                                        <div>
                                                                            <span class="font-12">
                                                                                {{ $t("text_pourboire.value") }}
                                                                            </span>
                                                                        </div>
                                                                        <div>
                                                                            <span class="font-12">
                                                                                {{
                                                                                    new Intl.NumberFormat("fr-FR", {
                                                                                        style: "currency",
                                                                                        currency: "XOF",
                                                                                    }).format(
                                                                                            pourboire == "autre"
                                                                                                    ? dataFormulaireQuick.amountPourboire
                                                                                                            ? parseFloat(
                                                                                                                    dataFormulaireQuick.amountPourboire
                                                                                                            )
                                                                                                            : 0
                                                                                                    : parseFloat(pourboire)
                                                                                    )
                                                                                }}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <!-- Pourboire -->

                                                                <!-- Montant à payer -->
                                                                <div class="container d-flex justify-content-between"
                                                                     :style="dataPage.frais == '1' ||
                                                                        dataPage.frais == '2' ||
                                                                        dataPage.frais == '3' ||
                                                                        dataPage.pourboire == '1'
                                                                        ? 'padding: 0px 30px 0px 30px; top: -20px; position: relative;'
                                                                        : ''
                                                                        " v-if="montantAPayer &&
            (dataPage.frais == '1' ||
                dataPage.frais == '2' ||
                dataPage.frais == '3' ||
                dataPage.pourboire == '1')
            ">
                                                                    <div>
                                                                        <span class="font-12">{{
                                                                                $t("text_amount_to_pay.value")
                                                                            }}</span>
                                                                    </div>

                                                                    <div>
                                                                        <span class="font-12">
                                                                            {{
                                                                                new Intl.NumberFormat("fr-FR", {
                                                                                    style: "currency",
                                                                                    currency: "XOF",
                                                                                }).format(parseFloat(montantAPayer))
                                                                            }}
                                                                        </span>
                                                                    </div>
                                                                </div>

                                                                <!-- Montant total -->
                                                                <div class="container d-flex justify-content-between mb-3"
                                                                     :style="dataPage.frais == '1' ||
                                                                        dataPage.frais == '2' ||
                                                                        dataPage.frais == '3' ||
                                                                        dataPage.pourboire == '1'
                                                                        ? 'padding: 0px 30px 0px 30px; top: -20px; position: relative;'
                                                                        : ''
                                                                        " v-if="typeForm == 'quickly' &&
            (dataPage.frais == '1' ||
                dataPage.frais == '2' ||
                dataPage.frais == '3' ||
                dataPage.pourboire == '1')
            ">
                                                                    <div>
                                                                        <span
                                                                                style="font-weight: 800; font-size: 22px">TOTAL</span>
                                                                    </div>
                                                                    <div>
                                                                        <span style="font-weight: 800; font-size: 22px"
                                                                              v-if="dataFormulaireQuick.montant">
                                                                            {{
                                                                                new Intl.NumberFormat("fr-FR", {
                                                                                    style: "currency",
                                                                                    currency: "XOF",
                                                                                }).format(
                                                                                        parseFloat(
                                                                                                dataFormulaireQuick.montant
                                                                                        )
                                                                                )
                                                                            }}
                                                                        </span>
                                                                        <span style="font-weight: 800; font-size: 22px"
                                                                              v-if="!dataFormulaireQuick.montant">
                                                                            {{
                                                                                new Intl.NumberFormat("fr-FR", {
                                                                                    style: "currency",
                                                                                    currency: "XOF",
                                                                                }).format(0)
                                                                            }}
                                                                        </span>
                                                                    </div>
                                                                </div>

                                                                <div class="container d-flex justify-content-between mb-3"
                                                                     :style="dataPage.frais == '1' ||
                                                                        dataPage.frais == '2' ||
                                                                        dataPage.frais == '3' ||
                                                                        dataPage.pourboire == '1'
                                                                        ? 'padding: 0px 30px 0px 30px; top: -20px; position: relative;'
                                                                        : ''
                                                                        " v-if="typeForm == 'normal' &&
            (dataPage.frais == '1' ||
                dataPage.frais == '2' ||
                dataPage.frais == '3' ||
                dataPage.pourboire == '1')
            ">
                                                                    <div>
                                                                        <span
                                                                                style="font-weight: 800; font-size: 22px">TOTAL</span>
                                                                    </div>
                                                                    <div>
                                                                        <span style="font-weight: 800; font-size: 22px"
                                                                              v-if="dataFormulaire.montant">
                                                                            {{
                                                                                new Intl.NumberFormat("fr-FR", {
                                                                                    style: "currency",
                                                                                    currency: "XOF",
                                                                                }).format(
                                                                                        parseFloat(dataFormulaire.montant)
                                                                                )
                                                                            }}
                                                                        </span>
                                                                        <span style="font-weight: 800; font-size: 22px"
                                                                              v-if="!dataFormulaire.montant">
                                                                            {{
                                                                                new Intl.NumberFormat("fr-FR", {
                                                                                    style: "currency",
                                                                                    currency: "XOF",
                                                                                }).format(0)
                                                                            }}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <!-- Montant total -->
                                                            </div>
                                                            <!-- Recapitulatif Montant à payer, pourboire, frais -->

                                                            <div :style="dataPage.frais == '1' ||
                                                                        dataPage.frais == '2' ||
                                                                        dataPage.frais == '3' ||
                                                                        dataPage.pourboire == '1'
                                                                        ? 'padding: 5px 25px 15px 25px; top: -20px; position: relative;'
                                                                        : ''
                                                                    ">
                                                                <button type="button" id="pay-btn" @click="sendMoMo"
                                                                        :class="!dataMoMo.numero
                                                                    ? 'btn btn-dark btn-disabled-custom btn-block'
                                                                    : 'btn btn-dark btn-block'
                                                                    " :disabled="!dataMoMo.numero">
                                                                    {{ $t("payment_modal.mtn.button.value") }}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="visa-qr-container__header">
                                                        <p class="instruction mt-3">
                                                            {{ $t("payment_modal.mtn.text.value") }}
                                                            <strong class="custom-text">*133#</strong>
                                                            {{ $t("payment_modal.mtn.text2.value") }}
                                                        </p>

                                                        <!-- MTN Message en cas d'échec -->
                                                        <div class="displayerror__details mt-3" v-if="mtnNOkay">
                                                            <div>
                                                                <p class="paiement-failed">
                                                                    {{
                                                                        $t(
                                                                                "payment_modal.mtn.payment_failure_title.value"
                                                                        )
                                                                    }}
                                                                </p>
                                                                <div class="error-information">
                                                                    {{
                                                                        $t(
                                                                                "payment_modal.mtn.payment_failure_details.value"
                                                                        )
                                                                    }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <!-- MTN Message en cas d'échec -->

                                                        <!-- MTN Message en cas d'attente de confirmation du paiement -->
                                                        <div v-if="mtnOkay" class="displayerror__details mt-3"
                                                             style="padding: 10px 5px 2px 5px">
                                                            <div class="qr__code">
                                                                <i class="fas fa-spinner fa-spin"
                                                                   style="font-size: 20px"></i>
                                                            </div>

                                                            <div>
                                                                <p class="paiement-failed"
                                                                   style="font-weight: 600; color: #261b64">
                                                                    {{
                                                                        $t(
                                                                                "payment_modal.mtn.payment_pending.value"
                                                                        )
                                                                    }}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <!-- MTN Message en cas d'attente de confirmation du paiement -->
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                                <!-- MTN Money -->

                                <!-- Moov Money -->
                                <div v-if="menuSelected == 'Moov Money'">
                                    <div class="checkout__stage">
                                        <section>
                                            <div class="visa-qr-container">
                                                <div>
                                                    <div class="mt-5">
                                                        <loading :active.sync="isLoadingFlooz" :can-cancel="false"
                                                                 :on-cancel="onCancelFlooz"
                                                                 :is-full-page="fullPageFlooz"
                                                                 loader="bars" :opacity="1"></loading>
                                                    </div>

                                                    <div class="qr__wrapper container">
                                                        <div class="element-pair mt-2 mb-3">
                                                            <div class="element-pair-container">
                                                                <div class="element-wrap element-wrap-on-left">
                                                                    <multiselect v-model="indiceMobileMoney"
                                                                                 :options="options" :multiple="false"
                                                                                 placeholder="Indicatif"
                                                                                 tag-placeholder="Indicatif"
                                                                                 track-by="code" label="country"
                                                                                 :searchable="true"
                                                                                 disabled>
                                                                        <template slot="singleLabel"
                                                                                  slot-scope="{ option }">{{
                                                                                option.country_code
                                                                            }}
                                                                        </template>
                                                                        <span slot="noResult"></span>
                                                                    </multiselect>
                                                                </div>
                                                                <div class="element-wrap element-wrap-on-right">
                                                                    <input type="tel" v-on:keypress="numbersOnly"
                                                                           :placeholder="$t('payment_modal.moov.placeholder.value')
                                                                                " class="element-is-input"
                                                                           v-model="dataMoov.numero"
                                                                           v-mask="'## ## ## ## ##'"/>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="element-wrap element-wrap-for-submit" :style="dataPage.frais == '1' ||
                                                            dataPage.frais == '2' ||
                                                            dataPage.frais == '3' ||
                                                            dataPage.pourboire == '1'
                                                            ? 'background: #f5f5f5; margin: 40px -30px 0px;'
                                                            : ''
                                                            ">
                                                            <img src="../assets/img/trait-recap.png"
                                                                 alt="Illustration zigzag recap"
                                                                 style="position: relative; top: -10px" v-if="dataPage.frais == '1' ||
                                                                    dataPage.frais == '2' ||
                                                                    dataPage.frais == '3' ||
                                                                    dataPage.pourboire == '1'
                                                                    "/>

                                                            <!-- Recapitulatif Montant à payer, pourboire, frais -->
                                                            <div class="mt-1" v-if="dataPage.merchant !== '25010'">
                                                                <!-- Fees -->
                                                                <div class="container d-flex justify-content-between mt-2 mb-3"
                                                                     :style="dataPage.frais == '1' ||
                                                                        dataPage.frais == '2' ||
                                                                        dataPage.frais == '3' ||
                                                                        dataPage.pourboire == '1'
                                                                        ? 'padding: 0px 30px 0px 30px; top: -20px; position: relative;'
                                                                        : ''
                                                                        " v-if="dataPage.frais == '1' ||
        dataPage.frais == '2' ||
        dataPage.frais == '3'
        ">
                                                                    <div>
                                                                        <h6 v-if="dataPage.frais == '1'">
                                                                            {{ $t("text_fees_payment.value") }}
                                                                        </h6>
                                                                        <h6 v-if="dataPage.frais == '2' ||
                                                                            dataPage.frais == '3'
                                                                            ">
                                                                            {{ $t("text_fees_forced_payment.value") }}
                                                                        </h6>
                                                                    </div>
                                                                    <label class="ui-switch switch-solid"
                                                                           v-if="dataPage.frais == '1'">
                                                                        <input type="checkbox"
                                                                               :checked="checkedFeesMoov"
                                                                               @click="tauxMontantMoov"/>
                                                                        <span style="left: 20px"></span>
                                                                    </label>
                                                                    <div class="ui-switch switch-solid" v-if="dataPage.frais == '2' ||
                                                                        dataPage.frais == '3'
                                                                        ">
                                                                        <input type="checkbox"
                                                                               :checked="checkedFeesMoov"
                                                                               @click="tauxMontantMoov"/>
                                                                        <span style="left: 20px"></span>
                                                                    </div>
                                                                </div>
                                                                <div class="container d-flex justify-content-between"
                                                                     :style="dataPage.frais == '1' ||
                                                                        dataPage.frais == '2' ||
                                                                        dataPage.frais == '3' ||
                                                                        dataPage.pourboire == '1'
                                                                        ? 'padding: 0px 30px 0px 30px; top: -20px; position: relative;'
                                                                        : ''
                                                                        " v-if="(dataPage.frais == '1' ||
        dataPage.frais == '2' ||
        dataPage.frais == '3') &&
        checkedFeesMoov
        ">
                                                                    <div>
                                                                        <span class="font-12">{{
                                                                                $t("text_fees.value")
                                                                            }}</span>
                                                                    </div>
                                                                    <div>
                                                                        <span class="font-12">
                                                                            {{
                                                                                dataPage.frais == "3"
                                                                                        ? new Intl.NumberFormat("fr-FR", {
                                                                                            style: "currency",
                                                                                            currency: "XOF",
                                                                                        }).format(
                                                                                                montantSave
                                                                                                        ? parseFloat(montantSave) *
                                                                                                        (parseFloat(
                                                                                                                        dataPage.taux_moov / 2
                                                                                                                ) /
                                                                                                                100)
                                                                                                        : 0
                                                                                        )
                                                                                        : new Intl.NumberFormat("fr-FR", {
                                                                                            style: "currency",
                                                                                            currency: "XOF",
                                                                                        }).format(
                                                                                                montantSave
                                                                                                        ? parseFloat(montantSave) *
                                                                                                        (parseFloat(
                                                                                                                        dataPage.taux_moov
                                                                                                                ) /
                                                                                                                100)
                                                                                                        : 0
                                                                                        )
                                                                            }}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <!-- Fees -->

                                                                <!-- Pourboire -->
                                                                <!-- Type formulaire normal -->
                                                                <div v-if="typeForm == 'normal'">
                                                                    <div class="container d-flex justify-content-between"
                                                                         :style="dataPage.frais == '1' ||
                                                                            dataPage.frais == '2' ||
                                                                            dataPage.frais == '3' ||
                                                                            dataPage.pourboire == '1'
                                                                            ? 'padding: 0px 30px 0px 30px; top: -20px; position: relative;'
                                                                            : ''
                                                                            " v-if="dataPage.pourboire == '1' &&
        pourboire &&
        (pourboire == 'autre'
            ? parseFloat(
                dataFormulaire.amountPourboire
            ) > 0
            : parseFloat(pourboire) > 0)
        ">
                                                                        <div>
                                                                            <span class="font-12">
                                                                                {{ $t("text_pourboire.value") }}
                                                                            </span>
                                                                        </div>
                                                                        <div>
                                                                            <span class="font-12">
                                                                                {{
                                                                                    new Intl.NumberFormat("fr-FR", {
                                                                                        style: "currency",
                                                                                        currency: "XOF",
                                                                                    }).format(
                                                                                            pourboire == "autre"
                                                                                                    ? dataFormulaire.amountPourboire
                                                                                                            ? parseFloat(
                                                                                                                    dataFormulaire.amountPourboire
                                                                                                            )
                                                                                                            : 0
                                                                                                    : parseFloat(pourboire)
                                                                                    )
                                                                                }}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <!-- Type formulaire paie vite -->
                                                                <div v-if="typeForm == 'quickly'">
                                                                    <div class="container d-flex justify-content-between"
                                                                         :style="dataPage.frais == '1' ||
                                                                            dataPage.frais == '2' ||
                                                                            dataPage.frais == '3' ||
                                                                            dataPage.pourboire == '1'
                                                                            ? 'padding: 0px 30px 0px 30px; top: -20px; position: relative;'
                                                                            : ''
                                                                            " v-if="dataPage.pourboire == '1' &&
        pourboire &&
        (pourboire == 'autre'
            ? parseFloat(
                dataFormulaireQuick.amountPourboire
            ) > 0
            : parseFloat(pourboire) > 0)
        ">
                                                                        <div>
                                                                            <span class="font-12">
                                                                                {{ $t("text_pourboire.value") }}
                                                                            </span>
                                                                        </div>
                                                                        <div>
                                                                            <span class="font-12">
                                                                                {{
                                                                                    new Intl.NumberFormat("fr-FR", {
                                                                                        style: "currency",
                                                                                        currency: "XOF",
                                                                                    }).format(
                                                                                            pourboire == "autre"
                                                                                                    ? dataFormulaireQuick.amountPourboire
                                                                                                            ? parseFloat(
                                                                                                                    dataFormulaireQuick.amountPourboire
                                                                                                            )
                                                                                                            : 0
                                                                                                    : parseFloat(pourboire)
                                                                                    )
                                                                                }}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <!-- Pourboire -->

                                                                <!-- Montant à payer -->
                                                                <div class="container d-flex justify-content-between"
                                                                     :style="dataPage.frais == '1' ||
                                                                        dataPage.frais == '2' ||
                                                                        dataPage.frais == '3' ||
                                                                        dataPage.pourboire == '1'
                                                                        ? 'padding: 0px 30px 0px 30px; top: -20px; position: relative;'
                                                                        : ''
                                                                        " v-if="montantAPayer &&
            (dataPage.frais == '1' ||
                dataPage.frais == '2' ||
                dataPage.frais == '3' ||
                dataPage.pourboire == '1')
            ">
                                                                    <div>
                                                                        <span class="font-12">{{
                                                                                $t("text_amount_to_pay.value")
                                                                            }}</span>
                                                                    </div>

                                                                    <div>
                                                                        <span class="font-12">
                                                                            {{
                                                                                new Intl.NumberFormat("fr-FR", {
                                                                                    style: "currency",
                                                                                    currency: "XOF",
                                                                                }).format(parseFloat(montantAPayer))
                                                                            }}
                                                                        </span>
                                                                    </div>
                                                                </div>

                                                                <!-- Montant total -->
                                                                <div class="container d-flex justify-content-between mb-3"
                                                                     :style="dataPage.frais == '1' ||
                                                                        dataPage.frais == '2' ||
                                                                        dataPage.frais == '3' ||
                                                                        dataPage.pourboire == '1'
                                                                        ? 'padding: 0px 30px 0px 30px; top: -20px; position: relative;'
                                                                        : ''
                                                                        " v-if="typeForm == 'quickly' &&
            (dataPage.frais == '1' ||
                dataPage.frais == '2' ||
                dataPage.frais == '3' ||
                dataPage.pourboire == '1')
            ">
                                                                    <div>
                                                                        <span
                                                                                style="font-weight: 800; font-size: 22px">TOTAL</span>
                                                                    </div>
                                                                    <div>
                                                                        <span style="font-weight: 800; font-size: 22px"
                                                                              v-if="dataFormulaireQuick.montant">
                                                                            {{
                                                                                new Intl.NumberFormat("fr-FR", {
                                                                                    style: "currency",
                                                                                    currency: "XOF",
                                                                                }).format(
                                                                                        parseFloat(
                                                                                                dataFormulaireQuick.montant
                                                                                        )
                                                                                )
                                                                            }}
                                                                        </span>
                                                                        <span style="font-weight: 800; font-size: 22px"
                                                                              v-if="!dataFormulaireQuick.montant">
                                                                            {{
                                                                                new Intl.NumberFormat("fr-FR", {
                                                                                    style: "currency",
                                                                                    currency: "XOF",
                                                                                }).format(0)
                                                                            }}
                                                                        </span>
                                                                    </div>
                                                                </div>

                                                                <div class="container d-flex justify-content-between mb-3"
                                                                     :style="dataPage.frais == '1' ||
                                                                        dataPage.frais == '2' ||
                                                                        dataPage.frais == '3' ||
                                                                        dataPage.pourboire == '1'
                                                                        ? 'padding: 0px 30px 0px 30px; top: -20px; position: relative;'
                                                                        : ''
                                                                        " v-if="typeForm == 'normal' &&
            (dataPage.frais == '1' ||
                dataPage.frais == '2' ||
                dataPage.frais == '3' ||
                dataPage.pourboire == '1')
            ">
                                                                    <div>
                                                                        <span
                                                                                style="font-weight: 800; font-size: 22px">TOTAL</span>
                                                                    </div>
                                                                    <div>
                                                                        <span style="font-weight: 800; font-size: 22px"
                                                                              v-if="dataFormulaire.montant">
                                                                            {{
                                                                                new Intl.NumberFormat("fr-FR", {
                                                                                    style: "currency",
                                                                                    currency: "XOF",
                                                                                }).format(
                                                                                        parseFloat(dataFormulaire.montant)
                                                                                )
                                                                            }}
                                                                        </span>
                                                                        <span style="font-weight: 800; font-size: 22px"
                                                                              v-if="!dataFormulaire.montant">
                                                                            {{
                                                                                new Intl.NumberFormat("fr-FR", {
                                                                                    style: "currency",
                                                                                    currency: "XOF",
                                                                                }).format(0)
                                                                            }}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <!-- Montant total -->
                                                            </div>
                                                            <!-- Recapitulatif Montant à payer, pourboire, frais -->

                                                            <div :style="dataPage.frais == '1' ||
                                                                        dataPage.frais == '2' ||
                                                                        dataPage.frais == '3' ||
                                                                        dataPage.pourboire == '1'
                                                                        ? 'padding: 5px 25px 15px 25px; top: -20px; position: relative;'
                                                                        : ''
                                                                    ">
                                                                <button type="button" @click="sendMoov" :class="!dataMoov.numero
                                                                    ? 'btn btn-dark btn-disabled-custom btn-block'
                                                                    : 'btn btn-dark btn-block'
                                                                    " :disabled="!dataMoov.numero">
                                                                    {{ $t("payment_modal.moov.button.value") }}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="visa-qr-container__header">
                                                        <p class="instruction mt-3">
                                                            {{ $t("payment_modal.moov.text.value") }}
                                                        </p>

                                                        <div class="displayerror__details mt-3" v-if="moovNOkay">
                                                            <div>
                                                                <p class="paiement-failed">
                                                                    {{
                                                                        $t(
                                                                                "payment_modal.moov.payment_failure_title.value"
                                                                        )
                                                                    }}
                                                                </p>
                                                                <div class="error-information">
                                                                    {{
                                                                        $t(
                                                                                "payment_modal.moov.payment_failure_details.value"
                                                                        )
                                                                    }}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div v-if="moovOkay" class="displayerror__details mt-3 mb-2"
                                                             style="padding: 10px 5px 2px 5px">
                                                            <!-- checkingFloozStatus() -->

                                                            <div class="qr__code mt-2 pt-2" v-if="showVerifMoov">
                                                                <i class="fas fa-spinner fa-spin"
                                                                   style="font-size: 20px"></i>
                                                            </div>
                                                            <div class="mt-2 pt-2"
                                                                 v-if="!showVerifMoov && !showInProcessMoov">
                                                                <p class="paiement-failed" style="font-weight: 600">
                                                                    {{
                                                                        $t(
                                                                                "payment_modal.moov.payment_waiting_1.value"
                                                                        )
                                                                    }}
                                                                </p>
                                                            </div>
                                                            <span class="timer"
                                                                  v-if="!showVerifMoov && !showInProcessMoov">
                                                                {{ formattedTimeLeft }} min
                                                            </span>
                                                            <div class="mt-2"
                                                                 v-if="!showVerifMoov && !showInProcessMoov">
                                                                <p class="paiement-failed" style="font-weight: 600">
                                                                    {{
                                                                        $t(
                                                                                "payment_modal.moov.payment_waiting_2.value"
                                                                        )
                                                                    }}
                                                                </p>
                                                            </div>

                                                            <p class="paiement-failed" style="font-weight: 600"
                                                               v-if="!showVerifMoov && !showInProcessMoov">
                                                                {{
                                                                    $t(
                                                                            "payment_modal.moov.ou_check_yourself.value"
                                                                    )
                                                                }}
                                                            </p>

                                                            <button type="button" @click="checkingFloozStatus"
                                                                    class="btn btn-dark mb-3"
                                                                    v-if="!showVerifMoov && !showInProcessMoov">
                                                                {{
                                                                    $t("payment_modal.moov.check_status.value")
                                                                }}
                                                            </button>

                                                            <div class="mt-2" v-if="showVerifMoov">
                                                                <p class="paiement-failed"
                                                                   style="font-weight: 600; color: #261b64">
                                                                    {{
                                                                        $t(
                                                                                "payment_modal.moov.payment_pending.value"
                                                                        )
                                                                    }}
                                                                </p>
                                                            </div>

                                                            <div class="qr__code mt-2 pt-2" v-if="showInProcessMoov">
                                                                <i class="fas fa-spinner fa-spin"
                                                                   style="font-size: 20px"></i>
                                                            </div>

                                                            <div class="mt-2" v-if="showInProcessMoov">
                                                                <p class="paiement-failed"
                                                                   style="font-weight: 600; color: #261b64">
                                                                    {{
                                                                        $t(
                                                                                "payment_modal.moov.payment_waiting_3.value"
                                                                        )
                                                                    }}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                                <!-- Moov Money -->

                                <!-- Tresor PAY -->
                                <div v-if="menuSelected == 'Tresor PAY'">
                                    <div class="checkout__stage">
                                        <section>
                                            <div class="visa-qr-container">
                                                <div>
                                                    <div class="mt-5">
                                                        <loading :active.sync="isLoadingTresorPay" :can-cancel="false"
                                                                 :on-cancel="onCancelTresorPay"
                                                                 :is-full-page="fullPageTresorPay"
                                                                 loader="bars" :opacity="1"></loading>
                                                    </div>

                                                    <div class="qr__wrapper container">
                                                        <div class="element-pair mt-2 mb-3">
                                                            <div class="element-pair-container">
                                                                <div class="element-wrap element-wrap-on-left">
                                                                    <multiselect v-model="indiceMobileMoney"
                                                                                 :options="options" :multiple="false"
                                                                                 placeholder="Indicatif"
                                                                                 tag-placeholder="Indicatif"
                                                                                 track-by="code" label="country"
                                                                                 :searchable="true"
                                                                                 disabled>
                                                                        <template slot="singleLabel"
                                                                                  slot-scope="{ option }">{{
                                                                                option.country_code
                                                                            }}
                                                                        </template>
                                                                        <span slot="noResult"></span>
                                                                    </multiselect>
                                                                </div>
                                                                <div class="element-wrap element-wrap-on-right">
                                                                    <input type="tel" v-on:keypress="numbersOnly"
                                                                           :placeholder="$t('payment_modal.tresor.placeholder.value')
                                                                                " class="element-is-input"
                                                                           v-model="dataTresorPAY.numero"
                                                                           v-mask="'## ## ## ## ##'"/>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="element-wrap element-wrap-for-submit" :style="dataPage.frais == '1' ||
                                                            dataPage.frais == '2' ||
                                                            dataPage.frais == '3' ||
                                                            dataPage.pourboire == '1'
                                                            ? 'background: #f5f5f5; margin: 40px -30px 0px;'
                                                            : ''
                                                            ">
                                                            <img src="../assets/img/trait-recap.png"
                                                                 alt="Illustration zigzag recap"
                                                                 style="position: relative; top: -10px" v-if="dataPage.frais == '1' ||
                                                                    dataPage.frais == '2' ||
                                                                    dataPage.frais == '3' ||
                                                                    dataPage.pourboire == '1'
                                                                    "/>

                                                            <!-- Recapitulatif Montant à payer, pourboire, frais -->
                                                            <div class="mt-1" v-if="dataPage.merchant !== '25010'">
                                                                <!-- Fees -->
                                                                <div class="container d-flex justify-content-between mt-2 mb-3"
                                                                     :style="dataPage.frais == '1' ||
                                                                        dataPage.frais == '2' ||
                                                                        dataPage.frais == '3' ||
                                                                        dataPage.pourboire == '1'
                                                                        ? 'padding: 0px 30px 0px 30px; top: -20px; position: relative;'
                                                                        : ''
                                                                        " v-if="dataPage.frais == '1' ||
        dataPage.frais == '2' ||
        dataPage.frais == '3'
        ">
                                                                    <div>
                                                                        <h6 v-if="dataPage.frais == '1'">
                                                                            {{ $t("text_fees_payment.value") }}
                                                                        </h6>
                                                                        <h6 v-if="dataPage.frais == '2' ||
                                                                            dataPage.frais == '3'
                                                                            ">
                                                                            {{ $t("text_fees_forced_payment.value") }}
                                                                        </h6>
                                                                    </div>
                                                                    <label class="ui-switch switch-solid"
                                                                           v-if="dataPage.frais == '1'">
                                                                        <input type="checkbox"
                                                                               :checked="checkedFeesTresorPAY"
                                                                               @click="tauxMontantTresor"/>
                                                                        <span style="left: 20px"></span>
                                                                    </label>
                                                                    <div class="ui-switch switch-solid" v-if="dataPage.frais == '2' ||
                                                                        dataPage.frais == '3'
                                                                        ">
                                                                        <input type="checkbox"
                                                                               :checked="checkedFeesTresorPAY"
                                                                               @click="tauxMontantTresor"/>
                                                                        <span style="left: 20px"></span>
                                                                    </div>
                                                                </div>
                                                                <div class="container d-flex justify-content-between"
                                                                     :style="dataPage.frais == '1' ||
                                                                        dataPage.frais == '2' ||
                                                                        dataPage.frais == '3' ||
                                                                        dataPage.pourboire == '1'
                                                                        ? 'padding: 0px 30px 0px 30px; top: -20px; position: relative;'
                                                                        : ''
                                                                        " v-if="(dataPage.frais == '1' ||
        dataPage.frais == '2' ||
        dataPage.frais == '3') &&
        checkedFeesTresorPAY
        ">
                                                                    <div>
                                                                        <span class="font-12">{{
                                                                                $t("text_fees.value")
                                                                            }}</span>
                                                                    </div>
                                                                    <div>
                                                                        <span class="font-12">
                                                                            {{
                                                                                dataPage.frais == "3"
                                                                                        ? new Intl.NumberFormat("fr-FR", {
                                                                                            style: "currency",
                                                                                            currency: "XOF",
                                                                                        }).format(
                                                                                                montantSave
                                                                                                        ? parseFloat(montantSave) *
                                                                                                        (parseFloat(
                                                                                                                        dataPage.taux_tresor / 2
                                                                                                                ) /
                                                                                                                100)
                                                                                                        : 0
                                                                                        )
                                                                                        : new Intl.NumberFormat("fr-FR", {
                                                                                            style: "currency",
                                                                                            currency: "XOF",
                                                                                        }).format(
                                                                                                montantSave
                                                                                                        ? parseFloat(montantSave) *
                                                                                                        (parseFloat(
                                                                                                                        dataPage.taux_tresor
                                                                                                                ) /
                                                                                                                100)
                                                                                                        : 0
                                                                                        )
                                                                            }}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <!-- Fees -->

                                                                <!-- Pourboire -->
                                                                <!-- Type formulaire normal -->
                                                                <div v-if="typeForm == 'normal'">
                                                                    <div class="container d-flex justify-content-between"
                                                                         :style="dataPage.frais == '1' ||
                                                                            dataPage.frais == '2' ||
                                                                            dataPage.frais == '3' ||
                                                                            dataPage.pourboire == '1'
                                                                            ? 'padding: 0px 30px 0px 30px; top: -20px; position: relative;'
                                                                            : ''
                                                                            " v-if="dataPage.pourboire == '1' &&
        pourboire &&
        (pourboire == 'autre'
            ? parseFloat(
                dataFormulaire.amountPourboire
            ) > 0
            : parseFloat(pourboire) > 0)
        ">
                                                                        <div>
                                                                            <span class="font-12">
                                                                                {{ $t("text_pourboire.value") }}
                                                                            </span>
                                                                        </div>
                                                                        <div>
                                                                            <span class="font-12">
                                                                                {{
                                                                                    new Intl.NumberFormat("fr-FR", {
                                                                                        style: "currency",
                                                                                        currency: "XOF",
                                                                                    }).format(
                                                                                            pourboire == "autre"
                                                                                                    ? dataFormulaire.amountPourboire
                                                                                                            ? parseFloat(
                                                                                                                    dataFormulaire.amountPourboire
                                                                                                            )
                                                                                                            : 0
                                                                                                    : parseFloat(pourboire)
                                                                                    )
                                                                                }}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <!-- Type formulaire paie vite -->
                                                                <div v-if="typeForm == 'quickly'">
                                                                    <div class="container d-flex justify-content-between"
                                                                         :style="dataPage.frais == '1' ||
                                                                            dataPage.frais == '2' ||
                                                                            dataPage.frais == '3' ||
                                                                            dataPage.pourboire == '1'
                                                                            ? 'padding: 0px 30px 0px 30px; top: -20px; position: relative;'
                                                                            : ''
                                                                            " v-if="dataPage.pourboire == '1' &&
        pourboire &&
        (pourboire == 'autre'
            ? parseFloat(
                dataFormulaireQuick.amountPourboire
            ) > 0
            : parseFloat(pourboire) > 0)
        ">
                                                                        <div>
                                                                            <span class="font-12">
                                                                                {{ $t("text_pourboire.value") }}
                                                                            </span>
                                                                        </div>
                                                                        <div>
                                                                            <span class="font-12">
                                                                                {{
                                                                                    new Intl.NumberFormat("fr-FR", {
                                                                                        style: "currency",
                                                                                        currency: "XOF",
                                                                                    }).format(
                                                                                            pourboire == "autre"
                                                                                                    ? dataFormulaireQuick.amountPourboire
                                                                                                            ? parseFloat(
                                                                                                                    dataFormulaireQuick.amountPourboire
                                                                                                            )
                                                                                                            : 0
                                                                                                    : parseFloat(pourboire)
                                                                                    )
                                                                                }}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <!-- Pourboire -->

                                                                <!-- Montant à payer -->
                                                                <div class="container d-flex justify-content-between"
                                                                     :style="dataPage.frais == '1' ||
                                                                        dataPage.frais == '2' ||
                                                                        dataPage.frais == '3' ||
                                                                        dataPage.pourboire == '1'
                                                                        ? 'padding: 0px 30px 0px 30px; top: -20px; position: relative;'
                                                                        : ''
                                                                        " v-if="montantAPayer &&
            (dataPage.frais == '1' ||
                dataPage.frais == '2' ||
                dataPage.frais == '3' ||
                dataPage.pourboire == '1')
            ">
                                                                    <div>
                                                                        <span class="font-12">{{
                                                                                $t("text_amount_to_pay.value")
                                                                            }}</span>
                                                                    </div>

                                                                    <div>
                                                                        <span class="font-12">
                                                                            {{
                                                                                new Intl.NumberFormat("fr-FR", {
                                                                                    style: "currency",
                                                                                    currency: "XOF",
                                                                                }).format(parseFloat(montantAPayer))
                                                                            }}
                                                                        </span>
                                                                    </div>
                                                                </div>

                                                                <!-- Montant total -->
                                                                <div class="container d-flex justify-content-between mb-3"
                                                                     :style="dataPage.frais == '1' ||
                                                                        dataPage.frais == '2' ||
                                                                        dataPage.frais == '3' ||
                                                                        dataPage.pourboire == '1'
                                                                        ? 'padding: 0px 30px 0px 30px; top: -20px; position: relative;'
                                                                        : ''
                                                                        " v-if="typeForm == 'quickly' &&
            (dataPage.frais == '1' ||
                dataPage.frais == '2' ||
                dataPage.frais == '3' ||
                dataPage.pourboire == '1')
            ">
                                                                    <div>
                                                                        <span
                                                                                style="font-weight: 800; font-size: 22px">TOTAL</span>
                                                                    </div>
                                                                    <div>
                                                                        <span style="font-weight: 800; font-size: 22px"
                                                                              v-if="dataFormulaireQuick.montant">
                                                                            {{
                                                                                new Intl.NumberFormat("fr-FR", {
                                                                                    style: "currency",
                                                                                    currency: "XOF",
                                                                                }).format(
                                                                                        parseFloat(
                                                                                                dataFormulaireQuick.montant
                                                                                        )
                                                                                )
                                                                            }}
                                                                        </span>
                                                                        <span style="font-weight: 800; font-size: 22px"
                                                                              v-if="!dataFormulaireQuick.montant">
                                                                            {{
                                                                                new Intl.NumberFormat("fr-FR", {
                                                                                    style: "currency",
                                                                                    currency: "XOF",
                                                                                }).format(0)
                                                                            }}
                                                                        </span>
                                                                    </div>
                                                                </div>

                                                                <div class="container d-flex justify-content-between mb-3"
                                                                     :style="dataPage.frais == '1' ||
                                                                        dataPage.frais == '2' ||
                                                                        dataPage.frais == '3' ||
                                                                        dataPage.pourboire == '1'
                                                                        ? 'padding: 0px 30px 0px 30px; top: -20px; position: relative;'
                                                                        : ''
                                                                        " v-if="typeForm == 'normal' &&
            (dataPage.frais == '1' ||
                dataPage.frais == '2' ||
                dataPage.frais == '3' ||
                dataPage.pourboire == '1')
            ">
                                                                    <div>
                                                                        <span
                                                                                style="font-weight: 800; font-size: 22px">TOTAL</span>
                                                                    </div>
                                                                    <div>
                                                                        <span style="font-weight: 800; font-size: 22px"
                                                                              v-if="dataFormulaire.montant">
                                                                            {{
                                                                                new Intl.NumberFormat("fr-FR", {
                                                                                    style: "currency",
                                                                                    currency: "XOF",
                                                                                }).format(
                                                                                        parseFloat(dataFormulaire.montant)
                                                                                )
                                                                            }}
                                                                        </span>
                                                                        <span style="font-weight: 800; font-size: 22px"
                                                                              v-if="!dataFormulaire.montant">
                                                                            {{
                                                                                new Intl.NumberFormat("fr-FR", {
                                                                                    style: "currency",
                                                                                    currency: "XOF",
                                                                                }).format(0)
                                                                            }}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <!-- Montant total -->
                                                            </div>
                                                            <!-- Recapitulatif Montant à payer, pourboire, frais -->

                                                            <div :style="dataPage.frais == '1' ||
                                                                        dataPage.frais == '2' ||
                                                                        dataPage.frais == '3' ||
                                                                        dataPage.pourboire == '1'
                                                                        ? 'padding: 5px 25px 15px 25px; top: -20px; position: relative;'
                                                                        : ''
                                                                    ">
                                                                <button type="button" @click="sendTresorPAY" :class="!dataTresorPAY.numero
                                                                    ? 'btn btn-dark btn-disabled-custom btn-block'
                                                                    : 'btn btn-dark btn-block'
                                                                    " :disabled="!dataTresorPAY.numero">
                                                                    {{ $t("payment_modal.tresor.button.value") }}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="visa-qr-container__header">
                                                        <p class="instruction mt-3">
                                                            {{ $t("payment_modal.tresor.text.value") }}
                                                        </p>

                                                        <div class="displayerror__details mt-3" v-if="tresorNOkay">
                                                            <div>
                                                                <p class="paiement-failed">
                                                                    {{
                                                                        $t(
                                                                                "payment_modal.tresor.payment_failure_title.value"
                                                                        )
                                                                    }}
                                                                </p>
                                                                <div class="error-information">
                                                                    {{
                                                                        payment_failure_details
                                                                    }}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div v-if="tresorOkay === true"
                                                             class="displayerror__details mt-3 mb-2"
                                                             style="padding: 10px 5px 2px 5px">
                                                            <!-- checkingFloozStatus() -->

                                                            <div class="qr__code mt-2 pt-2" v-if="showVerifTresor">
                                                                <i class="fas fa-spinner fa-spin"
                                                                   style="font-size: 20px"></i>
                                                            </div>
                                                            <div class="mt-2 pt-2"
                                                                 v-if="!showVerifTresor && !showInProcessTresor">
                                                                <p class="paiement-failed" style="font-weight: 600">
                                                                    {{
                                                                        $t(
                                                                                "payment_modal.tresor.payment_waiting_1.value"
                                                                        )
                                                                    }}
                                                                </p>
                                                            </div>

                                                            <div class="mt-2" v-if="showVerifTresor">
                                                                <p class="paiement-failed"
                                                                   style="font-weight: 600; color: #261b64">
                                                                    {{
                                                                        $t(
                                                                                "payment_modal.tresor.payment_pending.value"
                                                                        )
                                                                    }}
                                                                </p>
                                                            </div>

                                                            <div class="qr__code mt-2 pt-2" v-if="showInProcessTresor">
                                                                <i class="fas fa-spinner fa-spin"
                                                                   style="font-size: 20px"></i>
                                                            </div>

                                                            <div class="mt-2" v-if="showInProcessTresor">
                                                                <p class="paiement-failed"
                                                                   style="font-weight: 600; color: #261b64">
                                                                    {{
                                                                        $t(
                                                                                "payment_modal.tresor.payment_waiting_3.value"
                                                                        )
                                                                    }}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                                <!-- Tresor PAY -->

                                <!-- APaym USSD -->
                                <div v-if="menuSelected == 'APaym USSD'">
                                    <div class="checkout__stage">
                                        <section>
                                            <div class="visa-qr-container">
                                                <div class="mt-3">
                                                    <loading :active.sync="isLoadingAPaym" :can-cancel="false"
                                                             :on-cancel="onCancelAPaym" :is-full-page="fullPageAPaym"
                                                             loader="bars" :opacity="1"></loading>
                                                </div>
                                                <div>
                                                    <div class="qr__wrapper">
                                                        <div class="element-pair mt-2 mb-3">
                                                            <div class="element-pair-container mt-4">
                                                                <div class="element-wrap element-wrap-on-left">
                                                                    <multiselect v-model="dataApaymPay.indice"
                                                                                 :options="options" :multiple="false"
                                                                                 placeholder="Indicatif"
                                                                                 tag-placeholder="Indicatif"
                                                                                 track-by="code" label="country"
                                                                                 :searchable="true">
                                                                        <template slot="singleLabel"
                                                                                  slot-scope="{ option }">{{
                                                                                option.country_code
                                                                            }}
                                                                        </template>
                                                                        <span slot="noResult"></span>
                                                                    </multiselect>
                                                                </div>
                                                                <div class="element-wrap element-wrap-on-right">
                                                                    <input class="element-is-input" type="tel"
                                                                           v-on:keypress="numbersOnly"
                                                                           v-mask="'## ## ## ## ##'"
                                                                           v-model="dataApaymPay.numApaym"
                                                                           :placeholder="$t(
                                                                                    'payment_modal.ussd_apaym.placeholder.value'
                                                                                )
                                                                                "/>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="element-wrap">
                                                            <input type="tel" v-on:keypress="numbersOnly"
                                                                   class="element-is-input"
                                                                   :disabled="!dataApaymPay.numApaym"
                                                                   v-model="dataApaymPay.codeApaym" :placeholder="$t(
                                                                    'payment_modal.ussd_apaym.placeholder2.value'
                                                                )
                                                                    "/>
                                                        </div>

                                                        <div class="element-wrap element-wrap-for-submit" :style="dataPage.frais == '1' ||
                                                            dataPage.frais == '2' ||
                                                            dataPage.frais == '3' ||
                                                            dataPage.pourboire == '1'
                                                            ? 'background: #f5f5f5; margin: 40px -15px 0px;'
                                                            : ''
                                                            ">
                                                            <img src="../assets/img/trait-recap.png"
                                                                 alt="Illustration zigzag recap"
                                                                 style="position: relative; top: -10px" v-if="dataPage.frais == '1' ||
                                                                    dataPage.frais == '2' ||
                                                                    dataPage.frais == '3' ||
                                                                    dataPage.pourboire == '1'
                                                                    "/>

                                                            <!-- Recapitulatif Montant à payer, pourboire, frais -->
                                                            <div class="mt-1" v-if="dataPage.merchant !== '25010'">
                                                                <!-- Fees -->
                                                                <div class="container d-flex justify-content-between mt-2 mb-3"
                                                                     :style="dataPage.frais == '1' ||
                                                                        dataPage.frais == '2' ||
                                                                        dataPage.frais == '3' ||
                                                                        dataPage.pourboire == '1'
                                                                        ? 'padding: 0px 30px 0px 30px; top: -20px; position: relative;'
                                                                        : ''
                                                                        " v-if="dataPage.frais == '1' ||
        dataPage.frais == '2' ||
        dataPage.frais == '3'
        ">
                                                                    <div>
                                                                        <h6 v-if="dataPage.frais == '1'">
                                                                            {{ $t("text_fees_payment.value") }}
                                                                        </h6>
                                                                        <h6 v-if="dataPage.frais == '2' ||
                                                                            dataPage.frais == '3'
                                                                            ">
                                                                            {{ $t("text_fees_forced_payment.value") }}
                                                                        </h6>
                                                                    </div>
                                                                    <label class="ui-switch switch-solid"
                                                                           v-if="dataPage.frais == '1'">
                                                                        <input type="checkbox"
                                                                               :checked="checkedFeesApaym"
                                                                               @click="tauxMontantApaym"/>
                                                                        <span style="left: 20px"></span>
                                                                    </label>
                                                                    <div class="ui-switch switch-solid" v-if="dataPage.frais == '2' ||
                                                                        dataPage.frais == '3'
                                                                        ">
                                                                        <input type="checkbox"
                                                                               :checked="checkedFeesApaym"
                                                                               @click="tauxMontantApaym"/>
                                                                        <span style="left: 20px"></span>
                                                                    </div>
                                                                </div>
                                                                <div class="container d-flex justify-content-between"
                                                                     :style="dataPage.frais == '1' ||
                                                                        dataPage.frais == '2' ||
                                                                        dataPage.frais == '3' ||
                                                                        dataPage.pourboire == '1'
                                                                        ? 'padding: 0px 30px 0px 30px; top: -20px; position: relative;'
                                                                        : ''
                                                                        " v-if="(dataPage.frais == '1' ||
        dataPage.frais == '2' ||
        dataPage.frais == '3') &&
        checkedFeesApaym
        ">
                                                                    <div>
                                                                        <span class="font-12">{{
                                                                                $t("text_fees.value")
                                                                            }}</span>
                                                                    </div>
                                                                    <div>
                                                                        <span class="font-12">
                                                                            {{
                                                                                dataPage.frais == "3"
                                                                                        ? new Intl.NumberFormat("fr-FR", {
                                                                                            style: "currency",
                                                                                            currency: "XOF",
                                                                                        }).format(
                                                                                                montantSave
                                                                                                        ? parseFloat(montantSave) *
                                                                                                        (parseFloat(
                                                                                                                        dataPage.taux_apaym / 2
                                                                                                                ) /
                                                                                                                100)
                                                                                                        : 0
                                                                                        )
                                                                                        : new Intl.NumberFormat("fr-FR", {
                                                                                            style: "currency",
                                                                                            currency: "XOF",
                                                                                        }).format(
                                                                                                montantSave
                                                                                                        ? parseFloat(montantSave) *
                                                                                                        (parseFloat(
                                                                                                                        dataPage.taux_apaym
                                                                                                                ) /
                                                                                                                100)
                                                                                                        : 0
                                                                                        )
                                                                            }}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <!-- Fees -->

                                                                <!-- Pourboire -->
                                                                <!-- Type formulaire normal -->
                                                                <div v-if="typeForm == 'normal'">
                                                                    <div class="container d-flex justify-content-between"
                                                                         :style="dataPage.frais == '1' ||
                                                                            dataPage.frais == '2' ||
                                                                            dataPage.frais == '3' ||
                                                                            dataPage.pourboire == '1'
                                                                            ? 'padding: 0px 30px 0px 30px; top: -20px; position: relative;'
                                                                            : ''
                                                                            " v-if="dataPage.pourboire == '1' &&
        pourboire &&
        (pourboire == 'autre'
            ? parseFloat(
                dataFormulaire.amountPourboire
            ) > 0
            : parseFloat(pourboire) > 0)
        ">
                                                                        <div>
                                                                            <span class="font-12">
                                                                                {{ $t("text_pourboire.value") }}
                                                                            </span>
                                                                        </div>
                                                                        <div>
                                                                            <span class="font-12">
                                                                                {{
                                                                                    new Intl.NumberFormat("fr-FR", {
                                                                                        style: "currency",
                                                                                        currency: "XOF",
                                                                                    }).format(
                                                                                            pourboire == "autre"
                                                                                                    ? dataFormulaire.amountPourboire
                                                                                                            ? parseFloat(
                                                                                                                    dataFormulaire.amountPourboire
                                                                                                            )
                                                                                                            : 0
                                                                                                    : parseFloat(pourboire)
                                                                                    )
                                                                                }}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <!-- Type formulaire paie vite -->
                                                                <div v-if="typeForm == 'quickly'">
                                                                    <div class="container d-flex justify-content-between"
                                                                         :style="dataPage.frais == '1' ||
                                                                            dataPage.frais == '2' ||
                                                                            dataPage.frais == '3' ||
                                                                            dataPage.pourboire == '1'
                                                                            ? 'padding: 0px 30px 0px 30px; top: -20px; position: relative;'
                                                                            : ''
                                                                            " v-if="dataPage.pourboire == '1' &&
        pourboire &&
        (pourboire == 'autre'
            ? parseFloat(
                dataFormulaireQuick.amountPourboire
            ) > 0
            : parseFloat(pourboire) > 0)
        ">
                                                                        <div>
                                                                            <span class="font-12">
                                                                                {{ $t("text_pourboire.value") }}
                                                                            </span>
                                                                        </div>
                                                                        <div>
                                                                            <span class="font-12">
                                                                                {{
                                                                                    new Intl.NumberFormat("fr-FR", {
                                                                                        style: "currency",
                                                                                        currency: "XOF",
                                                                                    }).format(
                                                                                            pourboire == "autre"
                                                                                                    ? dataFormulaireQuick.amountPourboire
                                                                                                            ? parseFloat(
                                                                                                                    dataFormulaireQuick.amountPourboire
                                                                                                            )
                                                                                                            : 0
                                                                                                    : parseFloat(pourboire)
                                                                                    )
                                                                                }}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <!-- Pourboire -->

                                                                <!-- Montant à payer -->
                                                                <div class="container d-flex justify-content-between"
                                                                     :style="dataPage.frais == '1' ||
                                                                        dataPage.frais == '2' ||
                                                                        dataPage.frais == '3' ||
                                                                        dataPage.pourboire == '1'
                                                                        ? 'padding: 0px 30px 0px 30px; top: -20px; position: relative;'
                                                                        : ''
                                                                        " v-if="montantAPayer &&
            (dataPage.frais == '1' ||
                dataPage.frais == '2' ||
                dataPage.frais == '3' ||
                dataPage.pourboire == '1')
            ">
                                                                    <div>
                                                                        <span class="font-12">{{
                                                                                $t("text_amount_to_pay.value")
                                                                            }}</span>
                                                                    </div>

                                                                    <div>
                                                                        <span class="font-12">
                                                                            {{
                                                                                new Intl.NumberFormat("fr-FR", {
                                                                                    style: "currency",
                                                                                    currency: "XOF",
                                                                                }).format(parseFloat(montantAPayer))
                                                                            }}
                                                                        </span>
                                                                    </div>
                                                                </div>

                                                                <!-- Montant total -->
                                                                <div class="container d-flex justify-content-between mb-3"
                                                                     :style="dataPage.frais == '1' ||
                                                                        dataPage.frais == '2' ||
                                                                        dataPage.frais == '3' ||
                                                                        dataPage.pourboire == '1'
                                                                        ? 'padding: 0px 30px 0px 30px; top: -20px; position: relative;'
                                                                        : ''
                                                                        " v-if="typeForm == 'quickly' &&
            (dataPage.frais == '1' ||
                dataPage.frais == '2' ||
                dataPage.frais == '3' ||
                dataPage.pourboire == '1')
            ">
                                                                    <div>
                                                                        <span
                                                                                style="font-weight: 800; font-size: 22px">TOTAL</span>
                                                                    </div>
                                                                    <div>
                                                                        <span style="font-weight: 800; font-size: 22px"
                                                                              v-if="dataFormulaireQuick.montant">
                                                                            {{
                                                                                new Intl.NumberFormat("fr-FR", {
                                                                                    style: "currency",
                                                                                    currency: "XOF",
                                                                                }).format(
                                                                                        parseFloat(
                                                                                                dataFormulaireQuick.montant
                                                                                        )
                                                                                )
                                                                            }}
                                                                        </span>
                                                                        <span style="font-weight: 800; font-size: 22px"
                                                                              v-if="!dataFormulaireQuick.montant">
                                                                            {{
                                                                                new Intl.NumberFormat("fr-FR", {
                                                                                    style: "currency",
                                                                                    currency: "XOF",
                                                                                }).format(0)
                                                                            }}
                                                                        </span>
                                                                    </div>
                                                                </div>

                                                                <div class="container d-flex justify-content-between mb-3"
                                                                     :style="dataPage.frais == '1' ||
                                                                        dataPage.frais == '2' ||
                                                                        dataPage.frais == '3' ||
                                                                        dataPage.pourboire == '1'
                                                                        ? 'padding: 0px 30px 0px 30px; top: -20px; position: relative;'
                                                                        : ''
                                                                        " v-if="typeForm == 'normal' &&
            (dataPage.frais == '1' ||
                dataPage.frais == '2' ||
                dataPage.frais == '3' ||
                dataPage.pourboire == '1')
            ">
                                                                    <div>
                                                                        <span
                                                                                style="font-weight: 800; font-size: 22px">TOTAL</span>
                                                                    </div>
                                                                    <div>
                                                                        <span style="font-weight: 800; font-size: 22px"
                                                                              v-if="dataFormulaire.montant">
                                                                            {{
                                                                                new Intl.NumberFormat("fr-FR", {
                                                                                    style: "currency",
                                                                                    currency: "XOF",
                                                                                }).format(
                                                                                        parseFloat(dataFormulaire.montant)
                                                                                )
                                                                            }}
                                                                        </span>
                                                                        <span style="font-weight: 800; font-size: 22px"
                                                                              v-if="!dataFormulaire.montant">
                                                                            {{
                                                                                new Intl.NumberFormat("fr-FR", {
                                                                                    style: "currency",
                                                                                    currency: "XOF",
                                                                                }).format(0)
                                                                            }}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <!-- Montant total -->
                                                            </div>
                                                            <!-- Recapitulatif Montant à payer, pourboire, frais -->

                                                            <div :style="dataPage.frais == '1' ||
                                                                        dataPage.frais == '2' ||
                                                                        dataPage.frais == '3' ||
                                                                        dataPage.pourboire == '1'
                                                                        ? 'padding: 5px 25px 15px 25px; top: -20px; position: relative;'
                                                                        : ''
                                                                    ">
                                                                <button type="button" id="pay-btn" @click="sendAPaym"
                                                                        :class="!dataApaymPay.numApaym ||
                                                                        !dataApaymPay.codeApaym
                                                                        ? 'btn btn-dark btn-disabled-custom btn-block'
                                                                        : 'btn btn-dark btn-block'
                                                                        " :disabled="!dataApaymPay.numApaym ||
        !dataApaymPay.codeApaym
        ">
                                                                    {{
                                                                        $t("payment_modal.ussd_apaym.button.value")
                                                                    }}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="visa-qr-container__header">
                                                        <!-- <strong class="mb-2" style="font-size: 22px;">
                                                            *766#
                                                        </strong> -->
                                                        <p class="instruction mt-3">
                                                            {{ $t("payment_modal.ussd_apaym.text.value") }}
                                                            <strong class="custom-text">*766#</strong>
                                                            {{ $t("payment_modal.ussd_apaym.text2.value") }}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                                <!-- APaym USSD -->

                                <!-- APaym -->
                                <div v-if="menuSelected == 'APaym'">
                                    <!-- Content APaym Payment - Listing des cartes visa du profil APaym -->
                                    <div class="checkout__stage">
                                        <section style="width: 100%">
                                            <div class="visa-qr-container">
                                                <div>
                                                    <div class="mt-3">
                                                        <loading :active.sync="isLoadingAPaymAuth" :can-cancel="false"
                                                                 :on-cancel="onCancelAPaymAuth"
                                                                 :is-full-page="fullPageAPaymAuth"
                                                                 loader="bars" :opacity="1"></loading>
                                                    </div>

                                                    <div class="qr__wrapper">
                                                        <div class="element-pair mt-3" v-if="loginApaym">
                                                            <div class="element-pair-container mt-4">
                                                                <div class="element-wrap element-wrap-on-left"
                                                                     style="width: 5px; !important">
                                                                    <multiselect v-model="dataApaymPayAuth.indice"
                                                                                 :options="options" :multiple="false"
                                                                                 placeholder="Indicatif"
                                                                                 tag-placeholder="Indicatif"
                                                                                 track-by="code" label="country"
                                                                                 :searchable="true">
                                                                        <template slot="singleLabel"
                                                                                  slot-scope="{ option }">{{
                                                                                option.country_code
                                                                            }}
                                                                        </template>
                                                                        <span slot="noResult"></span>
                                                                    </multiselect>
                                                                </div>
                                                                <div class="element-wrap element-wrap-on-right">
                                                                    <div style="
                                      display: flex;
                                      flex-direction: row;
                                      align-items: center;
                                    ">
                                                                        <input class="element-is-input" type="tel"
                                                                               v-on:keypress="numbersOnly"
                                                                               v-mask="'## ## ## ## ##'"
                                                                               v-model="dataApaymPayAuth.numApaym"
                                                                               :placeholder="$t(
                                                                                        'payment_modal.apaym.placeholder.value'
                                                                                    )
                                                                                    "/>
                                                                        <!-- <i class="fas fa-arrow-right searchIcon" aria-hidden="true" @click="sendAPaymAuth"></i> -->
                                                                        <button type="button" @click="sendAPaymAuth"
                                                                                :class="!dataApaymPayAuth.numApaym
                                                                            ? 'btn btn-dark btn-disabled-custom searchIcon'
                                                                            : 'btn btn-dark searchIcon'
                                                                            " :disabled="!dataApaymPayAuth.numApaym">
                                                                            <i class="fas fa-arrow-right"
                                                                               aria-hidden="true"></i>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <!-- <div class="container mt-4 d-flex-inline">
                                                                <p
                                                                    class="text-danger mb-2"
                                                                    v-if="this.error !== null"
                                                                >
                                                                    <i class="fas fa-exclamation-circle"></i>
                                                                    {{ this.error }}
                                                                </p>
                                                                <vue-hcaptcha
                                                                    class="h-captcha"
                                                                    @verify="onVerify"
                                                                    @expired="onExpired"
                                                                    @error="onError"
                                                                    sitekey="cc4778d8-c49d-40ad-950c-ab566138bda2"
                                                                ></vue-hcaptcha>
                                                            </div> -->
                                                        </div>

                                                        <div class="element-wrap" v-if="hideCodePay">
                                                            <input type="tel" v-on:keypress="numbersOnly"
                                                                   class="element-is-input"
                                                                   :disabled="!dataApaymPayAuth.numApaym"
                                                                   v-model="dataApaymPayAuth.codeApaym" :placeholder="$t('payment_modal.apaym.placeholder2.value')
                                                                    "/>
                                                        </div>

                                                        <div class="element-wrap element-wrap-for-submit" :style="dataPage.frais == '1' ||
                                                            dataPage.frais == '2' ||
                                                            dataPage.frais == '3' ||
                                                            dataPage.pourboire == '1'
                                                            ? 'background: #f5f5f5; margin: 40px -15px 0px;'
                                                            : ''
                                                            " v-if="hideCodePay">
                                                            <img src="../assets/img/trait-recap.png"
                                                                 alt="Illustration zigzag recap"
                                                                 style="position: relative; top: -10px" v-if="dataPage.frais == '1' ||
                                                                    dataPage.frais == '2' ||
                                                                    dataPage.frais == '3' ||
                                                                    dataPage.pourboire == '1'
                                                                    "/>

                                                            <!-- Recapitulatif Montant à payer, pourboire, frais -->
                                                            <div class="mt-1" v-if="dataPage.merchant !== '25010'">
                                                                <!-- Fees -->
                                                                <div class="container d-flex justify-content-between mt-2 mb-3"
                                                                     :style="dataPage.frais == '1' ||
                                                                        dataPage.frais == '2' ||
                                                                        dataPage.frais == '3' ||
                                                                        dataPage.pourboire == '1'
                                                                        ? 'padding: 0px 30px 0px 30px; top: -20px; position: relative;'
                                                                        : ''
                                                                        " v-if="dataPage.frais == '1' ||
        dataPage.frais == '2' ||
        dataPage.frais == '3'
        ">
                                                                    <div>
                                                                        <h6 v-if="dataPage.frais == '1'">
                                                                            {{ $t("text_fees_payment.value") }}
                                                                        </h6>
                                                                        <h6 v-if="dataPage.frais == '2' ||
                                                                            dataPage.frais == '3'
                                                                            ">
                                                                            {{ $t("text_fees_forced_payment.value") }}
                                                                        </h6>
                                                                    </div>
                                                                    <label class="ui-switch switch-solid"
                                                                           v-if="dataPage.frais == '1'">
                                                                        <input type="checkbox"
                                                                               :checked="checkedFeesApaym"
                                                                               @click="tauxMontantApaym"/>
                                                                        <span style="left: 20px"></span>
                                                                    </label>
                                                                    <div class="ui-switch switch-solid" v-if="dataPage.frais == '2' ||
                                                                        dataPage.frais == '3'
                                                                        ">
                                                                        <input type="checkbox"
                                                                               :checked="checkedFeesApaym"
                                                                               @click="tauxMontantApaym"/>
                                                                        <span style="left: 20px"></span>
                                                                    </div>
                                                                </div>
                                                                <div class="container d-flex justify-content-between"
                                                                     :style="dataPage.frais == '1' ||
                                                                        dataPage.frais == '2' ||
                                                                        dataPage.frais == '3' ||
                                                                        dataPage.pourboire == '1'
                                                                        ? 'padding: 0px 30px 0px 30px; top: -20px; position: relative;'
                                                                        : ''
                                                                        " v-if="(dataPage.frais == '1' ||
        dataPage.frais == '2' ||
        dataPage.frais == '3') &&
        checkedFeesApaym
        ">
                                                                    <div>
                                                                        <span class="font-12">{{
                                                                                $t("text_fees.value")
                                                                            }}</span>
                                                                    </div>
                                                                    <div>
                                                                        <span class="font-12">
                                                                            {{
                                                                                dataPage.frais == "3"
                                                                                        ? new Intl.NumberFormat("fr-FR", {
                                                                                            style: "currency",
                                                                                            currency: "XOF",
                                                                                        }).format(
                                                                                                montantSave
                                                                                                        ? parseFloat(montantSave) *
                                                                                                        (parseFloat(
                                                                                                                        dataPage.taux_apaym / 2
                                                                                                                ) /
                                                                                                                100)
                                                                                                        : 0
                                                                                        )
                                                                                        : new Intl.NumberFormat("fr-FR", {
                                                                                            style: "currency",
                                                                                            currency: "XOF",
                                                                                        }).format(
                                                                                                montantSave
                                                                                                        ? parseFloat(montantSave) *
                                                                                                        (parseFloat(
                                                                                                                        dataPage.taux_apaym
                                                                                                                ) /
                                                                                                                100)
                                                                                                        : 0
                                                                                        )
                                                                            }}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <!-- Fees -->

                                                                <!-- Pourboire -->
                                                                <!-- Type formulaire normal -->
                                                                <div v-if="typeForm == 'normal'">
                                                                    <div class="container d-flex justify-content-between"
                                                                         :style="dataPage.frais == '1' ||
                                                                            dataPage.frais == '2' ||
                                                                            dataPage.frais == '3' ||
                                                                            dataPage.pourboire == '1'
                                                                            ? 'padding: 0px 30px 0px 30px; top: -20px; position: relative;'
                                                                            : ''
                                                                            " v-if="dataPage.pourboire == '1' &&
        pourboire &&
        (pourboire == 'autre'
            ? parseFloat(
                dataFormulaire.amountPourboire
            ) > 0
            : parseFloat(pourboire) > 0)
        ">
                                                                        <div>
                                                                            <span class="font-12">
                                                                                {{ $t("text_pourboire.value") }}
                                                                            </span>
                                                                        </div>
                                                                        <div>
                                                                            <span class="font-12">
                                                                                {{
                                                                                    new Intl.NumberFormat("fr-FR", {
                                                                                        style: "currency",
                                                                                        currency: "XOF",
                                                                                    }).format(
                                                                                            pourboire == "autre"
                                                                                                    ? dataFormulaire.amountPourboire
                                                                                                            ? parseFloat(
                                                                                                                    dataFormulaire.amountPourboire
                                                                                                            )
                                                                                                            : 0
                                                                                                    : parseFloat(pourboire)
                                                                                    )
                                                                                }}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <!-- Type formulaire paie vite -->
                                                                <div v-if="typeForm == 'quickly'">
                                                                    <div class="container d-flex justify-content-between"
                                                                         :style="dataPage.frais == '1' ||
                                                                            dataPage.frais == '2' ||
                                                                            dataPage.frais == '3' ||
                                                                            dataPage.pourboire == '1'
                                                                            ? 'padding: 0px 30px 0px 30px; top: -20px; position: relative;'
                                                                            : ''
                                                                            " v-if="dataPage.pourboire == '1' &&
        pourboire &&
        (pourboire == 'autre'
            ? parseFloat(
                dataFormulaireQuick.amountPourboire
            ) > 0
            : parseFloat(pourboire) > 0)
        ">
                                                                        <div>
                                                                            <span class="font-12">
                                                                                {{ $t("text_pourboire.value") }}
                                                                            </span>
                                                                        </div>
                                                                        <div>
                                                                            <span class="font-12">
                                                                                {{
                                                                                    new Intl.NumberFormat("fr-FR", {
                                                                                        style: "currency",
                                                                                        currency: "XOF",
                                                                                    }).format(
                                                                                            pourboire == "autre"
                                                                                                    ? dataFormulaireQuick.amountPourboire
                                                                                                            ? parseFloat(
                                                                                                                    dataFormulaireQuick.amountPourboire
                                                                                                            )
                                                                                                            : 0
                                                                                                    : parseFloat(pourboire)
                                                                                    )
                                                                                }}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <!-- Pourboire -->

                                                                <!-- Montant à payer -->
                                                                <div class="container d-flex justify-content-between"
                                                                     :style="dataPage.frais == '1' ||
                                                                        dataPage.frais == '2' ||
                                                                        dataPage.frais == '3' ||
                                                                        dataPage.pourboire == '1'
                                                                        ? 'padding: 0px 30px 0px 30px; top: -20px; position: relative;'
                                                                        : ''
                                                                        " v-if="montantAPayer &&
            (dataPage.frais == '1' ||
                dataPage.frais == '2' ||
                dataPage.frais == '3' ||
                dataPage.pourboire == '1')
            ">
                                                                    <div>
                                                                        <span class="font-12">{{
                                                                                $t("text_amount_to_pay.value")
                                                                            }}</span>
                                                                    </div>

                                                                    <div>
                                                                        <span class="font-12">
                                                                            {{
                                                                                new Intl.NumberFormat("fr-FR", {
                                                                                    style: "currency",
                                                                                    currency: "XOF",
                                                                                }).format(parseFloat(montantAPayer))
                                                                            }}
                                                                        </span>
                                                                    </div>
                                                                </div>

                                                                <!-- Montant total -->
                                                                <div class="container d-flex justify-content-between mb-3"
                                                                     :style="dataPage.frais == '1' ||
                                                                        dataPage.frais == '2' ||
                                                                        dataPage.frais == '3' ||
                                                                        dataPage.pourboire == '1'
                                                                        ? 'padding: 0px 30px 0px 30px; top: -20px; position: relative;'
                                                                        : ''
                                                                        " v-if="typeForm == 'quickly' &&
            (dataPage.frais == '1' ||
                dataPage.frais == '2' ||
                dataPage.frais == '3' ||
                dataPage.pourboire == '1')
            ">
                                                                    <div>
                                                                        <span
                                                                                style="font-weight: 800; font-size: 22px">TOTAL</span>
                                                                    </div>
                                                                    <div>
                                                                        <span style="font-weight: 800; font-size: 22px"
                                                                              v-if="dataFormulaireQuick.montant">
                                                                            {{
                                                                                new Intl.NumberFormat("fr-FR", {
                                                                                    style: "currency",
                                                                                    currency: "XOF",
                                                                                }).format(
                                                                                        parseFloat(
                                                                                                dataFormulaireQuick.montant
                                                                                        )
                                                                                )
                                                                            }}
                                                                        </span>
                                                                        <span style="font-weight: 800; font-size: 22px"
                                                                              v-if="!dataFormulaireQuick.montant">
                                                                            {{
                                                                                new Intl.NumberFormat("fr-FR", {
                                                                                    style: "currency",
                                                                                    currency: "XOF",
                                                                                }).format(0)
                                                                            }}
                                                                        </span>
                                                                    </div>
                                                                </div>

                                                                <div class="container d-flex justify-content-between mb-3"
                                                                     :style="dataPage.frais == '1' ||
                                                                        dataPage.frais == '2' ||
                                                                        dataPage.frais == '3' ||
                                                                        dataPage.pourboire == '1'
                                                                        ? 'padding: 0px 30px 0px 30px; top: -20px; position: relative;'
                                                                        : ''
                                                                        " v-if="typeForm == 'normal' &&
            (dataPage.frais == '1' ||
                dataPage.frais == '2' ||
                dataPage.frais == '3' ||
                dataPage.pourboire == '1')
            ">
                                                                    <div>
                                                                        <span
                                                                                style="font-weight: 800; font-size: 22px">TOTAL</span>
                                                                    </div>
                                                                    <div>
                                                                        <span style="font-weight: 800; font-size: 22px"
                                                                              v-if="dataFormulaire.montant">
                                                                            {{
                                                                                new Intl.NumberFormat("fr-FR", {
                                                                                    style: "currency",
                                                                                    currency: "XOF",
                                                                                }).format(
                                                                                        parseFloat(dataFormulaire.montant)
                                                                                )
                                                                            }}
                                                                        </span>
                                                                        <span style="font-weight: 800; font-size: 22px"
                                                                              v-if="!dataFormulaire.montant">
                                                                            {{
                                                                                new Intl.NumberFormat("fr-FR", {
                                                                                    style: "currency",
                                                                                    currency: "XOF",
                                                                                }).format(0)
                                                                            }}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <!-- Montant total -->
                                                            </div>
                                                            <!-- Recapitulatif Montant à payer, pourboire, frais -->

                                                            <div :style="dataPage.frais == '1' ||
                                                                        dataPage.frais == '2' ||
                                                                        dataPage.frais == '3' ||
                                                                        dataPage.pourboire == '1'
                                                                        ? 'padding: 5px 25px 15px 25px; top: -20px; position: relative;'
                                                                        : ''
                                                                    ">
                                                                <button type="button" @click="sendAPaymToken"
                                                                        :disabled="!dataApaymPayAuth.numApaym ||
                                                                    !dataApaymPayAuth.codeApaym
                                                                    " :class="!dataApaymPayAuth.numApaym ||
        !dataApaymPayAuth.codeApaym
        ? 'btn btn-dark btn-disabled-custom btn-block'
        : 'btn btn-dark btn-block'
        ">
                                                                    {{ $t("payment_modal.apaym.button.value") }}
                                                                </button>
                                                            </div>
                                                        </div>

                                                        <!-- Liste des cartes -->
                                                        <div v-if="showCard == true">
                                                            <ul class="list-unstyled media-list-divider text-left"
                                                                :style="cardsLength > 5
                                                                ? 'height: 392px; overflow-y: scroll;'
                                                                : 'height: auto;'
                                                                ">
                                                                <li class="media py-3" v-for="(carte, i) in cards"
                                                                    :key="i"
                                                                    style="cursor: pointer"
                                                                    @click="payWithApaym(carte)">
                                                                    <img :src="require(`../assets/img/cards/${carte.imgCard}`)
                                                                        " alt="Card image" class="mr-3" width="60"/>
                                                                    <div class="media-body flexbox" style="
                                      flex-direction: row;
                                      align-items: center;
                                    ">
                                                                        <div>
                                                                            <h6 class="mt-0 mb-1 font-weight-bold">
                                                                                <a href="javascript:void(0);">{{
                                                                                        carte.nickname
                                                                                    }}</a>
                                                                            </h6>
                                                                            <span class="font-12">
                                                                                Visa **** {{ carte.last_4_digit }}
                                                                            </span>
                                                                        </div>

                                                                        <a href="javascript:void(0)"
                                                                           class="btn btn-light btn-sm btn-rounded"
                                                                           style="line-height: 1.3" v-if="carte.favori_card == true &&
                                                                                $i18n.locale == 'fr'
                                                                                ">
                                                                            Par défaut
                                                                        </a>

                                                                        <a href="javascript:void(0)"
                                                                           class="btn btn-light btn-sm btn-rounded"
                                                                           style="line-height: 1.3" v-if="carte.favori_card == true &&
                                                                                $i18n.locale == 'en'
                                                                                ">
                                                                            By default
                                                                        </a>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                            <!-- <div>Cliquez sur l'une de vos cartes pour effectuer le paiement</div> -->
                                                        </div>
                                                        <!-- Liste des cartes -->
                                                    </div>

                                                    <div class="visa-qr-container__header" style="text-align: right">
                                                        <div v-if="showCard == true" style="text-align: center">
                                                            {{ $t("payment_modal.apaym.choose_card.value") }}
                                                        </div>

                                                        <div class="btn btn-link btn-sm my-4" v-if="showCard == true"
                                                             @click="disconnectApaym" style="
                                align-items: center;
                                background: #ffffff;
                                justify-content: center;
                                font-weight: 900;
                                border-color: #241d60;
                                line-height: 1.6;
                              ">
                                                            {{ $t("payment_modal.apaym.logout.value") }}
                                                        </div>

                                                        <p class="instruction mt-3" v-if="loginApaym"
                                                           style="text-align: center">
                                                            {{ $t("payment_modal.apaym.text.value") }}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                    <!-- Content APaym Payment - Listing des cartes visa du profil APaym -->
                                </div>
                                <!-- APaym -->

                                <!-- ECOBANK Pay -->
                                <div v-if="menuSelected == 'ECOBANK Pay'">
                                    <!-- Loader -->
                                    <loading :active.sync="isLoadingEcobank" :can-cancel="true" :on-cancel="onCancelQr"
                                             :is-full-page="fullPageEcobank" loader="bars" :opacity="1"></loading>
                                    <!-- Loader -->

                                    <!-- Content -->
                                    <div class="checkout__stage">
                                        <section>
                                            <div class="visa-qr-container">
                                                <div>
                                                    <div class="visa-qr-container__header">
                                                        <p class="instruction mt-3">
                                                            {{ $t("payment_modal.ecobank_qr.text.value") }}
                                                        </p>
                                                    </div>

                                                    <a download="ecobank-qr-code.png" class="btn btn-light mt-2 mb-2"
                                                       :href="ecobankQrCodeUri" style="
                              height: 35px;
                              line-height: 1.4;
                              background: #f4f4f4;
                              border: #f4f4f4;
                            ">
                                                        {{ $t("payment_modal.download_visa_qr.value") }}
                                                    </a>

                                                    <!-- Recapitulatif Montant à payer, pourboire, frais -->
                                                    <div class="mt-1" v-if="dataPage.merchant !== '25010'">
                                                        <!-- Fees -->
                                                        <div class="container d-flex justify-content-between mt-2 mb-3"
                                                             v-if="dataPage.frais == '1' ||
                                                                dataPage.frais == '2' ||
                                                                dataPage.frais == '3'
                                                                ">
                                                            <div>
                                                                <h6 v-if="dataPage.frais == '1'">
                                                                    {{ $t("text_fees_payment.value") }}
                                                                </h6>
                                                                <h6 v-if="dataPage.frais == '2' ||
                                                                    dataPage.frais == '3'
                                                                    ">
                                                                    {{ $t("text_fees_forced_payment.value") }}
                                                                </h6>
                                                            </div>
                                                            <label class="ui-switch switch-solid"
                                                                   v-if="dataPage.frais == '1'">
                                                                <input type="checkbox" :checked="checkedFeesApaym"
                                                                       @click="feeAmountEcobankQr"/>
                                                                <span style="left: 20px"></span>
                                                            </label>
                                                            <div class="ui-switch switch-solid" v-if="dataPage.frais == '2' || dataPage.frais == '3'
                                                                ">
                                                                <input type="checkbox" :checked="checkedFeesApaym"
                                                                       @click="feeAmountEcobankQr"/>
                                                                <span style="left: 20px"></span>
                                                            </div>
                                                        </div>
                                                        <div class="container d-flex justify-content-between" v-if="(dataPage.frais == '1' ||
                                                            dataPage.frais == '2' ||
                                                            dataPage.frais == '3') &&
                                                            checkedFeesApaym
                                                            ">
                                                            <div>
                                                                <span class="font-12">{{
                                                                        $t("text_fees.value")
                                                                    }}</span>
                                                            </div>
                                                            <div>
                                                                <span class="font-12">
                                                                    {{
                                                                        dataPage.frais == "3"
                                                                                ? new Intl.NumberFormat("fr-FR", {
                                                                                    style: "currency",
                                                                                    currency: "XOF",
                                                                                }).format(
                                                                                        montantSave
                                                                                                ? parseFloat(montantSave) *
                                                                                                (parseFloat(
                                                                                                                dataPage.taux_apaym / 2
                                                                                                        ) /
                                                                                                        100)
                                                                                                : 0
                                                                                )
                                                                                : new Intl.NumberFormat("fr-FR", {
                                                                                    style: "currency",
                                                                                    currency: "XOF",
                                                                                }).format(
                                                                                        montantSave
                                                                                                ? parseFloat(montantSave) *
                                                                                                (parseFloat(
                                                                                                                dataPage.taux_apaym
                                                                                                        ) /
                                                                                                        100)
                                                                                                : 0
                                                                                )
                                                                    }}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <!-- Fees -->

                                                        <!-- Pourboire -->
                                                        <!-- Type formulaire normal -->
                                                        <div v-if="typeForm == 'normal'">
                                                            <div class="container d-flex justify-content-between" v-if="dataPage.pourboire == '1' &&
                                                                pourboire &&
                                                                (pourboire == 'autre'
                                                                    ? parseFloat(
                                                                        dataFormulaire.amountPourboire
                                                                    ) > 0
                                                                    : parseFloat(pourboire) > 0)
                                                                ">
                                                                <div>
                                                                    <span class="font-12">
                                                                        {{ $t("text_pourboire.value") }}
                                                                    </span>
                                                                </div>
                                                                <div>
                                                                    <span class="font-12">
                                                                        {{
                                                                            new Intl.NumberFormat("fr-FR", {
                                                                                style: "currency",
                                                                                currency: "XOF",
                                                                            }).format(
                                                                                    pourboire == "autre"
                                                                                            ? dataFormulaire.amountPourboire
                                                                                                    ? parseFloat(
                                                                                                            dataFormulaire.amountPourboire
                                                                                                    )
                                                                                                    : 0
                                                                                            : parseFloat(pourboire)
                                                                            )
                                                                        }}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <!-- Type formulaire paie vite -->
                                                        <div v-if="typeForm == 'quickly'">
                                                            <div class="container d-flex justify-content-between" v-if="dataPage.pourboire == '1' &&
                                                                pourboire &&
                                                                (pourboire == 'autre'
                                                                    ? parseFloat(
                                                                        dataFormulaireQuick.amountPourboire
                                                                    ) > 0
                                                                    : parseFloat(pourboire) > 0)
                                                                ">
                                                                <div>
                                                                    <span class="font-12">
                                                                        {{ $t("text_pourboire.value") }}
                                                                    </span>
                                                                </div>
                                                                <div>
                                                                    <span class="font-12">
                                                                        {{
                                                                            new Intl.NumberFormat("fr-FR", {
                                                                                style: "currency",
                                                                                currency: "XOF",
                                                                            }).format(
                                                                                    pourboire == "autre"
                                                                                            ? dataFormulaireQuick.amountPourboire
                                                                                                    ? parseFloat(
                                                                                                            dataFormulaireQuick.amountPourboire
                                                                                                    )
                                                                                                    : 0
                                                                                            : parseFloat(pourboire)
                                                                            )
                                                                        }}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <!-- Pourboire -->

                                                        <!-- Montant à payer -->
                                                        <div class="container d-flex justify-content-between" v-if="montantAPayer &&
                                                                (dataPage.frais == '1' ||
                                                                    dataPage.frais == '2' ||
                                                                    dataPage.frais == '3' ||
                                                                    dataPage.pourboire == '1')
                                                                ">
                                                            <div>
                                                                <span class="font-12">{{
                                                                        $t("text_amount_to_pay.value")
                                                                    }}</span>
                                                            </div>

                                                            <div>
                                                                <span class="font-12">
                                                                    {{
                                                                        new Intl.NumberFormat("fr-FR", {
                                                                            style: "currency",
                                                                            currency: "XOF",
                                                                        }).format(parseFloat(montantAPayer))
                                                                    }}
                                                                </span>
                                                            </div>
                                                        </div>

                                                        <!-- Montant total -->
                                                        <div class="container d-flex justify-content-between mb-3"
                                                             v-if="typeForm == 'quickly' &&
                                                                (dataPage.frais == '1' ||
                                                                    dataPage.frais == '2' ||
                                                                    dataPage.frais == '3' ||
                                                                    dataPage.pourboire == '1')
                                                                ">
                                                            <div>
                                                                <span class=""
                                                                      style="font-weight: 800; font-size: 22px">TOTAL</span>
                                                            </div>
                                                            <div>
                                                                <span class="" style="font-weight: 800; font-size: 22px"
                                                                      v-if="dataFormulaireQuick.montant">
                                                                    {{
                                                                        new Intl.NumberFormat("fr-FR", {
                                                                            style: "currency",
                                                                            currency: "XOF",
                                                                        }).format(
                                                                                parseFloat(dataFormulaireQuick.montant)
                                                                        )
                                                                    }}

                                                                    <!-- new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'XOF' }).format(
                                                                        parseFloat(dataFormulaireQuick.montant)
                                                                    ) -->
                                                                </span>
                                                                <span class="" style="font-weight: 800; font-size: 22px"
                                                                      v-if="!dataFormulaireQuick.montant">
                                                                    {{
                                                                        new Intl.NumberFormat("fr-FR", {
                                                                            style: "currency",
                                                                            currency: "XOF",
                                                                        }).format(0)
                                                                    }}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div class="container d-flex justify-content-between mb-3"
                                                             v-if="typeForm == 'normal' &&
                                                                (dataPage.frais == '1' ||
                                                                    dataPage.frais == '2' ||
                                                                    dataPage.frais == '3' ||
                                                                    dataPage.pourboire == '1')
                                                                ">
                                                            <div>
                                                                <span class=""
                                                                      style="font-weight: 800; font-size: 22px">TOTAL</span>
                                                            </div>
                                                            <div>
                                                                <span class="" style="font-weight: 800; font-size: 22px"
                                                                      v-if="dataFormulaire.montant">
                                                                    {{
                                                                        new Intl.NumberFormat("fr-FR", {
                                                                            style: "currency",
                                                                            currency: "XOF",
                                                                        }).format(
                                                                                parseFloat(dataFormulaire.montant)
                                                                        )
                                                                    }}
                                                                    <!-- new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'XOF' }).format(
                                                                        parseFloat(dataFormulaire.montant)
                                                                    ) -->
                                                                </span>
                                                                <span class="" style="font-weight: 800; font-size: 22px"
                                                                      v-if="!dataFormulaire.montant">
                                                                    {{
                                                                        new Intl.NumberFormat("fr-FR", {
                                                                            style: "currency",
                                                                            currency: "XOF",
                                                                        }).format(0)
                                                                    }}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <!-- Montant total -->
                                                    </div>
                                                    <!-- Recapitulatif Montant à payer, pourboire, frais -->

                                                    <div class="qr__wrapper">
                                                        <div class="qr__code">
                                                            <img v-if="ecobankQrCodeUri" name="qrCodeImage"
                                                                 alt="ECOBANK Pay QR Code" :src="ecobankQrCodeUri"
                                                                 id="qrCodeImage" style="width: 68%"/>
                                                        </div>
                                                    </div>
                                                    <div class="qr__wrapper mt-2 mb-4">
                                                        <div class="qr__code">
                                                            <i class="fas fa-spinner fa-spin" style="font-size: 20px"
                                                               v-if="ecobankQrCodeUri"></i>
                                                        </div>
                                                        <div class="mt-2">
                                                            {{ $t("text_visa_qr_waiting.value") }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                    <!-- Content -->
                                </div>
                                <!-- ECOBANK Pay -->
                            </div>
                            <!-- **************************** -->
                            <!-- **************************** -->
                            <!-- ********** Content ********* -->
                            <!-- **************************** -->
                            <!-- **************************** -->

                            <!-- **************************** -->
                            <!-- **************************** -->
                            <!-- Second bloc -->
                            <!-- **************************** -->
                            <!-- **************************** -->
                            <div :style="(dataPage.frais == '1' ||
                                dataPage.frais == '2' ||
                                dataPage.frais == '3' ||
                                dataPage.pourboire == '1') &&
                                menuSelected == 'Visa QR'
                                ? 'display: flex; flex-direction: column; z-index: 1; width: 100%; padding: 13px; justify-content: center; background: rgb(244, 244, 244); border-radius: 10px;'
                                : 'display: flex; flex-direction: column; z-index: 1; width: 100%; padding: 13px; justify-content: center;'
                                " v-if="loyalty_card == '1' && showHeader">
                                <label class="mt-2" style="font-size: 12px; font-weight: 600">
                                    {{ $t("payment_modal.loyalty_card.value") }}
                                </label>
                                <div style="
                    display: flex;
                    flex-direction: row;
                    top: 5px;
                    z-index: 1;
                    align-items: center;
                    justify-content: center;
                    padding: 5px;
                  ">
                                    <span :style="pointLoyal.paid !== 1
                                        ? 'border-radius: 50% !important; height: 25px; width: 25px; border: 2px solid #261b64;'
                                        : 'border-radius: 50% !important; height: 25px; width: 25px; background: #261b64;'
                                        " class="mr-2" v-for="pointLoyal in pointLoyalty" :key="pointLoyal.id">
                                    </span>
                                    <img src="../assets/img/gift.png" alt="Gift" width="40"/>
                                </div>
                                <label class="mt-2" style="font-size: 12px; font-weight: 600" v-if="cadeau_a_offrir">
                                    {{ $t("payment_modal.bonus_offered.value") }}
                                    {{ cadeau_a_offrir.slice(0, 30) }}
                                </label>
                            </div>
                            <!-- **************************** -->
                            <!-- **************************** -->
                            <!-- Second bloc -->
                            <!-- **************************** -->
                            <!-- **************************** -->
                        </div>
                    </form>
                </div>
                <!-- Body -->
            </div>

            <!-- *********************** -->
            <!-- *********************** -->
            <!-- Let someone pay for you -->
            <!-- *********************** -->
            <!-- *********************** -->
            <div class="element-wrap element-wrap-for-submit flex flex-justify--space-between pfm-payForm__divider"
                 v-if="showPayBySomeone">
                <div class="divider-dashed"></div>
                <p>
                    {{ $t("payment_modal.sharing_or.value") }}
                </p>
                <div class="divider-dashed"></div>
            </div>
            <div class="element-wrap element-wrap-for-submit" style="margin-bottom: 4.3rem" v-if="showPayBySomeone">
                <a href="javascript:void(0);" @click="letSomeonePay" class="btn btn-block font-15 btn-pay-by-someone">
                    {{ $t("payment_modal.sharing.value") }}
                </a>
            </div>
            <!-- *********************** -->
            <!-- Let someone pay for you -->
            <!-- *********************** -->
        </div>
        <!-- ****************************************************** -->
        <!-- ****************************************************** -->
        <!-- Section du paiement | Affichage des moyens de paiement -->
        <!-- ****************************************************** -->
        <!-- ****************************************************** -->

        <!-- ****************************************************** -->
        <!-- ****************************************************** -->
        <!-- Section du partage | Affichage des options de partage  -->
        <!-- ****************************************************** -->
        <!-- ****************************************************** -->
        <div class="apaympro-wrapper animated fadeIn container mb-5" v-if="sharingSection" style="width: 500px">
            <div class="apaympro-page">
                <!-- Header -->
                <div class="apaympro-header" v-if="dataPage.dureeLien == '0'">
                    <div class="mb-1" style="display: flex; flex-direction: column; width: 100%">
                        <div class="text-modal mt-5 mb-4">
                            <img src="../assets/apaym-pay.png" alt="Logo APAYM Pay" width="150"/>
                        </div>

                        <!-- Montant -->
                        <div class="mt-3" style="display: contents">
                            <div v-if="typeForm == 'normal' && dataPage.merchant !== '25010'"
                                 style="text-align: center; display: flex; flex-direction: column;">
                                <span style="font-size: 25px; font-weight: 600; letter-spacing: 1.2px;">
                                    {{
                                        new Intl.NumberFormat("fr-FR", {
                                            style: "currency",
                                            currency: "XOF",
                                        }).format(parseFloat(montantSave))
                                    }}
                                </span>
                            </div>
                            <div v-if="typeForm == 'quickly' && dataPage.merchant !== '25010'"
                                 style="text-align: center; display: flex; flex-direction: column;">
                                <span style="font-size: 25px; font-weight: 600; letter-spacing: 1.2px;">
                                    {{
                                        new Intl.NumberFormat("fr-FR", {
                                            style: "currency",
                                            currency: "XOF",
                                        }).format(parseFloat(montantSave))
                                    }}
                                </span>
                            </div>
                            <div v-if="typeForm == 'superQuickly' && dataPage.merchant !== '25010'"
                                 style="text-align: center; display: flex; flex-direction: column;">
                                <span style="font-size: 25px; font-weight: 600; letter-spacing: 1.2px; ">
                                    {{
                                        new Intl.NumberFormat("fr-FR", {
                                            style: "currency",
                                            currency: "XOF",
                                        }).format(parseFloat(montantSave))
                                    }}
                                </span>
                            </div>
                        </div>
                        <!-- Montant -->

                        <div class="text-modal" style="padding-top: 5px">
                            <strong class="mb-4 font-16">
                                {{ $t("payment_modal.sharing_text.value") }}
                            </strong>

                            <p class="font-14">
                                {{ $t("payment_modal.sharing_sous_text.value") }}
                            </p>
                        </div>
                    </div>

                    <!-- Sharing bloc -->
                    <div class="sharingBloc">
                        <div class="sharingBloc">
                            <div>
                                <ShareNetwork network="facebook" :url="'https://pay.apaym.com/ref/' + refPayment"
                                              :title="dataPage.business_name + ' - Paye moi comme tu veux'"
                                              description="Veuillez me payer via Visa QR, Cartes Bancaires, Mobile Money, USSD APAYM et APAYM"
                                              quote="Le moyen de paiement marchand le plus simplifié et pratique"
                                              hashtags="APaymPro,PaieMoi,Marchand,APAYMPay"
                                              class="btn btn-lg btn-link btn-facebook">
                                    <i class="fab fa-facebook-messenger" style="font-size: 1.6rem"></i>
                                </ShareNetwork>

                                <ShareNetwork network="Email" url="" :title="'Reçu de paiement APAYM Pay - ' +
                                    dataPage.merchant +
                                    ' (Ref: ' +
                                    refPayment +
                                    '#' +
                                    ')'
                                    " :description="'Trouvez via le lien ci-dessous le reçu de paiement APAYM Pay - Ref: ' +
        refPayment +
        '#' +
        '\n\n' +
        'https://pay.apaym.com/recu/' +
        refPayment +
        '\n\n' +
        'Paiement effectué via chez le marchand ' +
        dataPage.merchant
        " class="btn btn-link btn-lg btn-gmail" style="padding: 12px !important">
                                    <i style="font-size: 22px" class="fa fa-envelope"></i>
                                </ShareNetwork>

                                <ShareNetwork network="WhatsApp" :url="'https://pay.apaym.com/ref/' + refPayment"
                                              :title="dataPage.business_name + ' - Paye moi comme tu veux'"
                                              description="Veuillez me payer via Visa QR, Cartes Bancaires, Mobile Money, USSD APAYM et APAYM"
                                              quote="Le moyen de paiement marchand le plus simplifié et pratique"
                                              hashtags="APaymPro,PaieMoi,Marchand,APAYMPay"
                                              class="btn btn-lg btn-link btn-whatsapp">
                                    <i class="fab fa-whatsapp" style="font-size: 1.6rem"></i>
                                </ShareNetwork>

                                <ShareNetwork network="telegram" :url="'https://pay.apaym.com/ref/' + refPayment"
                                              :title="dataPage.business_name + ' - Paye moi comme tu veux'"
                                              description="Veuillez me payer via Visa QR, Cartes Bancaires, Mobile Money, USSD APAYM et APAYM"
                                              quote="Le moyen de paiement marchand le plus simplifié et pratique"
                                              hashtags="APaymPro,PaieMoi,Marchand,APAYMPay"
                                              class="btn btn-lg btn-link btn-telegram">
                                    <i class="fab fa-telegram" style="font-size: 1.6rem"></i>
                                </ShareNetwork>
                            </div>
                        </div>
                    </div>
                    <!-- Sharing bloc -->

                    <div class="mt-4 pfm__copy" style="border-color: rgb(53 59 65)">
                        <p class="pfm__copy-link-preview" style="color: rgb(53 59 65)">
                            https://pay.apaym.com/ref/{{ refPayment }}
                        </p>
                        <button class="pfm__copy-button button button--cta" @click="copy" style="
                background: rgb(53 59 65);
                line-height: 20px;
                display: flex;
                color: #ffffff;
                height: -webkit-fill-available;
              ">
                            <span> {{ $t("payment_modal.copy.value") }} </span>
                        </button>
                    </div>
                    <!-- Sharing bloc -->
                </div>
                <!-- Header -->

                <!-- dataPage.merchant -->

                <!-- Header -->
                <div class="apaympro-header" v-if="dataPage.dureeLien == '1'">
                    <div class="mb-1" style="display: flex; flex-direction: column; width: 100%">
                        <div class="text-modal mt-5 mb-4">
                            <img src="../assets/apaym-pay.png" alt="Logo APAYM Pay" width="150"/>
                        </div>

                        <!-- Montant -->
                        <div class="mt-3" style="display: contents">
                            <div v-if="typeForm == 'normal' && dataPage.merchant !== '25010'" style="
                  text-align: center;
                  display: flex;
                  flex-direction: column;
                ">
                                <span style="
                    font-size: 25px;
                    font-weight: 600;
                    letter-spacing: 1.2px;
                  ">
                                    {{
                                        new Intl.NumberFormat("fr-FR", {
                                            style: "currency",
                                            currency: "XOF",
                                        }).format(parseFloat(montantSave))
                                    }}
                                </span>
                            </div>
                            <div v-if="typeForm == 'superQuickly' && dataPage.merchant !== '25010'" style="
                  text-align: center;
                  display: flex;
                  flex-direction: column;
                ">
                                <span style="
                    font-size: 25px;
                    font-weight: 600;
                    letter-spacing: 1.2px;
                  ">
                                    {{
                                        new Intl.NumberFormat("fr-FR", {
                                            style: "currency",
                                            currency: "XOF",
                                        }).format(parseFloat(montantSave))
                                    }}
                                </span>
                            </div>
                            <div v-if="typeForm == 'quickly' && dataPage.merchant !== '25010'" style="
                  text-align: center;
                  display: flex;
                  flex-direction: column;
                ">
                                <span style="
                    font-size: 25px;
                    font-weight: 600;
                    letter-spacing: 1.2px;
                  ">
                                    {{
                                        new Intl.NumberFormat("fr-FR", {
                                            style: "currency",
                                            currency: "XOF",
                                        }).format(parseFloat(montantSave))
                                    }}
                                </span>
                            </div>
                        </div>
                        <!-- Montant -->

                        <div class="text-modal" style="padding-top: 5px">
                            <strong class="mb-4 font-16">
                                {{ $t("payment_modal.sharing_text.value") }}
                            </strong>

                            <p class="font-14">
                                {{ $t("payment_modal.sharing_sous_text.value") }}
                            </p>
                        </div>
                    </div>

                    <!-- Sharing bloc -->
                    <div class="sharingBloc">
                        <!--v-if="!showLienReduit"-->
                        <div class="sharingBloc" v-if="!showLienReduit">
                            <div>
                                <ShareNetwork network="facebook" :url="'https://pay.apaym.com/ref/' + refPayment"
                                              :title="dataPage.business_name + ' - Paye moi comme tu veux'"
                                              description="Veuillez me payer via Visa QR, Cartes Bancaires, Mobile Money, USSD APAYM et APAYM"
                                              quote="Le moyen de paiement marchand le plus simplifié et pratique"
                                              hashtags="APaymPro,PaieMoi,Marchand,APAYMPay"
                                              class="btn btn-lg btn-link btn-facebook">
                                    <i class="fab fa-facebook-messenger" style="font-size: 1.6rem"></i>
                                </ShareNetwork>

                                <ShareNetwork network="Email" url="" :title="'Reçu de paiement APAYM Pay - ' +
                                    dataPage.business_name +
                                    ' (Ref: ' +
                                    refPayment +
                                    '#' +
                                    ')'
                                    " :description="'Trouvez via le lien ci-dessous le reçu de paiement APAYM Pay - Ref: ' +
        refPayment +
        '#' +
        '\n\n' +
        'https://pay.apaym.com/recu/' +
        refPayment +
        '\n\n' +
        'Paiement effectué via chez le marchand ' +
        dataPage.business_name
        " class="btn btn-link btn-lg btn-gmail" style="padding: 12px !important">
                                    <i style="font-size: 22px" class="fa fa-envelope"></i>
                                </ShareNetwork>

                                <ShareNetwork network="WhatsApp" :url="'https://pay.apaym.com/ref/' + refPayment"
                                              :title="dataPage.business_name + ' - Paye moi comme tu veux'"
                                              :disabled="!validiteLien"
                                              description="Veuillez me payer via Visa QR, Cartes Bancaires, Mobile Money, USSD APaym et APaym"
                                              quote="Le moyen de paiement marchand le plus simplifié et pratique"
                                              hashtags="APaymPro,PaieMoi,Marchand"
                                              class="btn btn-lg btn-link btn-whatsapp">
                                    <i class="fab fa-whatsapp" style="font-size: 1.6rem"></i>
                                </ShareNetwork>

                                <ShareNetwork network="telegram" :url="'https://pay.apaym.com/ref/' + refPayment"
                                              :title="dataPage.business_name + ' - Paye moi comme tu veux'"
                                              :disabled="!validiteLien"
                                              description="Veuillez me payer via Visa QR, Cartes Bancaires, Mobile Money, USSD APaym et APaym"
                                              quote="Le moyen de paiement marchand le plus simplifié et pratique"
                                              hashtags="APaymPro,PaieMoi,Marchand"
                                              class="btn btn-lg btn-link btn-telegram">
                                    <i class="fab fa-telegram" style="font-size: 1.6rem"></i>
                                </ShareNetwork>
                            </div>
                        </div>

                        <div class="sharingBloc" v-if="showLienReduit">
                            <div>
                                <button :disabled="!validiteLien" class="btn btn-lg btn-link btn-facebook"
                                        :style="!validiteLien ? 'color: rgb(0 0 0 / 8%);' : ''" @click="copyLink">
                                    <i class="fab fa-facebook-messenger" style="font-size: 1.6rem"></i>
                                </button>

                                <button :disabled="!validiteLien" class="btn btn-lg btn-link btn-facebook"
                                        :style="!validiteLien ? 'color: rgb(0 0 0 / 8%);' : ''" @click="copyLink">
                                    <i class="fa fa-envelope" style="font-size: 1.6rem"></i>
                                </button>

                                <button :disabled="!validiteLien" class="btn btn-lg btn-link btn-whatsapp"
                                        :style="!validiteLien ? 'color: rgb(0 0 0 / 8%);' : ''" @click="copyLink">
                                    <i class="fab fa-whatsapp" style="font-size: 1.6rem"></i>
                                </button>

                                <button :disabled="!validiteLien" class="btn btn-lg btn-link btn-telegram"
                                        :style="!validiteLien ? 'color: rgb(0 0 0 / 8%);' : ''" @click="copyLink">
                                    <i class="fab fa-telegram" style="font-size: 1.6rem"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <!-- Sharing bloc -->

                    <div class="mt-4 pfm__copy" style="border-color: rgb(53 59 65)" v-if="showLienReduit">
                        <p class="pfm__copy-link-preview" style="color: rgb(53 59 65)">
                            https://pay.apaym.com/ref/{{
                                getDescriptionTronque2(refPayment, 5)
                            }}
                        </p>
                        <!--<button class="pfm__copy-button button button&#45;&#45;cta" @click="copy"
                            style="background: rgb(53 59 65); line-height: 20px; display: flex; color: #ffffff; height: -webkit-fill-available;"
                        >
                            <span> {{ $t('payment_modal.copy.value') }} </span>
                        </button>-->
                    </div>

                    <div class="mt-4 pfm__copy" style="border-color: rgb(53 59 65)" v-if="!showLienReduit">
                        <p class="pfm__copy-link-preview" style="color: rgb(53 59 65)">
                            https://pay.apaym.com/ref/{{ refPayment }}
                        </p>
                    </div>

                    <div class="element-pair mt-4">
                        <div class="element-pair-container">
                            <div class="element-wrap element-wrap-on-left element-wrap-for-select" style="width: 100%">
                                <!--<div class="element-label">
                                    <span>{{$t('text_form.name_air_france.value')}}</span>
                                </div>-->
                                <select v-model="validiteLien" type="text" class="element-is-select" name="validite"
                                        v-on:change="validitePaiement">
                                    <option value="" selected>Durée de validité</option>
                                    <option value="1 Heure">1 Heure</option>
                                    <option value="24 Heures">24 Heures</option>
                                    <option value="1 Semaine">1 Semaine</option>
                                    <option value="1 Mois">1 Mois</option>
                                    <option value="Indéterminé">Indéterminé</option>
                                </select>
                            </div>
                            <div class="element-wrap element-wrap-on-right">
                                <button :disabled="!validiteLien" :class="!validiteLien
                                    ? 'btn btn-dark btn-disabled-custom'
                                    : 'btn btn-dark'
                                    " style="line-height: 1.7; height: 40px" @click="copy">
                                    <span> {{ $t("payment_modal.copy.value") }} </span>
                                </button>
                            </div>
                        </div>
                    </div>

                    <!--<div class="element-pair-container">
                        <div class="element-wrap element-wrap-for-select">
                            <div class="element-label">
                                <span> {{ $t("text_form.branch_store.value") }} </span>
                            </div>
                            <select v-model="dataFormulaire.boutique" type="text" class="element-is-select" name="succursale">
                                <option value="" selected> {{ $t("text_form.branch_store_option.value") }} </option>
                                <option :value="boutique" v-for="(boutique, index) in dataPage.boutiques" :key="index">
                                    {{ boutique.nom_boutique }}
                                </option>
                            </select>
                        </div>
                        <button class="pfm__copy-button button button&#45;&#45;cta" @click="copy"
                            style="background: rgb(53 59 65); line-height: 20px; display: flex; color: #ffffff; height: -webkit-fill-available;"
                        >
                            <span> {{ $t('payment_modal.copy.value') }} </span>
                        </button>
                    </div>-->

                    <!-- Sharing bloc -->
                </div>
                <!-- Header -->
            </div>

            <!-- Let someone pay for you -->
            <div class="element-wrap element-wrap-for-submit flex flex-justify--space-between pfm-payForm__divider"
                 style="margin: 30px 0px -10px; padding: 15px 7px 0px 7px">
                <div class="divider-dashed"></div>
                <p>
                    {{ $t("payment_modal.sharing_or.value") }}
                </p>
                <div class="divider-dashed"></div>
            </div>

            <div class="element-wrap element-wrap-for-submit" style="margin-bottom: 4.3rem">
                <a href="javascript:void(0);" @click="letSomeonePay" class="btn btn-block font-15 btn-pay-by-someone">
                    {{ $t("payment_modal.sharing_2.value") }}
                </a>
            </div>
            <!-- Let someone pay for you -->
        </div>
        <!-- ****************************************************** -->
        <!-- ****************************************************** -->
        <!-- Section du partage | Affichage des options de partage  -->
        <!-- ****************************************************** -->
        <!-- ****************************************************** -->


        <!-- Footer APaym Secured Badge -->
        <footer-item :hide_wgy_infos="dataPage.hide_wgy_infos" :hideRecu="hideRecu"></footer-item>
        <!-- Footer APaym Secured Badge -->

        <!-- *********** -->
        <!-- *********** -->
        <!-- MODAL BLOC -->
        <!-- *********** -->
        <!-- *********** -->

        <!-- Orange money modal -->
        <b-modal class="modal-content" id="openOrangeMoney" ref="openOrangeMoneyModal" no-close-on-backdrop hide-footer
                 hide-header scrollable style="padding: 0" size="lg">
            <div class="btn btn-link btn-floating btn-sm btn-close-modal display-for-large-only"
                 @click="closeModal('openOrangeMoneyModal')">
                <i class="fas fa-times"></i>
            </div>
            <div class="btn btn-link btn-floating btn-sm btn-close-modal-small display-for-small-only"
                 @click="closeModal('openOrangeMoneyModal')">
                <i class="fas fa-times" style="font-size: 23px; color: #ffffff"></i>
            </div>
            <div class="modalOrangeMoney">
                <iframe :src="urlOrange" frameborder="0" scrolling="yes" style="height: inherit; width: 100%"></iframe>
            </div>
        </b-modal>
        <!-- Orange money modal -->

        <!-- BICICI modal -->
        <b-modal class="modal-content" id="openBicici" ref="openBiciciModal" no-close-on-backdrop hide-footer
                 hide-header
                 scrollable style="padding: 0">
            <div class="btn btn-link btn-floating btn-sm btn-close-modal display-for-large-only"
                 @click="closeModal('openBiciciModal')">
                <i class="fas fa-times"></i>
            </div>
            <div class="btn btn-link btn-floating btn-sm btn-close-modal-small display-for-small-only"
                 @click="closeModal('openBiciciModal')">
                <i class="fas fa-times" style="font-size: 23px"></i>
            </div>
            <div class="modalBiciciMoney">
                <iframe :src="contentBicici" frameborder="0" scrolling="yes"
                        style="height: inherit; width: 100%"></iframe>
            </div>
        </b-modal>
        <!-- BICICI modal -->

        <!-- Wave modal -->
        <b-modal class="modal-content" id="openWave" ref="openWaveModal" no-close-on-backdrop hide-footer hide-header
                 scrollable style="padding: 0" size="lg">
            <div class="btn btn-link btn-floating btn-sm btn-close-modal display-for-large-only"
                 @click="closeModal('openWaveModal')">
                <i class="fas fa-times"></i>
            </div>
            <div class="btn btn-link btn-floating btn-sm btn-close-modal-small display-for-small-only"
                 @click="closeModal('openWaveModal')">
                <i class="fas fa-times" style="font-size: 23px; color: #ffffff"></i>
            </div>
            <div class="modalOrangeMoney">
                <iframe :src="urlWave" frameborder="0" scrolling="yes" style="height: inherit; width: 100%"></iframe>
            </div>
        </b-modal>
        <!-- Wave modal -->

        <!-- Calculator modal -->
        <b-modal class="modal-content" id="openCalculator" ref="openCalculatorModal" no-close-on-backdrop hide-footer
                 hide-header scrollable style="padding: 0">
            <div class="btn btn-link btn-floating btn-sm btn-close-modal display-for-large-only"
                 @click="closeModal('openCalculatorModal')">
                <i class="fas fa-times"></i>
            </div>
            <div class="btn btn-link btn-floating btn-sm btn-close-modal-small display-for-small-only"
                 @click="closeModal('openCalculatorModal')">
                <i class="fas fa-times" style="font-size: 23px"></i>
            </div>
            <div class="modalCalculator">
                <div class="displayCalculatorBtn">
                    <button type="button" class="btn btn-light" @click="setAmount">
                        {{ $t("calculator_mode.btn_validate.value") }}
                    </button>
                    <button type="button" class="btn btn-dark" @click="payViaCalculator"
                            v-if="formQuiclyPayment == true">
                        {{ $t("calculator_mode.btn_pay.value") }}
                    </button>
                </div>
                <div class="calculator">
                    <div class="answer">
                        {{ answer }}
                    </div>
                    <div class="display">
                        {{ logList + current }}
                    </div>
                    <div @click="clear" id="clear" class="btnCalculator operator greyBtn">
                        C
                    </div>
                    <div @click="sign" id="sign" class="btnCalculator operator greyBtn">
                        +/–
                    </div>
                    <div @click="percent" id="percent" class="btnCalculator operator greyBtn">
                        %
                    </div>
                    <div @click="divide" id="divide" class="btnCalculator operator">
                        ÷
                    </div>
                    <div @click="append('7')" id="n7" class="btnCalculator">7</div>
                    <div @click="append('8')" id="n8" class="btnCalculator">8</div>
                    <div @click="append('9')" id="n9" class="btnCalculator">9</div>
                    <div @click="times" id="times" class="btnCalculator operator">×</div>
                    <div @click="append('4')" id="n4" class="btnCalculator">4</div>
                    <div @click="append('5')" id="n5" class="btnCalculator">5</div>
                    <div @click="append('6')" id="n6" class="btnCalculator">6</div>
                    <div @click="minus" id="minus" class="btnCalculator operator">–</div>
                    <div @click="append('1')" id="n1" class="btnCalculator">1</div>
                    <div @click="append('2')" id="n2" class="btnCalculator">2</div>
                    <div @click="append('3')" id="n3" class="btnCalculator">3</div>
                    <div @click="plus" id="plus" class="btnCalculator operator">+</div>
                    <div @click="append('0')" id="n0" class="zero">0</div>
                    <div @click="dot" id="dot" class="btnCalculator">.</div>
                    <div @click="equal" id="equal" class="btnCalculator operator">=</div>
                </div>
            </div>
        </b-modal>
        <!-- Calculator modal -->

        <!-- QR Code For Sharing modal -->
        <b-modal class="modal-content" id="openQrCode" ref="openQrCodeModal" no-close-on-backdrop hide-footer
                 hide-header
                 scrollable style="padding: 0">
            <div class="btn btn-link btn-floating btn-sm btn-close-modal display-for-large-only"
                 @click="closeModal('openQrCodeModal')">
                <i class="fas fa-times" style="font-size: 23px"></i>
            </div>
            <div class="btn btn-link btn-floating btn-sm btn-close-modal-small display-for-small-only"
                 @click="closeModal('openQrCodeModal')">
                <i class="fas fa-times" style="font-size: 23px"></i>
            </div>
            <div style="
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        ">
                <h5 class="text-center mt-5">
                    {{ $t("text_qr_sharing_link.value") }}
                </h5>
                <img :src="dataPage.qr_code" alt=""/>
                <a :href="dataPage.link" class="font-14 mb-3">
                    {{ dataPage.link }}
                </a>
            </div>
        </b-modal>
        <!-- QR Code For Sharing modal -->

        <!-- Convert amount modal -->
        <b-modal class="modal-content" id="convertAmount" ref="convertAmountModal" hide-footer hide-header scrollable
                 style="padding: 0; border-radius: 0 !important" title="Convertir" no-close-on-backdrop>
            <div class="modal-header">
                <h4 class="modal-title">
                    {{ $t("text_form.convert_amount.value") }}
                </h4>
                <button class="btn btn-link btn-floating btn-sm btn-close-modal"
                        @click="closeModal('convertAmountModal')"
                        style="left: 93%; top: 20px">
                    <i class="fas fa-times font-18"></i>
                </button>
            </div>

            <form class="payment-form mt-5">
                <div class="container mt-3 mb-3">
                    <div class="element-pair">
                        <div class="element-pair-container">
                            <div class="element-wrap">
                                <div class="element-label">
                                    <span>
                                        {{ $t("text_form.amount_to_convert.value") }}
                                    </span>
                                </div>
                                <input type="tel" class="element-is-input" name="amount" v-model="amountToConvert"/>
                            </div>

                            <div class="element-wrap">
                                <div class="element-label">
                                    <span>
                                        {{ $t("text_form.from_currency.value") }}
                                    </span>
                                </div>
                                <multiselect v-model="currencyFrom" :options="currencies" :multiple="false"
                                             placeholder="Indicatif" tag-placeholder="Indicatif" track-by="devisecode"
                                             label="currency_to_show" :searchable="true">
                                    <template slot="singleLabel" slot-scope="{ option }">
                                        <img :src="option.flag" alt="" width="20"/>
                                        {{ option.devisecode }}
                                    </template>
                                    <span slot="noResult"></span>
                                </multiselect>
                            </div>

                            <div class="element-wrap" style="width: min-content">
                                <div class="element-label">
                                    <span>
                                        {{ $t("text_form.to_currency.value") }}
                                    </span>
                                </div>
                                <multiselect v-model="currencyTo" :options="currencies" :multiple="false"
                                             placeholder="Indicatif" tag-placeholder="Indicatif" track-by="devisecode"
                                             label="currency_to_show" :searchable="true" disabled>
                                    <template slot="singleLabel" slot-scope="{ option }">
                                        <img :src="option.flag" alt="" width="20"/>
                                        {{ option.devisecode }}
                                    </template>
                                    <span slot="noResult"></span>
                                </multiselect>
                            </div>
                        </div>
                    </div>

                    <div class="element-pair ml-2" v-if="showConversion && amountToConvert != ''">
                        <div class="element-pair-container" style="flex-direction: column">
                            <h5 class="text-dark" style="font-weight: 900">
                                {{ amountToConvert }} {{ currencyFrom.devisename }} =
                            </h5>
                            <h2 class="text-dark" style="font-weight: 900; margin-top: 0px">
                                {{ convertedAmount }} {{ currencyTo.devisename }}
                            </h2>
                            <!-- dataPage.type == 'SP' || dataPage.type == 'APaym' ? -->
                            <span v-if="dataPage.type == 'SP' || dataPage.type == 'APaym'
                                ? convertedAmount > 300000
                                : convertedAmount > 1000000
                                " class="text-danger font-17">
                                {{ $t("text_form.limit_amount.value") }}
                                <strong v-if="dataPage.type == 'SP' || dataPage.type == 'APaym'">
                                    {{ Math.round(300000 / conversionRate) }}
                                    {{ currencyFrom.devisename }}
                                </strong>
                                <strong v-if="dataPage.type !== 'SP' && dataPage.type !== 'APaym'">
                                    {{ Math.round(1000000 / conversionRate) }}
                                    {{ currencyFrom.devisename }}
                                </strong>
                            </span>
                        </div>
                    </div>

                    <div class="element-wrap element-wrap-for-submit mt-3 mb-3" style="text-align: right">
                        <button type="button" class="btn btn-dark" @click="convertAmount">
                            {{ $t("text_form.convert_amount.value") }}
                        </button>
                        <button type="button" class="btn btn-success ml-3" @click="setConvertedAmount" :disabled="convertedAmount == '' ||
                            (dataPage.type == 'SP' || dataPage.type == 'APaym'
                                ? convertedAmount > 300000
                                : convertedAmount > 1000000)
                            ">
                            {{ $t("text_form.validate_conversion.value") }}
                        </button>
                    </div>
                </div>
            </form>
        </b-modal>
        <!-- Convert amount modal -->

        <!-- Yango Video modal -->
        <b-modal class="modal-content" id="openYangoVideo" ref="openYangoVideoModal" no-close-on-backdrop hide-footer
                 hide-header scrollable style="padding: 0">
            <div class="btn btn-link btn-floating btn-sm btn-close-modal display-for-large-only"
                 @click="closeModal('openYangoVideoModal')">
                <i class="fas fa-times" style="font-size: 23px"></i>
            </div>
            <div class="btn btn-link btn-floating btn-sm btn-close-modal-small display-for-small-only"
                 @click="closeModal('openYangoVideoModal')">
                <i class="fas fa-times" style="font-size: 23px"></i>
            </div>
            <div style="
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        ">
                <video-embed css="embed-responsive-1by1" :params="{ autoplay: 1 }"
                             src="https://youtu.be/Dwv5GxKT6WY"></video-embed>
            </div>
        </b-modal>
        <!-- Yango Video modal -->

        <!-- *********** -->
        <!-- *********** -->
        <!-- MODAL BLOC -->
        <!-- *********** -->
        <!-- *********** -->
    </div>
</template>

<script src="https://js.pusher.com/7.0/pusher.min.js">
</script>
<script>
	import Loading from "vue-loading-overlay";
	import "vue-loading-overlay/dist/vue-loading.css";
	import json from "@/constants/flags.json";
	import delegations_pdci from "@/constants/delegation-pdci.json";
	import motifs_pdci from "@/constants/motif-pdci.json";
	import grades_pdci from "@/constants/grade-pdci.json";
	import traded_company_list from "@/constants/societe-en-bourse.json";
	import currencies_list from "@/constants/currencies.json";
	import {sweetAlert} from "@/constants/functions";
	import axios from "axios";
	import {apiUrl, _reference} from "../constants/config";
	import {trim} from "jquery";
	import Multiselect from "vue-multiselect";
	import TopBar from "@/components/TopBar";
	import SkeletonLoader from "@/components/SkeletonLoader";
	import SearchMerchant from "@/components/SearchMerchant";
	import FooterItem from "@/components/FooterItem";
	import SuccessPage from "@/components/SuccessPage";
	import VueHcaptcha from "@hcaptcha/vue-hcaptcha";
	import createNumberMask from "text-mask-addons/dist/createNumberMask";

	const TIME_LIMIT = 180;

	export default {
		created() {
		},
		props: {
			reCaptchaCompat: {
				type: Boolean,
				default: true,
			},
			sentry: {
				type: Boolean,
				default: true,
			},
		},
		components: {
			Loading,
			VueHcaptcha,
			multiselect: Multiselect,
			"top-bar": TopBar,
			"skeleton-loader": SkeletonLoader,
			"search-merchant": SearchMerchant,
			"footer-item": FooterItem,
			"success-page": SuccessPage,
		},
		data() {
			return {
				montantFormate: "",
				validiteLien: "",
				montantFormateQuick: "",
				showLienReduit: true,
				currencyMask: createNumberMask({
					prefix: "",
					includeThousandsSeparator: true,
					allowNegative: false,
					thousandsSeparatorSymbol: " ",
				}),
				formRechargement: {
					issuer_name: "",
					card_type_code: "",
					card_type_description: "",
				},
				logList: "",
				current: "",
				answer: "0",
				operatorClicked: true,
				showCard: false,
				cards: [],
				cardsLength: 0,
				loginApaym: true,
				hideCodePay: false,
				tabIndex: 0,
				mtnOkay: false,
				mtnNOkay: false,
				tresorOkay: false,
				tresorNOkay: false,
				showPayBySomeone: true,
				orangeOkay: false,
				orangeNOkay: false,
				waveOkay: false,
				waveNOkay: false,
				moovOkay: false,
				moovNOkay: false,
				biciciNOkay: false,
				hideRecu: true,
				isLoading: false,
				fullPage: true,
				isLoadingQr: false,
				fullPageQr: false,
				isLoadingEcobank: false,
				fullPageEcobank: false,
				isLoadingTresorPay: false,
				fullPageTresorPay: false,
				isLoadingMomo: false,
				fullPageMomo: false,
				isLoadingAPaym: false,
				fullPageAPaym: false,
				isLoadingAPaymAuth: false,
				fullPageAPaymAuth: false,
				isLoadingOrange: false,
				fullPageOrange: false,
				isLoadingFlooz: false,
				fullPageFlooz: false,
				isLoadingBicici: false,
				fullPageBicici: false,
				isLoadingWave: false,
				fullPageWave: false,
				indice: {i: "225"},
				indiceMobileMoney: "225",
				indiceToShow: "",
				json: [],
				qrCodeUri: "",
				ecobankQrCodeUri: "",
				urlOrange: "",
				urlWave: "",
				isBicici: false,
				isOtherCard: false,
				cardChoice: true,
				contentBicici: "",
				formBicici: false,
				cardType: "",
				events: [],
				dataFormulaire: {
					nom: "",
					prenom: "",
					telephone: "",
					email: "",
					montant: "",
					motif: "",
					devise: "XOF",
					amountPourboire: "",
					society: "",
					key_payment: "",
					deleg: "",
					grade: "",
					numeroCompte: "",
					societeEnBourse: "",
					nombreActions: "",
					boutique: "",
					immatriculation: "",
					service: "",
					formule: "",
					// NSIA ASSURANCE
					typeClient: "",
					nomEntreprise: "",
					numeroPolice: "",
					numeroQuittance: "",
					// ZINO CIGARES EL.
					adresse: "",
					quantite: "1",
					vip: "Non",
				},
				dataFormulaireQuick: {
					nom: "",
					prenom: "",
					telephone: "",
					telephone2: "",
					email: "",
					montant: "",
					motif: "",
					devise: "XOF",
					amountPourboire: "",
					society: "",
					key_payment: "",
					numeroCompte: "",
					societeEnBourse: "",
					nombreActions: "",
					boutique: "",
					immatriculation: "",
					service: "",
					formule: "",
					quantite: "1",
				},
				dataApaymPay: {indice: "225", numApaym: "", codeApaym: ""},
				dataApaymPayAuth: {
					indice: "225",
					numApaym: "",
					codeApaym: "",
					token: "",
				},
				dataVisaCard: {
					cardHolderName: "",
					pan: "",
					expirationMonth: "",
					expirationYear: "",
					cvv: "",
				},
				dataMoMo: {numero: ""},
				dataTresorPAY: {numero: ""},
				dataMoov: {numero: ""},
				url_data: {
					reference: "",
					code_page: "",
					slug: "",
					montant: "",
					indice: "",
					numero: "",
					nom: "",
					prenom: "",
					email: "",
					motif: "",
					key_payment: "",
					redirect_key: "",
					formule: "",
					url_redirect: ""
				},
				dataPage: {
					name_page: "",
					description_page: "",
					description_page_2: "",
					business_logo: "",
					business_name: "",
					business_vpan: "",
					amount: "",
					amount2: "",
					page_redirect: "",
					email_notify: "",
					success_message: "",
					seo_image: "",
					slug: "",
					type: "",
					nom: "",
					verified: "",
					rcc: "",
					business_email: "",
					business_phone: "",
					facebook: "",
					instagram: "",
					whatsapp: "",
					site: "",
					youtube: "",
					twitter: "",
					ville: "",
					qr_code: "",
					link: "",
					partner: "",
					pourboire: "",
					frais: "",
					deleg_pdci: "",
					collected_amount: "",
					collected_qty: "",
					hide_wgy_infos: "",
					show_fields_by_default: "",
					show_email_by_default: "",
					taux_apaym: "",
					taux_om: "",
					taux_mtn: "",
					taux_cb: "",
					taux_moov: "",
					taux_wave: "",
					taux_tresor: "2",
					boutiques: [],
					custom_fields: "",
					urlRedirection: "",
					redirectWithCode: "",
					fixedAmount: "",
					dureeLien: "",
					subMerchant: "",
					merchant: "",
					air_france: 0,
					baie_sirenes: 0,
					damac: 0,
					yango: 0,
					umed: 0,
					eticket: [],
				},
				slug_boutique: "",
				hasProgram: true,
				langs: ["fr", "en"],
				logoOfSelectedService: "visa-qr.png",
				logoSmallDevice: "visa-qr.png",
				showErrorPage: false,
				formPayment: false,
				formQuiclyPayment: false,
				formSuperQuicklyPayment: false,
				formLoader: false,
				traded_company: [],
				infosSup: true,
				infosSup2: true,
				typeForm: "",
				modePay: "",
				refPayment: "",
				operator: null,
				modalOrange: false,
				modalTresor: false,
				delegationsPdci: [],
				motifsPdci: [],
				gradesPdci: [],
				options: [],
				minCardYear: new Date().getFullYear(),
				modalWave: false,
				modalBicici: false,
				mobileDevice: false,
				checkedFeesApaym: false,
				checkedFeesOm: false,
				checkedFeesWave: false,
				checkedFeesMtn: false,
				checkedFeesCb: false,
				expired: false,
				sharingSection: false,
				checkedFeesMoov: false,
				checkedFeesTresorPAY: false,
				pourboire: "0",
				montantSave: "",
				sauvegardeMontant: "",
				sendNotif: "sms",
				modePayment: "",
				showMenu: true,
				showHeader: false,
				showContent: false,
				menuSelected: "Visa QR",
				error: null,
				currencyFrom: "EUR",
				currencyTo: "XOF",
				currencies: [],
				showConversion: false,
				amountToConvert: "",
				convertedAmount: "",
				devise: "",
				montantDevise: "",
				conversionRate: "",
				montantAPayer: 0,
				imgStatus: "bronze.png",
				dataYangoRechargement: {
					driver_profil_id: "",
					driver_phone_number: "",
					amount: "",
					profil_id: "",
					ref_transaction: "",
					adresse_ip: "",
					device_ID: "",
					phone_Name: "",
				},
				token: null,
				eKey: null,
				verified: false,
				dataLocation: {latitude: "", longitude: ""},
				pointLoyalty: [],
				loyalty_card: "",
				nombre_points: 0,
				cadeau_a_offrir: "",
				nombre_paiements: 0,
				nombre_transactions: 0,
				statusClient: "",
				typeCarte: "",
				is_platinum_card: "",
				detailsMontantPdci: "",
				showPaymentSection: false,
				stopInterval: null,
				timePassed: 0,
				timerInterval: null,
				showVerifMoov: false,
				showVerifTresor: false,
				payment_failure_details: "",
				showInProcessTresor: false,
				showInProcessMoov: false,
				modesPaiementDispo: [
					{
						mode: "cb_visa",
						actif: "non",
						libelle: "VISA",
						img: "visa.png",
					},
					{
						mode: "cb_mastercard",
						actif: "non",
						libelle: "MASTERCARD",
						img: "mastercard.png",
					},
					{
						mode: "orange_money",
						actif: "non",
						libelle: "Orange Money",
						img: "om.png",
					},
					{
						mode: "wave",
						actif: "non",
						libelle: "Wave",
						img: "wave.png",
					},
					{
						mode: "mtn_money",
						actif: "non",
						libelle: "MTN Money",
						img: "momo.png",
					},
					{
						mode: "moov_money",
						actif: "non",
						libelle: "Moov Money",
						img: "flooz.png",
					},
					{
						mode: "tresor_pay",
						actif: "non",
						libelle: "Tresor PAY",
						img: "tresor-money.png",
					},
					/*{
                        mode: "ussd",
                        actif: "non",
                        libelle: "APaym USSD",
                        img: "apaym-ussd.png",
                    },
                    {
                        mode: "apaym",
                        actif: "non",
                        libelle: "APaym",
                        img: "apaym.png",
                    },
                    {
                        mode: "qr",
                        actif: "non",
                        libelle: "Visa QR",
                        img: "visa-qr.png",
                    },
                    {
                        mode: "ecobank_pay",
                        actif: "non",
                        libelle: "ECOBANK Pay",
                        img: "ecobank-pay.png",
                    },*/
				],
				modesPaiementDispo2: [
					{
						mode: "cb_visa",
						actif: "non",
						libelle: "VISA",
						img: "visa.png",
					},
					{
						mode: "cb_mastercard",
						actif: "non",
						libelle: "MASTERCARD",
						img: "mastercard.png",
					},
					{
						mode: "orange_money",
						actif: "non",
						libelle: "Orange Money",
						img: "om.png",
					},
					{
						mode: "wave",
						actif: "non",
						libelle: "Wave",
						img: "wave.png",
					},
					{
						mode: "mtn_money",
						actif: "non",
						libelle: "MTN Money",
						img: "momo.png",
					},
					{
						mode: "moov_money",
						actif: "non",
						libelle: "Moov Money",
						img: "flooz.png",
					},
					{
						mode: "tresor_pay",
						actif: "non",
						libelle: "Tresor PAY",
						img: "tresor-money.png",
					},
					/*{
                        mode: "qr",
                        actif: "non",
                        libelle: "Visa QR",
                        img: "visa-qr.png",
                    },
                    {
                        mode: "ecobank_pay",
                        actif: "non",
                        libelle: "ECOBANK Pay",
                        img: "ecobank-pay.png",
                    },*/
				],
				expirationMonth: [
					{
						id: 1,
						month: "01",
						libelle: "Janvier",
						libelleEn: "January",
					},
					{
						id: 2,
						month: "02",
						libelle: "Février",
						libelleEn: "February",
					},
					{
						id: 3,
						month: "03",
						libelle: "Mars",
						libelleEn: "March",
					},
					{
						id: 4,
						month: "04",
						libelle: "Avril",
						libelleEn: "April",
					},
					{
						id: 5,
						month: "05",
						libelle: "Mai",
						libelleEn: "May",
					},
					{
						id: 6,
						month: "06",
						libelle: "Juin",
						libelleEn: "June",
					},
					{
						id: 7,
						month: "07",
						libelle: "Juillet",
						libelleEn: "July",
					},
					{
						id: 8,
						month: "08",
						libelle: "Août",
						libelleEn: "August",
					},
					{
						id: 9,
						month: "09",
						libelle: "Septembre",
						libelleEn: "September",
					},
					{
						id: 10,
						month: "10",
						libelle: "Octobre",
						libelleEn: "October",
					},
					{
						id: 11,
						month: "11",
						libelle: "Novembre",
						libelleEn: "November",
					},
					{
						id: 12,
						month: "12",
						libelle: "Décembre",
						libelleEn: "December",
					},
				],
				typeServices: [
					{
						id: 1,
						nom_service: "Visite technique  - Véhicules Légers",
					},
					{
						id: 2,
						nom_service: "Visite technique - Véhicules Poids Lourds PL1-TP3",
					},
					{
						id: 3,
						nom_service: "Visite technique - Véhicules Poids Lourds PL2-TP4",
					},
					{
						id: 4,
						nom_service: "Visite technique - Véhicules Poids Lourds PL1-SR",
					},
					{
						id: 5,
						nom_service: "Visite technique - Véhicules Poids Lourds PL2-TR",
					},
					{
						id: 6,
						nom_service: "Visite technique - Véhicules Poids Lourds REVISITE VL",
					},
					{
						id: 7,
						nom_service: "Visite technique - Véhicules Poids Lourds REVISITE PL",
					},
					{
						id: 8,
						nom_service: "Diagnostic technique (Pré-visite)",
					},
					{
						id: 9,
						nom_service: "Vignette automobile",
					},
					{
						id: 10,
						nom_service: "Vignette Moto",
					},
					{
						id: 11,
						nom_service: "Identification de véhicule neuf",
					},
					{
						id: 12,
						nom_service: "Identification de véhicule importé d’occasion",
					},
					{
						id: 13,
						nom_service: "Fabrication de plaques- Moto",
					},
					{
						id: 14,
						nom_service: "Fabrication de plaques - Véhicules légers",
					},
					{
						id: 15,
						nom_service: "Fabrication de plaques - Poids lourds",
					},
					{
						id: 16,
						nom_service: "Fabrication de plaques - Semi-remorque",
					},
					{
						id: 17,
						nom_service: "Sécurisation plaque",
					},
					{
						id: 18,
						nom_service: "Réception à titre Isolé (RTI)",
					},
					{
						id: 19,
						nom_service: "Transfert plaque Immatriculation (TPI)",
					},
					{
						id: 20,
						nom_service: "Extrait carte grise",
					},
					{
						id: 21,
						nom_service: "Duplicata visite Technique",
					},
					{
						id: 22,
						nom_service: "Domiciliation de véhicule",
					},
					{
						id: 23,
						nom_service: "Diagnostic-Visite Technique",
					},
					{
						id: 24,
						nom_service: "Visite compteur horokilométrique",
					},
				],
				showBtnSearch: "true",
				source_pay: ""
			};
		},
		computed: {
			formattedTimeLeft() {
				const timeLeft = this.timeLeft;
				const minutes = Math.floor(timeLeft / 60);
				let seconds = timeLeft % 60;

				if (seconds < 10) {
					seconds = `0${seconds}`;
				}

				return `${minutes}:${seconds}`;
			},

			timeLeft() {
				return TIME_LIMIT - this.timePassed;
			},

			checkForm() {
				const {
					motif,
					immatriculation,
					typeClient,
					nomEntreprise,
					nom,
					prenom,
					numeroPolice,
					telephone,
					montantFormate,
				} = this.dataFormulaire;


				if (
					!numeroPolice ||
					!telephone ||
					telephone.length < 11 ||
					!montantFormate.replace(/\s/g, "") ||
					montantFormate.replace(/\s/g, "") < 100 ||
					montantFormate.replace(/\s/g, "") > 1000000
				) {
					return true;
				}

				return false;
			},
		},

		methods: {
			scrollToTop() {
				window.scrollTo(0, 0);
			},

			// Close Sidebar Produit
			closeSidebar(hide) {
				console.log("Produit choisit");
				hide();
			},

			feeAmount() {
				this.checkedFeesApaym = !this.checkedFeesApaym;
			},

			/*** Calculatrice ****/
			// Set calculate value to amount to pay
			setAmount() {
				if (parseFloat(this.answer) < 100 || this.answer == "") {
					sweetAlert(
						this.$swal,
						true,
						"top-end",
						"info",
						"Info",
						"Veuillez renseigner un montant supérieur ou égal à 100 F CFA !"
					);
					return false;
				}

				if (isNaN(this.answer)) {
					sweetAlert(
						this.$swal,
						true,
						"top-end",
						"info",
						"Info",
						"Veuillez renseigner un montant correct !"
					);
					return false;
				}

				// Set amount to the form payment
				if (
					this.formQuiclyPayment == true ||
					this.formSuperQuicklyPayment == true
				) {
					// this.dataFormulaireQuick.montant = this.answer.toString() ;
					this.montantFormateQuick = this.answer.toString();
				} else {
					// this.dataFormulaire.montant = this.answer.toString() ;
					this.montantFormate = this.answer.toString();
				}

				this.current = "";
				this.answer = "0";
				this.logList = "";
				this.$refs["openCalculatorModal"].hide();
			},

			// Do payment from calculator modal
			payViaCalculator() {
				if (parseFloat(this.answer) < 100 || this.answer == "") {
					sweetAlert(
						this.$swal,
						true,
						"top-end",
						"info",
						"Info",
						"Veuillez renseigner un montant supérieur ou égal à 100 F !"
					);
					return false;
				}

				let limitPay = this.dataPage.type == "SP" ? 300000 : 1000000;

				if (parseFloat(this.answer) > limitPay) {
					sweetAlert(
						this.$swal,
						true,
						"top-end",
						"info",
						"Info",
						"Veuillez renseigner un montant inférieur ou égal à " +
						Intl.NumberFormat("fr-FR", {
							style: "currency",
							currency: "XOF",
						}).format(limitPay)
					);
					return false;
				}

				if (isNaN(this.answer)) {
					sweetAlert(
						this.$swal,
						true,
						"top-end",
						"info",
						"Info",
						"Veuillez renseigner un montant correct !"
					);
					return false;
				}

				this.dataFormulaireQuick.montant = this.answer.toString();
				this.current = "";
				this.answer = "0";
				this.logList = "";
				this.$refs["openCalculatorModal"].hide();
				this.payerQuickly();
			},

			append(number) {
				if (this.operatorClicked) {
					this.current = "";
					this.operatorClicked = false;
				}

				this.current = `${this.current}${number}`;
			},

			addtoLog(operator) {
				if (this.operatorClicked == false) {
					this.logList += `${this.current} ${operator} `;
					this.current = "";
					this.operatorClicked = true;
				}
			},

			clear() {
				this.current = "";
				this.answer = "0";
				this.logList = "";
				this.operatorClicked = false;
			},

			sign() {
				if (this.current != "") {
					this.current =
						this.current.charAt(0) === "-"
							? this.current.slice(1)
							: `-${this.current}`;
				}
			},

			percent() {
				if (this.current != "") {
					this.current = `${parseFloat(this.current) / 100}`;
				}
			},

			dot() {
				if (this.current.indexOf(".") === -1) {
					this.append(".");
				}
			},

			divide() {
				this.addtoLog("/");
			},

			times() {
				this.addtoLog("*");
			},

			minus() {
				this.addtoLog("-");
			},

			plus() {
				this.addtoLog("+");
			},

			equal() {
				if (this.operatorClicked == false) {
					this.answer = eval(this.logList + this.current);
				} else {
					this.answer = "";
				}
			},
			/*** Calculatrice ****/

			// Copy payment link on mobile device
			copy() {
				if (this.dataPage.dureeLien == "0") {
					try {
						var successful = navigator.clipboard.writeText(
							"https://pay.apaym.com/ref/" + this.refPayment
						);
						sweetAlert(
							this.$swal,
							true,
							"top-end",
							"success",
							this.$t("payment_modal.apaym.sweet_alert_text.value"),
							""
						);
					} catch (err) {
						console.log(err);
					}

					return false;
				}

				if (this.showLienReduit == false) {
					try {
						var successful = navigator.clipboard.writeText(
							"https://pay.apaym.com/ref/" + this.refPayment
						);
						sweetAlert(
							this.$swal,
							true,
							"top-end",
							"success",
							this.$t("payment_modal.apaym.sweet_alert_text.value"),
							""
						);
					} catch (err) {
						console.log(err);
					}

					return false;
				}

				this.$swal({
					title: "Donnée requise",
					html: "<div style='line-height: 1.5; font-size: 18px;'> Veuillez choisir la durée de validité de votre lien de paiement !</div>",
					icon: "info",
				});
			},

			validitePaiement() {
				if (this.validiteLien == "") {
					this.$swal({
						title: "Donnée requise",
						html: "<div style='line-height: 1.5; font-size: 18px;'> Veuillez choisir la durée de validité de votre lien de paiement !</div>",
						icon: "info",
					});

					return false;
				}

				this.$swal({
					title: "Êtes-vous certain(e) ?",
					html:
						"<div style='line-height: 1.5; font-size: 18px;'> Vous allez partager ce lien de paiement pour une durée de " +
						this.validiteLien +
						"</div>",
					icon: "info",
					showCancelButton: true,
					cancelButtonColor: "#6e7881",
					confirmButtonText: "Oui je valide",
					cancelButtonText: "Non",
					reverseButtons: true,
				}).then((result) => {
					if (result.isConfirmed) {
						this.isLoading = true;
						let dataSend = new FormData();
						dataSend.append("dureeValidite", this.validiteLien);
						dataSend.append(
							"reference",
							window.localStorage.getItem("_reference_" + this.url_data.slug)
						);

						axios
						.post(apiUrl + "api/paiement-marchand/validite-lien", dataSend)
						.then((response) => {
							this.isLoading = false;

							if (response.data["code"] == "1") {
								this.showLienReduit = false;
								sweetAlert(
									this.$swal,
									true,
									"top-end",
									"success",
									"Succès",
									response.data["message"]
								);
								return false;
							}

							sweetAlert(
								this.$swal,
								true,
								"top-end",
								"info",
								"Info",
								response.data["message"]
							);
						})
						.catch((error) => {
							this.isLoading = false;
							console.log(error);
							sweetAlert(
								this.$swal,
								true,
								"top-end",
								"error",
								"Erreur",
								"Une erreur est survenue, veuillez réessayer plus tard"
							);
						});
					}
				});
			},

			copyLink() {
				this.$swal({
					title: "Donnée requise",
					html: "<div style='line-height: 1.5; font-size: 18px;'> Veuillez choisir la durée de validité de votre lien de paiement !</div>",
					icon: "info",
				});
			},

			// Copy payment link on desktop
			copyDesktop() {
				let testingCodeToCopy = document.querySelector("#paymentLinkDesktop");
				testingCodeToCopy.setAttribute("type", "text");
				testingCodeToCopy.select();

				try {
					var successful = document.execCommand("copy");
					var msg = successful ? "successful" : "unsuccessful";
					console.log(msg);
					sweetAlert(
						this.$swal,
						true,
						"top-end",
						"info",
						"Info",
						"Le lien de paiement a été copié !"
					);
				} catch (err) {
					console.log(err);
				}

				/* unselect the range */
				testingCodeToCopy.setAttribute("type", "hidden");
				window.getSelection().removeAllRanges();
			},

			// Vérifier le PAN renseigné
			checkPan(evt) {
				console.log(evt);

				evt = evt ? evt : window.event;
				var charCode = evt.which ? evt.which : evt.keyCode;
				if (
					charCode > 31 &&
					(charCode < 48 || charCode > 57) &&
					charCode !== 46
				) {
					evt.preventDefault();
					return false;
				}

				this.formRechargement.issuer_name = "";
				this.formRechargement.card_type_code = "";
				this.formRechargement.card_type_description = "";

				if (this.dataVisaCard.pan.replace(/\s/g, "").length < 16) {
					this.formRechargement.issuer_name = "";
					this.formRechargement.card_type_code = "";
					this.formRechargement.card_type_description = "";
					return false;
				}

				// Literal Object JavaScript
				const cardType = {
					C: "Crédit",
					D: "Débit",
					H: "Charge Card",
					P: "Prépayée",
					R: "Débit différé",
				};

				// Init process
				let dataSend = new FormData();
				dataSend.append("pan", this.dataVisaCard.pan.replace(/\s/g, ""));

				axios
				.post("https://carte.abidjan.net/cabine/api/check-pan", dataSend)
				.then((response) => {
					// On a obtenu un message d'erreur

					if (
						(response["data"]["errorMessage"] ||
							response["data"]["ppgsNetworkInfo"]) &&
						!response["data"]["visaNetworkInfo"]
					) {
						return false;
					}

					this.formRechargement.issuer_name =
						response["data"]["visaNetworkInfo"][0]["issuerName"];
					this.formRechargement.card_type_code =
						response["data"]["visaNetworkInfo"][0]["cardTypeCode"];
					this.formRechargement.card_type_description =
						cardType[this.formRechargement.card_type_code];
				})
				.catch((error) => {
					console.log(error);
				});
			},

			showInfosSup() {
				this.infosSup = !this.infosSup;
			},

			showInfosSup2() {
				this.infosSup2 = !this.infosSup2;
			},

			// Allows numbers only, amount field
			numbersOnly(evt) {
				evt = evt ? evt : window.event;
				var charCode = evt.which ? evt.which : evt.keyCode;
				if (
					charCode > 31 &&
					(charCode < 48 || charCode > 57) &&
					charCode !== 46
				) {
					evt.preventDefault();
				} else {
					return true;
				}
			},

			// Reload la page
			reload() {
				location.reload();
			},

			hideSectionPayment() {
				if (sessionStorage.getItem("redirect_value")) {
					window.history.back();
					return false;
				}
				if (sessionStorage.getItem("n")) sessionStorage.removeItem("n");
				if (sessionStorage.getItem("e")) sessionStorage.removeItem("e");
				if (sessionStorage.getItem("f")) sessionStorage.removeItem("f");
				if (sessionStorage.getItem("m")) sessionStorage.removeItem("m");
				if (sessionStorage.getItem("p")) sessionStorage.removeItem("p");
				if (sessionStorage.getItem("k")) sessionStorage.removeItem("k");
				if (sessionStorage.getItem("r")) sessionStorage.removeItem("r");
				if (sessionStorage.getItem("t")) sessionStorage.removeItem("t");
				if (sessionStorage.getItem("i")) sessionStorage.removeItem("i");

				this.dataFormulaireQuick.formule = "";
				this.dataFormulaire.formule = "";
				this.dataFormulaire = {
					nom: "",
					prenom: "",
					telephone: "",
					email: "",
					// montant: "",
					motif: "",
					devise: "XOF",
					amountPourboire: "",
					society: "",
					key_payment: "",
					deleg: "",
					grade: "",
					numeroCompte: "",
					societeEnBourse: "",
					nombreActions: "",
					boutique: "",
					immatriculation: "",
					service: "",
					formule: "",
				};
				this.dataFormulaireQuick = {
					nom: "",
					prenom: "",
					telephone: "",
					telephone2: "",
					email: "",
					// montant: "",
					motif: "",
					devise: "XOF",
					amountPourboire: "",
					society: "",
					key_payment: "",
					numeroCompte: "",
					societeEnBourse: "",
					nombreActions: "",
					boutique: "",
					immatriculation: "",
					service: "",
					formule: "",
				};

				// this.dataPage.amount = "";
				this.montantFormate = "";
				this.montantFormateQuick = "";

				this.scrollToTop();
				this.showPaymentSection = !this.showPaymentSection;
				// this.typeForm == "normal" ? this.formPayment = !this.formPayment : this.formQuiclyPayment = !this.formQuiclyPayment ;
				if (this.typeForm == "normal") {
					this.formPayment = !this.formPayment;
					return false;
				}

				if (this.typeForm == "quickly") {
					this.formQuiclyPayment = !this.formQuiclyPayment;
					return false;
				}

				if (this.typeForm == "superQuickly") {
					this.formSuperQuicklyPayment = !this.formSuperQuicklyPayment;
					return false;
				}
			},

			// BICICI payment, select type of card (Visa or Mastercard)
			selectCardType(event) {
				this.cardType = event.target.value;
			},

			// Fermer un modal, en paramètre on a la référence du modal que l'on souhaite fermer
			closeModal(refModal) {
				this.scrollToTop();

				if (refModal == "openPaymentModal") {
					this.logoOfSelectedService = "visa-qr-1.png";
				}

				if (this.typeForm == "quickly") {
					this.montantSave !== ""
						? (this.dataFormulaireQuick.montant = this.montantSave)
						: console.log("");
				} else {
					this.montantSave !== ""
						? (this.dataFormulaire.montant = this.montantSave)
						: console.log("");
				}

				this.$refs[refModal].hide();
			},

			onCancel() {
				console.log("Loader dismiss");
			},

			onCancelAPaym() {
				console.log("Loader APaym dismiss");
			},

			onCancelAPaymAuth() {
				console.log("Loader APaym Auth dismiss");
			},

			onCancelQr() {
				console.log("Loader QR Code dismiss");
			},

			onCancelMomo() {
				console.log("Loader MoMo dismiss");
			},

			onCancelTresorPay() {
				console.log("Loader Tresor dismiss");
			},

			onCancelOrange() {
				console.log("Loader Orange money dismiss");
			},

			onCancelWave() {
				console.log("Loader Wave dismiss");
			},

			onCancelFlooz() {
				console.log("Loader Flooz dismiss");
			},

			onCancelBicici() {
				console.log("Loader BICICI dismiss");
			},

			// Get information of payment page
			getInfosPage(slug) {
				this.isLoading = true;
				let dataSend = new FormData();

				// On va voir si il s'agit d'un qr_link ou d'un link simplement
				// <!-- Wave
				this.source_pay = window.sessionStorage.getItem("slug_info") !== "" &&
				window.sessionStorage.getItem("slug_info") != null &&
				window.sessionStorage.getItem("slug_info") !== undefined
					? 'qr_link'
					: 'link';

				dataSend.append("slug", slug);

				// console.log(this.source_pay)

				var req = axios
				.post(apiUrl + "api/paiement-marchand/page-infos", dataSend)
				.then((response) => {
					this.isLoading = false;
					if (response.data.code !== 1) {
						this.formLoader = true;
						this.showErrorPage = !this.showErrorPage;
						return false;
					}

					if (response.data.slug !== this.url_data.slug) {
						this.formLoader = true;
						this.showErrorPage = !this.showErrorPage;
						return false;
					}

					this.dataPage.taux_apaym = response.data.taux_apaym;
					this.dataPage.taux_cb = response.data.taux_cb;
					this.dataPage.taux_moov = response.data.taux_moov;
					this.dataPage.taux_mtn = response.data.taux_mtn;
					this.dataPage.taux_om = response.data.taux_om;
					this.dataPage.taux_wave = response.data.taux_wave;
					this.dataPage.name_page = response.data.name_page;
					this.dataPage.description_page_2 = response.data.description_page;
					this.dataPage.description_page = response.data.description_page
						? this.getDescriptionTronque(response.data.description_page, 90)
						: response.data.description_page;
					this.dataPage.business_logo =
						response.data.business_logo !== ""
							? "https://apaym.com/cms/images/logo_marchand/" +
							response.data.business_logo
							: "";
					this.dataPage.business_name = response.data.business_name;
					this.dataPage.business_vpan = response.data.vpan;
					this.dataPage.amount =
						response.data.amount !== ""
							? response.data.amount
							: sessionStorage.getItem("m") !== null &&
							sessionStorage.getItem("m") !== ""
								? sessionStorage.getItem("m")
								: "";
					this.showPayBySomeone =
						sessionStorage.getItem("k") !== null &&
						sessionStorage.getItem("k") !== ""
							? false
							: true;
					this.dataPage.page_redirect = response.data.page_redirect;
					this.dataPage.email_notify = response.data.email_notify;
					this.dataPage.success_message = response.data.success_message;
					this.dataPage.seo_image = response.data.seo_image;
					this.dataPage.slug = response.data.slug;
					this.dataPage.type = response.data.type;
					this.dataPage.nom = response.data.nom;
					this.dataPage.verified = response.data.verified;
					this.dataPage.rcc = response.data.rcc;
					this.dataPage.business_email = response.data.business_email;
					this.dataPage.business_phone = response.data.business_phone;
					this.dataPage.facebook = response.data.facebook;
					this.dataPage.instagram = response.data.instagram;
					this.dataPage.whatsapp = response.data.whatsapp;
					this.dataPage.site = response.data.site;
					this.dataPage.youtube = response.data.youtube;
					this.dataPage.twitter = response.data.twitter;
					this.dataPage.ville = response.data.ville;
					this.dataPage.qr_code = response.data.qr_code;
					this.dataPage.link = response.data.lien;
					this.dataPage.partner = response.data.partenaire;
					this.dataPage.pourboire = response.data.tip;
					this.dataPage.frais = response.data.fees;
					this.dataPage.deleg_pdci = response.data.deleg_pdci;
					this.dataPage.yango = response.data.yango;
					this.dataPage.air_france = response.data.air_france;
					this.dataPage.damac = response.data.damac;
					this.dataPage.baie_sirenes = response.data.baie_sirenes;
					this.dataPage.umed = response.data.umed;
					this.dataPage.collected_amount = response.data.collected_amount;
					this.dataPage.collected_qty = response.data.collected_qty;
					this.dataPage.hide_wgy_infos = response.data.hide_wgy_infos;
					this.dataPage.show_fields_by_default =
						response.data.show_fields_by_default;
					this.dataPage.show_email_by_default =
						response.data.show_email_by_default;
					this.dataPage.custom_fields = response.data.custom_fields;
					this.dataPage.urlRedirection = response.data.urlRedirection;
					this.dataPage.redirectWithCode = response.data.redirectWithCode;
					this.dataPage.fixedAmount = response.data.fixed_amount;
					this.dataPage.dureeLien = response.data.duree_lien;
					this.dataPage.subMerchant = response.data.subMerchantId;

					this.dataPage.eticket = [...response.data.eticket];
					// this.dataPage.amount = response.data.montant_fixe;
					this.dataPage.merchant = response.data.merchant;

					// Récupérer les boutiques ainsi que les agents des boutiques pour le marchand
					for (let donnee of response.data.boutiques) {
						if (donnee.responsables.length <= 0) {
							this.dataPage.boutiques.push({
								store_id: donnee.store_id,
								nom_boutique: donnee.nom_boutique.toUpperCase(),
								terminal_id: "",
							});
						} else {
							this.dataPage.boutiques.push({
								store_id: donnee.store_id,
								nom_boutique: donnee.nom_boutique.toUpperCase(),
								terminal_id: "",
							});

							for (let responsable of donnee.responsables) {
								if (responsable.terminal_id !== "0" && responsable.nom_agent) {
									this.dataPage.boutiques.push({
										store_id: donnee.store_id,
										nom_boutique: "          - " + responsable.nom_agent,
										terminal_id: responsable.terminal_id,
									});
								} else if (responsable.terminal_id !== "0") {
									this.dataPage.boutiques.push({
										store_id: donnee.store_id,
										nom_boutique:
											"          - Agent Nº " + responsable.terminal_id,
										terminal_id: responsable.terminal_id,
									});
								}
							}
						}
					}


					this.formLoader = true;
					this.indice.i =
						sessionStorage.getItem("i") !== undefined &&
						sessionStorage.getItem("i") !== null
							? sessionStorage.getItem("i")
							: "225";
					this.getIndiceJson();

					response.data.montant_fixe
						? (this.dataPage.amount = response.data.montant_fixe)
						: (this.dataPage.amount = this.dataPage.amount);


					response.data.montant_fixe
						? (this.dataPage.amount2 = response.data.montant_fixe)
						: (this.dataPage.amount2 = this.dataPage.amount);

					if (response.data.formulaireSimplifie == "1") {
						this.typeForm = "superQuickly";
						this.formSuperQuicklyPayment = !this.formSuperQuicklyPayment;
						this.montantFormateQuick = this.dataPage.amount;
						this.dataFormulaireQuick.telephone =
							sessionStorage.getItem("t") !== undefined &&
							sessionStorage.getItem("t") !== null
								? sessionStorage.getItem("t")
								: "";
						this.dataFormulaireQuick.nom =
							sessionStorage.getItem("n") !== undefined &&
							sessionStorage.getItem("n") !== null
								? sessionStorage.getItem("n")
								: "";
						this.dataFormulaireQuick.prenom =
							sessionStorage.getItem("p") !== undefined &&
							sessionStorage.getItem("p") !== null
								? sessionStorage.getItem("p")
								: "";
						this.dataFormulaireQuick.email =
							sessionStorage.getItem("e") !== undefined &&
							sessionStorage.getItem("e") !== null
								? sessionStorage.getItem("e")
								: "";
						this.dataFormulaireQuick.motif =
							sessionStorage.getItem("r") !== undefined &&
							sessionStorage.getItem("r") !== null
								? sessionStorage.getItem("r")
								: "";
						this.dataFormulaireQuick.key_payment =
							sessionStorage.getItem("k") !== undefined &&
							sessionStorage.getItem("k") !== null
								? sessionStorage.getItem("k")
								: "";
						this.dataFormulaireQuick.formule =
							sessionStorage.getItem("f") !== undefined &&
							sessionStorage.getItem("f") !== null
								? sessionStorage.getItem("f")
								: "";

						//SHOP
						this.dataFormulaireQuick.store_id =
							sessionStorage.getItem("s") !== undefined &&
							sessionStorage.getItem("s") !== null
								? sessionStorage.getItem("s")
								: "";
						this.dataFormulaireQuick.slug_boutique =
							sessionStorage.getItem("sb") !== undefined &&
							sessionStorage.getItem("sb") !== null
								? sessionStorage.getItem("sb")
								: "";

						let formule;
						if (this.dataFormulaireQuick.formule) {
							formule = this.dataPage.eticket.filter(
								(form) => form.code_formule == this.dataFormulaireQuick.formule
							);
							this.getMontantFormule(this.typeForm, formule[0]);
						}
						// response.data.deleg_pdci == "3" signifie qu'on est sur le lien Abidjan.net Taxi (Pour les chauffeurs Yango)
						response.data.yango == 1
							? (this.dataFormulaireQuick.motif = "Rechargement")
							: "";
					} else {
						if (response.data.paye_vite == "1") {
							this.typeForm = "quickly";
							this.formQuiclyPayment = !this.formQuiclyPayment;
							this.montantFormateQuick = this.dataPage.amount;
							this.dataFormulaireQuick.telephone =
								sessionStorage.getItem("t") !== undefined &&
								sessionStorage.getItem("t") !== null
									? sessionStorage.getItem("t")
									: "";
							this.dataFormulaireQuick.nom =
								sessionStorage.getItem("n") !== undefined &&
								sessionStorage.getItem("n") !== null
									? sessionStorage.getItem("n")
									: "";
							this.dataFormulaireQuick.prenom =
								sessionStorage.getItem("p") !== undefined &&
								sessionStorage.getItem("p") !== null
									? sessionStorage.getItem("p")
									: "";
							this.dataFormulaireQuick.email =
								sessionStorage.getItem("e") !== undefined &&
								sessionStorage.getItem("e") !== null
									? sessionStorage.getItem("e")
									: "";
							this.dataFormulaireQuick.motif =
								sessionStorage.getItem("r") !== undefined &&
								sessionStorage.getItem("r") !== null
									? sessionStorage.getItem("r")
									: "";
							this.dataFormulaireQuick.key_payment =
								sessionStorage.getItem("k") !== undefined &&
								sessionStorage.getItem("k") !== null
									? sessionStorage.getItem("k")
									: "";
							this.dataFormulaireQuick.formule =
								sessionStorage.getItem("f") !== undefined &&
								sessionStorage.getItem("f") !== null
									? sessionStorage.getItem("f")
									: "";

							//SHOP
							this.dataFormulaireQuick.store_id =
								sessionStorage.getItem("s") !== undefined &&
								sessionStorage.getItem("s") !== null
									? sessionStorage.getItem("s")
									: "";
							this.dataFormulaireQuick.slug_boutique =
								sessionStorage.getItem("sb") !== undefined &&
								sessionStorage.getItem("sb") !== null
									? sessionStorage.getItem("sb")
									: "";

							let formule;
							if (this.dataFormulaireQuick.formule) {
								formule = this.dataPage.eticket.filter(
									(form) =>
										form.code_formule == this.dataFormulaireQuick.formule
								);
								this.getMontantFormule(this.typeForm, formule[0]);
							}
							// response.data.deleg_pdci == "3" signifie qu'on est sur le lien Abidjan.net Taxi (Pour les chauffeurs Yango)
							response.data.yango == 1
								? (this.dataFormulaireQuick.motif = "Rechargement")
								: "";
						} else if (response.data.paye_vite !== "1") {
							this.typeForm = "normal";
							this.formPayment = !this.formPayment;
							this.montantFormate = this.dataPage.amount;
							this.dataFormulaire.telephone =
								sessionStorage.getItem("t") !== undefined &&
								sessionStorage.getItem("t") !== null
									? sessionStorage.getItem("t")
									: "";
							this.dataFormulaire.nom =
								sessionStorage.getItem("n") !== undefined &&
								sessionStorage.getItem("n") !== null
									? sessionStorage.getItem("n")
									: "";
							this.dataFormulaire.prenom =
								sessionStorage.getItem("p") !== undefined &&
								sessionStorage.getItem("p") !== null
									? sessionStorage.getItem("p")
									: "";
							this.dataFormulaire.email =
								sessionStorage.getItem("e") !== undefined &&
								sessionStorage.getItem("e") !== null
									? sessionStorage.getItem("e")
									: "";
							this.dataFormulaire.motif =
								sessionStorage.getItem("r") !== undefined &&
								sessionStorage.getItem("r") !== null
									? sessionStorage.getItem("r")
									: "";
							this.dataFormulaire.key_payment =
								sessionStorage.getItem("k") !== undefined &&
								sessionStorage.getItem("k") !== null
									? sessionStorage.getItem("k")
									: "";
							this.dataFormulaire.formule =
								sessionStorage.getItem("f") !== undefined &&
								sessionStorage.getItem("f") !== null
									? sessionStorage.getItem("f")
									: "";

							//SHOP
							this.dataFormulaire.store_id =
								sessionStorage.getItem("s") !== undefined &&
								sessionStorage.getItem("s") !== null
									? sessionStorage.getItem("s")
									: "";
							this.dataFormulaire.slug_boutique =
								sessionStorage.getItem("sb") !== undefined &&
								sessionStorage.getItem("sb") !== null
									? sessionStorage.getItem("sb")
									: "";

							let formule;

							if (this.dataFormulaire.formule) {
								formule = this.dataPage.eticket.filter(
									(form) => form.code_formule == this.dataFormulaire.formule
								);
								this.getMontantFormule(this.typeForm, formule[0]);
							}

							response.data.yango == 1
								? (this.dataFormulaire.motif = "Rechargement")
								: "";
						}
					}

					// Traitons les modes de paiement actif pour le marchand
					for (let i = 0; i < response.data.modes_paiement.length; i++) {
						for (let j = 0; j < this.modesPaiementDispo.length; j++) {
							if (
								response.data.modes_paiement[i] ==
								this.modesPaiementDispo[j].mode
							) {
								if (this.modesPaiementDispo[j].mode === 'wave' && this.source_pay === 'qr_link') {
									// console.log("<=== Ne pas afficher wave ===>")
									this.modesPaiementDispo[j].actif = "non";
								} else {
									this.modesPaiementDispo[j].actif = "oui";
								}
							}
						}
					}

					// console.table(this.modesPaiementDispo)

					// modesPaiementDispo2
					for (let i = 0; i < response.data.modes_paiement.length; i++) {
						for (let j = 0; j < this.modesPaiementDispo2.length; j++) {
							if (
								response.data.modes_paiement[i] ==
								this.modesPaiementDispo2[j].mode
							) {
								if (this.modesPaiementDispo2[j].mode === 'wave' && this.source_pay === 'qr_link') {
									this.modesPaiementDispo2[j].actif = "non";
								} else {
									this.modesPaiementDispo2[j].actif = "oui";
								}
							}
						}
					}

					// Exécuter la fonction de pré-enregistrement en fonction du typeForm
					if (response.data.formulaireSimplifie == "1") {
						sessionStorage.getItem("redirect_value") !== undefined &&
						sessionStorage.getItem("redirect_value") !== null
							? this.payerJusteLeMontant()
							: console.log("------- Nope -------");
					} else {
						if (response.data.paye_vite !== "1") {
							sessionStorage.getItem("redirect_value") !== undefined &&
							sessionStorage.getItem("redirect_value") !== null
								? this.payer()
								: console.log("------- Nope -------");
						} else {
							sessionStorage.getItem("redirect_value") !== undefined &&
							sessionStorage.getItem("redirect_value") !== null
								? this.payerQuickly()
								: console.log("------- Nope -------");
						}
					}
				})
				.catch((error) => {
					console.log(error);
					this.isLoading = false;
					this.$swal("Une erreur est survenue !", error, "error");
				});

				req.then((x) => console.log("Done! " + x));
			},

			getDescriptionTronque(text, nombreCharAtronquer) {
				let delim = " [suite...]",
					length = nombreCharAtronquer;
				if (nombreCharAtronquer < text.length) {
					while (text[length] != " " && length > 0) {
						length--;
					}

					if (length == 0) {
						return text.substring(0, nombreCharAtronquer) + delim;
					}

					return text.substring(0, length) + delim;
				}

				return text;
			},

			getDescriptionTronque2(text, nombreCharAtronquer) {
				let delim = "...",
					length = nombreCharAtronquer;
				if (nombreCharAtronquer < text.length) {
					while (text[length] != " " && length > 0) {
						length--;
					}

					if (length == 0) {
						return text.substring(0, nombreCharAtronquer) + delim;
					}

					return text.substring(0, length) + delim;
				}

				return text;
			},

			// Open calculator modal
			openCalculator() {
				this.$refs["openCalculatorModal"].show();
			},

			// Form normal payment
			payer() {
				this.dataPage.deleg_pdci !== "1"
					? (this.dataFormulaire.montant = this.montantFormate.replace(/\s/g, ""))
					: "";

				this.dataPage.fixedAmount !== "0"
					? (this.dataFormulaire.motif = "Achat carte marchand Pro ")
					: console.log("*****");

				if (this.dataPage.custom_fields == "1") {
					this.dataFormulaire.nom = "Client";
					this.dataFormulaire.prenom = "inconnu";
					this.dataFormulaire.motif = this.dataFormulaire.service;
				}

				if (
					this.dataPage.merchant !== "24111" && this.dataPage.merchant !== "25010" && this.dataPage.merchant !== "24714" &&
					(this.dataFormulaire.nom.replace(/\s/g, "") == "" ||
						this.dataFormulaire.prenom.replace(/\s/g, "") == "" ||
						this.dataFormulaire.telephone.replace(/\s/g, "") == "" ||
						this.dataFormulaire.montant.replace(/\s/g, "") == "" ||
						this.dataFormulaire.motif.replace(/\s/g, "") == "")
				) {
					sweetAlert(
						this.$swal,
						true,
						"top-end",
						"info",
						"Info",
						"Veuillez renseigner tous les champs non optionnels !"
					);
					return false;
				}


				this.dataPage.merchant === '25010' ? this.dataFormulaire.motif = "Paiement chez CONVERSATION" : '';

				if (
					this.dataPage.merchant === '25010' &&
					(
						this.dataFormulaire.nom.replace(/\s/g, "") == "" || this.dataFormulaire.prenom.replace(/\s/g, "") == "" ||
						this.dataFormulaire.telephone.replace(/\s/g, "") == "" || this.dataFormulaire.email.replace(/\s/g, "") == ""
					)
				) {

					sweetAlert(
						this.$swal,
						true,
						"top-end",
						"info",
						"Info",
						"Veuillez renseigner tous les champs non optionnels !"
					);

					return false;
				}


				if (
					this.dataPage.merchant === "24111" &&
					this.dataFormulaire.typeClient === "Particulier" &&
					(this.dataFormulaire.nom.replace(/\s/g, "") == "" ||
						this.dataFormulaire.prenom.replace(/\s/g, "") == "")
				) {
					sweetAlert(
						this.$swal,
						true,
						"top-end",
						"info",
						"Info",
						"Veuillez renseigner tous les champs non optionnels !"
					);
					return false;
				}

				if (
					(this.dataPage.merchant === "24111" &&
						this.dataFormulaire.typeClient === "Entreprise" &&
						this.dataFormulaire.nomEntreprise.replace(/\s/g, "") == "") ||
					(this.dataFormulaire.motif === "Règlement prime auto" &&
						this.dataFormulaire.immatriculation.replace(/\s/g, "") == "")
				) {
					sweetAlert(
						this.$swal,
						true,
						"top-end",
						"info",
						"Info",
						"Veuillez renseigner tous les champs non optionnels !"
					);
					return false;
				}

				if (
					this.dataPage.merchant === "24714" &&
					(this.dataFormulaire.quantite == "" ||
						this.dataFormulaire.adresse == "")
				) {
					sweetAlert(
						this.$swal,
						true,
						"top-end",
						"info",
						"Info",
						"Veuillez renseigner tous les champs non optionnels !"
					);
					return false;
				}

				if (isNaN(this.dataFormulaire.montant)) {
					sweetAlert(
						this.$swal,
						true,
						"top-end",
						"info",
						"Info",
						"Veuillez renseigner un montant correct !"
					);
					return false;
				}

				/*if ( this.dataPage.merchant == "20483" )
                {*/
				if (this.dataFormulaire.montant % 5 !== 0) {
					sweetAlert(
						this.$swal,
						true,
						"top-end",
						"info",
						"Info",
						"Veuillez renseigner un montant mutiple de 5 !"
					);
					return false;
				}

				if (this.dataPage.deleg_pdci == "1") {
					if (
						this.dataFormulaire.deleg.name == "" ||
						this.dataFormulaire.deleg.name == undefined
					) {
						sweetAlert(
							this.$swal,
							true,
							"top-end",
							"info",
							"Info",
							"Veuillez renseigner la délégation !"
						);
						return false;
					}

					if (this.dataFormulaire.motif == "") {
						sweetAlert(
							this.$swal,
							true,
							"top-end",
							"info",
							"Info",
							"Veuillez renseigner le motif !"
						);
						return false;
					}

					if (
						this.dataFormulaire.grade.organe == "" ||
						this.dataFormulaire.grade.organe == undefined
					) {
						sweetAlert(
							this.$swal,
							true,
							"top-end",
							"info",
							"Info",
							"Veuillez renseigner le grade !"
						);
						return false;
					}
				} else if (this.dataPage.damac === 1) {
					if (this.dataFormulaire.motif == "") {
						sweetAlert(
							this.$swal,
							true,
							"top-end",
							"info",
							"Info",
							"Veuillez renseigner le numéro d'appartement !"
						);
						return false;
					}
				} else {
					// console.log("") ;
				}

				// Les marchands de type SGI
				if (this.dataPage.type === "SGI") {
					if (
						this.dataFormulaire.numeroCompte == "" ||
						this.dataFormulaire.numeroCompte <= 0
					) {
						sweetAlert(
							this.$swal,
							true,
							"top-end",
							"info",
							"Veuillez renseigner votre numéro de compte titre !",
							""
						);
						return false;
					}
				}
				// Les marchands de type SGI


				// BOA CAPITAL SECUTIES
				if (this.dataPage.merchant === '24821') {
					if (
						this.dataFormulaire.numeroCompte == "" ||
						this.dataFormulaire.numeroCompte <= 0
					) {
						sweetAlert(
							this.$swal,
							true,
							"top-end",
							"info",
							"Veuillez renseigner votre numéro de compte titre !",
							""
						);
						return false;
					}
				}
				// BOA CAPITAL SECUTIES

				if (this.dataPage.custom_fields == "1") {
					if (
						this.dataFormulaire.immatriculation.replace(/\s/g, "") == "" ||
						this.dataFormulaire.service == ""
					) {
						sweetAlert(
							this.$swal,
							true,
							"top-end",
							"info",
							"Veuillez renseigner votre immatriculation ainsi que le type de service !",
							""
						);
						return false;
					}
				}

				if (this.dataFormulaire.email.replace(/\s/g, "")) {
					if (!this.validateEmail(this.dataFormulaire.email.replace(/\s/g, ""))) {
						sweetAlert(
							this.$swal,
							true,
							"top-end",
							"info",
							"Info",
							"Veuillez renseigner une adresse e-mail correcte !"
						);
						return false;
					}
				}

				if (this.dataFormulaire.nom.replace(/\s/g, "")) {
					if (!this.validateString(this.dataFormulaire.nom.replace(/\s/g, ""))) {
						sweetAlert(
							this.$swal,
							true,
							"top-end",
							"info",
							"Info",
							"Veuillez renseigner un nom correct !"
						);
						return false;
					}
				}

				if (this.dataFormulaire.prenom.replace(/\s/g, "")) {
					if (!this.validateString(trim(this.dataFormulaire.prenom))) {
						sweetAlert(
							this.$swal,
							true,
							"top-end",
							"info",
							"Info",
							"Veuillez renseigner un prénom correct !"
						);
						return false;
					}
				}

				if (this.dataFormulaire.society.replace(/\s/g, "")) {
					if (!this.validateString(trim(this.dataFormulaire.society))) {
						sweetAlert(
							this.$swal,
							true,
							"top-end",
							"info",
							"Info",
							"Veuillez renseigner un nom de société correct !"
						);
						return false;
					}
				}

				if (this.dataFormulaire.motif.replace(/\s/g, "")) {
					if (!this.validateSpecialString(trim(this.dataFormulaire.motif))) {
						sweetAlert(
							this.$swal,
							true,
							"top-end",
							"info",
							"Info",
							"Veuillez renseigner un motif correct !"
						);
						return false;
					}
				}

				if (this.dataPage.eticket.length > 0) {
					if (this.dataFormulaire.formule == "") {
						sweetAlert(
							this.$swal,
							true,
							"top-end",
							"info",
							"Info",
							"Veuillez sélectionner une formule de ticket !"
						);
						return false;
					}
				}

				this.montantSave =
					parseFloat(this.dataFormulaire.montant) +
					(this.pourboire !== "autre"
						? parseFloat(this.pourboire)
						: this.dataFormulaire.amountPourboire
							? parseFloat(this.dataFormulaire.amountPourboire)
							: 0);

				this.montantAPayer = parseFloat(this.dataFormulaire.montant);

				// Set toutes la values à false
				this.qrCodeUri = "";
				this.orangeOkay = false;
				this.orangeNOkay = false;
				this.mtnOkay = false;
				this.mtnNOkay = false;
				this.moovNOkay = false;
				this.moovOkay = false;
				this.tresorOkay = false;
				this.tresorNOkay = false;

				// Init Payment process
				this.isLoading = true;
				let dataSend = new FormData();
				dataSend.append("code_page", this.url_data.slug);
				dataSend.append(
					"nom",
					this.dataFormulaire.typeClient === "Entreprise"
						? trim(this.dataFormulaire.nomEntreprise)
						: trim(this.dataFormulaire.nom)
				);
				dataSend.append("prenom", trim(this.dataFormulaire.prenom));
				dataSend.append(
					"numero",
					this.indice.i.code +
					"" +
					this.dataFormulaire.telephone.replace(/\s/g, "")
				);
				dataSend.append("indice", this.indice.i.code);
				dataSend.append("amount", this.dataFormulaire.montant);
				dataSend.append("email", trim(this.dataFormulaire.email));
				dataSend.append("adresse", trim(this.dataFormulaire.adresse));
				dataSend.append("quantite", trim(this.dataFormulaire.quantite));
				dataSend.append("vip", trim(this.dataFormulaire.vip));
				dataSend.append("numero_police", trim(this.dataFormulaire.numeroPolice));
				dataSend.append(
					"numero_quittance",
					trim(this.dataFormulaire.numeroQuittance)
				);
				dataSend.append("motif", trim(this.dataFormulaire.motif));
				dataSend.append(
					"societe",
					this.dataPage.deleg_pdci !== "1"
						? trim(this.dataFormulaire.society)
						: trim(this.dataFormulaire.deleg.name)
				);
				dataSend.append("grade", trim(this.dataFormulaire.grade.organe));
				dataSend.append("key_payment", trim(this.dataFormulaire.key_payment));
				dataSend.append("numero_compte", this.dataFormulaire.numeroCompte);
				dataSend.append(
					"societe_en_bourse",
					this.dataFormulaire.societeEnBourse.company == undefined
						? ""
						: this.dataFormulaire.societeEnBourse.company
				);
				dataSend.append(
					"nombre_actions",
					parseFloat(this.dataFormulaire.nombreActions)
				);
				dataSend.append("send_customer_notif", this.sendNotif);
				// Valeurs de conversion
				dataSend.append("conversion_devise", this.devise);
				dataSend.append("conversion_rate", this.taux_apaym);
				dataSend.append(
					"montant_devise",
					this.conversionRate !== ""
						? this.dataFormulaire.montant / this.conversionRate
						: this.montantDevise
				);
				dataSend.append("deleg_pdci", this.dataPage.deleg_pdci);
				dataSend.append("adresse_ip", this.dataYangoRechargement.adresse_ip);
				dataSend.append(
					"device_type",
					this.$browserDetect.meta.name +
					" " +
					this.$browserDetect.meta.version +
					" " +
					this.$browserDetect.meta.ua
				);
				dataSend.append("store_id", this.dataFormulaire.boutique.store_id);
				dataSend.append("terminal_id", this.dataFormulaire.boutique.terminal_id);
				dataSend.append(
					"immatriculation",
					this.dataFormulaire.immatriculation.toUpperCase()
				);
				dataSend.append("service", this.dataFormulaire.service);
				dataSend.append(
					"code_formule",
					this.isObject(this.dataFormulaire.formule)
						? this.dataFormulaire.formule.code_formule
						: this.dataFormulaire.formule
				);
				dataSend.append(
					"store_id",
					window.sessionStorage.getItem("s") !== "" &&
					window.sessionStorage.getItem("s") != null &&
					window.sessionStorage.getItem("s") !== undefined
						? window.sessionStorage.getItem("s")
						: this.dataFormulaire.boutique.store_id
				);
				dataSend.append(
					"slug_boutique",
					window.sessionStorage.getItem("sb") !== ""
						? window.sessionStorage.getItem("sb")
						: ""
				);

				//  Zino Cigares
				if (this.dataPage.merchant === "24714") {
					dataSend.append("motif", trim("Précommande de boîte de cigare"));
				}

				// source pay
				dataSend.append(
					"source_pay",
					window.sessionStorage.getItem("slug_info") !== "" &&
					window.sessionStorage.getItem("slug_info") != null &&
					window.sessionStorage.getItem("slug_info") !== undefined
						? 'qr_link'
						: 'link'
				);

				axios
				.post(apiUrl + "api/paiement-marchand/initier-paiement", dataSend)
				.then((response) => {
					this.isLoading = false;
					if (response.data.code == 1) {
						this.scrollToTop();

						// _reference.set("ref_"+ this.url_data.slug +"_"+ response.data["reference"]);
						this.url_data.reference = response.data["reference"];
						window.localStorage.setItem(
							"_reference_" + this.url_data.slug,
							response.data["reference"]
						);
						this.refPayment = response.data["reference"];
						// this.$refs['openPaymentModal'].show();
						this.showPaymentSection = true;
						this.formPayment = !this.formPayment;

						// Set the phone number to APaym login
						this.dataApaymPayAuth.numApaym = this.dataFormulaire.telephone;
						this.dataApaymPay.numApaym = this.dataFormulaire.telephone;

						this.indiceToShow = this.indice.i.code;
						this.dataApaymPay.indice = this.indice.i.code;
						this.dataApaymPayAuth.indice = this.indice.i.code;
						this.indice.i = "225";
						this.getIndiceJson();

						// Set MTN MoMo number if it begins by 05 or Set Moov Money number if it begins by 01
						var first2Chiffres = this.dataFormulaire.telephone
						.replace(/\s/g, "")
						.substring(0, 2);

						if (first2Chiffres == "05") {
							this.dataMoMo.numero = this.dataFormulaire.telephone;
						} else if (first2Chiffres == "01") {
							this.dataMoov.numero = this.dataFormulaire.telephone;
						}

						// Formulaire Visa/Mastercard
						this.dataVisaCard.cardHolderName =
							trim(this.dataFormulaire.nom) +
							" " +
							trim(this.dataFormulaire.prenom);

						this.typeForm = "normal";
						// Get Additional Data
						this.dataYangoRechargement.driver_profil_id =
							response.data["driver_profil_id"];
						this.dataYangoRechargement.driver_phone_number =
							response.data["driver_phone_number"];
						this.dataYangoRechargement.profil_id = response.data["profil_id"];
						this.dataYangoRechargement.ref_transaction =
							response.data["reference"];

						// Nombre de transactions pour connaitre le statut du client
						// BLEU (1 - 4), BRONZE (5 - 9), SILVER (10 - 19), GOLD (20 - 29), PLATINUM (30 - Infini)
						this.nombre_transactions = response.data["nombre_transactions"];
						this.is_platinum_card = response.data["is_platinum_card"];

						if (this.nombre_transactions >= 0) {
							if (this.nombre_transactions <= 20) {
								this.statusClient = "Bronze";
								this.imgStatus = "bronze.png";
							} else if (this.nombre_transactions <= 40) {
								this.statusClient = "Silver";
								this.imgStatus = "silver.png";
							} else if (this.nombre_transactions <= 65) {
								this.statusClient = "Gold";
								this.imgStatus = "gold.png";
							} else if (this.nombre_transactions <= 90) {
								this.statusClient = "Platinum";
								this.imgStatus = "platinum.png";
							} else if (this.nombre_transactions <= 130) {
								this.statusClient = "Diamond";
								this.imgStatus = "diamond.png";
							} else {
								this.statusClient = "Infinite";
								this.imgStatus = "infinite.png";
							}

							if (
								this.is_platinum_card == "1" &&
								this.nombre_transactions <= 90
							) {
								this.statusClient = "Platinum";
								this.imgStatus = "platinum.png";
							}
						} else {
							this.nombre_transactions = ""; // Le client n'est pas un client APaym
						}

						// Carte de fidélité
						this.loyalty_card = response.data["loyalty_card"];
						this.nombre_paiements = response.data["nombre_paiements"];
						this.nombre_points = parseFloat(response.data["nombre_points"]);
						this.cadeau_a_offrir = response.data["cadeau_a_offrir"];
						this.pointLoyalty = [];
						this.nombre_points -= 1;

						if (this.nombre_paiements <= this.nombre_points) {
							for (let i = 0; i < this.nombre_paiements; i++) {
								this.pointLoyalty.push({
									id: i + 1,
									paid: 1,
								});
							}

							let reste = this.nombre_points - this.nombre_paiements;
							let nbr = this.nombre_paiements;
							// for ( let i = reste ; i > 0; i-- ) {
							for (let i = 1; i <= reste; i++) {
								this.pointLoyalty.push({
									id: nbr + 1,
									paid: 0,
								});
								nbr += 1;
							}
						} else {
							for (let i = 0; i < this.nombre_points; i++) {
								this.pointLoyalty.push({
									id: i + 1,
									paid: 0,
								});
							}
						}

						return false;
					}

					sweetAlert(
						this.$swal,
						true,
						"top-end",
						"error",
						"Erreur",
						response.data["message"]
					);
					this.dataFormulaire.montant = this.montantSave;
				})
				.catch((error) => {
					this.isLoading = false;
					console.log(error);
					sweetAlert(
						this.$swal,
						true,
						"top-end",
						"error",
						"Erreur",
						"Une erreur est survenue, veuillez réessayer plus tard"
					);
				});
			},

			// Form quickly payment
			payerQuickly() {
				this.dataFormulaireQuick.montant = this.montantFormateQuick.replace(
					/\s/g,
					""
				);

				if (this.dataFormulaireQuick.montant == "") {
					sweetAlert(
						this.$swal,
						true,
						"top-end",
						"info",
						"Veuillez renseigner le montant !",
						""
					);
					return false;
				}

				if (isNaN(this.dataFormulaireQuick.montant)) {
					sweetAlert(
						this.$swal,
						true,
						"top-end",
						"info",
						"Veuillez renseigner un montant correct !",
						""
					);
					return false;
				}


				if (this.dataFormulaireQuick.montant % 5 !== 0) {
					sweetAlert(
						this.$swal,
						true,
						"top-end",
						"info",
						"Info",
						"Veuillez renseigner un montant mutiple de 5 !"
					);
					return false;
				}

				if (this.dataPage.merchant !== '24823') {

					if (this.dataPage.show_fields_by_default == "1") {

						if (
							this.dataFormulaireQuick.motif.replace(/\s/g, "") == "" ||
							this.dataFormulaireQuick.email.replace(/\s/g, "") == "" ||
							this.dataFormulaireQuick.society.replace(/\s/g, "") == "" ||
							this.dataFormulaireQuick.nom.replace(/\s/g, "") == "" ||
							this.dataFormulaireQuick.prenom.replace(/\s/g, "") == "" ||
							this.dataFormulaireQuick.telephone.replace(/\s/g, "") == ""
						) {
							sweetAlert(
								this.$swal,
								true,
								"top-end",
								"info",
								"Info",
								"Veuillez renseigner tous les champs !"
							);
							return false;
						}

						if (
							!this.validateEmail(this.dataFormulaireQuick.email.replace(/\s/g, ""))
						) {
							sweetAlert(
								this.$swal,
								true,
								"top-end",
								"info",
								"Info",
								"Veuillez renseigner une adresse e-mail correcte !"
							);
							return false;
						}

						if (!this.validateString(trim(this.dataFormulaireQuick.nom))) {
							sweetAlert(
								this.$swal,
								true,
								"top-end",
								"info",
								"Info",
								"Veuillez renseigner un nom correct !"
							);
							return false;
						}

						if (!this.validateString(trim(this.dataFormulaireQuick.prenom))) {
							sweetAlert(
								this.$swal,
								true,
								"top-end",
								"info",
								"Info",
								"Veuillez renseigner un prénom correct !"
							);
							return false;
						}

						if (!this.validateString(trim(this.dataFormulaireQuick.society))) {
							sweetAlert(
								this.$swal,
								true,
								"top-end",
								"info",
								"Info",
								"Veuillez renseigner un nom de société correct !"
							);
							return false;
						}
					}

					if (this.dataPage.show_fields_by_default !== "1") {
						this.dataPage.custom_fields == "1"
							? (this.dataFormulaireQuick.motif = this.dataFormulaireQuick.service)
							: "";

						if (
							this.dataFormulaireQuick.motif.replace(/\s/g, "") == "" ||
							this.dataFormulaireQuick.telephone.replace(/\s/g, "") == ""
						) {
							sweetAlert(
								this.$swal,
								true,
								"top-end",
								"info",
								"Info",
								"Veuillez renseigner tous les champs !"
							);
							return false;
						}
					}

					if (this.dataPage.custom_fields == "1") {
						if (
							this.dataFormulaireQuick.immatriculation.replace(/\s/g, "") == "" ||
							this.dataFormulaireQuick.service == ""
						) {
							sweetAlert(
								this.$swal,
								true,
								"top-end",
								"info",
								"Veuillez renseigner votre immatriculation ainsi que le type de service !",
								""
							);
							return false;
						}
					}

					if (this.dataFormulaireQuick.email.replace(/\s/g, "")) {
						if (
							!this.validateEmail(this.dataFormulaireQuick.email.replace(/\s/g, ""))
						) {
							sweetAlert(
								this.$swal,
								true,
								"top-end",
								"info",
								"Info",
								"Veuillez renseigner une adresse e-mail correcte !"
							);
							return false;
						}
					}

					if (this.dataFormulaireQuick.nom.replace(/\s/g, "")) {
						if (
							!this.validateString(this.dataFormulaireQuick.nom.replace(/\s/g, ""))
						) {
							sweetAlert(
								this.$swal,
								true,
								"top-end",
								"info",
								"Info",
								"Veuillez renseigner un nom correct !"
							);
							return false;
						}
					}

					if (this.dataFormulaireQuick.prenom.replace(/\s/g, "")) {
						if (
							!this.validateString(
								this.dataFormulaireQuick.prenom.replace(/\s/g, "")
							)
						) {
							sweetAlert(
								this.$swal,
								true,
								"top-end",
								"info",
								"Info",
								"Veuillez renseigner un prénom correct !"
							);
							return false;
						}
					}

					if (this.dataFormulaireQuick.society.replace(/\s/g, "")) {
						if (
							!this.validateString(
								this.dataFormulaireQuick.society.replace(/\s/g, "")
							)
						) {
							sweetAlert(
								this.$swal,
								true,
								"top-end",
								"info",
								"Info",
								"Veuillez renseigner un nom de société correct !"
							);
							return false;
						}
					}

					if (this.dataFormulaireQuick.motif.replace(/\s/g, "")) {
						if (!this.validateSpecialString(trim(this.dataFormulaireQuick.motif))) {
							sweetAlert(
								this.$swal,
								true,
								"top-end",
								"info",
								"Info",
								"Veuillez renseigner un motif correct !"
							);
							return false;
						}
					}

					if (this.dataPage.eticket.length > 0) {
						if (this.dataFormulaireQuick.formule == "") {
							sweetAlert(
								this.$swal,
								true,
								"top-end",
								"info",
								"Info",
								"Veuillez sélectionner une formule de ticket !"
							);
							return false;
						}
					}
				}

				if (this.dataPage.merchant === '24823') {

					this.dataFormulaireQuick.motif = "Paiement ticket(s) MEET & GREAT NINHO"

					if (this.dataFormulaireQuick.telephone.replace(/\s/g, "") == "") {
						sweetAlert(
							this.$swal,
							true,
							"top-end",
							"info",
							"Info",
							"Veuillez renseigner un numéro de téléphone correct !"
						);
						return false;
					}

					if (this.dataFormulaireQuick.email.replace(/\s/g, "") === "") {

						sweetAlert(
							this.$swal,
							true,
							"top-end",
							"info",
							"Info",
							"Veuillez renseigner une adresse e-mail correcte !"
						);
						return false;

					}

					if (this.dataFormulaireQuick.nom.replace(/\s/g, "") === "") {

						sweetAlert(
							this.$swal,
							true,
							"top-end",
							"info",
							"Info",
							"Veuillez renseigner un nom correct !"
						);
						return false;

					}

					if (this.dataFormulaireQuick.prenom.replace(/\s/g, "") === "") {

						sweetAlert(
							this.$swal,
							true,
							"top-end",
							"info",
							"Info",
							"Veuillez renseigner un prénom correct !"
						);
						return false;

					}

				}

				this.montantSave =
					parseFloat(this.dataFormulaireQuick.montant) +
					(this.pourboire !== "autre"
						? parseFloat(this.pourboire)
						: this.dataFormulaireQuick.amountPourboire
							? parseFloat(this.dataFormulaireQuick.amountPourboire)
							: 0);

				this.montantAPayer = parseFloat(this.dataFormulaireQuick.montant);

				// Set toutes la values à false
				this.qrCodeUri = "";
				this.orangeOkay = false;
				this.orangeNOkay = false;
				this.mtnOkay = false;
				this.mtnNOkay = false;
				this.moovNOkay = false;
				this.moovOkay = false;
				this.tresorOkay = false;
				this.tresorNOkay = false;

				// Init Payment process
				this.isLoading = true;
				let dataSend = new FormData();
				dataSend.append("code_page", this.url_data.slug);
				dataSend.append(
					"nom",
					this.dataFormulaireQuick.nom !== ""
						? trim(this.dataFormulaireQuick.nom)
						: "Client"
				);
				dataSend.append(
					"prenom",
					this.dataFormulaireQuick.prenom !== ""
						? trim(this.dataFormulaireQuick.prenom)
						: "inconnu"
				);
				dataSend.append(
					"numero",
					this.dataFormulaireQuick.telephone !== ""
						? this.indice.i.code +
						"" +
						this.dataFormulaireQuick.telephone.replace(/\s/g, "")
						: "Numero inconnu"
				);
				dataSend.append(
					"numero2",
					this.dataFormulaireQuick.telephone2 !== ""
						? this.indice.i.code +
						"" +
						this.dataFormulaireQuick.telephone2.replace(/\s/g, "")
						: "Numero inconnu"
				);
				dataSend.append(
					"indice",
					this.indice.i.code !== "" ? this.indice.i.code : "Indice inconnu"
				);
				dataSend.append("amount", this.dataFormulaireQuick.montant);
				// dataSend.append("frais", frais);
				dataSend.append(
					"email",
					this.dataFormulaireQuick.email !== ""
						? trim(this.dataFormulaireQuick.email)
						: ""
				);
				dataSend.append(
					"motif",
					this.dataFormulaireQuick.motif !== ""
						? trim(this.dataFormulaireQuick.motif)
						: ""
				);
				dataSend.append(
					"societe",
					this.dataFormulaireQuick.society !== ""
						? trim(this.dataFormulaireQuick.society)
						: ""
				);
				dataSend.append(
					"key_payment",
					trim(this.dataFormulaireQuick.key_payment)
				);
				dataSend.append("numero_compte", "");
				dataSend.append("societe_en_bourse", "");
				dataSend.append("nombre_actions", 0);
				dataSend.append("send_customer_notif", this.sendNotif);
				dataSend.append("conversion_devise", this.devise);
				dataSend.append("conversion_rate", this.taux_apaym);
				// dataSend.append("montantDevise", this.montantDevise);
				dataSend.append(
					"montant_devise",
					this.conversionRate !== ""
						? this.dataFormulaireQuick.montant / this.conversionRate
						: this.montantDevise
				);
				dataSend.append("deleg_pdci", this.dataPage.deleg_pdci);
				dataSend.append("adresse_ip", this.dataYangoRechargement.adresse_ip);
				dataSend.append(
					"device_type",
					this.$browserDetect.meta.name +
					" " +
					this.$browserDetect.meta.version +
					" " +
					this.$browserDetect.meta.ua
				);
				dataSend.append("store_id", this.dataFormulaireQuick.boutique.store_id);
				dataSend.append(
					"terminal_id",
					this.dataFormulaireQuick.boutique.terminal_id
				);
				dataSend.append(
					"immatriculation",
					this.dataFormulaireQuick.immatriculation.toUpperCase()
				);
				dataSend.append("service", this.dataFormulaireQuick.service);
				dataSend.append(
					"code_formule",
					this.isObject(this.dataFormulaireQuick.formule)
						? this.dataFormulaireQuick.formule.code_formule
						: this.dataFormulaireQuick.formule
				);
				dataSend.append(
					"store_id",
					window.sessionStorage.getItem("s") !== "" &&
					window.sessionStorage.getItem("s") != null &&
					window.sessionStorage.getItem("s") !== undefined
						? window.sessionStorage.getItem("s")
						: this.dataFormulaireQuick.boutique.store_id
				);
				dataSend.append(
					"slug_boutique",
					window.sessionStorage.getItem("sb") !== ""
						? window.sessionStorage.getItem("sb")
						: ""
				);

				dataSend.append("quantite", trim(this.dataFormulaireQuick.quantite));

				// source pay
				dataSend.append(
					"source_pay",
					window.sessionStorage.getItem("slug_info") !== "" &&
					window.sessionStorage.getItem("slug_info") != null &&
					window.sessionStorage.getItem("slug_info") !== undefined
						? 'qr_link'
						: 'link'
				);

				axios
				.post(apiUrl + "api/paiement-marchand/initier-paiement", dataSend)
				.then((response) => {
					this.isLoading = false;

					if (response.data.code == 1) {
						this.scrollToTop();

						// _reference.set(response.data["reference"]);
						this.url_data.reference = response.data["reference"];
						window.localStorage.setItem(
							"_reference_" + this.url_data.slug,
							response.data["reference"]
						);

						this.refPayment = response.data["reference"];
						// this.$refs['openPaymentModal'].show();
						this.showPaymentSection = true;
						this.formQuiclyPayment = !this.formQuiclyPayment;

						// Set the phone number to APaym login
						this.dataApaymPayAuth.numApaym = this.dataFormulaireQuick.telephone;
						this.dataApaymPay.numApaym = this.dataFormulaireQuick.telephone;
						// console.log("Indicatif : "+ this.indice.i.code) ;
						this.indiceToShow = this.indice.i.code;
						this.dataApaymPay.indice = this.indice.i.code;
						this.dataApaymPayAuth.indice = this.indice.i.code;
						this.indice.i = "225";
						this.getIndiceJson();

						// Formulaire Visa/Mastercard
						this.dataVisaCard.cardHolderName =
							trim(this.dataFormulaireQuick.nom) +
							" " +
							trim(this.dataFormulaireQuick.prenom);

						// Set MTN MoMo number if it begins by 05 or Set Moov Money number if it begins by 01
						var first2Chiffres = this.dataFormulaireQuick.telephone
						.replace(/\s/g, "")
						.substring(0, 2);

						if (first2Chiffres == "05") {
							this.dataMoMo.numero = this.dataFormulaireQuick.telephone;
						} else if (first2Chiffres == "01") {
							this.dataMoov.numero = this.dataFormulaireQuick.telephone;
						}

						this.typeForm = "quickly";
						// Get Additional Data
						this.dataYangoRechargement.driver_profil_id =
							response.data["driver_profil_id"];
						this.dataYangoRechargement.driver_phone_number =
							response.data["driver_phone_number"];
						this.dataYangoRechargement.profil_id = response.data["profil_id"];
						this.dataYangoRechargement.ref_transaction =
							response.data["reference"];

						// Nombre de transactions pour connaitre le statut du client
						// BLEU (1 - 4), BRONZE (5 - 9), SILVER (10 - 19), GOLD (20 - 29), PLATINUM (30 - Infini)
						this.nombre_transactions = response.data["nombre_transactions"];
						this.is_platinum_card = response.data["is_platinum_card"];

						if (this.nombre_transactions >= 0) {
							if (this.nombre_transactions <= 20) {
								this.statusClient = "Bronze";
								this.imgStatus = "bronze.png";
							} else if (this.nombre_transactions <= 40) {
								this.statusClient = "Silver";
								this.imgStatus = "silver.png";
							} else if (this.nombre_transactions <= 65) {
								this.statusClient = "Gold";
								this.imgStatus = "gold.png";
							} else if (this.nombre_transactions <= 90) {
								this.statusClient = "Platinum";
								this.imgStatus = "platinum.png";
							} else if (this.nombre_transactions <= 130) {
								this.statusClient = "Diamond";
								this.imgStatus = "diamond.png";
							} else {
								this.statusClient = "Infinite";
								this.imgStatus = "infinite.png";
							}

							if (
								this.is_platinum_card == "1" &&
								this.nombre_transactions <= 90
							) {
								this.statusClient = "Platinum";
								this.imgStatus = "platinum.png";
							}
						} else {
							this.nombre_transactions = ""; // Le client n'est pas un client APaym
						}

						// Carte de fidélité
						this.loyalty_card = response.data["loyalty_card"];
						this.nombre_paiements = response.data["nombre_paiements"];
						this.nombre_points = parseFloat(response.data["nombre_points"]);
						this.cadeau_a_offrir = response.data["cadeau_a_offrir"];

						this.pointLoyalty = [];
						this.nombre_points -= 1;
						if (this.nombre_paiements <= this.nombre_points) {
							for (let i = 0; i < this.nombre_paiements; i++) {
								this.pointLoyalty.push({
									id: i + 1,
									paid: 1,
								});
							}

							let reste = this.nombre_points - this.nombre_paiements;
							let nbr = this.nombre_paiements;
							// for ( let i = reste ; i > 0; i-- ) {
							for (let i = 1; i <= reste; i++) {
								this.pointLoyalty.push({
									id: nbr + 1,
									paid: 0,
								});
								nbr += 1;
							}
						} else {
							for (let i = 0; i < this.nombre_points; i++) {
								this.pointLoyalty.push({
									id: i + 1,
									paid: 0,
								});
							}
						}

						return false;
					}

					sweetAlert(
						this.$swal,
						true,
						"top-end",
						"error",
						"Erreur",
						response.data["message"]
					);
					this.dataFormulaireQuick.montant = this.montantSave;
				})
				.catch((error) => {
					this.isLoading = false;
					console.log(error);
					sweetAlert(
						this.$swal,
						true,
						"top-end",
						"error",
						"Erreur",
						"Une erreur est survenue, veuillez réessayer plus tard"
					);
				});
			},

			payerAirFrance() {
				this.dataFormulaire.montant = this.montantFormate.replace(/\s/g, "");
				this.dataFormulaire.motif = "Paiement chez Air France";

				if (
					this.dataFormulaire.nom.replace(/\s/g, "") == "" ||
					this.dataFormulaire.prenom.replace(/\s/g, "") == "" ||
					this.dataFormulaire.telephone.replace(/\s/g, "") == "" ||
					this.dataFormulaire.montant.replace(/\s/g, "") == "" ||
					this.dataFormulaire.motif.replace(/\s/g, "") == "" ||
					this.dataFormulaire.numeroCompte.replace(/\s/g, "") == "" ||
					this.dataFormulaire.email.replace(/\s/g, "") == "" ||
					this.dataFormulaire.service.replace(/\s/g, "") == ""
				) {
					// this.dataFormulaire.immatriculation.replace(/\s/g, "") == "" ||
					sweetAlert(
						this.$swal,
						true,
						"top-end",
						"info",
						"Info",
						"Veuillez renseigner tous les champs non optionnels !"
					);
					return false;
				}

				if (isNaN(this.dataFormulaire.montant)) {
					sweetAlert(
						this.$swal,
						true,
						"top-end",
						"info",
						"Info",
						"Veuillez renseigner un montant correct !"
					);
					return false;
				}

				if (this.dataFormulaire.montant % 5 !== 0) {
					sweetAlert(
						this.$swal,
						true,
						"top-end",
						"info",
						"Info",
						"Veuillez renseigner un montant mutiple de 5 !"
					);
					return false;
				}

				if (this.dataFormulaire.email.replace(/\s/g, "")) {
					if (!this.validateEmail(this.dataFormulaire.email.replace(/\s/g, ""))) {
						sweetAlert(
							this.$swal,
							true,
							"top-end",
							"info",
							"Info",
							"Veuillez renseigner une adresse e-mail correcte !"
						);
						return false;
					}
				}

				if (this.dataFormulaire.nom.replace(/\s/g, "")) {
					if (!this.validateString(this.dataFormulaire.nom.replace(/\s/g, ""))) {
						sweetAlert(
							this.$swal,
							true,
							"top-end",
							"info",
							"Info",
							"Veuillez renseigner un nom correct !"
						);
						return false;
					}
				}

				if (this.dataFormulaire.prenom.replace(/\s/g, "")) {
					if (!this.validateString(trim(this.dataFormulaire.prenom))) {
						sweetAlert(
							this.$swal,
							true,
							"top-end",
							"info",
							"Info",
							"Veuillez renseigner un prénom correct !"
						);
						return false;
					}
				}

				if (this.dataFormulaire.motif.replace(/\s/g, "")) {
					if (!this.validateSpecialString(trim(this.dataFormulaire.motif))) {
						sweetAlert(
							this.$swal,
							true,
							"top-end",
							"info",
							"Info",
							"Veuillez renseigner un motif correct !"
						);
						return false;
					}
				}

				if (this.dataPage.eticket.length > 0) {
					if (this.dataFormulaire.formule == "") {
						sweetAlert(
							this.$swal,
							true,
							"top-end",
							"info",
							"Info",
							"Veuillez sélectionner une formule de ticket !"
						);
						return false;
					}
				}

				this.montantSave =
					parseFloat(this.dataFormulaire.montant) +
					(this.pourboire !== "autre"
						? parseFloat(this.pourboire)
						: this.dataFormulaire.amountPourboire
							? parseFloat(this.dataFormulaire.amountPourboire)
							: 0);

				this.montantAPayer = parseFloat(this.dataFormulaire.montant);

				// Set toutes la values à false
				this.qrCodeUri = "";
				this.orangeOkay = false;
				this.orangeNOkay = false;
				this.mtnOkay = false;
				this.mtnNOkay = false;
				this.moovNOkay = false;
				this.moovOkay = false;
				this.tresorOkay = false;
				this.tresorNOkay = false;

				// Init Payment process
				this.isLoading = true;
				let dataSend = new FormData();
				dataSend.append("code_page", this.url_data.slug);
				dataSend.append("nom", trim(this.dataFormulaire.nom));
				dataSend.append("prenom", trim(this.dataFormulaire.prenom));
				dataSend.append(
					"numero",
					this.indice.i.code +
					"" +
					this.dataFormulaire.telephone.replace(/\s/g, "")
				);
				dataSend.append("indice", this.indice.i.code);
				dataSend.append("amount", this.dataFormulaire.montant);
				dataSend.append("email", trim(this.dataFormulaire.email));
				dataSend.append("motif", trim(this.dataFormulaire.motif));
				dataSend.append(
					"societe",
					this.dataPage.deleg_pdci !== "1"
						? trim(this.dataFormulaire.society)
						: trim(this.dataFormulaire.deleg.name)
				);
				dataSend.append("grade", trim(this.dataFormulaire.grade.organe));
				dataSend.append("key_payment", trim(this.dataFormulaire.key_payment));
				dataSend.append("numero_compte", this.dataFormulaire.numeroCompte);
				dataSend.append(
					"societe_en_bourse",
					this.dataFormulaire.societeEnBourse.company == undefined
						? ""
						: this.dataFormulaire.societeEnBourse.company
				);
				dataSend.append(
					"nombre_actions",
					parseFloat(this.dataFormulaire.nombreActions)
				);
				dataSend.append("send_customer_notif", this.sendNotif);
				// Valeurs de conversion
				dataSend.append("conversion_devise", this.devise);
				dataSend.append("conversion_rate", this.taux_apaym);
				dataSend.append(
					"montant_devise",
					this.conversionRate !== ""
						? this.dataFormulaire.montant / this.conversionRate
						: this.montantDevise
				);
				dataSend.append("deleg_pdci", this.dataPage.deleg_pdci);
				dataSend.append("adresse_ip", this.dataYangoRechargement.adresse_ip);
				dataSend.append(
					"device_type",
					this.$browserDetect.meta.name +
					" " +
					this.$browserDetect.meta.version +
					" " +
					this.$browserDetect.meta.ua
				);
				dataSend.append("store_id", this.dataFormulaire.boutique.store_id);
				dataSend.append("terminal_id", this.dataFormulaire.boutique.terminal_id);
				dataSend.append(
					"immatriculation",
					trim(this.dataFormulaire.immatriculation)
				);
				dataSend.append("service", this.dataFormulaire.service);
				dataSend.append("code_formule", this.dataFormulaire.formule);
				dataSend.append(
					"store_id",
					window.sessionStorage.getItem("s") !== "" &&
					window.sessionStorage.getItem("s") != null &&
					window.sessionStorage.getItem("s") !== undefined
						? window.sessionStorage.getItem("s")
						: this.dataFormulaire.boutique.store_id
				);
				dataSend.append(
					"slug_boutique",
					window.sessionStorage.getItem("sb") !== ""
						? window.sessionStorage.getItem("sb")
						: ""
				);

				// source pay
				dataSend.append(
					"source_pay",
					window.sessionStorage.getItem("slug_info") !== "" &&
					window.sessionStorage.getItem("slug_info") != null &&
					window.sessionStorage.getItem("slug_info") !== undefined
						? 'qr_link'
						: 'link'
				);

				axios
				.post(apiUrl + "api/paiement-marchand/initier-paiement", dataSend)
				.then((response) => {
					this.isLoading = false;
					if (response.data.code == 1) {
						this.scrollToTop();

						// _reference.set("ref_"+ this.url_data.slug +"_"+ response.data["reference"]);
						this.url_data.reference = response.data["reference"];
						window.localStorage.setItem(
							"_reference_" + this.url_data.slug,
							response.data["reference"]
						);
						this.refPayment = response.data["reference"];
						// this.$refs['openPaymentModal'].show();
						this.showPaymentSection = true;
						this.formPayment = !this.formPayment;

						// Set the phone number to APaym login
						this.dataApaymPayAuth.numApaym = this.dataFormulaire.telephone;
						this.dataApaymPay.numApaym = this.dataFormulaire.telephone;

						this.indiceToShow = this.indice.i.code;
						this.dataApaymPay.indice = this.indice.i.code;
						this.dataApaymPayAuth.indice = this.indice.i.code;
						this.indice.i = "225";
						this.getIndiceJson();

						// Set MTN MoMo number if it begins by 05 or Set Moov Money number if it begins by 01
						var first2Chiffres = this.dataFormulaire.telephone
						.replace(/\s/g, "")
						.substring(0, 2);

						if (first2Chiffres == "05") {
							this.dataMoMo.numero = this.dataFormulaire.telephone;
						} else if (first2Chiffres == "01") {
							this.dataMoov.numero = this.dataFormulaire.telephone;
						}

						// Formulaire Visa/Mastercard
						this.dataVisaCard.cardHolderName =
							trim(this.dataFormulaire.nom) +
							" " +
							trim(this.dataFormulaire.prenom);

						this.typeForm = "normal";
						// Get Additional Data
						this.dataYangoRechargement.driver_profil_id =
							response.data["driver_profil_id"];
						this.dataYangoRechargement.driver_phone_number =
							response.data["driver_phone_number"];
						this.dataYangoRechargement.profil_id = response.data["profil_id"];
						this.dataYangoRechargement.ref_transaction =
							response.data["reference"];

						// Nombre de transactions pour connaitre le statut du client
						// BLEU (1 - 4), BRONZE (5 - 9), SILVER (10 - 19), GOLD (20 - 29), PLATINUM (30 - Infini)
						this.nombre_transactions = response.data["nombre_transactions"];
						this.is_platinum_card = response.data["is_platinum_card"];

						if (this.nombre_transactions >= 0) {
							if (this.nombre_transactions <= 20) {
								this.statusClient = "Bronze";
								this.imgStatus = "bronze.png";
							} else if (this.nombre_transactions <= 40) {
								this.statusClient = "Silver";
								this.imgStatus = "silver.png";
							} else if (this.nombre_transactions <= 65) {
								this.statusClient = "Gold";
								this.imgStatus = "gold.png";
							} else if (this.nombre_transactions <= 90) {
								this.statusClient = "Platinum";
								this.imgStatus = "platinum.png";
							} else if (this.nombre_transactions <= 130) {
								this.statusClient = "Diamond";
								this.imgStatus = "diamond.png";
							} else {
								this.statusClient = "Infinite";
								this.imgStatus = "infinite.png";
							}

							if (
								this.is_platinum_card == "1" &&
								this.nombre_transactions <= 90
							) {
								this.statusClient = "Platinum";
								this.imgStatus = "platinum.png";
							}
						} else {
							this.nombre_transactions = ""; // Le client n'est pas un client APaym
						}

						// Carte de fidélité
						this.loyalty_card = response.data["loyalty_card"];
						this.nombre_paiements = response.data["nombre_paiements"];
						this.nombre_points = parseFloat(response.data["nombre_points"]);
						this.cadeau_a_offrir = response.data["cadeau_a_offrir"];
						this.pointLoyalty = [];
						this.nombre_points -= 1;

						if (this.nombre_paiements <= this.nombre_points) {
							for (let i = 0; i < this.nombre_paiements; i++) {
								this.pointLoyalty.push({
									id: i + 1,
									paid: 1,
								});
							}

							let reste = this.nombre_points - this.nombre_paiements;
							let nbr = this.nombre_paiements;
							// for ( let i = reste ; i > 0; i-- ) {
							for (let i = 1; i <= reste; i++) {
								this.pointLoyalty.push({
									id: nbr + 1,
									paid: 0,
								});
								nbr += 1;
							}
						} else {
							for (let i = 0; i < this.nombre_points; i++) {
								this.pointLoyalty.push({
									id: i + 1,
									paid: 0,
								});
							}
						}

						return false;
					}

					sweetAlert(
						this.$swal,
						true,
						"top-end",
						"error",
						"Erreur",
						response.data["message"]
					);
					this.dataFormulaire.montant = this.montantSave;
				})
				.catch((error) => {
					this.isLoading = false;
					console.log(error);
					sweetAlert(
						this.$swal,
						true,
						"top-end",
						"error",
						"Erreur",
						"Une erreur est survenue, veuillez réessayer plus tard"
					);
				});
			},

			payerBaieSirenes() {
				this.dataFormulaire.montant = this.montantFormate.replace(/\s/g, "");

				if (
					this.dataFormulaire.nom.replace(/\s/g, "") == "" ||
					this.dataFormulaire.prenom.replace(/\s/g, "") == "" ||
					this.dataFormulaire.telephone.replace(/\s/g, "") == "" ||
					this.dataFormulaire.montant.replace(/\s/g, "") == "" ||
					this.dataFormulaire.motif.replace(/\s/g, "") == ""
				) {
					// this.dataFormulaire.immatriculation.replace(/\s/g, "") == "" ||
					sweetAlert(
						this.$swal,
						true,
						"top-end",
						"info",
						"Info",
						"Veuillez renseigner tous les champs non optionnels !"
					);
					return false;
				}

				if (isNaN(this.dataFormulaire.montant)) {
					sweetAlert(
						this.$swal,
						true,
						"top-end",
						"info",
						"Info",
						"Veuillez renseigner un montant correct !"
					);
					return false;
				}

				if (this.dataFormulaire.montant % 5 !== 0) {
					sweetAlert(
						this.$swal,
						true,
						"top-end",
						"info",
						"Info",
						"Veuillez renseigner un montant mutiple de 5 !"
					);
					return false;
				}

				if (this.dataFormulaire.nom.replace(/\s/g, "")) {
					if (!this.validateString(this.dataFormulaire.nom.replace(/\s/g, ""))) {
						sweetAlert(
							this.$swal,
							true,
							"top-end",
							"info",
							"Info",
							"Veuillez renseigner un nom correct !"
						);
						return false;
					}
				}

				if (this.dataFormulaire.prenom.replace(/\s/g, "")) {
					if (!this.validateString(trim(this.dataFormulaire.prenom))) {
						sweetAlert(
							this.$swal,
							true,
							"top-end",
							"info",
							"Info",
							"Veuillez renseigner un prénom correct !"
						);
						return false;
					}
				}

				if (this.dataFormulaire.motif.replace(/\s/g, "")) {
					if (!this.validateSpecialString(trim(this.dataFormulaire.motif))) {
						sweetAlert(
							this.$swal,
							true,
							"top-end",
							"info",
							"Info",
							"Veuillez renseigner un numéro de réservation correct !"
						);
						return false;
					}
				}

				if (this.dataFormulaire.society.replace(/\s/g, "")) {
					if (!this.validateSpecialString(trim(this.dataFormulaire.society))) {
						sweetAlert(
							this.$swal,
							true,
							"top-end",
							"info",
							"Info",
							"Veuillez renseigner une remarque correcte !"
						);
						return false;
					}
				}

				if (this.dataPage.eticket.length > 0) {
					if (this.dataFormulaire.formule == "") {
						sweetAlert(
							this.$swal,
							true,
							"top-end",
							"info",
							"Info",
							"Veuillez sélectionner une formule de ticket !"
						);
						return false;
					}
				}

				this.montantSave =
					parseFloat(this.dataFormulaire.montant) +
					(this.pourboire !== "autre"
						? parseFloat(this.pourboire)
						: this.dataFormulaire.amountPourboire
							? parseFloat(this.dataFormulaire.amountPourboire)
							: 0);

				this.montantAPayer = parseFloat(this.dataFormulaire.montant);

				// Set toutes la values à false
				this.qrCodeUri = "";
				this.orangeOkay = false;
				this.orangeNOkay = false;
				this.mtnOkay = false;
				this.mtnNOkay = false;
				this.moovNOkay = false;
				this.moovOkay = false;
				this.tresorOkay = false;
				this.tresorNOkay = false;

				// Init Payment process
				this.isLoading = true;
				let dataSend = new FormData();
				dataSend.append("code_page", this.url_data.slug);
				dataSend.append("nom", trim(this.dataFormulaire.nom));
				dataSend.append("prenom", trim(this.dataFormulaire.prenom));
				dataSend.append(
					"numero",
					this.indice.i.code +
					"" +
					this.dataFormulaire.telephone.replace(/\s/g, "")
				);
				dataSend.append("indice", this.indice.i.code);
				dataSend.append("amount", this.dataFormulaire.montant);
				dataSend.append("email", trim(this.dataFormulaire.email));
				dataSend.append("motif", trim(this.dataFormulaire.motif));
				dataSend.append(
					"societe",
					this.dataPage.deleg_pdci !== "1"
						? trim(this.dataFormulaire.society)
						: trim(this.dataFormulaire.deleg.name)
				);
				dataSend.append("grade", trim(this.dataFormulaire.grade.organe));
				dataSend.append("key_payment", trim(this.dataFormulaire.key_payment));
				dataSend.append("numero_compte", this.dataFormulaire.numeroCompte);
				dataSend.append(
					"societe_en_bourse",
					this.dataFormulaire.societeEnBourse.company == undefined
						? ""
						: this.dataFormulaire.societeEnBourse.company
				);
				dataSend.append(
					"nombre_actions",
					parseFloat(this.dataFormulaire.nombreActions)
				);
				dataSend.append("send_customer_notif", this.sendNotif);
				// Valeurs de conversion
				dataSend.append("conversion_devise", this.devise);
				dataSend.append("conversion_rate", this.taux_apaym);
				dataSend.append(
					"montant_devise",
					this.conversionRate !== ""
						? this.dataFormulaire.montant / this.conversionRate
						: this.montantDevise
				);
				dataSend.append("deleg_pdci", this.dataPage.deleg_pdci);
				dataSend.append("adresse_ip", this.dataYangoRechargement.adresse_ip);
				dataSend.append(
					"device_type",
					this.$browserDetect.meta.name +
					" " +
					this.$browserDetect.meta.version +
					" " +
					this.$browserDetect.meta.ua
				);
				dataSend.append("store_id", this.dataFormulaire.boutique.store_id);
				dataSend.append("terminal_id", this.dataFormulaire.boutique.terminal_id);
				dataSend.append(
					"immatriculation",
					trim(this.dataFormulaire.immatriculation)
				);
				dataSend.append("service", this.dataFormulaire.service);
				dataSend.append(
					"store_id",
					window.sessionStorage.getItem("s") !== "" &&
					window.sessionStorage.getItem("s") != null &&
					window.sessionStorage.getItem("s") !== undefined
						? window.sessionStorage.getItem("s")
						: this.dataFormulaire.boutique.store_id
				);
				dataSend.append(
					"slug_boutique",
					window.sessionStorage.getItem("sb") !== ""
						? window.sessionStorage.getItem("sb")
						: ""
				);

				// source pay
				dataSend.append(
					"source_pay",
					window.sessionStorage.getItem("slug_info") !== "" &&
					window.sessionStorage.getItem("slug_info") != null &&
					window.sessionStorage.getItem("slug_info") !== undefined
						? 'qr_link'
						: 'link'
				);

				axios
				.post(apiUrl + "api/paiement-marchand/initier-paiement", dataSend)
				.then((response) => {
					this.isLoading = false;
					if (response.data.code == 1) {
						this.scrollToTop();

						// _reference.set("ref_"+ this.url_data.slug +"_"+ response.data["reference"]);
						this.url_data.reference = response.data["reference"];
						window.localStorage.setItem(
							"_reference_" + this.url_data.slug,
							response.data["reference"]
						);
						this.refPayment = response.data["reference"];
						// this.$refs['openPaymentModal'].show();
						this.showPaymentSection = true;
						this.formPayment = !this.formPayment;

						// Set the phone number to APaym login
						this.dataApaymPayAuth.numApaym = this.dataFormulaire.telephone;
						this.dataApaymPay.numApaym = this.dataFormulaire.telephone;

						this.indiceToShow = this.indice.i.code;
						this.dataApaymPay.indice = this.indice.i.code;
						this.dataApaymPayAuth.indice = this.indice.i.code;
						this.indice.i = "225";
						this.getIndiceJson();

						// Set MTN MoMo number if it begins by 05 or Set Moov Money number if it begins by 01
						var first2Chiffres = this.dataFormulaire.telephone
						.replace(/\s/g, "")
						.substring(0, 2);

						if (first2Chiffres == "05") {
							this.dataMoMo.numero = this.dataFormulaire.telephone;
						} else if (first2Chiffres == "01") {
							this.dataMoov.numero = this.dataFormulaire.telephone;
						}

						// Formulaire Visa/Mastercard
						this.dataVisaCard.cardHolderName =
							trim(this.dataFormulaire.nom) +
							" " +
							trim(this.dataFormulaire.prenom);

						this.typeForm = "normal";
						// Get Additional Data
						this.dataYangoRechargement.driver_profil_id =
							response.data["driver_profil_id"];
						this.dataYangoRechargement.driver_phone_number =
							response.data["driver_phone_number"];
						this.dataYangoRechargement.profil_id = response.data["profil_id"];
						this.dataYangoRechargement.ref_transaction =
							response.data["reference"];

						// Nombre de transactions pour connaitre le statut du client
						// BLEU (1 - 4), BRONZE (5 - 9), SILVER (10 - 19), GOLD (20 - 29), PLATINUM (30 - Infini)
						this.nombre_transactions = response.data["nombre_transactions"];
						this.is_platinum_card = response.data["is_platinum_card"];

						if (this.nombre_transactions >= 0) {
							if (this.nombre_transactions <= 20) {
								this.statusClient = "Bronze";
								this.imgStatus = "bronze.png";
							} else if (this.nombre_transactions <= 40) {
								this.statusClient = "Silver";
								this.imgStatus = "silver.png";
							} else if (this.nombre_transactions <= 65) {
								this.statusClient = "Gold";
								this.imgStatus = "gold.png";
							} else if (this.nombre_transactions <= 90) {
								this.statusClient = "Platinum";
								this.imgStatus = "platinum.png";
							} else if (this.nombre_transactions <= 130) {
								this.statusClient = "Diamond";
								this.imgStatus = "diamond.png";
							} else {
								this.statusClient = "Infinite";
								this.imgStatus = "infinite.png";
							}

							if (
								this.is_platinum_card == "1" &&
								this.nombre_transactions <= 90
							) {
								this.statusClient = "Platinum";
								this.imgStatus = "platinum.png";
							}
						} else {
							this.nombre_transactions = ""; // Le client n'est pas un client APaym
						}

						// Carte de fidélité
						this.loyalty_card = response.data["loyalty_card"];
						this.nombre_paiements = response.data["nombre_paiements"];
						this.nombre_points = parseFloat(response.data["nombre_points"]);
						this.cadeau_a_offrir = response.data["cadeau_a_offrir"];
						this.pointLoyalty = [];
						this.nombre_points -= 1;

						if (this.nombre_paiements <= this.nombre_points) {
							for (let i = 0; i < this.nombre_paiements; i++) {
								this.pointLoyalty.push({
									id: i + 1,
									paid: 1,
								});
							}

							let reste = this.nombre_points - this.nombre_paiements;
							let nbr = this.nombre_paiements;
							// for ( let i = reste ; i > 0; i-- ) {
							for (let i = 1; i <= reste; i++) {
								this.pointLoyalty.push({
									id: nbr + 1,
									paid: 0,
								});
								nbr += 1;
							}
						} else {
							for (let i = 0; i < this.nombre_points; i++) {
								this.pointLoyalty.push({
									id: i + 1,
									paid: 0,
								});
							}
						}

						return false;
					}

					sweetAlert(
						this.$swal,
						true,
						"top-end",
						"error",
						"Erreur",
						response.data["message"]
					);
					this.dataFormulaire.montant = this.montantSave;
				})
				.catch((error) => {
					this.isLoading = false;
					console.log(error);
					sweetAlert(
						this.$swal,
						true,
						"top-end",
						"error",
						"Erreur",
						"Une erreur est survenue, veuillez réessayer plus tard"
					);
				});
			},

			payerJusteLeMontant() {
				this.dataFormulaireQuick.montant = this.montantFormateQuick.replace(
					/\s/g,
					""
				);

				if (this.dataFormulaireQuick.montant === "") {
					sweetAlert(
						this.$swal,
						true,
						"top-end",
						"info",
						"Veuillez renseigner le montant !",
						""
					);
					return false;
				}

				if (isNaN(this.dataFormulaireQuick.montant)) {
					sweetAlert(
						this.$swal,
						true,
						"top-end",
						"info",
						"Veuillez renseigner un montant correct !",
						""
					);
					return false;
				}

				if (this.dataFormulaireQuick.montant % 5 !== 0) {
					sweetAlert(
						this.$swal,
						true,
						"top-end",
						"info",
						"Info",
						"Veuillez renseigner un montant mutiple de 5 !"
					);
					return false;
				}

				if (this.dataFormulaireQuick.email.replace(/\s/g, "")) {
					if (
						!this.validateEmail(this.dataFormulaireQuick.email.replace(/\s/g, ""))
					) {
						sweetAlert(
							this.$swal,
							true,
							"top-end",
							"info",
							"Info",
							"Veuillez renseigner une adresse e-mail correcte !"
						);
						return false;
					}
				}

				if (this.dataFormulaireQuick.nom.replace(/\s/g, "")) {
					if (
						!this.validateString(this.dataFormulaireQuick.nom.replace(/\s/g, ""))
					) {
						sweetAlert(
							this.$swal,
							true,
							"top-end",
							"info",
							"Info",
							"Veuillez renseigner un nom correct !"
						);
						return false;
					}
				}

				if (this.dataFormulaireQuick.prenom.replace(/\s/g, "")) {
					if (
						!this.validateString(
							this.dataFormulaireQuick.prenom.replace(/\s/g, "")
						)
					) {
						sweetAlert(
							this.$swal,
							true,
							"top-end",
							"info",
							"Info",
							"Veuillez renseigner un prénom correct !"
						);
						return false;
					}
				}

				if (this.dataFormulaireQuick.society.replace(/\s/g, "")) {
					if (
						!this.validateString(
							this.dataFormulaireQuick.society.replace(/\s/g, "")
						)
					) {
						sweetAlert(
							this.$swal,
							true,
							"top-end",
							"info",
							"Info",
							"Veuillez renseigner un nom de société correct !"
						);
						return false;
					}
				}

				if (this.dataFormulaireQuick.motif.replace(/\s/g, "")) {
					if (!this.validateSpecialString(trim(this.dataFormulaireQuick.motif))) {
						sweetAlert(
							this.$swal,
							true,
							"top-end",
							"info",
							"Info",
							"Veuillez renseigner un motif correct !"
						);
						return false;
					}
				}

				if (this.dataPage.eticket.length > 0) {
					if (this.dataFormulaireQuick.formule == "") {
						sweetAlert(
							this.$swal,
							true,
							"top-end",
							"info",
							"Info",
							"Veuillez sélectionner une formule de ticket !"
						);
						return false;
					}
				}

				this.montantSave =
					parseFloat(this.dataFormulaireQuick.montant) +
					(this.pourboire !== "autre"
						? parseFloat(this.pourboire)
						: this.dataFormulaireQuick.amountPourboire
							? parseFloat(this.dataFormulaireQuick.amountPourboire)
							: 0);

				this.montantAPayer = parseFloat(this.dataFormulaireQuick.montant);

				// Set toutes la values à false
				this.qrCodeUri = "";
				this.orangeOkay = false;
				this.orangeNOkay = false;
				this.mtnOkay = false;
				this.mtnNOkay = false;
				this.moovNOkay = false;
				this.moovOkay = false;
				this.tresorOkay = false;
				this.tresorNOkay = false;

				// Init Payment process
				this.isLoading = true;
				let dataSend = new FormData();
				dataSend.append("code_page", this.url_data.slug);
				dataSend.append(
					"nom",
					this.dataFormulaireQuick.nom !== ""
						? trim(this.dataFormulaireQuick.nom)
						: "Client"
				);
				dataSend.append(
					"prenom",
					this.dataFormulaireQuick.prenom !== ""
						? trim(this.dataFormulaireQuick.prenom)
						: "inconnu"
				);
				dataSend.append(
					"numero",
					this.dataFormulaireQuick.telephone !== ""
						? this.indice.i.code +
						"" +
						this.dataFormulaireQuick.telephone.replace(/\s/g, "")
						: "Numero inconnu"
				);
				dataSend.append(
					"numero2",
					this.dataFormulaireQuick.telephone2 !== ""
						? this.indice.i.code +
						"" +
						this.dataFormulaireQuick.telephone2.replace(/\s/g, "")
						: "Numero inconnu"
				);
				dataSend.append(
					"indice",
					this.indice.i.code !== "" ? this.indice.i.code : "Indice inconnu"
				);
				dataSend.append("amount", this.dataFormulaireQuick.montant);
				// dataSend.append("frais", frais);
				dataSend.append(
					"email",
					this.dataFormulaireQuick.email !== ""
						? trim(this.dataFormulaireQuick.email)
						: ""
				);
				dataSend.append(
					"motif",
					this.dataFormulaireQuick.motif !== ""
						? trim(this.dataFormulaireQuick.motif)
						: "Paiement chez " + this.dataPage.business_name
				);
				dataSend.append(
					"societe",
					this.dataFormulaireQuick.society !== ""
						? trim(this.dataFormulaireQuick.society)
						: ""
				);
				dataSend.append(
					"key_payment",
					trim(this.dataFormulaireQuick.key_payment)
				);
				dataSend.append("numero_compte", "");
				dataSend.append("societe_en_bourse", "");
				dataSend.append("nombre_actions", 0);
				dataSend.append("send_customer_notif", this.sendNotif);
				dataSend.append("conversion_devise", this.devise);
				dataSend.append("conversion_rate", this.taux_apaym);
				// dataSend.append("montantDevise", this.montantDevise);
				dataSend.append(
					"montant_devise",
					this.conversionRate !== ""
						? this.dataFormulaireQuick.montant / this.conversionRate
						: this.montantDevise
				);
				dataSend.append("deleg_pdci", this.dataPage.deleg_pdci);
				dataSend.append("adresse_ip", this.dataYangoRechargement.adresse_ip);
				dataSend.append(
					"device_type",
					this.$browserDetect.meta.name +
					" " +
					this.$browserDetect.meta.version +
					" " +
					this.$browserDetect.meta.ua
				);
				dataSend.append("store_id", this.dataFormulaireQuick.boutique.store_id);
				dataSend.append(
					"terminal_id",
					this.dataFormulaireQuick.boutique.terminal_id
				);
				dataSend.append(
					"immatriculation",
					this.dataFormulaireQuick.immatriculation.toUpperCase()
				);
				dataSend.append("service", this.dataFormulaireQuick.service);
				dataSend.append(
					"code_formule",
					this.isObject(this.dataFormulaireQuick.formule)
						? this.dataFormulaireQuick.formule.code_formule
						: this.dataFormulaireQuick.formule
				);
				dataSend.append(
					"store_id",
					window.sessionStorage.getItem("s") !== "" &&
					window.sessionStorage.getItem("s") != null &&
					window.sessionStorage.getItem("s") !== undefined
						? window.sessionStorage.getItem("s")
						: this.dataFormulaireQuick.boutique.store_id
				);
				dataSend.append(
					"slug_boutique",
					window.sessionStorage.getItem("sb") !== ""
						? window.sessionStorage.getItem("sb")
						: ""
				);

				// source pay
				dataSend.append(
					"source_pay",
					window.sessionStorage.getItem("slug_info") !== "" &&
					window.sessionStorage.getItem("slug_info") != null &&
					window.sessionStorage.getItem("slug_info") !== undefined
						? 'qr_link'
						: 'link'
				);

				axios
				.post(apiUrl + "api/paiement-marchand/initier-paiement", dataSend)
				.then((response) => {
					this.isLoading = false;

					if (response.data.code == 1) {
						this.scrollToTop();

						// _reference.set(response.data["reference"]);
						this.url_data.reference = response.data["reference"];
						window.localStorage.setItem(
							"_reference_" + this.url_data.slug,
							response.data["reference"]
						);

						this.refPayment = response.data["reference"];
						// this.$refs['openPaymentModal'].show();
						this.showPaymentSection = true;
						this.formSuperQuicklyPayment = !this.formSuperQuicklyPayment;

						// Set the phone number to APaym login
						this.dataApaymPayAuth.numApaym = this.dataFormulaireQuick.telephone;
						this.dataApaymPay.numApaym = this.dataFormulaireQuick.telephone;
						// console.log("Indicatif : "+ this.indice.i.code) ;
						this.indiceToShow = this.indice.i.code;
						this.dataApaymPay.indice = this.indice.i.code;
						this.dataApaymPayAuth.indice = this.indice.i.code;
						this.indice.i = "225";
						this.getIndiceJson();

						// Formulaire Visa/Mastercard
						this.dataVisaCard.cardHolderName =
							trim(this.dataFormulaireQuick.nom) +
							" " +
							trim(this.dataFormulaireQuick.prenom);

						// Set MTN MoMo number if it begins by 05 or Set Moov Money number if it begins by 01
						var first2Chiffres = this.dataFormulaireQuick.telephone
						.replace(/\s/g, "")
						.substring(0, 2);

						if (first2Chiffres == "05") {
							this.dataMoMo.numero = this.dataFormulaireQuick.telephone;
						} else if (first2Chiffres == "01") {
							this.dataMoov.numero = this.dataFormulaireQuick.telephone;
						}

						this.typeForm = "superQuickly";
						// Get Additional Data
						this.dataYangoRechargement.driver_profil_id =
							response.data["driver_profil_id"];
						this.dataYangoRechargement.driver_phone_number =
							response.data["driver_phone_number"];
						this.dataYangoRechargement.profil_id = response.data["profil_id"];
						this.dataYangoRechargement.ref_transaction =
							response.data["reference"];

						// Nombre de transactions pour connaitre le statut du client
						// BLEU (1 - 4), BRONZE (5 - 9), SILVER (10 - 19), GOLD (20 - 29), PLATINUM (30 - Infini)
						this.nombre_transactions = response.data["nombre_transactions"];
						this.is_platinum_card = response.data["is_platinum_card"];

						if (this.nombre_transactions >= 0) {
							if (this.nombre_transactions <= 20) {
								this.statusClient = "Bronze";
								this.imgStatus = "bronze.png";
							} else if (this.nombre_transactions <= 40) {
								this.statusClient = "Silver";
								this.imgStatus = "silver.png";
							} else if (this.nombre_transactions <= 65) {
								this.statusClient = "Gold";
								this.imgStatus = "gold.png";
							} else if (this.nombre_transactions <= 90) {
								this.statusClient = "Platinum";
								this.imgStatus = "platinum.png";
							} else if (this.nombre_transactions <= 130) {
								this.statusClient = "Diamond";
								this.imgStatus = "diamond.png";
							} else {
								this.statusClient = "Infinite";
								this.imgStatus = "infinite.png";
							}

							if (
								this.is_platinum_card == "1" &&
								this.nombre_transactions <= 90
							) {
								this.statusClient = "Platinum";
								this.imgStatus = "platinum.png";
							}
						} else {
							this.nombre_transactions = ""; // Le client n'est pas un client APaym
						}

						// Carte de fidélité
						this.loyalty_card = response.data["loyalty_card"];
						this.nombre_paiements = response.data["nombre_paiements"];
						this.nombre_points = parseFloat(response.data["nombre_points"]);
						this.cadeau_a_offrir = response.data["cadeau_a_offrir"];

						this.pointLoyalty = [];
						this.nombre_points -= 1;
						if (this.nombre_paiements <= this.nombre_points) {
							for (let i = 0; i < this.nombre_paiements; i++) {
								this.pointLoyalty.push({
									id: i + 1,
									paid: 1,
								});
							}

							let reste = this.nombre_points - this.nombre_paiements;
							let nbr = this.nombre_paiements;
							// for ( let i = reste ; i > 0; i-- ) {
							for (let i = 1; i <= reste; i++) {
								this.pointLoyalty.push({
									id: nbr + 1,
									paid: 0,
								});
								nbr += 1;
							}
						} else {
							for (let i = 0; i < this.nombre_points; i++) {
								this.pointLoyalty.push({
									id: i + 1,
									paid: 0,
								});
							}
						}

						return false;
					}

					sweetAlert(
						this.$swal,
						true,
						"top-end",
						"error",
						"Erreur",
						response.data["message"]
					);
					this.dataFormulaireQuick.montant = this.montantSave;
				})
				.catch((error) => {
					this.isLoading = false;
					console.log(error);
					sweetAlert(
						this.$swal,
						true,
						"top-end",
						"error",
						"Erreur",
						"Une erreur est survenue, veuillez réessayer plus tard"
					);
				});
			},

			// Vérifier objet
			isObject(value) {
				return (
					typeof value === "object" && value !== null && !Array.isArray(value)
				);
			},

			// Visa QR
			generateQr(ref, feesPaidBy) {
				this.isLoadingQr = true;
				let dataSend = new FormData();
				dataSend.append("reference", ref);
				dataSend.append("feesPaidBy", feesPaidBy);

				axios
				.post(apiUrl + "api/paiement-marchand/generer-qr-code", dataSend)
				.then((response) => {
					this.isLoadingQr = false;
					if (response.data.code == "success") {
						this.qrCodeUri = response.data.qrCodeUri;
						return false;
					}

					sweetAlert(
						this.$swal,
						true,
						"top-end",
						"error",
						"Erreur",
						response.data["message"]
					);
				})
				.catch((error) => {
					this.isLoadingQr = false;
					console.log(error);
					sweetAlert(
						this.$swal,
						true,
						"top-end",
						"error",
						"Erreur",
						"Une erreur est survenue, veuillez réessayer plus tard"
					);
				});
			},

			// Visa QR, payer les frais
			feeAmountQr() {
				this.checkedFeesApaym = !this.checkedFeesApaym;

				if (this.typeForm == "normal") {
					let montant = this.montantSave;
					let frais = 0;
					let feesPaidBy = 0;
					if (this.checkedFeesApaym) {
						this.dataFormulaire.montant =
							this.dataPage.frais == "3"
								? parseFloat(montant) +
								parseFloat(
									montant * (parseFloat(this.dataPage.taux_apaym / 2) / 100)
								)
								: parseFloat(montant) +
								parseFloat(
									montant * (parseFloat(this.dataPage.taux_apaym) / 100)
								);

						frais =
							this.dataPage.frais == "3"
								? parseFloat(
									montant * (parseFloat(this.dataPage.taux_apaym / 2) / 100)
								)
								: parseFloat(
									montant * (parseFloat(this.dataPage.taux_apaym) / 100)
								);
						// On lance la requête pour modifier le montant ainsi que le frais
						// this.modifierMontantFrais(this.dataFormulaire.montant, frais) ;

						if (this.dataPage.frais == "2") {

							feesPaidBy = 2;
						} else if (this.dataPage.frais == "1") {
							// Payer les frais est facultatif
							if (this.checkedFeesApaym == true) {
								feesPaidBy = 1;
							}
						} else if (this.dataPage.frais == "3") {
							// Frais partagés
							if (this.checkedFeesApaym == true) {
								feesPaidBy = 3;
							}
						}

						this.generateQr(
							window.localStorage.getItem("_reference_" + this.url_data.slug),
							frais,
							this.dataFormulaire.montant
						);

						return false;
					}

					this.dataFormulaire.montant = this.montantSave;
					this.generateQr(
						window.localStorage.getItem("_reference_" + this.url_data.slug),
						feesPaidBy
					);
				} else {
					let montant = this.montantSave;
					let frais = 0;
					let feesPaidBy = 0;
					if (this.checkedFeesApaym) {
						console.log(montant);
						this.dataFormulaireQuick.montant =
							this.dataPage.frais == "3"
								? parseFloat(montant) +
								parseFloat(
									montant * (parseFloat(this.dataPage.taux_apaym / 2) / 100)
								)
								: parseFloat(montant) +
								parseFloat(
									montant * (parseFloat(this.dataPage.taux_apaym) / 100)
								);

						frais =
							this.dataPage.frais == "3"
								? parseFloat(
									montant * (parseFloat(this.dataPage.taux_apaym / 2) / 100)
								)
								: parseFloat(
									montant * (parseFloat(this.dataPage.taux_apaym) / 100)
								);
						feesPaidBy = 3;
						// On lance la requête pour modifier le montant ainsi que le frais
						// this.modifierMontantFrais(this.dataFormulaire.montant, frais) ;

						this.generateQr(
							window.localStorage.getItem("_reference_" + this.url_data.slug),
							feesPaidBy
						);


						return false;
					}

// Si le client doit obligatoirement payer les frais
					if (this.dataPage.frais == "2") {

						feesPaidBy = 2;
					} else if (this.dataPage.frais == "1") {
						// Payer les frais est facultatif
						if (this.checkedFeesOm == true) {
							feesPaidBy = 1;
						}
					} else if (this.dataPage.frais == "3") {
						// Frais partagés
						if (this.checkedFeesOm == true) {
							feesPaidBy = 3;
						}
					}
					this.dataFormulaireQuick.montant = this.montantSave;
					frais = 0;
					this.generateQr(
						window.localStorage.getItem("_reference_" + this.url_data.slug),
						feesPaidBy
					);
				}
			},

			// ECOBANK QR
			generateEcobankQr(ref, frais, montant) {
				this.isLoadingEcobank = true;
				let dataSend = new FormData();
				dataSend.append("reference", ref);
				dataSend.append("frais", frais);
				dataSend.append("montant", montant);
				dataSend.append(
					"indice",
					this.indice.i.code !== "" ? this.indice.i.code : "Indice inconnu"
				);

				axios
				.post(
					apiUrl + "api/paiement-marchand/generer-dynamic-qr-code-ecobank",
					dataSend
				)
				.then((response) => {
					this.isLoadingEcobank = false;
					if (response.data.code == "success") {
						this.ecobankQrCodeUri = response.data.qrCodeUri;
						return false;
					}

					sweetAlert(
						this.$swal,
						true,
						"top-end",
						"error",
						"Erreur",
						response.data["message"]
					);
				})
				.catch((error) => {
					this.isLoadingEcobank = false;
					console.log(error);
					sweetAlert(
						this.$swal,
						true,
						"top-end",
						"error",
						"Erreur",
						"Une erreur est survenue, veuillez réessayer plus tard"
					);
				});
			},

			// ECOBANK QR, payer les frais
			feeAmountEcobankQr() {
				this.checkedFeesApaym = !this.checkedFeesApaym;

				if (this.typeForm == "normal") {
					let montant = this.montantSave;
					let frais = 0;
					if (this.checkedFeesApaym) {
						// console.log(montant) ;
						this.dataFormulaire.montant = Math.round(
							parseFloat(montant) +
							(this.checkedFeesApaym
								? parseFloat(
									montant * (parseFloat(this.dataPage.taux_apaym) / 100)
								)
								: 0)
						);

						frais = this.checkedFeesApaym
							? Math.round(
								parseFloat(
									montant * (parseFloat(this.dataPage.taux_apaym) / 100)
								)
							)
							: 0;

						this.generateEcobankQr(
							window.localStorage.getItem("_reference_" + this.url_data.slug),
							frais,
							this.dataFormulaire.montant
						);

						return false;
					}

					this.dataFormulaire.montant = this.montantSave;
					frais = 0;
					console.log("Montant = " + this.dataFormulaire.montant);
					console.log("frais = " + frais);
					this.generateEcobankQr(
						window.localStorage.getItem("_reference_" + this.url_data.slug),
						"",
						this.dataFormulaire.montant
					);
				} else {
					// this.sauvegardeMontant = this.dataFormulaireQuick.montant ;
					let montant = this.montantSave;
					let frais = 0;
					if (this.checkedFeesApaym) {
						console.log(montant);
						this.dataFormulaireQuick.montant = Math.round(
							parseFloat(montant) +
							(this.checkedFeesApaym
								? parseFloat(
									montant * (parseFloat(this.dataPage.taux_apaym) / 100)
								)
								: 0)
						);

						frais = this.checkedFeesApaym
							? Math.round(
								parseFloat(
									montant * (parseFloat(this.dataPage.taux_apaym) / 100)
								)
							)
							: 0;

						this.generateEcobankQr(
							window.localStorage.getItem("_reference_" + this.url_data.slug),
							frais,
							this.dataFormulaireQuick.montant
						);

						console.log("Montant = " + this.dataFormulaireQuick.montant);
						console.log("frais = " + frais);

						return false;
					}

					this.dataFormulaireQuick.montant = this.montantSave;
					frais = 0;
					console.log("Montant = " + this.dataFormulaireQuick.montant);
					console.log("frais = " + frais);
					this.generateEcobankQr(
						window.localStorage.getItem("_reference_" + this.url_data.slug),
						"",
						this.dataFormulaireQuick.montant
					);
				}
			},

			// APaym payment code
			sendAPaym() {
				if (
					this.dataApaymPay.numApaym.replace(/\s/g, "") == "" ||
					this.dataApaymPay.codeApaym.replace(/\s/g, "") == ""
				) {
					sweetAlert(
						this.$swal,
						true,
						"top-end",
						"info",
						"Info",
						"Veuillez renseigner votre numéro de téléphone APaym ainsi que votre code de paiement !"
					);
					return false;
				}

				let montant = this.montantSave,
					frais = 0,
					feesPaidBy = 0;
				// Si le client doit obligatoirement payer les frais
				if (this.dataPage.frais == "2") {
					montant =
						parseFloat(this.montantSave) +
						(parseFloat(this.montantSave) *
							parseFloat(this.dataPage.taux_apaym)) /
						100;
					frais =
						(parseFloat(this.montantSave) *
							parseFloat(this.dataPage.taux_apaym)) /
						100;
					feesPaidBy = 2;
				} else if (this.dataPage.frais == "1") {
					// Payer les frais est facultatif
					if (this.checkedFeesApaym == true) {
						// montant = parseFloat(this.montantSave) + ( parseFloat(this.montantSave) * parseFloat(this.dataPage.taux_apaym) / 100 ) ;
						montant =
							parseFloat(this.montantSave) +
							(parseFloat(this.montantSave) *
								parseFloat(this.dataPage.taux_apaym)) /
							100;
						frais =
							(parseFloat(this.montantSave) *
								parseFloat(this.dataPage.taux_apaym)) /
							100;
						feesPaidBy = 1;
					}
				} else if (this.dataPage.frais == "3") {
					// Frais partagés
					if (this.checkedFeesApaym == true) {
						montant =
							parseFloat(this.montantSave) +
							(parseFloat(this.montantSave) *
								parseFloat(this.dataPage.taux_apaym / 2)) /
							100;
						frais =
							(parseFloat(this.montantSave) *
								parseFloat(this.dataPage.taux_apaym / 2)) /
							100;
						feesPaidBy = 3;
					}
				}

				this.isLoadingAPaym = true;
				let dataSend = new FormData();
				// dataSend.append("reference", _reference.get());
				dataSend.append(
					"reference",
					window.localStorage.getItem("_reference_" + this.url_data.slug)
				);
				dataSend.append(
					"numAPaym",
					this.dataApaymPay.indice.code +
					"" +
					this.dataApaymPay.numApaym.replace(/\s/g, "")
				);
				dataSend.append(
					"codePaiement",
					this.dataApaymPay.codeApaym.replace(/\s/g, "")
				);
				dataSend.append("feesPaidBy", feesPaidBy);

				axios
				.post(apiUrl + "api/paiement-marchand/code-paiement", dataSend)
				.then((response) => {
					this.isLoadingAPaym = false;

					if (response.data.code == 1) {
						// this.dataPage.deleg_pdci == "3" ? this.doYangoRechargement() : console.log("Call back") ;
						this.hideRecu = false;

						this.showPaymentSection = false;
						if (this.typeForm == "normal") {
							this.formPayment = true;
						} else {
							this.formQuiclyPayment = true;
						}

						if (this.slug_boutique != null) {
							setTimeout(() => {
								window.location.href =
									"https://shopapaym.weblogy.net/" + this.slug_boutique;
							}, 2000);
							return;
						}

						if (this.url_data.url_redirect != null) {

							// if ( this.dataPage.merchant == '25377' )
							// {
							//     setTimeout(() => {
							//         window.location.href = this.url_data.url_redirect +"/recu/?num_paiement="+ window.sessionStorage.getItem("k");
							//     }, 2000);
							//     return;
							// }
							setTimeout(() => {
								window.location.href = this.url_data.url_redirect;
							}, 2000);
							return;
						}

						if (this.dataPage.urlRedirection !== "" && this.dataPage.urlRedirection !== null) {
							if (this.dataPage.merchant == '25377') {
								setTimeout(() => {
									window.location.href = this.dataPage.urlRedirection + "recu/?num_paiement=" + window.sessionStorage.getItem("k");
								}, 2000);
								return;
							}

							setTimeout(
								(window.location.href = this.dataPage.urlRedirection),
								12000
							)
						}

						// this.dataPage.urlRedirection !== "" &&
						// this.dataPage.urlRedirection !== null
						//     ? setTimeout(
						//         (window.location.href = this.dataPage.urlRedirection),
						//         12000
						//     )
						//     : console.log("");

						return false;
					}

					sweetAlert(
						this.$swal,
						true,
						"top-end",
						"error",
						"Erreur",
						response.data["message"]
					);
				})
				.catch((error) => {
					this.isLoadingAPaym = false;
					console.log(error);
					sweetAlert(
						this.$swal,
						true,
						"top-end",
						"error",
						"Erreur",
						"Une erreur est survenue, veuillez réessayer plus tard"
					);
				});
			},

			// APaym authentication - Send connection code
			sendAPaymAuth() {
				if (this.dataApaymPayAuth.numApaym.replace(/\s/g, "") == "") {
					sweetAlert(
						this.$swal,
						true,
						"top-end",
						"info",
						"Info",
						"Veuillez renseigner numéro de téléphone APaym ainsi que votre code de paiement !"
					);
					return false;
				}

				this.isLoadingAPaymAuth = true;
				this.hideCodePay = false;

				let dataSend = new FormData();
				dataSend.append(
					"numero",
					this.dataApaymPayAuth.indice.code +
					"" +
					this.dataApaymPayAuth.numApaym.replace(/\s/g, "")
				);

				axios
				.post(apiUrl + "api/paiement-marchand/authentification-apaym", dataSend)
				.then((response) => {
					this.isLoadingAPaymAuth = false;

					if (response.data.code == 1) {
						this.hideCodePay = true;
						this.dataApaymPayAuth.token = response.data.token;
						return false;
					}

					sweetAlert(
						this.$swal,
						true,
						"top-end",
						"error",
						"Erreur",
						response.data["message"]
					);
				})
				.catch((error) => {
					this.isLoadingAPaymAuth = false;
					console.log(error);
					sweetAlert(
						this.$swal,
						true,
						"top-end",
						"error",
						"Erreur",
						"Une erreur est survenue, veuillez réessayer plus tard"
					);
				});
			},

			// APaym authentication
			sendAPaymToken() {
				if (
					this.dataApaymPayAuth.numApaym.replace(/\s/g, "") == "" ||
					this.dataApaymPayAuth.codeApaym.replace(/\s/g, "") == ""
				) {
					sweetAlert(
						this.$swal,
						true,
						"top-end",
						"info",
						"Info",
						"Veuillez renseigner numéro de téléphone APaym ainsi que votre code de connexion !"
					);
					return false;
				}

				this.isLoadingAPaymAuth = true;
				let dataSend = new FormData();
				dataSend.append(
					"numero",
					this.dataApaymPayAuth.indice.code +
					"" +
					this.dataApaymPayAuth.numApaym.replace(/\s/g, "")
				);
				dataSend.append(
					"codeConnexion",
					this.dataApaymPayAuth.codeApaym.replace(/\s/g, "")
				);
				dataSend.append("token", this.dataApaymPayAuth.token);

				axios
				.post(apiUrl + "api/paiement-marchand/verifier-otp", dataSend)
				.then((response) => {
					this.isLoadingAPaymAuth = false;

					if (response.data.code == 1) {
						// Mask APaym auth form
						this.loginApaym = false;
						this.hideCodePay = false;

						// Show list of cards
						this.cards = [];
						this.cardsLength = response.data["nombre_cards"];
						this.showCard = true;

						for (let card of response.data["cards"]) {
							if (card.card_key_name == "NSIACIVISAPCN") {
								this.cards.push({
									id: card.id,
									bank: card.bank,
									card_color: `background-image: linear-gradient(to right bottom, ${card.card_color}, ${card.card_color}, ${card.card_color}, ${card.card_color}, ${card.card_color})`,
									last_4_digit: card.last_4_digit,
									nickname: card.nickname,
									favori_card: card.favori_card,
									favori_card_: card.favori_card ? 1 : 0,
									imgCard: "Carte-apaym-01.png",
								});
							} else if (card.card_key_name == "UBACIVISAPPB") {
								this.cards.push({
									id: card.id,
									bank: card.bank,
									card_color: `background-image: linear-gradient(to right bottom, ${card.card_color}, ${card.card_color}, ${card.card_color}, ${card.card_color}, ${card.card_color})`,
									last_4_digit: card.last_4_digit,
									nickname: card.nickname,
									favori_card: card.favori_card,
									favori_card_: card.favori_card ? 1 : 0,
									imgCard: "Carte-apaym-06.png",
								});
							} else if (card.card_key_name == "UBACIVISAPPN") {
								this.cards.push({
									id: card.id,
									bank: card.bank,
									card_color: `background-image: linear-gradient(to right bottom, ${card.card_color}, ${card.card_color}, ${card.card_color}, ${card.card_color}, ${card.card_color})`,
									last_4_digit: card.last_4_digit,
									nickname: card.nickname,
									favori_card: card.favori_card,
									favori_card_: card.favori_card ? 1 : 0,
									imgCard: "Carte-apaym-05.png",
								});
							} else if (card.card_key_name == "UBACIVISAVCN") {
								this.cards.push({
									id: card.id,
									bank: card.bank,
									card_color: `background-image: linear-gradient(to right bottom, ${card.card_color}, ${card.card_color}, ${card.card_color}, ${card.card_color}, ${card.card_color})`,
									last_4_digit: card.last_4_digit,
									nickname: card.nickname,
									favori_card: card.favori_card,
									favori_card_: card.favori_card ? 1 : 0,
									imgCard: "Carte-Virtuelle-APAYM-UBA.png",
								});
							} else {
								this.cards.push({
									id: card.id,
									bank: card.bank,
									card_color: `background-image: linear-gradient(to right bottom, ${card.card_color}, ${card.card_color}, ${card.card_color}, ${card.card_color}, ${card.card_color})`,
									last_4_digit: card.last_4_digit,
									nickname: card.nickname,
									favori_card: card.favori_card,
									favori_card_: card.favori_card ? 1 : 0,
									imgCard: "visa-autre.png",
								});
							}
						}

						return false;
					}

					sweetAlert(
						this.$swal,
						true,
						"top-end",
						"error",
						"Erreur",
						response.data["message"]
					);

					this.showCard = false;
					this.hideCodePay = false;
					this.loginApaym = true;
					this.dataApaymPayAuth.codeApaym = "";
					this.dataApaymPayAuth.token = "";
				})
				.catch((error) => {
					this.isLoadingAPaymAuth = false;
					this.showCard = false;
					this.hideCodePay = false;
					this.loginApaym = true;
					this.dataApaymPayAuth.codeApaym = "";
					this.dataApaymPayAuth.token = "";

					console.log(error);
					sweetAlert(
						this.$swal,
						true,
						"top-end",
						"error",
						"Erreur",
						"Une erreur est survenue, veuillez réessayer plus tard"
					);
				});
			},

			// APaym payment with card from wallet
			payWithApaym(carte) {
				let devise =
					this.dataFormulaire.devise !== ""
						? this.dataFormulaire.devise
						: this.dataFormulaireQuick.devise;
				let montant = this.montantSave,
					frais = 0,
					feesPaidBy = 0;
				if (this.dataPage.frais == "2") {
					montant =
						parseFloat(this.montantSave) +
						(parseFloat(this.montantSave) *
							parseFloat(this.dataPage.taux_apaym)) /
						100;

					frais =
						(parseFloat(this.montantSave) *
							parseFloat(this.dataPage.taux_apaym)) /
						100;
					feesPaidBy = 2;
				} else if (this.dataPage.frais == "1") {
					// Payer les frais est facultatif
					if (this.checkedFeesApaym == true) {
						montant =
							parseFloat(this.montantSave) +
							(parseFloat(this.montantSave) *
								parseFloat(this.dataPage.taux_apaym)) /
							100;

						frais =
							(parseFloat(this.montantSave) *
								parseFloat(this.dataPage.taux_apaym)) /
							100;
						feesPaidBy = 1;
					}
				} else if (this.dataPage.frais == "3") {
					// Frais partagés
					if (this.checkedFeesApaym == true) {
						montant =
							parseFloat(this.montantSave) +
							(parseFloat(this.montantSave) *
								parseFloat(this.dataPage.taux_apaym / 2)) /
							100;
						frais =
							(parseFloat(this.montantSave) *
								parseFloat(this.dataPage.taux_apaym / 2)) /
							100;
						feesPaidBy = 3;
					}
				}

				this.$swal({
					title: this.$t("payment_modal.apaym.text_validate_payment_title.value"),
					html:
						"<div style='line-height: 1.5; font-size: 18px;'>" +
						this.$t("payment_modal.apaym.text_validate_payment.value") +
						"" +
						carte.nickname +
						" Visa **** <b>" +
						carte.last_4_digit +
						"</b>" +
						this.$t("payment_modal.apaym.text_validate_payment2.value") +
						"<b>" +
						new Intl.NumberFormat("fr-FR", {
							style: "currency",
							currency: devise,
						}).format(parseFloat(montant)) +
						"</b> " +
						this.$t("payment_modal.apaym.text_validate_payment3.value") +
						"</div>",
					icon: "info",
					showCancelButton: true,
					cancelButtonColor: "#6e7881",
					confirmButtonText: this.$t(
						"payment_modal.apaym.text_validate_payment_yes.value"
					),
					cancelButtonText: this.$t(
						"payment_modal.apaym.text_validate_payment_no.value"
					),
					reverseButtons: true,
				}).then((result) => {
					if (result.isConfirmed) {
						this.isLoadingAPaymAuth = true;
						let dataSend = new FormData();
						dataSend.append(
							"numero",
							this.dataApaymPayAuth.indice.code +
							"" +
							this.dataApaymPayAuth.numApaym.replace(/\s/g, "")
						);
						dataSend.append("token", this.dataApaymPayAuth.token);
						dataSend.append(
							"reference",
							window.localStorage.getItem("_reference_" + this.url_data.slug)
						);
						dataSend.append("card_id", carte.id);
						dataSend.append("feesPaidBy", feesPaidBy);

						axios
						.post(apiUrl + "api/paiement-marchand/apaym", dataSend)
						.then((response) => {
							this.isLoadingAPaymAuth = false;
							this.$swal.close();

							if (response.data.code == 1) {
								this.hideRecu = false;
								this.dataApaymPayAuth.codeApaym = "";
								this.dataApaymPayAuth.token = "";

								this.showPaymentSection = false;
								if (this.typeForm == "normal") {
									this.formPayment = true;
								} else {
									this.formQuiclyPayment = true;
								}

								if (this.slug_boutique != null) {
									setTimeout(() => {
										window.location.href =
											"https://shopapaym.weblogy.net/" + this.slug_boutique;
									}, 2000);
									return;
								}

								if (this.url_data.url_redirect != null) {

									// if ( this.dataPage.merchant == '25377' )
									// {
									//     setTimeout(() => {
									//         window.location.href = this.url_data.url_redirect +"/recu/?num_paiement="+ window.sessionStorage.getItem("k");
									//     }, 2000);
									//     return;
									// }
									setTimeout(() => {
										window.location.href = this.url_data.url_redirect;
									}, 2000);
									return;
								}

								if (this.dataPage.urlRedirection !== "" && this.dataPage.urlRedirection !== null) {
									if (this.dataPage.merchant == '25377') {
										setTimeout(() => {
											window.location.href = this.dataPage.urlRedirection + "recu/?num_paiement=" + window.sessionStorage.getItem("k");
										}, 2000);
										return;
									}

									setTimeout(
										(window.location.href = this.dataPage.urlRedirection),
										12000
									)
								}

								// this.dataPage.urlRedirection !== "" &&
								// this.dataPage.urlRedirection !== null
								//     ? setTimeout(
								//         (window.location.href = this.dataPage.urlRedirection),
								//         12000
								//     )
								//     : console.log("");

								return false;
							}

							sweetAlert(
								this.$swal,
								true,
								"top-end",
								"error",
								"Erreur",
								response.data["message"]
							);
							this.showCard = false;
							this.hideCodePay = false;
							this.loginApaym = true;
							this.dataApaymPayAuth.codeApaym = "";
							this.dataApaymPayAuth.token = "";
						})
						.catch((error) => {
							this.isLoadingAPaymAuth = false;
							console.log(error);
							sweetAlert(
								this.$swal,
								true,
								"top-end",
								"error",
								"Erreur",
								"Une erreur est survenue, veuillez réessayer plus tard"
							);
							this.showCard = false;
							this.hideCodePay = false;
							this.loginApaym = true;
							this.dataApaymPayAuth.codeApaym = "";
							this.dataApaymPayAuth.token = "";
						});
					}
				});
			},

			// Disconnect APaym account
			disconnectApaym() {
				this.showCard = false;
				this.hideCodePay = false;
				this.loginApaym = true;

				this.dataApaymPayAuth.codeApaym = "";
				this.dataApaymPayAuth.token = "";
			},

			sendVisa() {
				if (
					this.dataVisa.pan.replace(/\s/g, "") == "" ||
					this.dataVisa.expiration.replace(/\s/g, "") == "" ||
					this.dataVisa.cvv.replace(/\s/g, "") == ""
				) {
					sweetAlert(
						this.$swal,
						true,
						"top-end",
						"info",
						"Info",
						"Veuillez renseigner les informations de votre carte Visa !"
					);
					return false;
				}
			},

			// Bank card - BICICI Form
			sendBicici() {
				if (this.cardType == "") {
					sweetAlert(
						this.$swal,
						true,
						"top-end",
						"info",
						"Attention",
						"Veuillez préciser si votre carte est Visa ou Mastercard !"
					);
					return false;
				}

				this.biciciNOkay = false;
				this.isLoadingBicici = true;

				let dataSend = new FormData();
				dataSend.append(
					"reference",
					window.localStorage.getItem("_reference_" + this.url_data.slug)
				);
				dataSend.append("cardType", this.cardType);

				axios
				.post(apiUrl + "api/payment-merchant/bicici", dataSend)
				.then((response) => {
					this.isLoadingBicici = false;

					if (response.data.code == 1) {
						if (response.data.status == "INITIALIZED") {
							this.contentBicici = response.data.redirect_url;
							this.isBicici = !this.isBicici;
							this.formBicici = !this.formBicici;
							this.modalBicici = true;
							return false;
						}

						sweetAlert(
							this.$swal,
							true,
							"top-end",
							"error",
							"Erreur",
							"Une erreur est survenue, veuillez réessayer plus tard"
						);
						return false;
					}

					sweetAlert(
						this.$swal,
						true,
						"top-end",
						"error",
						"Erreur",
						"Une erreur est survenue, veuillez réessayer plus tard"
					);
					return false;
				})
				.catch((error) => {
					this.isLoadingBicici = false;
					console.log(error);
					sweetAlert(
						this.$swal,
						true,
						"top-end",
						"error",
						"Erreur",
						"Une erreur est survenue, veuillez réessayer plus tard"
					);
				});
			},

			// Vérifier qu'un numéro de carte est Visa
			isVisaCardNumberValid(cardNumber) {
				console.log(cardNumber);

				// Regex to check valid
				// cardNumber
				let regex = new RegExp(/^4[0-9]{12}(?:[0-9]{3})?$/);

				// if cardNumber
				// is empty return false
				if (cardNumber == null) {
					return "false";
				}

				// Return true if the cardNumber
				// matched the ReGex
				if (regex.test(cardNumber) == true) {
					return "true";
				} else {
					return "false";
				}
			},

			// Vérifier qu'un numéro de carte est Mastercard
			isMastercardCardNumberValid(cardNumber) {
				console.log(cardNumber);

				// Regex to check valid
				// cardNumber
				let regex = new RegExp(
					/^5[1-5][0-9]{14}|^(222[1-9]|22[3-9]\\d|2[3-6]\\d{2}|27[0-1]\\d|2720)[0-9]{12}$/
				);

				// if cardNumber
				// is empty return false
				if (cardNumber == null) {
					return "false";
				}

				// Return true if the cardNumber
				// matched the ReGex
				if (regex.test(cardNumber) == true) {
					return "true";
				} else {
					return "false";
				}
			},

			sendCardPayment() {

				let montant = this.montantSave,
					frais = 0,
					cardHolder = "",
					whoPayFees = "MARCHAND";

				if (this.dataPage.frais == "2") {
					montant =
						parseFloat(this.montantSave) +
						(parseFloat(this.montantSave) * parseFloat(this.dataPage.taux_cb)) /
						100;

					frais =
						(parseFloat(this.montantSave) * parseFloat(this.dataPage.taux_cb)) /
						100;

				} else if (this.dataPage.frais == "1") {

					if (this.checkedFeesCb == true) {
						montant =
							parseFloat(this.montantSave) +
							(parseFloat(this.montantSave) * parseFloat(this.dataPage.taux_cb)) /
							100;

						frais =
							(parseFloat(this.montantSave) * parseFloat(this.dataPage.taux_cb)) /
							100;
					}
				} else if (this.dataPage.frais == "3") {
					// Frais partagés
					if (this.checkedFeesCb == true) {
						montant =
							parseFloat(this.montantSave) +
							(parseFloat(this.montantSave) *
								parseFloat(this.dataPage.taux_cb / 2)) /
							100;
						frais =
							(parseFloat(this.montantSave) *
								parseFloat(this.dataPage.taux_cb / 2)) /
							100;
						whoPayFees = "CLIENT";
					}
				}

				if (
					this.dataVisaCard.pan.replace(/\s/g, "") == "" ||
					this.dataVisaCard.expirationMonth == "" ||
					this.dataVisaCard.expirationYear == "" ||
					this.dataVisaCard.cvv.replace(/\s/g, "") == ""
				) {
					this.$swal({
						title: "Information",
						icon: "info",
						html: "<strong class='font-16' style='line-height: 1.5'>Veuillez renseigner toutes les informations du formulaire !</strong>",
					});

					return false;
				}

				this.isLoadingBicici = true;

				if (this.dataVisaCard.cardHolderName.replace(/\s/g, "") == "") {
					this.dataVisaCard.cardHolderName = "Client APaym";
				}

				if (this.menuSelected == "VISA") {

					/*if (!this.formRechargement.issuer_name) {
                        this.isLoadingBicici = false;
                        this.$swal({
                            title: "Information",
                            icon: "info",
                            html: "<strong class='font-16' style='line-height: 1.5'>Veuillez renseigner une carte Visa valide !</strong>",
                        });
                        
                        return false;
                    }*/

					if (
						this.isVisaCardNumberValid(
							this.dataVisaCard.pan.replace(/\s/g, "")
						) === "true"
					) {
						if (this.dataPage.merchant == "21449" ||
							this.dataPage.merchant == "24545" ||
							this.dataPage.merchant == "25695" ||
							this.dataPage.merchant == "25764" ||
							this.dataPage.merchant == "24831" ||
							this.dataPage.merchant == "25682" || this.dataPage.merchant == "21040") {
							window.location.href =
								"https://carte.abidjan.net/mobilemoney/paymentoffshore.php?pan=" +
								this.dataVisaCard.pan.replace(/\s/g, "") +
								"&amount=" +
								montant +
								"&cardholderName=" +
								this.dataVisaCard.cardHolderName.replace(/\s/g, "") +
								"&expiry=" +
								this.dataVisaCard.expirationYear +
								"-" +
								this.dataVisaCard.expirationMonth +
								"&cvv=" +
								this.dataVisaCard.cvv +
								"&frais=" +
								frais +
								"&cardType=" +
								this.menuSelected +
								"&slug=" +
								this.url_data.slug +
								"&reference=" +
								window.localStorage.getItem("_reference_" + this.url_data.slug);
							return false;
						}

						window.location.href =
							"https://carte.abidjan.net/mobilemoney/paymentrequest.php?pan=" +
							this.dataVisaCard.pan.replace(/\s/g, "") +
							"&amount=" +
							montant +
							"&cardholderName=" +
							this.dataVisaCard.cardHolderName.replace(/\s/g, "") +
							"&expiry=" +
							this.dataVisaCard.expirationYear +
							"-" +
							this.dataVisaCard.expirationMonth +
							"&cvv=" +
							this.dataVisaCard.cvv +
							"&frais=" +
							frais +
							"&cardType=" +
							this.menuSelected +
							"&slug=" +
							this.url_data.slug +
							"&reference=" +
							window.localStorage.getItem("_reference_" + this.url_data.slug);

						return false;
					}

					this.isLoadingBicici = false;
					this.$swal({
						title: "Information",
						icon: "info",
						html: "<strong class='font-16' style='line-height: 1.5'>Veuillez renseigner une carte Visa valide !</strong>",
					});
				} else {
					if (
						this.isMastercardCardNumberValid(this.dataVisaCard.pan.replace(/\s/g, "")) === "true"
					) {
						if (this.dataPage.merchant == "21449" ||
							this.dataPage.merchant == "24545" ||
							this.dataPage.merchant == "25695" ||
							this.dataPage.merchant == "25764" ||
							this.dataPage.merchant == "24831" ||
							this.dataPage.merchant == "25682" ||
							this.dataPage.merchant == "21040") {
							window.location.href =
								"https://carte.abidjan.net/mobilemoney/paymentoffshore.php?pan=" +
								this.dataVisaCard.pan.replace(/\s/g, "") +
								"&amount=" +
								montant +
								"&cardholderName=" +
								this.dataVisaCard.cardHolderName.replace(/\s/g, "") +
								"&expiry=" +
								this.dataVisaCard.expirationYear +
								"-" +
								this.dataVisaCard.expirationMonth +
								"&cvv=" +
								this.dataVisaCard.cvv +
								"&frais=" +
								frais +
								"&cardType=" +
								this.menuSelected +
								"&slug=" +
								this.url_data.slug +
								"&reference=" +
								window.localStorage.getItem("_reference_" + this.url_data.slug);
							return false;
						}

						window.location.href =
							"https://carte.abidjan.net/mobilemoney/paymentrequest.php?pan=" +
							this.dataVisaCard.pan.replace(/\s/g, "") +
							"&amount=" +
							montant +
							"&cardholderName=" +
							this.dataVisaCard.cardHolderName.replace(/\s/g, "") +
							"&expiry=" +
							this.dataVisaCard.expirationYear +
							"-" +
							this.dataVisaCard.expirationMonth +
							"&cvv=" +
							this.dataVisaCard.cvv +
							"&frais=" +
							frais +
							"&cardType=" +
							this.menuSelected +
							"&slug=" +
							this.url_data.slug +
							"&reference=" +
							window.localStorage.getItem("_reference_" + this.url_data.slug);
						return false;
					}

					this.isLoadingBicici = false;
					this.$swal({
						title: "Information",
						icon: "info",
						html: "<strong class='font-16' style='line-height: 1.5'>Veuillez renseigner une Mastercard valide !</strong>",
					});
				}

				// return false;

				/*window.location.href = "https://carte.abidjan.net/mobilemoney/paymentrequest.php?pan="+this.dataVisaCard.pan.replace(/\s/g, '')
                    +"&amount="+ montant +"&cardholderName="+ this.dataVisaCard.cardHolderName + "&expiry="+ this.dataVisaCard.expirationYear+"-"+this.dataVisaCard.expirationMonth
                    + "&cvv="+ this.dataVisaCard.cvv + "&frais="+ frais +  "&cardType=" + this.menuSelected + "&reference="+ window.localStorage.getItem("_reference_"+ this.url_data.slug)
                ;*/

				// window.location.href = "https://carte.abidjan.net/pro/api/init-card-payment/"+ window.localStorage.getItem("_reference_"+ this.dataPage.slug) ;
				// window.location.href = apiUrl +"api/paiement-marchand/paiement-carte-bancaire/"+ this.url_data.reference.substring(0, this.url_data.reference.length - 1) +"/"+ Math.round(montant) +"/"+ Math.round(frais) ;
			},

			sendCardPaymentUba() {
				let montant = this.montantSave,
					frais = 0,
					feesPaidBy = 0;

				if (this.dataPage.frais == "2") {
					montant =
						parseFloat(this.montantSave) +
						(parseFloat(this.montantSave) * parseFloat(this.dataPage.taux_cb)) /
						100;
					frais =
						(parseFloat(this.montantSave) * parseFloat(this.dataPage.taux_cb)) /
						100;
					feesPaidBy = 2;
				} else if (this.dataPage.frais == "1") {
					if (this.checkedFeesCb == true) {
						montant =
							parseFloat(this.montantSave) +
							(parseFloat(this.montantSave) * parseFloat(this.dataPage.taux_cb)) /
							100;
						frais =
							(parseFloat(this.montantSave) * parseFloat(this.dataPage.taux_cb)) /
							100;
						feesPaidBy = 1;
					}
				} else if (this.dataPage.frais == "3") {
					// Frais partagés
					if (this.checkedFeesCb == true) {
						montant =
							parseFloat(this.montantSave) +
							(parseFloat(this.montantSave) *
								parseFloat(this.dataPage.taux_cb / 2)) /
							100;
						frais =
							(parseFloat(this.montantSave) *
								parseFloat(this.dataPage.taux_cb / 2)) /
							100;
						feesPaidBy = 3;
					}
				}

				window.location.href =
					apiUrl +
					"api/paiement-marchand/paiement-carte-bancaireTest/" +
					this.url_data.reference.substring(
						0,
						this.url_data.reference.length - 1
					) +
					"/" +
					feesPaidBy;
			},

			// Orange money payment
			sendOM(option) {
				this.orangeOkay = false;
				this.orangeNOkay = false;
				this.isLoadingOrange = true;

				let montant = this.montantSave,
					frais = 0,
					feesPaidBy = 0;
				// Si le client doit obligatoirement payer les frais
				if (this.dataPage.frais == "2") {
					montant =
						parseFloat(this.montantSave) +
						(parseFloat(this.montantSave) * parseFloat(this.dataPage.taux_om)) /
						100;
					frais =
						(parseFloat(this.montantSave) * parseFloat(this.dataPage.taux_om)) /
						100;
					feesPaidBy = 2;
				} else if (this.dataPage.frais == "1") {
					// Payer les frais est facultatif
					if (this.checkedFeesOm == true) {
						montant =
							parseFloat(this.montantSave) +
							(parseFloat(this.montantSave) * parseFloat(this.dataPage.taux_om)) /
							100;
						frais =
							(parseFloat(this.montantSave) * parseFloat(this.dataPage.taux_om)) /
							100;
						feesPaidBy = 1;
					}
				} else if (this.dataPage.frais == "3") {
					// Frais partagés
					if (this.checkedFeesOm == true) {
						montant =
							parseFloat(this.montantSave) +
							(parseFloat(this.montantSave) *
								parseFloat(this.dataPage.taux_om / 2)) /
							100;
						frais =
							(parseFloat(this.montantSave) *
								parseFloat(this.dataPage.taux_om / 2)) /
							100;
						feesPaidBy = 3;
					}
				}

				let dataSend = new FormData();
				dataSend.append(
					"reference",
					window.localStorage.getItem("_reference_" + this.url_data.slug)
				);
				dataSend.append("feesPaidBy", feesPaidBy);

				axios
				.post(apiUrl + "api/paiement-marchand/omoney", dataSend)
				.then((response) => {
					if (response.data.code == 1) {
						if (response.data.url == null || response.data.url == "") {
							this.isLoadingOrange = false;
							this.$refs["openOrangeMoneyModal"].hide();
							sweetAlert(
								this.$swal,
								true,
								"top-end",
								"error",
								"Erreur",
								"Veuillez reprendre tout le processus, si le problème persiste, veuillez essayer un autre moyen de paiement svp."
							);

							return false;
						}

						if (option == "app") {
							window.location.href = response.data.url;
							return false;
						}

						if (this.isMobile()) {
							window.location.href = "tel:%23 144*82 %23";
						}

						// Open iframe in Modal
						this.isLoadingOrange = false;
						this.urlOrange = response.data.url;
						this.orangeOkay = true;
						this.modalOrange = true;
						this.$refs["openOrangeMoneyModal"].show();

						return false;
					}

					sweetAlert(
						this.$swal,
						true,
						"top-end",
						"error",
						"Erreur",
						response.data["message"]
					);
					this.isLoadingOrange = false;
				})
				.catch((error) => {
					this.isLoadingOrange = false;
					console.log(error);
					sweetAlert(
						this.$swal,
						true,
						"top-end",
						"error",
						"Erreur",
						"Une erreur est survenue, veuillez réessayer plus tard"
					);
				});
			},

			// Wave payment
			sendWave() {
				this.waveOkay = false;
				this.waveNOkay = false;
				this.isLoadingWave = true;

				let montant = this.montantSave,
					frais = 0,
					feesPaidBy = 0;

				// Si le client doit obligatoirement payer les frais
				if (this.dataPage.frais == "2") {
					montant =
						parseFloat(this.montantSave) +
						(parseFloat(this.montantSave) * parseFloat(this.dataPage.taux_wave)) /
						100;
					frais =
						(parseFloat(this.montantSave) * parseFloat(this.dataPage.taux_wave)) /
						100;
					feesPaidBy = 2;
				} else if (this.dataPage.frais == "1") {
					// Payer les frais est facultatif
					if (this.checkedFeesWave == true) {
						montant =
							parseFloat(this.montantSave) +
							(parseFloat(this.montantSave) *
								parseFloat(this.dataPage.taux_wave)) /
							100;
						frais =
							(parseFloat(this.montantSave) *
								parseFloat(this.dataPage.taux_wave)) /
							100;
						feesPaidBy = 1;
					}
				} else if (this.dataPage.frais == "3") {
					// Frais partagés
					if (this.checkedFeesWave == true) {
						montant =
							parseFloat(this.montantSave) +
							(parseFloat(this.montantSave) *
								parseFloat(this.dataPage.taux_wave / 2)) /
							100;
						frais =
							(parseFloat(this.montantSave) *
								parseFloat(this.dataPage.taux_wave / 2)) /
							100;
						feesPaidBy = 3;
					}
				}

				let dataSend = new FormData();
				dataSend.append(
					"reference",
					window.localStorage.getItem("_reference_" + this.url_data.slug)
				);
				dataSend.append("feesPaidBy", feesPaidBy);

				axios
				.post(apiUrl + "api/paiement-marchand/wave", dataSend)
				.then((response) => {
					this.isLoadingWave = false;
					this.waveOkay = true;

					if (response.data.code == 1) {
						if (response.data.url == null || response.data.url == "") {
							this.isLoadingWave = false;
							this.$refs["openWaveModal"].hide();
							sweetAlert(
								this.$swal,
								true,
								"top-end",
								"error",
								"Erreur",
								"Veuillez reprendre tout le processus, si le problème persiste, veuillez essayer un autre moyen de paiement svp."
							);

							return false;
						}

						// Il s'agit d'un téléphone mobile, on lance l'app Wave directement
						// L'utilisateur doit s'assurer d'avoir installé la version à jour de l'app
						if (this.isMobile()) {
							window.location.href = response.data.url;
							return false;
						}

						// Open iframe in Modal
						this.urlWave = response.data.url;
						this.modalWave = true;
						this.$refs["openWaveModal"].show();

						return false;
					}

					sweetAlert(
						this.$swal,
						true,
						"top-end",
						"error",
						"Erreur",
						response.data["message"]
					);
					this.isLoadingWave = false;
				})
				.catch((error) => {
					this.isLoadingWave = false;
					console.log(error);
					sweetAlert(
						this.$swal,
						true,
						"top-end",
						"error",
						"Erreur",
						"Une erreur est survenue, veuillez réessayer plus tard"
					);
				});
			},

			// MTN MoMo payment
			sendMoMo() {
				if (this.dataMoMo.numero.replace(/\s/g, "") == "") {
					sweetAlert(
						this.$swal,
						true,
						"top-end",
						"info",
						"Info",
						"Veuillez renseigner votre numéro MoMo !"
					);
					return false;
				}

				this.mtnOkay = false;
				this.mtnNOkay = false;
				this.isLoadingMomo = true;

				let montant = this.montantSave,
					frais = 0,
					feesPaidBy = 0;
				// Si le client doit obligatoirement payer les frais
				if (this.dataPage.frais == "2") {
					montant =
						parseFloat(this.montantSave) +
						(parseFloat(this.montantSave) * parseFloat(this.dataPage.taux_mtn)) /
						100;
					frais =
						(parseFloat(this.montantSave) * parseFloat(this.dataPage.taux_mtn)) /
						100;
					feesPaidBy = 2;
				} else if (this.dataPage.frais == "1") {
					// Payer les frais est facultatif
					if (this.checkedFeesMtn == true) {
						montant =
							parseFloat(this.montantSave) +
							(parseFloat(this.montantSave) *
								parseFloat(this.dataPage.taux_mtn)) /
							100;
						frais =
							(parseFloat(this.montantSave) *
								parseFloat(this.dataPage.taux_mtn)) /
							100;
						feesPaidBy = 1;
					}
				} else if (this.dataPage.frais == "3") {
					// Payer les frais est partagé
					if (this.checkedFeesMtn == true) {
						montant =
							parseFloat(this.montantSave) +
							(parseFloat(this.montantSave) *
								parseFloat(this.dataPage.taux_mtn / 2)) /
							100;
						frais =
							(parseFloat(this.montantSave) *
								parseFloat(this.dataPage.taux_mtn / 2)) /
							100;
						feesPaidBy = 3;
					}
				}

				let dataSend = new FormData();
				dataSend.append(
					"reference",
					window.localStorage.getItem("_reference_" + this.url_data.slug)
				);
				dataSend.append("numero", this.dataMoMo.numero.replace(/\s/g, ""));
				dataSend.append("montant", montant);
				dataSend.append("frais", frais);
				dataSend.append("feesPaidBy", feesPaidBy);

				axios
				.post(apiUrl + "api/paiement-marchand/momo", dataSend)
				.then((response) => {
					if (response.data.code == 1000) {
						this.isLoadingMomo = false;
						this.mtnOkay = true;
						return false;
					}

					sweetAlert(
						this.$swal,
						true,
						"top-end",
						"error",
						"Erreur",
						response.data["message"]
					);
					this.isLoadingMomo = false;
				})
				.catch((error) => {
					this.isLoadingMomo = false;
					console.log(error);
					sweetAlert(
						this.$swal,
						true,
						"top-end",
						"error",
						"Erreur",
						"Une erreur est survenue, veuillez réessayer plus tard"
					);
				});
			},

			// Moov money payment
			sendMoov() {
				if (this.dataMoov.numero.replace(/\s/g, "") == "") {
					sweetAlert(
						this.$swal,
						true,
						"top-end",
						"info",
						"Info",
						"Veuillez renseigner votre numéro Flooz !"
					);
					return false;
				}

				this.moovOkay = false;
				this.moovNOkay = false;
				this.isLoadingFlooz = true;

				let montant = this.montantSave,
					frais = 0,
					feesPaidBy = 0;
				// Si le client doit obligatoirement payer les frais
				if (this.dataPage.frais == "2") {
					montant =
						parseFloat(this.montantSave) +
						(parseFloat(this.montantSave) * parseFloat(this.dataPage.taux_moov)) /
						100;
					frais =
						(parseFloat(this.montantSave) * parseFloat(this.dataPage.taux_moov)) /
						100;
					feesPaidBy = 2;
				} else if (this.dataPage.frais == "1") {
					// Payer les frais est facultatif
					if (this.checkedFeesMoov == true) {
						montant =
							parseFloat(this.montantSave) +
							(parseFloat(this.montantSave) *
								parseFloat(this.dataPage.taux_moov)) /
							100;
						frais =
							(parseFloat(this.montantSave) *
								parseFloat(this.dataPage.taux_moov)) /
							100;
						feesPaidBy = 1;
					}
				} else if (this.dataPage.frais == "3") {
					// Payer les frais est partagé
					if (this.checkedFeesMoov == true) {
						montant =
							parseFloat(this.montantSave) +
							(parseFloat(this.montantSave) *
								parseFloat(this.dataPage.taux_moov / 2)) /
							100;
						frais =
							(parseFloat(this.montantSave) *
								parseFloat(this.dataPage.taux_moov / 2)) /
							100;
						feesPaidBy = 3;
					}
				}

				let dataSend = new FormData();
				// dataSend.append("reference", _reference.get());
				dataSend.append(
					"reference",
					window.localStorage.getItem("_reference_" + this.url_data.slug)
				);
				dataSend.append("numero", this.dataMoov.numero.replace(/\s/g, ""));
				dataSend.append("feesPaidBy", feesPaidBy);

				axios
				.post(
					apiUrl + "api/paiement-marchand/flooz",
					// 'https://carte.abidjan.net/apaym_pro/api/payment-merchant/flooz',
					dataSend
				)
				.then((response) => {
					if (response.data.code == 1) {
						this.isLoadingFlooz = false;
						this.moovOkay = true;
						// this.startTimer();
						this.showVerifMoov = false;
						this.showInProcessMoov = true;

						this.stopInterval = setInterval(() => {
							this.checkingFloozStatus();
						}, 15000); // 15s

						return false;
					}

					sweetAlert(
						this.$swal,
						true,
						"top-end",
						"error",
						"Erreur",
						response.data["message"]
					); //
					this.isLoadingFlooz = false;
				})
				.catch((error) => {
					this.isLoadingFlooz = false;
					console.log(error);
					sweetAlert(
						this.$swal,
						true,
						"top-end",
						"error",
						"Erreur",
						"Une erreur est survenue, veuillez réessayer plus tard"
					);
				});
			},

			// Check if moov payment is made successfully or not
			checkingFloozStatus() {
				let dataSend = new FormData();
				dataSend.append(
					"reference",
					window.localStorage.getItem("_reference_" + this.url_data.slug)
				);

				axios
				.post(
					apiUrl + "api/paiement-marchand/statut-flooz",
					// 'https://carte.abidjan.net/apaym_pro/api/payment-merchant/verify-flooz-status',
					dataSend
				)
				.then((response) => {
					// Transaction was successfully
					if (response.data.statusCode == 0) {
						clearInterval(this.stopInterval);
						// this.dataPage.deleg_pdci == "3" ? this.doYangoRechargement() : console.log("Call back") ;

						if (
							this.dataPage.urlRedirection !== "" &&
							this.dataPage.urlRedirection !== null
						) {
							this.dataPage.redirectWithCode == "1"
								? setTimeout(
									(window.location.href =
										this.dataPage.urlRedirection +
										"?code=" +
										sessionStorage.getItem("k")),
									12000
								)
								: setTimeout(
									(window.location.href = this.dataPage.urlRedirection),
									12000
								);
						}

						// this.dataPage.urlRedirection !== "" && this.dataPage.urlRedirection !== null ? setTimeout( window.location.href = this.dataPage.urlRedirection, 12000) : console.log("") ;
						this.isLoadingFlooz = false;
						this.hideRecu = false;
						this.modePay = "Moov Money";
						this.modePayment = "Moov Money";
						// this.$refs['openPaymentModal'].hide();
						this.showPaymentSection = false;
						if (this.typeForm == "normal") {
							this.formPayment = true;
						} else {
							this.formQuiclyPayment = true;
						}

						if (this.slug_boutique != null) {
							setTimeout(() => {
								window.location.href =
									"https://shopapaym.weblogy.net/" + this.slug_boutique;
							}, 2000);
							return;
						}

						if (this.url_data.url_redirect != null) {

							setTimeout(() => {
								window.location.href = this.url_data.url_redirect;
							}, 2000);
							return;
						}

						if (
							this.dataPage.urlRedirection !== "" &&
							this.dataPage.urlRedirection !== null
						) {

							if (this.dataPage.merchant == '25377') {
								setTimeout(() => {
									window.location.href = this.dataPage.urlRedirection + "recu/?num_paiement=" + window.sessionStorage.getItem("k");
								}, 2000);
								return;
							}


							this.dataPage.redirectWithCode == "1"
								? setTimeout(
									(window.location.href =
										this.dataPage.urlRedirection +
										"?code=" +
										window.sessioStorage.getItem("k")),
									12000
								)
								: setTimeout(
									(window.location.href = this.dataPage.urlRedirection),
									12000
								);
						}


						return false;

					} else if (response.data.statusCode == 47) {

						// Transaction is still pending
						this.showVerifMoov = false;
						this.showInProcessMoov = true;
						this.checkMoovAgain();
						return false;

					} else {
						// Transaction was failed
						clearInterval(this.stopInterval);
						this.isLoadingFlooz = false;
						this.moovNOkay = true;
						this.moovOkay = false;
					}
				})
				.catch((error) => {
					this.isLoadingFlooz = false;
					console.log(error);
					sweetAlert(
						this.$swal,
						true,
						"top-end",
						"error",
						"Erreur",
						"Une erreur est survenue, veuillez réessayer plus tard"
					);
					clearInterval(this.stopInterval);
				});
			},

			checkMoovAgain() {
				// Vérification du statut de la transaction Flooz
				this.stopInterval = setInterval(() => {
					this.checkingFloozStatus();
				}, 30000); // 60s => 1min
			},

			// TresorPAY Payment
			sendTresorPAY() {
				if (this.dataTresorPAY.numero.replace(/\s/g, "") == "") {
					sweetAlert(
						this.$swal,
						true,
						"top-end",
						"info",
						"Info",
						"Veuillez renseigner votre numéro Trésor Pay !"
					);
					return false;
				}

				this.tresorOkay = false;
				this.tresorNOkay = false;
				this.isLoadingTresorPay = true;

				let montant = this.montantSave,
					frais = 0,
					feesPaidBy = 0;
				// Si le client doit obligatoirement payer les frais
				if (this.dataPage.frais == "2") {
					montant =
						parseFloat(this.montantSave) +
						(parseFloat(this.montantSave) * parseFloat(this.dataPage.taux_tresor)) /
						100;
					frais =
						(parseFloat(this.montantSave) * parseFloat(this.dataPage.taux_tresor)) /
						100;
					feesPaidBy = 2;
				} else if (this.dataPage.frais == "1") {
					// Payer les frais est facultatif
					if (this.checkedFeesTresorPAY == true) {
						montant =
							parseFloat(this.montantSave) +
							(parseFloat(this.montantSave) *
								parseFloat(this.dataPage.taux_tresor)) /
							100;
						frais =
							(parseFloat(this.montantSave) *
								parseFloat(this.dataPage.taux_tresor)) /
							100;
						feesPaidBy = 1;
					}
				} else if (this.dataPage.frais == "3") {
					// Payer les frais est partagé
					if (this.checkedFeesTresorPAY == true) {
						montant =
							parseFloat(this.montantSave) +
							(parseFloat(this.montantSave) *
								parseFloat(this.dataPage.taux_tresor / 2)) /
							100;
						frais =
							(parseFloat(this.montantSave) *
								parseFloat(this.dataPage.taux_tresor / 2)) /
							100;
						feesPaidBy = 3;
					}
				}

				let dataSend = new FormData();
				dataSend.append(
					"reference",
					window.localStorage.getItem("_reference_" + this.url_data.slug)
				);
				dataSend.append("numero", this.dataTresorPAY.numero.replace(/\s/g, ""));
				dataSend.append("montant", montant);
				dataSend.append("frais", frais);
				dataSend.append("feesPaidBy", feesPaidBy);

				axios
				.post(apiUrl + "api/paiement-marchand/tresorpay/payerAvecTresorPay", dataSend)
				.then((response) => {
					if (response.data.code == 200) {
						this.isLoadingTresorPay = false;
						this.showInProcessTresor = true;
						this.tresorOkay = true;
						return false;
					}

					sweetAlert(
						this.$swal,
						true,
						"top-end",
						"error",
						"Erreur",
						response.data["message"]
					);
					this.isLoadingTresorPay = false;
				})
				.catch((error) => {
					this.isLoadingTresorPay = false;
					console.log(error);
					sweetAlert(
						this.$swal,
						true,
						"top-end",
						"error",
						"Erreur",
						"Une erreur est survenue, veuillez réessayer plus tard"
					);
				});
			},

			unSlugify(slug) {
				const result = slug.replace(/\-/g, " ");
				return result.replace(/\w\S*/g, function (txt) {
					return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
				});
			},

			// Is device a mobile device ?
			isMobile() {
				var check = false;
				(function (a) {
					if (
						/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
							a
						) ||
						/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
							a.substr(0, 4)
						)
					)
						check = true;
				})(navigator.userAgent || navigator.vendor || window.opera);
				return check;
			},

			// Store the language value
			changeLanguage(event) {
				window.localStorage.setItem("lang", event.target.value);
			},

			getIndiceJson() {
				this.json = [...json];
				this.options = [];

				for (let j of this.json) {
					this.options.push({
						country_code: j.flag + " +" + j.dial_code,
						country: j.flag + " " + j.name + " +" + j.dial_code,
						code: j.dial_code,
					});
				}

				// Indicatifs
				this.indice.i = this.options.find(
					(option) => option.code === this.indice.i
				);
				this.dataApaymPay.indice = this.options.find(
					(option) => option.code === this.dataApaymPay.indice
				);
				this.dataApaymPayAuth.indice = this.options.find(
					(option) => option.code === this.dataApaymPayAuth.indice
				);

				// Indicatif pour les mobile money ivoiriens
				this.indiceMobileMoney = this.options.find(
					(option) => option.code === "225"
				);
			},

			selectMenu(payment) {
				this.menuSelected = payment;
				this.showMenu = !this.showMenu;
				this.showHeader = !this.showHeader;
				this.showContent = !this.showContent;

				this.typeForm == "normal"
					? (this.dataFormulaire.montant = this.montantSave)
					: (this.dataFormulaireQuick.montant = this.montantSave);

				this.checkedFeesApaym = false;
				this.checkedFeesOm = false;
				this.checkedFeesCb = false;
				this.checkedFeesWave = false;
				this.checkedFeesMtn = false;
				this.checkedFeesMoov = false;
				this.checkedFeesTresorPAY = false;

				this.scrollToTop();

				switch (payment) {
					case "Visa QR":
						// On paie les frais obligatoirement
						if (this.dataPage.frais == "2" || this.dataPage.frais == "3") {
							// Générons le QR Code prenant en compte le frais obligatoire
							this.feeAmountQr();
						} else {
							// Générons le QR Code
							this.generateQr(
								window.localStorage.getItem("_reference_" + this.url_data.slug),
								"",
								this.typeForm == "normal"
									? this.dataFormulaire.montant
									: this.dataFormulaireQuick.montant
							);
						}

						this.logoSmallDevice = "visa-qr.png";

						break;

					case "VISA":
						// On paie les frais obligatoirement
						if (this.dataPage.frais == "2") {
							this.checkedFeesCb = !this.checkedFeesCb;
							this.tauxMontant(this.dataPage.taux_cb);
						} else {
							console.log("Paiement par " + payment);
						}

						if (this.dataPage.frais == "3") {
							this.checkedFeesCb = !this.checkedFeesCb;
							this.tauxMontant(this.dataPage.taux_cb / 2);
						}

						this.logoSmallDevice = "visa.png";

						break;

					case "MASTERCARD":
						// On paie les frais obligatoirement
						if (this.dataPage.frais == "2") {
							this.checkedFeesCb = !this.checkedFeesCb;
							this.tauxMontant(this.dataPage.taux_cb);
						} else {
							console.log("Paiement par " + payment);
						}

						if (this.dataPage.frais == "3") {
							this.checkedFeesCb = !this.checkedFeesCb;
							this.tauxMontant(this.dataPage.taux_cb / 2);
						}

						this.logoSmallDevice = "mastercard.png";

						break;

					case "Orange Money":
						// On paie les frais obligatoirement
						if (this.dataPage.frais == "2") {
							this.checkedFeesOm = !this.checkedFeesOm;
							this.tauxMontant(this.dataPage.taux_om);
						} else {
							console.log("Paiement par " + payment);
						}

						if (this.dataPage.frais == "3") {
							this.checkedFeesOm = !this.checkedFeesOm;
							this.tauxMontant(this.dataPage.taux_om / 2);
						}

						this.logoSmallDevice = "om.png";

						break;

					case "Wave":
						// On paie les frais obligatoirement
						if (this.dataPage.frais == "2") {
							this.checkedFeesWave = !this.checkedFeesWave;
							this.tauxMontant(this.dataPage.taux_wave);
						} else {
							console.log("Paiement par " + payment);
						}

						if (this.dataPage.frais == "3") {
							this.checkedFeesWave = !this.checkedFeesWave;
							this.tauxMontant(this.dataPage.taux_wave / 2);
						}

						this.logoSmallDevice = "wave.png";

						break;

					case "MTN Money":
						// On paie les frais obligatoirement
						if (this.dataPage.frais == "2") {
							this.checkedFeesMtn = !this.checkedFeesMtn;
							this.tauxMontant(this.dataPage.taux_mtn);
						} else {
							console.log("Paiement par " + payment);
						}

						if (this.dataPage.frais == "3") {
							this.checkedFeesMtn = !this.checkedFeesMtn;
							this.tauxMontant(this.dataPage.taux_mtn / 2);
						}

						this.logoSmallDevice = "momo.png";

						break;

					case "Moov Money":
						// On paie les frais obligatoirement
						if (this.dataPage.frais == "2") {
							this.checkedFeesMoov = !this.checkedFeesMoov;
							this.tauxMontant(this.dataPage.taux_moov);
						} else {
							console.log("Paiement par " + payment);
						}

						if (this.dataPage.frais == "3") {
							this.checkedFeesMoov = !this.checkedFeesMoov;
							this.tauxMontant(this.dataPage.taux_moov / 2);
						}

						this.logoSmallDevice = "flooz.png";

						break;

					case "Tresor PAY":
						// On paie les frais obligatoirement
						if (this.dataPage.frais == "2") {
							this.checkedFeesTresorPAY = !this.checkedFeesTresorPAY;
							this.tauxMontant(this.dataPage.taux_tresor);
						} else {
							console.log("Paiement par " + payment);
						}

						if (this.dataPage.frais == "3") {
							this.checkedFeesTresorPAY = !this.checkedFeesTresorPAY;
							this.tauxMontant(this.dataPage.taux_tresor / 2);
						}

						this.logoSmallDevice = "tresor-money.png";

						break;

					case "APaym USSD":
						// On paie les frais obligatoirement
						if (this.dataPage.frais == "2") {
							this.checkedFeesApaym = !this.checkedFeesApaym;
							this.tauxMontant(this.dataPage.taux_apaym);
						} else {
							console.log("Paiement par " + payment);
						}

						if (this.dataPage.frais == "3") {
							this.checkedFeesApaym = !this.checkedFeesApaym;
							this.tauxMontant(this.dataPage.taux_apaym / 2);
						}

						this.logoSmallDevice = "apaym-ussd.png";

						break;

					case "APaym":
						// On paie les frais obligatoirement
						if (this.dataPage.frais == "2") {
							this.checkedFeesApaym = !this.checkedFeesApaym;
							this.tauxMontant(this.dataPage.taux_apaym);
						} else {
							console.log("Paiement par " + payment);
						}

						if (this.dataPage.frais == "3") {
							this.checkedFeesApaym = !this.checkedFeesApaym;
							this.tauxMontant(this.dataPage.taux_apaym / 2);
						}

						this.logoSmallDevice = "apaym.png";

						break;

					case "ECOBANK Pay":
						// On paie les frais obligatoirement
						if (this.dataPage.frais == "2" || this.dataPage.frais == "3") {
							// Générons le QR Code prenant en compte le frais obligatoire
							this.feeAmountEcobankQr();
						} else {
							// Générons le QR Code
							this.generateEcobankQr(
								window.localStorage.getItem("_reference_" + this.url_data.slug),
								"",
								this.typeForm == "normal"
									? this.dataFormulaire.montant
									: this.dataFormulaireQuick.montant
							);
						}

						this.logoSmallDevice = "ecobank-pay.png";

						break;
				}

				this.cardType =
					payment == "VISA" || payment == "MASTERCARD" ? payment : "";
				this.typeCarte =
					this.cardType.charAt(0).toUpperCase() +
					this.cardType.slice(1).toLowerCase();
			},

			// Calculer le montant total à payer prenant en
			// compte le frais obligatoire à payer
			tauxMontant(taux) {
				let montant = this.montantSave,
					frais = 0;

				if (this.typeForm == "normal") {
					this.dataFormulaire.montant =
						parseFloat(montant) +
						// ( this.pourboire !== 'autre' ? parseFloat(this.pourboire) : ( this.dataFormulaire.amountPourboire ? parseFloat( this.dataFormulaire.amountPourboire ) : 0 ) ) +
						parseFloat(montant * (parseFloat(taux) / 100));

					frais = parseFloat(montant * (parseFloat(taux) / 100));
					// On lance la requête pour modifier le montant ainsi que le frais
					// this.modifierMontantFrais(this.dataFormulaire.montant, frais) ;

					return false;
				}

				this.dataFormulaireQuick.montant =
					parseFloat(montant) +
					// ( this.pourboire !== 'autre' ? parseFloat(this.pourboire) : ( this.dataFormulaireQuick.amountPourboire ? parseFloat( this.dataFormulaire.amountPourboire ) : 0 ) ) +
					parseFloat(montant * (parseFloat(taux) / 100));

				frais = parseFloat(montant * (parseFloat(taux) / 100));
				// On lance la requête pour modifier le montant ainsi que le frais
				// this.modifierMontantFrais(this.dataFormulaireQuick.montant, frais) ;
			},

			// Calculer le montant total à payer prenant en
			// compte le frais à payer
			tauxMontantOm() {
				let montant = this.montantSave,
					frais = 0;
				this.checkedFeesOm = !this.checkedFeesOm;

				// Formulaire normal
				if (this.typeForm == "normal") {
					if (this.checkedFeesOm) {
						this.dataFormulaire.montant =
							this.dataPage.frais == "3"
								? parseFloat(montant) +
								parseFloat(
									montant * (parseFloat(this.dataPage.taux_om / 2) / 100)
								)
								: parseFloat(montant) +
								parseFloat(montant * (parseFloat(this.dataPage.taux_om) / 100));

						frais =
							this.dataPage.frais == "3"
								? parseFloat(
									montant * (parseFloat(this.dataPage.taux_om / 2) / 100)
								)
								: parseFloat(montant * (parseFloat(this.dataPage.taux_om) / 100));
						// On lance la requête pour modifier le montant ainsi que le frais
						// this.modifierMontantFrais(this.dataFormulaire.montant, frais) ;

						return false;
					}

					this.dataFormulaire.montant = this.montantSave;
					frais = 0;
					// this.modifierMontantFrais(this.dataFormulaire.montant, frais) ;
					return false;
				}

				// Formulaire Paie vite
				if (this.checkedFeesOm) {
					this.dataFormulaireQuick.montant =
						this.dataPage.frais == "3"
							? parseFloat(montant) +
							parseFloat(
								montant * (parseFloat(this.dataPage.taux_om / 2) / 100)
							)
							: parseFloat(montant) +
							parseFloat(montant * (parseFloat(this.dataPage.taux_om) / 100));

					frais =
						this.dataPage.frais == "3"
							? parseFloat(
								montant * (parseFloat(this.dataPage.taux_om / 2) / 100)
							)
							: parseFloat(montant * (parseFloat(this.dataPage.taux_om) / 100));
					// On lance la requête pour modifier le montant ainsi que le frais
					// this.modifierMontantFrais(this.dataFormulaireQuick.montant, frais) ;

					return false;
				}

				this.dataFormulaireQuick.montant = this.montantSave;
				frais = 0;
				// this.modifierMontantFrais(this.dataFormulaireQuick.montant, frais) ;
			},

			// Calculer le montant total à payer prenant en
			// compte le frais à payer
			tauxMontantWave() {
				let montant = this.montantSave,
					frais = 0;
				this.checkedFeesWave = !this.checkedFeesWave;

				// Formulaire normal
				if (this.typeForm == "normal") {
					if (this.checkedFeesWave) {
						this.dataFormulaire.montant =
							this.dataPage.frais == "3"
								? parseFloat(montant) +
								parseFloat(
									montant * (parseFloat(this.dataPage.taux_wave / 2) / 100)
								)
								: parseFloat(montant) +
								parseFloat(
									montant * (parseFloat(this.dataPage.taux_wave) / 100)
								);

						frais =
							this.dataPage.frais == "3"
								? parseFloat(
									montant * (parseFloat(this.dataPage.taux_wave / 2) / 100)
								)
								: parseFloat(
									montant * (parseFloat(this.dataPage.taux_wave) / 100)
								);
						// On lance la requête pour modifier le montant ainsi que le frais
						// this.modifierMontantFrais(this.dataFormulaire.montant, frais) ;

						return false;
					}

					this.dataFormulaire.montant = this.montantSave;
					frais = 0;
					// this.modifierMontantFrais(this.dataFormulaire.montant, frais) ;
					return false;
				}

				// Formulaire Paie vite
				if (this.checkedFeesWave) {
					this.dataFormulaireQuick.montant =
						this.dataPage.frais == "3"
							? parseFloat(montant) +
							parseFloat(
								montant * (parseFloat(this.dataPage.taux_wave / 2) / 100)
							)
							: parseFloat(montant) +
							parseFloat(montant * (parseFloat(this.dataPage.taux_wave) / 100));

					frais =
						this.dataPage.frais == "3"
							? parseFloat(
								montant * (parseFloat(this.dataPage.taux_wave / 2) / 100)
							)
							: parseFloat(montant * (parseFloat(this.dataPage.taux_wave) / 100));
					// On lance la requête pour modifier le montant ainsi que le frais
					// this.modifierMontantFrais(this.dataFormulaireQuick.montant, frais) ;

					return false;
				}

				this.dataFormulaireQuick.montant = this.montantSave;
				frais = 0;
				// this.modifierMontantFrais(this.dataFormulaireQuick.montant, frais) ;
			},

			// Calculer le montant total à payer prenant en
			// compte le frais à payer
			tauxMontantMtn() {
				let montant = this.montantSave,
					frais = 0;
				this.checkedFeesMtn = !this.checkedFeesMtn;

				// Formulaire normal
				if (this.typeForm == "normal") {
					if (this.checkedFeesMtn) {
						this.dataFormulaire.montant =
							this.dataPage.frais == "3"
								? parseFloat(montant) +
								parseFloat(
									montant * (parseFloat(this.dataPage.taux_mtn / 2) / 100)
								)
								: parseFloat(montant) +
								parseFloat(
									montant * (parseFloat(this.dataPage.taux_mtn) / 100)
								);

						frais =
							this.dataPage.frais == "3"
								? parseFloat(
									montant * (parseFloat(this.dataPage.taux_mtn / 2) / 100)
								)
								: parseFloat(
									montant * (parseFloat(this.dataPage.taux_mtn) / 100)
								);
						// On lance la requête pour modifier le montant ainsi que le frais
						// this.modifierMontantFrais(this.dataFormulaire.montant, frais) ;

						return false;
					}

					this.dataFormulaire.montant = this.montantSave;
					frais = 0;
					// this.modifierMontantFrais(this.dataFormulaire.montant, frais) ;
					return false;
				}

				// Formulaire Paie vite
				if (this.checkedFeesMtn) {
					this.dataFormulaireQuick.montant =
						this.dataPage.frais == "3"
							? parseFloat(montant) +
							parseFloat(
								montant * (parseFloat(this.dataPage.taux_mtn / 2) / 100)
							)
							: parseFloat(montant) +
							parseFloat(montant * (parseFloat(this.dataPage.taux_mtn) / 100));

					frais =
						this.dataPage.frais == "3"
							? parseFloat(
								montant * (parseFloat(this.dataPage.taux_mtn / 2) / 100)
							)
							: parseFloat(montant * (parseFloat(this.dataPage.taux_mtn) / 100));
					// On lance la requête pour modifier le montant ainsi que le frais
					// this.modifierMontantFrais(this.dataFormulaireQuick.montant, frais) ;

					return false;
				}

				this.dataFormulaireQuick.montant = this.montantSave;
				frais = 0;
				// this.modifierMontantFrais(this.dataFormulaireQuick.montant, frais) ;
			},

			// Calculer le montant total à payer prenant en
			// compte le frais à payer
			tauxMontantMoov() {
				let montant = this.montantSave,
					frais = 0;
				this.checkedFeesMoov = !this.checkedFeesMoov;

				// Formulaire normal
				if (this.typeForm == "normal") {
					if (this.checkedFeesMoov) {
						this.dataFormulaire.montant =
							this.dataPage.frais == "3"
								? parseFloat(montant) +
								parseFloat(
									montant * (parseFloat(this.dataPage.taux_moov / 2) / 100)
								)
								: parseFloat(montant) +
								parseFloat(
									montant * (parseFloat(this.dataPage.taux_moov) / 100)
								);

						frais =
							this.dataPage.frais == "3"
								? parseFloat(
									montant * (parseFloat(this.dataPage.taux_moov / 2) / 100)
								)
								: parseFloat(
									montant * (parseFloat(this.dataPage.taux_moov) / 100)
								);
						// On lance la requête pour modifier le montant ainsi que le frais
						// this.modifierMontantFrais(this.dataFormulaire.montant, frais) ;

						return false;
					}

					this.dataFormulaire.montant = this.montantSave;
					frais = 0;
					// this.modifierMontantFrais(this.dataFormulaire.montant, frais) ;
					return false;
				}

				// Formulaire Paie vite
				if (this.checkedFeesMoov) {
					this.dataFormulaireQuick.montant =
						this.dataPage.frais == "3"
							? parseFloat(montant) +
							parseFloat(
								montant * (parseFloat(this.dataPage.taux_moov / 2) / 100)
							)
							: parseFloat(montant) +
							parseFloat(montant * (parseFloat(this.dataPage.taux_moov) / 100));

					frais =
						this.dataPage.frais == "3"
							? parseFloat(
								montant * (parseFloat(this.dataPage.taux_moov / 2) / 100)
							)
							: parseFloat(montant * (parseFloat(this.dataPage.taux_moov) / 100));
					// On lance la requête pour modifier le montant ainsi que le frais
					// this.modifierMontantFrais(this.dataFormulaireQuick.montant, frais) ;

					return false;
				}

				this.dataFormulaireQuick.montant = this.montantSave;
				frais = 0;
				// this.modifierMontantFrais(this.dataFormulaireQuick.montant, frais) ;
			},

			// Calculer le montant total à payer prenant en
			// compte le frais à payer
			tauxMontantTresor() {
				let montant = this.montantSave,
					frais = 0;
				this.checkedFeesTresorPAY = !this.checkedFeesTresorPAY;

				// Formulaire normal
				if (this.typeForm == "normal") {
					if (this.checkedFeesTresorPAY) {
						this.dataFormulaire.montant =
							this.dataPage.frais == "3"
								? parseFloat(montant) +
								parseFloat(
									montant * (parseFloat(this.dataPage.taux_tresor / 2) / 100)
								)
								: parseFloat(montant) +
								parseFloat(
									montant * (parseFloat(this.dataPage.taux_tresor) / 100)
								);

						frais =
							this.dataPage.frais == "3"
								? parseFloat(
									montant * (parseFloat(this.dataPage.taux_tresor) / 100)
								)
								: parseFloat(
									montant * (parseFloat(this.dataPage.taux_tresor) / 100)
								);
						// On lance la requête pour modifier le montant ainsi que le frais
						// this.modifierMontantFrais(this.dataFormulaire.montant, frais) ;

						return false;
					}

					this.dataFormulaire.montant = this.montantSave;
					frais = 0;
					// this.modifierMontantFrais(this.dataFormulaire.montant, frais) ;
					return false;
				}

				// Formulaire Paie vite
				if (this.checkedFeesTresorPAY) {
					this.dataFormulaireQuick.montant =
						this.dataPage.frais == "3"
							? parseFloat(montant) +
							parseFloat(
								montant * (parseFloat(this.dataPage.taux_tresor / 2) / 100)
							)
							: parseFloat(montant) +
							parseFloat(montant * (parseFloat(this.dataPage.taux_tresor) / 100));

					frais =
						this.dataPage.frais == "3"
							? parseFloat(
								montant * (parseFloat(this.dataPage.taux_tresor / 2) / 100)
							)
							: parseFloat(montant * (parseFloat(this.dataPage.taux_tresor) / 100));
					// On lance la requête pour modifier le montant ainsi que le frais
					// this.modifierMontantFrais(this.dataFormulaireQuick.montant, frais) ;

					return false;
				}

				this.dataFormulaireQuick.montant = this.montantSave;
				frais = 0;
				// this.modifierMontantFrais(this.dataFormulaireQuick.montant, frais) ;
			},

			// Calculer le montant total à payer prenant en
			// compte le frais à payer
			tauxMontantCb() {
				let montant = this.montantSave,
					frais = 0;
				this.checkedFeesCb = !this.checkedFeesCb;

				// Formulaire normal
				if (this.typeForm == "normal") {
					if (this.checkedFeesCb) {
						this.dataFormulaire.montant =
							parseFloat(montant) +
							// ( this.pourboire !== 'autre' ? parseFloat(this.pourboire) : ( this.dataFormulaire.amountPourboire ? parseFloat( this.dataFormulaire.amountPourboire ) : 0 ) ) +
							parseFloat(montant * (parseFloat(this.dataPage.taux_cb) / 100));

						frais = parseFloat(
							montant * (parseFloat(this.dataPage.taux_cb) / 100)
						);
						// On lance la requête pour modifier le montant ainsi que le frais
						// this.modifierMontantFrais(this.dataFormulaire.montant, frais) ;

						return false;
					}

					this.dataFormulaire.montant = this.montantSave;
					frais = 0;
					// this.modifierMontantFrais(this.dataFormulaire.montant, frais) ;
					return false;
				}

				// Formulaire Paie vite
				if (this.checkedFeesCb) {
					this.dataFormulaireQuick.montant =
						parseFloat(montant) +
						// ( this.pourboire !== 'autre' ? parseFloat(this.pourboire) : ( this.dataFormulaireQuick.amountPourboire ? parseFloat( this.dataFormulaire.amountPourboire ) : 0 ) ) +
						parseFloat(montant * (parseFloat(this.dataPage.taux_cb) / 100));

					frais = parseFloat(montant * (parseFloat(this.dataPage.taux_cb) / 100));
					// On lance la requête pour modifier le montant ainsi que le frais
					// this.modifierMontantFrais(this.dataFormulaireQuick.montant, frais) ;

					return false;
				}

				this.dataFormulaireQuick.montant = this.montantSave;
				frais = 0;
				// this.modifierMontantFrais(this.dataFormulaireQuick.montant, frais) ;
			},

			// Calculer le montant total à payer prenant en
			// compte le frais à payer
			tauxMontantApaym() {
				let montant = this.montantSave,
					frais = 0;
				this.checkedFeesApaym = !this.checkedFeesApaym;

				// Formulaire normal
				if (this.typeForm == "normal") {
					if (this.checkedFeesApaym) {
						this.dataFormulaire.montant =
							this.dataPage.frais == "3"
								? parseFloat(montant) +
								parseFloat(
									montant * (parseFloat(this.dataPage.taux_apaym / 2) / 100)
								)
								: parseFloat(montant) +
								parseFloat(
									montant * (parseFloat(this.dataPage.taux_apaym) / 100)
								);

						frais =
							this.dataPage.frais == "3"
								? parseFloat(
									montant * (parseFloat(this.dataPage.taux_apaym / 2) / 100)
								)
								: parseFloat(
									montant * (parseFloat(this.dataPage.taux_apaym) / 100)
								);
						// On lance la requête pour modifier le montant ainsi que le frais
						// this.modifierMontantFrais(this.dataFormulaire.montant, frais) ;

						return false;
					}

					this.dataFormulaire.montant = this.montantSave;
					frais = 0;
					// this.modifierMontantFrais(this.dataFormulaire.montant, frais) ;
					return false;
				}

				// Formulaire Paie vite
				if (this.checkedFeesApaym) {
					this.dataFormulaireQuick.montant =
						this.dataPage.frais == "3"
							? parseFloat(montant) +
							parseFloat(
								montant * (parseFloat(this.dataPage.taux_apaym / 2) / 100)
							)
							: parseFloat(montant) +
							parseFloat(
								montant * (parseFloat(this.dataPage.taux_apaym) / 100)
							);

					frais =
						this.dataPage.frais == "3"
							? parseFloat(
								montant * (parseFloat(this.dataPage.taux_apaym / 2) / 100)
							)
							: parseFloat(
								montant * (parseFloat(this.dataPage.taux_apaym) / 100)
							);
					// On lance la requête pour modifier le montant ainsi que le frais
					// this.modifierMontantFrais(this.dataFormulaireQuick.montant, frais) ;

					return false;
				}

				this.dataFormulaireQuick.montant = this.montantSave;
				frais = 0;
				// this.modifierMontantFrais(this.dataFormulaireQuick.montant, frais) ;
			},

			// Modifier le montant et le frais de la transaction
			// Cas de paiement des frais (Obligatoire ou pas)
			modifierMontantFrais(montant, frais) {
				this.isLoading = true;
				let dataSend = new FormData();
				dataSend.append(
					"reference",
					window.localStorage.getItem("_reference_" + this.url_data.slug)
				);
				dataSend.append("frais", frais);
				dataSend.append("montant", montant);

				axios
				.post(apiUrl + "api/paiement-marchand/frais-montant", dataSend)
				.then((response) => {
					this.isLoading = false;
					if (response.data.code == 1) {
						return false;
					}

					sweetAlert(
						this.$swal,
						true,
						"top-end",
						"error",
						"Erreur",
						response.data["message"]
					);
				})
				.catch((error) => {
					this.isLoading = false;
					console.log(error);
					sweetAlert(
						this.$swal,
						true,
						"top-end",
						"error",
						"Erreur",
						"Une erreur est survenue, veuillez réessayer plus tard"
					);
				});
			},

			getDelegationsPdci() {
				this.delegationsPdci = [];
				for (let del of delegations_pdci) {
					this.delegationsPdci.push({
						code: del.code,
						name: del.name_delegation,
						name_to_show:
							del.name_delegation.length > 28
								? del.name_delegation.slice(0, 28) + "..."
								: del.name_delegation,
					});
				}

				this.delegationsPdci.sort(function (a, b) {
					if (a.name < b.name) {
						return -1;
					}
					if (a.name > b.name) {
						return 1;
					}
					return 0;
				});
			},

			getMotifPdci() {
				this.motifsPdci = [];
				for (let mot of motifs_pdci) {
					this.motifsPdci.push({
						code: mot.code,
						motif: mot.motif,
					});
				}

				this.motifsPdci.sort(function (a, b) {
					if (a.motif < b.motif) {
						return -1;
					}
					if (a.motif > b.motif) {
						return 1;
					}
					return 0;
				});
			},

			getGradePdci() {
				this.gradesPdci = [];
				for (let grade of grades_pdci) {
					this.gradesPdci.push({
						id: grade.id,
						organe: grade.organe,
						adhesion: grade.adhesion,
						carte: grade.carte,
						montant_total: grade.montant_total,
						qualite_membre: grade.qualite_membre,
						cotisation: grade.cotisation,
						name_to_show:
							grade.organe.length > 30
								? grade.organe.slice(0, 30) + "..."
								: grade.organe,
					});
				}

				// this.dataFormulaire.grade = this.gradesPdci.find( option => option.id === 1 );
				this.gradesPdci.sort(function (a, b) {
					if (a.organe < b.organe) {
						return -1;
					}
					if (a.organe > b.organe) {
						return 1;
					}
					return 0;
				});
			},

			setMontantPdci() {
				switch (this.dataFormulaire.motif) {
					case "NOUVELLE ADHÉSION":
						this.removeGradeByMotif(true, "show_adhesion");
						this.dataFormulaire.grade.montant_total !== "" &&
						this.dataFormulaire.grade.montant_total !== undefined
							? (this.dataFormulaire.montant =
								this.dataFormulaire.grade.montant_total)
							: (this.dataFormulaire.montant = "");
						this.dataPage.amount = this.dataFormulaire.grade.montant_total;

						this.detailsMontantPdci =
							" = Coût Adhésion + Carte + Cotisation année d'adhésion";
						break;
					case "RÉÉDITION DE CARTE DE MEMBRE":
						this.removeGradeByMotif(true, "show_reedition_carte");
						this.dataFormulaire.grade.carte !== "" &&
						this.dataFormulaire.grade.carte !== undefined
							? (this.dataFormulaire.montant = this.dataFormulaire.grade.carte)
							: (this.dataFormulaire.montant = "");
						this.dataPage.amount = this.dataFormulaire.grade.carte;
						this.detailsMontantPdci = " = Coût Réedition carte";
						break;
					case "NOUVELLE QUALITÉ DE MEMBRE":
						this.removeGradeByMotif(true, "show_qualite_membre");
						this.dataFormulaire.grade.qualite_membre !== "" &&
						this.dataFormulaire.grade.qualite_membre !== undefined
							? (this.dataFormulaire.montant =
								this.dataFormulaire.grade.qualite_membre)
							: (this.dataFormulaire.montant = "");
						this.dataPage.amount = this.dataFormulaire.grade.qualite_membre;
						this.detailsMontantPdci =
							" = Coût Carte + Cotisation année d'adhésion";
						break;
					case "COTISATION ANNUELLE":
						this.removeGradeByMotif(true, "show_cotisation");
						this.dataFormulaire.grade.cotisation !== "" &&
						this.dataFormulaire.grade.cotisation !== undefined
							? (this.dataFormulaire.montant =
								this.dataFormulaire.grade.cotisation)
							: (this.dataFormulaire.montant = "");
						this.dataPage.amount = this.dataFormulaire.grade.cotisation;
						this.detailsMontantPdci = "";
						break;
					case "COTISATION EXCEPTIONNELLE":
						this.removeGradeByMotif(true, "show_cotisation");
						this.detailsMontantPdci = "";
						this.dataFormulaire.montant = "";
						this.dataPage.amount = "";
						break;
					default:
						this.dataFormulaire.montant = "";
						this.dataPage.amount = "";
						this.getGradePdci();
						this.detailsMontantPdci = "";
						break;
				}
			},

			removeGradeByMotif(value, grade_to_show) {
				this.gradesPdci = [];

				// for ( let [key, grade] of Object.entries(grades_pdci) ) {
				for (let grade of grades_pdci) {
					if (grade[grade_to_show] === value) {
						this.gradesPdci.push({
							id: grade.id,
							organe: grade.organe,
							adhesion: grade.adhesion,
							carte: grade.carte,
							montant_total: grade.montant_total,
							qualite_membre: grade.qualite_membre,
							cotisation: grade.cotisation,
							name_to_show:
								grade.organe.length > 30
									? grade.organe.slice(0, 30) + "..."
									: grade.organe,
						});
					}
				}

				// this.dataFormulaire.grade = this.gradesPdci.find( option => option.id === 1 );
				this.gradesPdci.sort(function (a, b) {
					if (a.organe < b.organe) {
						return -1;
					}
					if (a.organe > b.organe) {
						return 1;
					}
					return 0;
				});
			},

			getCurrencies() {
				this.currencies = [];
				for (let curr of currencies_list) {
					this.currencies.push({
						symbol: curr.symbol,
						devisename: curr.devisename,
						devisecode: curr.devisecode,
						flag: curr.flag,
						currency_to_show: curr.devisecode + " - " + curr.devisename,
					});
				}

				this.currencies.sort(function (a, b) {
					if (a.devisename < b.devisename) {
						return -1;
					}
					if (a.devisename > b.devisename) {
						return 1;
					}
					return 0;
				});

				this.currencyFrom = this.currencies.find(
					(option) => option.devisecode === "EUR"
				);
				this.currencyTo = this.currencies.find(
					(option) => option.devisecode === "XOF"
				);
			},

			convertAmount() {
				if (this.amountToConvert.replace(/\s/g, "") == "") {
					return false;
				}

				// Lancer la conversion du montant
				this.isLoading = true;
				let dataSend = new FormData();
				dataSend.append("amount", this.amountToConvert);
				dataSend.append("fromCurrency", this.currencyFrom.devisecode);
				dataSend.append("toCurrency", "XOF"); // this.currencyTo.devisecode

				axios
				.post(
					"https://carte.abidjan.net/apaym_pro/api/payment-merchant/convert",
					dataSend
				)
				.then((response) => {
					this.isLoading = false;

					if (response.data.code == 1) {
						this.showConversion = true;
						this.convertedAmount = response.data.response.destinationAmount;
						this.conversionRate = response.data.response.conversionRate;

						return false;
					}
				})
				.catch((error) => {
					this.isLoading = false;
					console.log(error);
					sweetAlert(
						this.$swal,
						true,
						"top-end",
						"error",
						"Erreur",
						"Une erreur est survenue, veuillez réessayer plus tard"
					);
				});
			},

			setConvertedAmount() {
				if (this.convertedAmount !== "") {
					this.typeForm == "normal"
						? (this.dataFormulaire.montant = this.convertedAmount)
						: (this.dataFormulaireQuick.montant = this.convertedAmount);

					// Set value from conversion
					this.devise =
						this.currencyFrom.devisecode + " - " + this.currencyFrom.devisename;
					this.taux_apaym = this.conversionRate;
					this.montantDevise = this.amountToConvert;

					this.convertedAmount = "";
					this.showConversion = false;
					this.amountToConvert = "";
					this.$refs["convertAmountModal"].hide();
					return false;
				}
			},

			getIpAddress() {
				axios
				.get("https://ipinfo.io/json?token=8c8fd7115a940f")
				.then((response) => {
					this.dataYangoRechargement.adresse_ip =
						response.data.ip +
						" - " +
						response.data.org +
						" | " +
						response.data.country +
						" - " +
						response.data.city +
						" - " +
						response.data.region;
					// console.log(response);
				})
				.catch((err) => {
					console.log(err);
				});
			},

			getLocation() {
				navigator.geolocation.getCurrentPosition(
					(position) => {
						console.log("latitude = " + position.coords.latitude);
						console.log("longitude = " + position.coords.longitude);
						this.dataLocation.latitude = position.coords.latitude;
						this.dataLocation.longitude = position.coords.longitude;
					},
					(error) => {
						console.log(error.message);
					}
				);
			},

			onTimesUp() {
				clearInterval(this.timerInterval);
			},

			startTimer() {
				this.timerInterval = setInterval(() => (this.timePassed += 1), 1000);
			},

			onVerify(token, ekey) {
				this.verified = true;
				this.token = token;
				this.eKey = ekey;
				console.log(`Callback token: ${token}, ekey: ${ekey}`);
			},

			onExpired() {
				this.verified = false;
				this.token = null;
				this.eKey = null;
				this.expired = true;
				console.log("Expired");
			},

			onError(err) {
				this.token = null;
				this.eKey = null;
				this.error = err;
				this.verified = false;
				console.log(`Error: ${err}`);
			},

			letSomeonePay() {
				this.scrollToTop();
				this.showPaymentSection = !this.showPaymentSection;
				this.sharingSection = !this.sharingSection;
			},

			// Sociétés côté en bourse
			publiclyTradedCompany() {
				this.traded_company = [];
				for (let company of traded_company_list) {
					this.traded_company.push({
						id: company.id,
						symbol: company.symbol,
						company: company.company,
						sector: company.sector,
						symbol_to_show: company.symbol + " - " + company.sector,
						company_to_show: company.symbol + " - " + company.company,
					});
				}

				this.traded_company.sort(function (a, b) {
					if (a.company < b.company) {
						return -1;
					}
					if (a.company > b.company) {
						return 1;
					}
					return 0;
				});

				// this.dataFormulaire.societeEnBourse.company = this.traded_company.find( option => option.id === 1 );
			},

			validateEmail(email) {
				if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
					return true;
				} else {
					return false;
				}
			},

			validateString(string) {
				if (/^[A-Za-z-'çÇéÉèÈêÊëË ]+$/.test(string)) {
					return true;
				} else {
					return false;
				}
			},

			validateSpecialString(string) {
				console.log(string);
				return true;
				/*if (/^[a-zA-Z0-9-&.'#()*º ]+$/.test(string)) {
                    return true ;
                } else {
                    return false ;
                }*/
			},

			//
			getMontantFormule(typeForm, formule) {

				console.log(formule)

				if (typeForm === "normal") {
					this.montantFormate = String(formule.montant);
					this.dataPage.amount = String(formule.montant);
				} else {
					this.dataPage.amount = String(formule.montant);
					this.montantFormateQuick = String(formule.montant);
				}
			},

			selectQuantity() {
				this.dataFormulaire.amount = this.montantFormate * parseInt(this.dataFormulaire.quantite)
				this.montantFormate = String(this.dataPage.amount2 * parseInt(this.dataFormulaire.quantite));
				this.dataPage.amount = String(this.dataPage.amount2 * parseInt(this.dataFormulaire.quantite));
				console.log(this.montantFormate)
			},

			selectQuantity2() {
				let amount = this.dataPage.amount;
				this.dataFormulaireQuick.amount = this.montantFormateQuick * parseInt(this.dataFormulaireQuick.quantite)
				this.montantFormateQuick = String(this.dataPage.amount2 * parseInt(this.dataFormulaireQuick.quantite));
				this.dataPage.amount = String(this.dataPage.amount2 * parseInt(this.dataFormulaireQuick.quantite));
				console.log(this.montantFormateQuick)
			}
		},

		mounted() {

			window.scrollTo(0, 0);

			// console.log( this.isMobile() );
			this.mobileDevice = this.isMobile();

			this.getIndiceJson();
			this.getDelegationsPdci();
			this.getMotifPdci();
			this.getGradePdci();
			this.getCurrencies();
			this.getIpAddress();
			this.publiclyTradedCompany();
			// this.getLocation();

			if (window.location.pathname.includes("/nourahama/")) {
				if (
					window.location.pathname.split("/nourahama/")[1] !== undefined &&
					window.location.pathname.split("/nourahama/")[1] !== ""
				) {
					window.location.href =
						"https://pay.apaym.com/" +
						window.location.pathname.split("/nourahama/")[1];
					return false;
				}
			}

			this.url_data.slug = this.$route.params.slug;
			this.url_data.montant =
				this.$route.params.montant !== "" &&
				this.$route.params.montant !== undefined
					? this.$route.params.montant
					: "";
			this.url_data.indice = this.$route.params.indice;
			this.url_data.numero = this.$route.params.tel;
			this.url_data.nom = this.$route.params.nom;
			this.url_data.prenom = this.$route.params.prenom;
			this.url_data.email = this.$route.params.email;
			this.url_data.motif = this.$route.params.reason;
			this.url_data.key_payment = this.$route.params.key;
			this.url_data.redirect_key =
				this.$route.params.redirect_key !== "" &&
				this.$route.params.redirect_key !== undefined
					? this.$route.params.redirect_key
					: "";
			this.url_data.url_redirect = this.$route.params.url_redirect;
			this.url_data.formule = this.$route.params.formule;

			// SHOP
			this.url_data.store_id = this.$route.params.store_id;
			this.url_data.slug_boutique = this.$route.params.slug_boutique;

			this.slug_boutique =
				sessionStorage.getItem("sb") !== "" &&
				sessionStorage.getItem("sb") !== undefined
					? sessionStorage.getItem("sb")
					: "";

			console.log("=======================");
			console.log(this.slug_boutique == null);
			console.log("=======================");

			if (!this.url_data.slug) {
				this.formLoader = true;
				this.showErrorPage = !this.showErrorPage;
				return false;
			}

			if (this.url_data.montant !== "") {
				sessionStorage.setItem("m", this.url_data.montant);
				this.url_data.indice
					? sessionStorage.setItem("i", this.url_data.indice)
					: console.log("");
				this.url_data.numero
					? sessionStorage.setItem("t", this.url_data.numero)
					: console.log("");
				this.url_data.nom
					? sessionStorage.setItem("n", this.url_data.nom)
					: console.log("");
				this.url_data.prenom
					? sessionStorage.setItem("p", this.url_data.prenom)
					: console.log("");
				this.url_data.email
					? sessionStorage.setItem("e", this.url_data.email)
					: console.log("");
				this.url_data.motif
					? sessionStorage.setItem("r", this.url_data.motif)
					: console.log("");
				this.url_data.key_payment
					? sessionStorage.setItem("k", this.url_data.key_payment)
					: console.log("");
				this.url_data.url_redirect
					? sessionStorage.setItem("u", this.url_data.url_redirect)
					: console.log("");
				this.url_data.formule
					? sessionStorage.setItem("f", this.url_data.formule)
					: console.log("");

				// Shop
				this.url_data.store_id
					? sessionStorage.setItem("s", this.url_data.store_id)
					: console.log("");
				this.url_data.slug_boutique
					? sessionStorage.setItem("sb", this.url_data.slug_boutique)
					: console.log("");

				sessionStorage.setItem(
					"redirect_value",
					this.url_data.redirect_key == 1 ? "false" : "true"
				);

				this.url_data.redirect_key == 1
					? (window.location.href = "https://pay.apaym.com/" + this.url_data.slug)
					: "";
				// this.url_data.redirect_key == 1 ? window.location.href = "http://localhost:8080/"+ this.url_data.slug : "" ;
				// https://pay.apaym.com/
			}

			if (this.url_data.slug.search("qr_") !== -1) {
				sessionStorage.setItem("slug_info", this.url_data.slug);
				this.url_data.slug = this.url_data.slug.replace("qr_", "");
				window.location.href = "https://pay.apaym.com/" + this.url_data.slug
				// window.location.href = "http://localhost:8080/"+ this.url_data.slug

				return false;
			}

			this.getInfosPage(this.url_data.slug);

			this.showBtnSearch = sessionStorage.getItem("redirect_value") || "true";

			// Update screen page with Pusher
			Pusher.logToConsole = false;
			var channel = this.$pusher.subscribe("wby-channel");
			var my = this;
			var sessionStorage1 = sessionStorage;
			var response = function (data) {
				if (
					localStorage.getItem("route") == "defaultPage" ||
					localStorage.getItem("route") == "refPage"
				) {
					if (
						my.dataFormulaireQuick.montant.toString() !== "" ||
						my.dataFormulaire.montant.toString() !== ""
					) {
						if (data.type == "QR") {
							my.modePayment = "Visa QR";
						} else if (data.type == "BICICI") {
							my.modePayment = my.$t("payment_modal.bicici.value");
						} else {
							my.modePayment = data.type;
						}

						if (data.type == "QR") {
							if (
								data.status == "success" &&
								data.reference ==
								window.localStorage.getItem("_reference_" + my.url_data.slug)
							) {
								my.modePay = "Visa QR";
								my.hideRecu = false;
								// my.dataPage.deleg_pdci == "3" ? my.doYangoRechargement() : console.log("Call back") ;
								my.showPaymentSection = false;
								my.sharingSection = false;

								if (my.typeForm == "normal") {
									my.formPayment = true;
								} else if (my.typeForm == "quickly") {
									my.formQuiclyPayment = true;
								} else {
									my.formSuperQuicklyPayment = true;
								}

								if (my.slug_boutique != null) {
									setTimeout(() => {
										window.location.href =
											"https://shopapaym.weblogy.net/" + my.slug_boutique;
									}, 2000);
									return;
								}

								if (my.url_data.url_redirect != null) {

									// if ( my.dataPage.merchant == '25377' )
									// {
									//     setTimeout(() => {
									//         window.location.href = my.url_data.url_redirect +"/recu/?num_paiement="+ window.sessionStorage.getItem("k");
									//     }, 2000);
									//     return;
									// }

									setTimeout(() => {
										window.location.href = my.url_data.url_redirect;
									}, 2000);
									return;
								}

								if (
									my.dataPage.urlRedirection !== "" &&
									my.dataPage.urlRedirection !== null
								) {

									if (my.dataPage.merchant == '25377') {
										setTimeout(() => {
											window.location.href = my.dataPage.urlRedirection + "recu/?num_paiement=" + window.sessionStorage.getItem("k");
										}, 2000);
										return;
									}


									my.dataPage.redirectWithCode == "1"
										? setTimeout(
											(window.location.href =
												my.dataPage.urlRedirection +
												"?code=" +
												sessionStorage1.getItem("k")),
											12000
										)
										: setTimeout(
											(window.location.href = my.dataPage.urlRedirection),
											12000
										);
								}

								// my.dataPage.urlRedirection !== "" && my.dataPage.urlRedirection !== null ? setTimeout( window.location.href = my.dataPage.urlRedirection, 12000) : "" ;

								return false;
							}
						} else if (data.type == "MTN Money") {
							if (
								data.status == "success" &&
								data.reference ==
								window.localStorage.getItem("_reference_" + my.url_data.slug)
							) {
								my.modePay = "MTN Money";
								my.hideRecu = false;
								// my.dataPage.deleg_pdci == "3" ? my.doYangoRechargement() : console.log("Call back") ;
								// my.$refs['openPaymentModal'].hide();
								my.showPaymentSection = false;
								my.sharingSection = false;

								if (my.typeForm == "normal") {
									my.formPayment = true;
								} else if (my.typeForm == "quickly") {
									my.formQuiclyPayment = true;
								} else {
									my.formSuperQuicklyPayment = true;
								}

								if (my.slug_boutique != null) {
									setTimeout(() => {
										window.location.href =
											"https://shopapaym.weblogy.net/" + my.slug_boutique;
									}, 2000);
									return;
								}

								if (my.url_data.url_redirect != null) {

									// if ( my.dataPage.merchant == '25377' )
									// {
									//     setTimeout(() => {
									//         window.location.href = my.url_data.url_redirect +"/recu/?num_paiement="+ window.sessionStorage.getItem("k");
									//     }, 2000);
									//     return;
									// }
									setTimeout(() => {
										window.location.href = my.url_data.url_redirect;
									}, 2000);
									return;
								}

								if (
									my.dataPage.urlRedirection !== "" &&
									my.dataPage.urlRedirection !== null
								) {

									if (my.dataPage.merchant == '25377') {
										setTimeout(() => {
											window.location.href = my.dataPage.urlRedirection + "recu/?num_paiement=" + window.sessionStorage.getItem("k");
										}, 2000);
										return;
									}

									my.dataPage.redirectWithCode == "1"
										? setTimeout(
											(window.location.href =
												my.dataPage.urlRedirection +
												"?code=" +
												sessionStorage1.getItem("k")),
											12000
										)
										: setTimeout(
											(window.location.href = my.dataPage.urlRedirection),
											12000
										);
								}

								// my.dataPage.urlRedirection !== "" && my.dataPage.urlRedirection !== null ? setTimeout( window.location.href = my.dataPage.urlRedirection, 12000) : "" ;
							} else if (
								(data.responseCode == "529" ||
									data.responseCode == "515" ||
									data.responseCode == "9" ||
									data.responseCode == "100") &&
								data.reference ==
								window.localStorage.getItem("_reference_" + my.url_data.slug)
							) {
								my.isLoadingMomo = false;
								my.mtnNOkay = true;
								my.mtnOkay = false;
							}
						} else if (data.type == "Orange Money") {
							if (
								data.status == "success" &&
								data.reference ==
								window.localStorage.getItem("_reference_" + my.url_data.slug)
							) {

								// window.localStorage.setItem("merchant", my.dataPage.merchant)
								// window.localStorage.setItem("url_redirect", my.url_data.url_redirect)

								my.modePay = "Orange Money";
								my.hideRecu = false;
								// my.dataPage.deleg_pdci == "3" ? my.doYangoRechargement() : console.log("Call back") ;
								// my.$refs['openPaymentModal'].hide();
								my.modalOrange == true
									? my.$refs["openOrangeMoneyModal"].hide()
									: "";
								my.showPaymentSection = false;
								my.sharingSection = false;

								if (my.typeForm == "normal") {
									my.formPayment = true;
								} else if (my.typeForm == "quickly") {
									my.formQuiclyPayment = true;
								} else {
									my.formSuperQuicklyPayment = true;
								}

								if (my.slug_boutique != null) {
									setTimeout(() => {
										window.location.href =
											"https://shopapaym.weblogy.net/" + my.slug_boutique;
									}, 2000);
									return;
								}

								if (my.url_data.url_redirect != null) {

									// if ( my.dataPage.merchant == '25377' )
									// {
									//     setTimeout(() => {
									//         window.location.href = my.url_data.url_redirect +"/recu/?num_paiement="+ window.sessionStorage.getItem("k");
									//     }, 2000);
									//     return;
									// }
									setTimeout(() => {
										window.location.href = my.url_data.url_redirect;
									}, 2000);
									return;
								}

								if (
									my.dataPage.urlRedirection !== "" &&
									my.dataPage.urlRedirection !== null
								) {

									if (my.dataPage.merchant == '25377') {
										setTimeout(() => {
											window.location.href = my.dataPage.urlRedirection + "recu/?num_paiement=" + window.sessionStorage.getItem("k");
										}, 2000);
										return;
									}

									my.dataPage.redirectWithCode == "1"
										? setTimeout(
											(window.location.href =
												my.dataPage.urlRedirection +
												"?code=" +
												sessionStorage1.getItem("k")),
											12000
										)
										: setTimeout(
											(window.location.href = my.dataPage.urlRedirection),
											12000
										);
								}

								// my.dataPage.urlRedirection !== "" && my.dataPage.urlRedirection !== null ? setTimeout( window.location.href = my.dataPage.urlRedirection, 12000) : "" ;
							} else if (
								data.responseCode !== "SUCCESS" &&
								data.reference ==
								window.localStorage.getItem("_reference_" + my.url_data.slug)
							) {
								my.isLoadingOrange = false;
								my.orangeNOkay = true;
								my.orangeOkay = false;
								my.modalOrange == true
									? my.$refs["openOrangeMoneyModal"].hide()
									: "";
							}
						} else if (data.type == "Tresor PAY") {
							if (
								data.status == "success" &&
								data.reference ==
								window.localStorage.getItem("_reference_" + my.url_data.slug)
							) {
								my.modePay = "Tresor PAY";
								my.hideRecu = false;
								// my.dataPage.deleg_pdci == "3" ? my.doYangoRechargement() : console.log("Call back") ;
								// my.$refs['openPaymentModal'].hide();
								my.showPaymentSection = false;
								my.sharingSection = false;

								if (my.typeForm == "normal") {
									my.formPayment = true;
								} else if (my.typeForm == "quickly") {
									my.formQuiclyPayment = true;
								} else {
									my.formSuperQuicklyPayment = true;
								}

								if (my.slug_boutique != null) {
									setTimeout(() => {
										window.location.href =
											"https://shopapaym.weblogy.net/" + my.slug_boutique;
									}, 2000);
									return;
								}

								if (my.url_data.url_redirect != null) {

									// if ( my.dataPage.merchant == '25377' )
									// {
									//     setTimeout(() => {
									//         window.location.href = my.url_data.url_redirect +"/recu/?num_paiement="+ window.sessionStorage.getItem("k");
									//     }, 2000);
									//     return;
									// }
									setTimeout(() => {
										window.location.href = my.url_data.url_redirect;
									}, 2000);
									return;
								}

								if (
									my.dataPage.urlRedirection !== "" &&
									my.dataPage.urlRedirection !== null
								) {

									if (my.dataPage.merchant == '25377') {
										setTimeout(() => {
											window.location.href = my.dataPage.urlRedirection + "recu/?num_paiement=" + window.sessionStorage.getItem("k");
										}, 2000);
										return;
									}

									my.dataPage.redirectWithCode == "1"
										? setTimeout(
											(window.location.href =
												my.dataPage.urlRedirection +
												"?code=" +
												sessionStorage1.getItem("k")),
											12000
										)
										: setTimeout(
											(window.location.href = my.dataPage.urlRedirection),
											12000
										);
								}

								// my.dataPage.urlRedirection !== "" && my.dataPage.urlRedirection !== null ? setTimeout( window.location.href = my.dataPage.urlRedirection, 12000) : "" ;
							} else if (
								data.responseCode !== "SUCCESS" &&
								data.reference ==
								window.localStorage.getItem("_reference_" + my.url_data.slug)
							) {
								my.isLoadingTresorPay = false;
								my.tresorNOkay = true;
								my.tresorOkay = false;

								my.payment_failure_details = data.message;
							}
						} else if (data.type == "WAVE") {
							if (
								data.status == "success" &&
								data.reference ==
								window.localStorage.getItem("_reference_" + my.url_data.slug)
							) {
								my.modePay = "Wave";
								my.hideRecu = false;
								// my.dataPage.deleg_pdci == "3" ? my.doYangoRechargement() : console.log("Call back") ;
								my.$refs["openWaveModal"].hide();
								// my.modalWave == true ? my.$refs['openWaveModal'].hide() : "" ;
								my.showPaymentSection = false;
								my.sharingSection = false;
								my.waveNOkay = false;
								my.waveOkay = false;

								if (my.typeForm == "normal") {
									my.formPayment = true;
								} else if (my.typeForm == "quickly") {
									my.formQuiclyPayment = true;
								} else {
									my.formSuperQuicklyPayment = true;
								}

								if (my.slug_boutique != null) {
									setTimeout(() => {
										window.location.href =
											"https://shopapaym.weblogy.net/" + my.slug_boutique;
									}, 2000);
									return;
								}

								if (my.url_data.url_redirect != null) {

									// if ( my.dataPage.merchant == '25377' )
									// {
									//     sessionStorage1.setItem("k")
									//     setTimeout(() => {
									//         window.location.href = my.url_data.url_redirect +"/recu/?num_paiement="+ window.sessionStorage.getItem("k");
									//     }, 2000);
									//     return;
									// }
									setTimeout(() => {
										window.location.href = my.url_data.url_redirect;
									}, 2000);
									return;
								}

								if (
									my.dataPage.urlRedirection !== "" &&
									my.dataPage.urlRedirection !== null
								) {
									if (my.dataPage.merchant == '25377') {
										//sessionStorage1.setItem("k")
										setTimeout(() => {
											window.location.href = my.dataPage.urlRedirection + "recu/?num_paiement=" + window.sessionStorage.getItem("k");
										}, 2000);
										return;
									}

									my.dataPage.redirectWithCode == "1"
										? setTimeout(
											(window.location.href =
												my.dataPage.urlRedirection +
												"?code=" +
												sessionStorage1.getItem("k")),
											12000
										)
										: setTimeout(
											(window.location.href = my.dataPage.urlRedirection),
											12000
										);
								}

								// my.dataPage.urlRedirection !== "" && my.dataPage.urlRedirection !== null ? setTimeout( window.location.href = my.dataPage.urlRedirection, 12000) : "" ;
							} else if (
								data.responseCode !== "SUCCESS" &&
								data.reference ==
								window.localStorage.getItem("_reference_" + my.url_data.slug)
							) {
								my.isLoadingWave = false;
								my.waveNOkay = true;
								my.waveOkay = false;
								my.$refs["openWaveModal"].hide();
								// my.modalWave == true ? my.$refs['openWaveModal'].hide() : "" ;
							}
						} else if (data.type == "BICICI") {
							if (
								data.status == "success" &&
								data.reference ==
								window.localStorage.getItem("_reference_" + my.url_data.slug)
							) {
								my.modePay = "Cartes Bancaires";
								my.hideRecu = false;
								// my.dataPage.deleg_pdci == "3" ? my.doYangoRechargement() : console.log("Call back") ;
								my.sharingSection = false;
								// my.$refs['openPaymentModal'].hide();
								// my.modalBicici == true ? my.$refs['openBiciciModal'].hide() : "";
								my.showPaymentSection = false;
								if (my.typeForm == "normal") {
									my.formPayment = true;
								} else if (my.typeForm == "quickly") {
									my.formQuiclyPayment = true;
								} else {
									my.formSuperQuicklyPayment = true;
								}

								if (my.url_data.url_redirect != null) {

									// if ( my.dataPage.merchant == '25377' )
									// {
									//     setTimeout(() => {
									//         window.location.href = my.url_data.url_redirect +"/recu/?num_paiement="+ window.sessionStorage.getItem("k");
									//     }, 2000);
									//     return;
									// }
									setTimeout(() => {
										window.location.href = my.url_data.url_redirect;
									}, 2000);
									return;
								}

								if (
									my.dataPage.urlRedirection !== "" &&
									my.dataPage.urlRedirection !== null
								) {

									if (my.dataPage.merchant == '25377') {
										setTimeout(() => {
											window.location.href = my.dataPage.urlRedirection + "recu/?num_paiement=" + window.sessionStorage.getItem("k");
										}, 2000);
										return;
									}

									my.dataPage.redirectWithCode == "1"
										? setTimeout(
											(window.location.href =
												my.dataPage.urlRedirection +
												"?code=" +
												sessionStorage1.getItem("k")),
											12000
										)
										: setTimeout(
											(window.location.href = my.dataPage.urlRedirection),
											12000
										);
								}

								// my.dataPage.urlRedirection !== "" && my.dataPage.urlRedirection !== null ? setTimeout( window.location.href = my.dataPage.urlRedirection, 12000) : "" ;
							} else if (
								data.responseCode !== "BICICI-SUCCESS" &&
								data.reference ==
								window.localStorage.getItem("_reference_" + my.url_data.slug)
							) {
								my.formBicici = false;
								my.isBicici = false;
								my.cardType = "";
								my.biciciNOkay = true;
								// my.modalBicici == true ? my.$refs['openBiciciModal'].hide() : "";
							}
						}
					}
				} else {
					console.log("Payment Page");
				}
			};

			// Écouteur sur les mises à jour des paiements pour checker le statut
			channel.bind("payment-merchant", response);
		},
		updated() {
			this.mobileDevice = this.isMobile();
			// console.log("Is a mobile device ? "+ this.mobileDevice) ;
		},
		watch: {
			timeLeft(newValue) {
				if (newValue === 0) {
					this.onTimesUp();
					this.showVerifMoov = true;
					this.checkingFloozStatus();
				}
			},
		},
	};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .btn-close-modal {
        position: absolute;
        font-size: 22px;
        color: #1b1e21;
        top: -4px;
        cursor: pointer;
        font-weight: 700;
        left: 102%;
        height: 25px;
        width: 25px;
    }

    .btn-close-modal-small {
        position: absolute;
        color: #1b1e21;
        top: 0;
        cursor: pointer;
        font-weight: 700;
        right: 0;
        /* height: 25px; */
        width: 25px;
        z-index: 50;
    }

    .text-modal-small {
        position: relative;
        color: #1b1e21;
        top: 5px;
        font-size: 16px;
        display: flex;
        justify-content: center;
        white-space: break-spaces;
        align-items: baseline;
    }

    .btn-disabled-custom {
        background: rgb(0 0 0 / 8%);
        border: rgb(0 0 0 / 8%);
        color: #1b1e21;
    }

    .searchIcon {
        /*margin-left: -35px;*/
        cursor: pointer;
        /* color: #261b64; */
        /*color: #343a40;*/
        /*cursor: pointer;*/
        /*font-size: 25px;*/
        /*z-index: 50;*/

        margin-left: -49px;
        cursor: pointer;
        /* color: #261b64; */
        /* color: #343a40; */
        cursor: pointer;
        /* font-size: 25px; */
        z-index: 50;
        height: 35px;
        line-height: normal;
        border-radius: 4px;
    }

    .payment__type {
        cursor: pointer;
        transition: all 0.15s ease;
        margin-right: 0px;
        white-space: nowrap;
    }

    .pdf-content {
        width: 100%;
        background: #fff;
    }

    .pdf-content .report-info {
        display: flex;
        padding: 20px;
        padding-bottom: 0px;
    }

    .pdf-content .report-info .user-details {
        display: flex;
        flex-direction: column;
        flex-shrink: 0;
    }

    /* h3::first-letter{
                        color: #ffdddd !important;
                        font-weight: 900;
                    } */

    .cols,
    .columns {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap;
        margin-left: -0.4rem;
        margin-right: -0.4rem;
    }

    .cols.col-gapless,
    .columns.col-gapless {
        margin-left: 0;
        margin-right: 0;
    }

    .cols.col-gapless > .column,
    .columns.col-gapless > .column {
        padding-left: 0;
        padding-right: 0;
    }

    .cols.col-oneline,
    .columns.col-oneline {
        -webkit-flex-wrap: nowrap;
        flex-wrap: nowrap;
        overflow-x: auto;
    }

    .cols.col-gapless > .column,
    .columns.col-gapless > .column {
        padding-left: 0;
        padding-right: 0;
    }

    .cols.col-oneline,
    .columns.col-oneline {
        -webkit-flex-wrap: nowrap;
        flex-wrap: nowrap;
        overflow-x: auto;
    }

    /* .column,
                    [class~="col-"] {
                        -webkit-flex: 1 1;
                        flex: 1 1;
                        max-width: 100%;
                        padding-left: 0.4rem;
                        padding-right: 0.4rem;
                    } */
    .column.col-1,
    .column.col-2,
    .column.col-3,
    .column.col-4,
    .column.col-5,
    .column.col-6,
    .column.col-7,
    .column.col-8,
    .column.col-9,
    .column.col-10,
    .column.col-11,
    .column.col-12,
    .column.col-auto,
    [class~="col-"].col-1,
    [class~="col-"].col-2,
    [class~="col-"].col-3,
    [class~="col-"].col-4,
    [class~="col-"].col-5,
    [class~="col-"].col-6,
    [class~="col-"].col-7,
    [class~="col-"].col-8,
    [class~="col-"].col-9,
    [class~="col-"].col-10,
    [class~="col-"].col-11,
    [class~="col-"].col-12,
    [class~="col-"].col-auto {
        -webkit-flex: none;
        flex: none;
    }

    .column {
        padding-left: 0.8rem;
        padding-right: 0.8rem;
    }

    @media (max-width: 480px) {
        .main-layout .children .product-column {
            padding: 0 10px;
        }
    }

    @media (max-width: 600px) {
        .main-layout .children .product-column {
            padding: 0 10px;
        }
    }

    .product {
        margin-top: 30px;
    }

    .product {
        border-radius: 6px;
        height: 280px;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
        flex-direction: column;
        cursor: pointer;
    }

    @media (max-width: 480px) {
        .product {
            margin-top: 10px;
        }
    }

    @media (max-width: 600px) {
        .product {
            margin-top: 10px;
        }
    }

    /* .product:hover .product__details__name {
                        text-decoration: underline;
                    } */
    .product__media-container {
        border-radius: 6px 6px 0 0;
        background: #eceaeb;
        -webkit-align-items: center;
        align-items: center;
        -webkit-justify-content: center;
        justify-content: center;
        height: 250px;
        overflow: hidden;
    }

    .product__media-container--single-file {
        display: -webkit-flex;
        display: flex;
        padding: 20px;
    }

    .product__media {
        max-width: 100%;
        max-height: 100%;
        border-radius: 6px;
        object-fit: contain;
        outline: none;
    }

    .product__details {
        background: #fff;
        border-radius: 0 0 6px 6px;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
        flex-direction: column;
        -webkit-justify-content: space-between;
        justify-content: space-between;
        padding: 20px 20px;
        -webkit-flex-grow: 1;
        flex-grow: 1;
    }

    .product__details__price {
        color: #1a1a1a;
        font-weight: 800;
        font-size: 14px;
        line-height: 20px;
        text-decoration: none;
        text-align: center;
    }

    @media (max-width: 600px) {
        .product__details__price {
            text-align: center;
            font-size: 13px;
            line-height: 16px;
        }
    }

    @media (max-width: 480px) {
        .product__details__price {
            text-align: center;
            font-size: 13px;
            line-height: 16px;
        }
    }

    .product__details__name {
        color: #57584e;
        font-weight: 500;
        font-size: 15px;
        line-height: 20px;
        z-index: 2;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        min-height: -webkit-fit-content;
        min-height: -moz-fit-content;
        min-height: fit-content;
        text-align: center;
    }

    .product__details__name a {
        color: #57584e;
    }

    @media (max-width: 600px) {
        .product__details__name {
            text-align: center;
            font-size: 15px;
            line-height: 18px;
        }
    }

    @media (max-width: 480px) {
        .product__details__name {
            text-align: center;
            font-size: 15px;
            line-height: 18px;
        }
    }

    .modal-container .product-view__media--img,
    .product-view__media--img {
        pointer-events: none;
    }

    .modal-container .product-view__media--img,
    .modal-container .product-view__media--video,
    .product-view__media--img,
    .product-view__media--video {
        max-width: 100%;
        max-height: 100%;
        border-radius: 6px;
        object-fit: contain;
        outline: none;
    }

    .blocAbsolute {
        display: flex;
        position: absolute;
        flex-direction: row;
        z-index: 1;
        align-items: center;
        width: 100%;
        padding: 3px;
        justify-content: space-between;
        border-bottom: 1px solid rgb(255, 159, 0);
    }

    .blocNonAbsolute {
        display: flex;
        flex-direction: row;
        z-index: 1;
        align-items: center;
        width: 100%;
        padding: 3px;
        justify-content: space-between;
        border-bottom: 1px solid rgb(255, 159, 0);
    }

    .timer {
        position: relative;
        /* top: 6px; */
        font-size: 28px;
        font-weight: 700;
        color: #db110d;
    }

    .btn-copy-custom {
        background: rgb(53 59 65);
        line-height: 20px;
        display: flex;
        color: #ffffff;
        height: -webkit-fill-available;
    }

    .formules-container {
        width: 100%;
        display: inline-flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        gap: 12px;
    }

    @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
        .formules-container {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-auto-rows: 96px;
            grid-gap: 20px;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
        }

        .tooltip .tooltip-inner {
            display: none !important;
        }

        /* .formules-container::-webkit-scrollbar {
                        display: none;
                    } */
    }

    .formule-bloc {
        position: relative;
        background-color: #f6f6f6;
        border: 1px solid #b2bec3;

        border-radius: 5px;
        width: 135px;
        height: 96px;
        max-height: 100px;
        padding: 5px;
        cursor: pointer;

        font-size: 12px;
        transition: all 0.3s ease-in-out;
    }

    .formule-bloc-disabled {
        position: relative;
        background-color: #eeeeeeb5 !important;
        color: #6a6c6e !important;
        border: 1px solid #b2bec3;

        border-radius: 5px;
        width: 135px;
        height: 96px;
        max-height: 100px;
        padding: 5px;
        cursor: pointer;

        font-size: 12px;
        transition: all 0.3s ease-in-out;
    }

    .formule-bloc:hover {
        transform: scale(1.05);
        background-color: #fdfdfd;
    }

    @media (max-width: 600px) {
        .formule-bloc {
            height: 95px;
            width: 119px;
        }

        .formule-bloc:hover {
            transform: scale(1);
        }
    }

    @media (max-width: 480px) {
        .formule-bloc {
            height: 7rem;
            width: 10rem;
        }

        .formule-bloc:hover {
            transform: scale(1);
        }
    }

    .formule-info {
        border-radius: 5px;
        text-align: center;
        padding: 5px;
        border: 1px solid #dadada;
        margin-top: 20px;
        width: 100%;
    }

    .image-container {
        width: 100%;
        /*height: 250px;*/
        overflow: hidden;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>
